/*
 * ADMIN CHANGE-CANCEL-REQUEST GRID
 */

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";

import { TableData } from "./TableData";
import { TableHeader } from "./TableHeader";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";
import { BrokerApis, RequestApis } from "../../../api";
import { oneMonthDateRangeText } from "../../../utils/CommonUtils";

const Admin_ChangeRequest = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasRecords, setHasRecords] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [listData, setListData] = useState();
  const [searchText, setSearchText] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [brokerList, setBrokerList] = useState([]);
  const [broker, setBroker] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const [insuranceType, setInsuranceType] = useState("");
  const [page, setPage] = useState(1);
  const [requestStatus, setRequestStatus] = useState("OPEN");
  const [requestType, setRequestType] = useState("");
  const [buttonLoading, setButtonLoading] = useState("");
  const [searchButton, setSearchButton] = useState(true);
  const [advancedSearch, setAdvancedSearch] = useState({});

  const propertyMapping = {
    insurance_type: setInsuranceType,
    broker_code: setBroker,
    created_at: (value) =>
      setDateRange(value?.split?.(",")?.map?.((dateStr) => new Date(dateStr))),
    q: setSearchText,
    status: setRequestStatus,
    type_of_change: setRequestType,
  };

  useEffect(() => {
    setSearchParams((searchParams) => {
      if (!searchParams.has("created_at")) {
        searchParams.set("created_at", oneMonthDateRangeText());
      }
      if (!searchParams.has("page")) {
        searchParams.set("page", 1);
      }
      if (!searchParams.has("status")) {
        searchParams.set("status", "OPEN");
      }
      return searchParams;
    });

    const params = [];
    searchParams.forEach((value, key) => {
      params.push([key, value]);
    });
    const obj = params.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    Object.keys(propertyMapping)?.forEach((key) => {
      if (obj?.hasOwnProperty(key)) {
        propertyMapping?.[key]?.(obj?.[key]);
      }
    });

    setPage(Number(obj["page"]));

    const { page, ...rest } = obj;

    setAdvancedSearch(rest);

    const checkRecords = async () => {
      const resp = await RequestApis.getAllChangeRequests();
      setHasRecords(resp?.data?.results?.length > 0);
    };
    checkRecords();
    getBrokerList();
  }, []);

  useEffect(() => {
    if (hasRecords && page && searchButton) {
      allRequestsList();
    }
  }, [hasRecords, page, searchButton]); // Removed sortKey, sortOrder to avoid sorting from API

  const getBrokerList = async () => {
    const params = { sortOrder: "", sortKey: "name" };
    const res = await BrokerApis.getBrokerList(params);
    setBrokerList(
      res?.success ? [{ label: "All", value: 0 }, ...res?.data?.results] : []
    );
  };

  // Get all change/cancel records
  const allRequestsList = async () => {
    setDataLoading(true);
    let params = {
      sortOrder: "",
      sortKey: "",
      page,
      advancedSearch,
    };
    const resp = await RequestApis.getAllChangeRequests(params);
    if (resp) {
      setButtonLoading("");
      setDataLoading(false);
      setSearchButton(false);
      setLoading(false);
    }
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
  };

  return (
    <AdminPageLayout>
      <Typography variant="h1">Change / Cancel Request</Typography>

      <TableHeader
        advancedSearch={advancedSearch}
        setAdvancedSearch={setAdvancedSearch}
        brokerList={brokerList}
        broker={broker}
        setBroker={setBroker}
        dateRange={dateRange}
        setDateRange={setDateRange}
        searchText={searchText}
        setSearchText={setSearchText}
        insuranceType={insuranceType}
        setInsuranceType={setInsuranceType}
        requestStatus={requestStatus}
        requestType={requestType}
        setPage={setPage}
        setSearchButton={setSearchButton}
        buttonLoading={buttonLoading}
        setButtonLoading={setButtonLoading}
        setSearchParams={setSearchParams}
      />

      <TableData
        caption="Change / Cancel Request"
        hasRecords={hasRecords}
        dataList={dataList}
        setDataList={setDataList}
        listData={listData}
        setSearchText={setSearchText}
        setSortKey={setSortKey}
        setSortOrder={setSortOrder}
        page={page}
        setPage={setPage}
        requestStatus={requestStatus}
        setRequestStatus={setRequestStatus}
        requestType={requestType}
        setRequestType={setRequestType}
        loading={loading}
        dataLoading={dataLoading}
        setAdvancedSearch={setAdvancedSearch}
        setSearchButton={setSearchButton}
        setSearchParams={setSearchParams}
      />
    </AdminPageLayout>
  );
};

export { Admin_ChangeRequest };
