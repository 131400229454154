/**
 * All (SAD-IVF) Application APIs
 */

import httpClient from "../utils/HttpUtils";

export const ApplicationApis = {
  getAllPolicies: async (payload) => {
    const { sortOrder, sortKey, page, advancedSearch } = payload ?? {};
    let reqUrl = `/applications`;
    if (payload)
      reqUrl += `?sort=${sortOrder ? "-" : ""}${sortKey}&page=${page}`;

    if (advancedSearch && Object?.keys?.(advancedSearch)?.length > 0) {
      let filtersPart = "";
      for (const [key, value] of Object.entries(advancedSearch)) {
        filtersPart += `&filters[${key}]=${value}`;
      }
      reqUrl += filtersPart;
    }
    try {
      const resp = await httpClient.get(reqUrl);
      return resp;
    } catch (error) {
      console.log("Error getAllPolicies:", error);
    }
  },
};
