/*
 * Admin module -> Wizard -> Certificate Batch Download
 */

import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";

import { TableData } from "./TableData";
import { TableHeader } from "./TableHeader";
import { GoBack } from "../../../components";
import { CertificateBatchApis } from "../../../api";
import { oneMonthDateRangeString } from "../../../utils/CommonUtils";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";

const CertificateBatch = () => {
  const [dataList, setDataList] = useState([]);
  const [listData, setListData] = useState();
  const [searchButton, setSearchButton] = useState(true);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [buttonLoading, setButtonLoading] = useState("");
  const [country, setCountry] = useState("US");
  const [state, setState] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [broker, setBroker] = useState(0);
  const [advancedSearch, setAdvancedSearch] = useState(null);
  // const [advancedSearch, setAdvancedSearch] = useState(
  //   oneMonthDateRangeString("effective_from")
  // );

  useEffect(() => {
    getPolicies();
  }, [page, searchButton]); // Removed sortKey, sortOrder to avoid sorting from API

  const getPolicies = async () => {
    setDataLoading(true);
    let params = {
      sortOrder: "", // Remove "" to sort from API
      sortKey: "", // Remove "" to sort from API
      page,
      advancedSearch,
    };
    const resp = await CertificateBatchApis.getCertificateIssuedPolicies(
      params
    );
    if (resp) {
      setButtonLoading("");
      setDataLoading(false);
      setLoading(false);
    }
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
  };

  return (
    <AdminPageLayout>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <GoBack />
        <Grid item xs={6} sm={7} md={8} lg={9} xl={10}>
          <Typography variant="h1" sx={{ margin: "0" }}>
            Certificate Batch Download
          </Typography>
        </Grid>
      </Box>

      <TableHeader
        setPage={setPage}
        searchButton={searchButton}
        setSearchButton={setSearchButton}
        buttonLoading={buttonLoading}
        setButtonLoading={setButtonLoading}
        country={country}
        setCountry={setCountry}
        state={state}
        setState={setState}
        stateList={stateList}
        setStateList={setStateList}
        broker={broker}
        setBroker={setBroker}
        setAdvancedSearch={setAdvancedSearch}
      />
      <TableData
        caption="Certificate Batch Download"
        dataList={dataList}
        listData={listData}
        stateList={stateList}
        page={page}
        setPage={setPage}
        loading={loading}
        setLoading={setLoading}
        dataLoading={dataLoading}
      />
    </AdminPageLayout>
  );
};

export { CertificateBatch };
