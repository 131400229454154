/*
 * Common description-cell component for long notes for all grids
 */

import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Tooltip } from "../Tooltip";

const RenderDescriptionCell = ({
  description,
  cellWidth,
  visibleLength = 20,
}) => {
  const [expanded, setExpanded] = useState(false);
  const truncatedDescription = expanded
    ? description
    : description?.slice?.(0, visibleLength) +
      (description?.length > visibleLength ? "..." : "");

  return (
    <div className="text-center">
      <Typography sx={{ wordWrap: "break-word", width: cellWidth ?? "18rem" }}>
        {truncatedDescription || ""}
        {description?.length > visibleLength && (
          <Tooltip title={expanded ? "Show less" : "Show more"}>
            <span
              style={{ cursor: "pointer", color: "#0A7691" }}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? " (less)" : " (more)"}
            </span>
          </Tooltip>
        )}
      </Typography>
    </div>
  );
};

export { RenderDescriptionCell };
