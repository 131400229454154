/*
 * SAD ADMIN GRID DATA
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Box,
  Grid,
  IconButton,
  Checkbox,
  Typography,
  CircularProgress,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RequestPageIcon from "@mui/icons-material/RequestPage";

import {
  CommonModal,
  Dropdown,
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderChip,
  RenderDocumentIcon,
  RenderTableHeader,
  Tooltip,
} from "../../../../components";
import ReactTable from "../../../../React Table";
import { AllRoutes } from "../../../../routes";
import {
  formatString,
  formattedMDYDate,
  isInvoiceVisible,
} from "../../../../utils/CommonUtils";
import { PaymentTypes, PolicyDocTypes, PolicyTypes } from "../../constants";
import { ActionApis, AttachmentApis, SadApis } from "../../../../api";
import { setSadSummary } from "../../../../redux/slices/globalSlice";
import { coverageTypeLabel, tableNames } from "../../../../constants";
import { theme } from "../../../../styles/theme";

const { canceled, underReview } = PolicyTypes;

const TableData = ({
  caption,
  dataList,
  listData,
  setSearchText,
  setSortKey,
  setSortOrder,
  brokerDropdown,
  page,
  setPage,
  loading,
  dataLoading,
  setDataList,
  setSearchButton,
  setSearchParams,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [documentLink, setDocumentLink] = useState("");
  const [updateModal, setUpdateModal] = useState({ status: false, text: "" });
  const [actionLoading, setActionLoading] = useState(false);
  const [hiddenTitle, setHiddenTitle] = useState("");

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  const updateApplication = async (application_id, params) => {
    setActionLoading(true);
    const res = await SadApis.updateApplication({ application_id, params });
    if (res?.success) {
      setDataList((prev) =>
        prev?.map((item) => (item?.id === res?.data?.id ? res?.data : item))
      );
    }
    setActionLoading(false);
  };

  const openActionDropDown = (e, rowData) => {
    const {
      id: application_id,
      allowed_actions,
      alerts,
      certificate_issued_at,
      status,
    } = rowData || {};
    const { invoice, application, certificate } = PolicyDocTypes;

    let fixedActions = [
      // {
      //   text: "Edit Alert Status",
      //   route: () => {
      //     dispatch(setSadSummary({}));
      //     navigate(`${AllRoutes.Sad_EditStatus.route}/${application_id}`);
      //   },
      // },
      {
        text: "Edit Application",
        route: () => {
          dispatch(setSadSummary({}));
          navigate(`${AllRoutes.Sad_EditApplication.route}/${application_id}`);
        },
      },
      {
        text: "Email Application",
        route: () => getPolicyDocs(application_id, application),
      },
    ];

    if (certificate_issued_at) {
      fixedActions?.push({
        text: "Email Certificate",
        route: () => getPolicyDocs(application_id, certificate),
      });
    }

    if (isInvoiceVisible(alerts, status)) {
      fixedActions?.push({
        text: "Email Invoice",
        route: () => getPolicyDocs(application_id, invoice),
      });
    }

    const allowedActions = allowed_actions?.map((item) => ({
      text: item.label,
      route: () => policyAction(application_id, item.value),
    }));

    setClickable([...fixedActions, ...allowedActions]);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const getPolicyDocs = async (application_id, policy_doc_id, showIframe) => {
    !showIframe && setActionLoading(true);
    if (showIframe) {
      setShowDocumentModal(true);
      setHiddenTitle(formatString(policy_doc_id));
    }

    let payload = { application_id, policy_doc_id };
    if (!showIframe) {
      payload = { ...payload, send_email: true };
    }
    const resp = await AttachmentApis.getPolicyDoc(payload);
    if (resp) {
      setActionLoading(false);
      showIframe
        ? setDocumentLink(resp)
        : setUpdateModal({ status: true, text: "Email sent successfully!" });
    } else {
      setActionLoading(false);
      setShowDocumentModal(false);
    }
  };

  const policyAction = async (application_id, action) => {
    setActionLoading(true);
    const resp = await ActionApis.updatePolicyAction({
      application_id,
      action,
    });
    !!resp && setActionLoading(false);
    if (resp?.success) {
      setSearchButton((p) => !p);
      setUpdateModal({ status: true, text: "Status updated successfully" });
    }
  };

  const tableColumns = [
    {
      id: "Action",
      header: "Action",
      cell: ({ row }) => {
        const { status } = row?.original ?? {};
        return (
          <IconButton
            disabled={status === canceled}
            onClick={(e) => openActionDropDown(e, row.original)}
            aria-label={"More actions of " + row.original?.application_number}
          >
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
    {
      id: "Policy Status",
      accessorKey: "status",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Status"
          headerKey="status"
        />
      ),
      cell: (info) => {
        const status = info.getValue();
        return (
          <RenderChip
            label={status === underReview ? "Submitted" : undefined}
            status={status}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Policy Number",
      header: "Policy Number",
      accessorKey: "application_number",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Number"
          headerKey="application_number"
        />
      ),
      cell: (info) => (
        <RenderCellInfo text={info.getValue()} sx={{ color: "#0A7691" }} />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Coverage Type",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Coverage Type"
          headerKey="coverage_type"
        />
      ),
      accessorKey: "coverage_type",
      cell: (info) => {
        const { coverage_type, insurance_type } = info.row?.original;
        return (
          <RenderCellInfo
            text={coverageTypeLabel(insurance_type, coverage_type)}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Alert Status",
      header: "Alert Status",
      accessorKey: "alerts",
      cell: (info) => {
        const alertStatus = info.getValue()?.length
          ? info
              .getValue()
              ?.map((alert, index) => (
                <div key={index}> {`${alert ?? ""}`}</div>
              ))
          : "";
        return info.getValue()?.length === 0 ? (
          <div className="text-center">
            <Tooltip title="No Alerts">
              <FiberManualRecordIcon
                fontSize="small"
                className="none-text-grey"
                title={undefined}
              />
            </Tooltip>
            <span className="hidden-visually">No Alerts</span>
          </div>
        ) : (
          <div className="text-center">
            <Tooltip tooltip={alertStatus}>
              <FiberManualRecordIcon
                fontSize="small"
                className="none-text-red"
                title={undefined}
              />
            </Tooltip>
            <div className="hidden-visually">{alertStatus}</div>
          </div>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Status",
      header: "Payment Status",
      accessorKey: "payment_status",
      cell: (info) => {
        const { invoice } = info.row.original ?? {};
        const paymentStatus = invoice?.payment_status;
        let icon;

        if (paymentStatus == PaymentTypes.paid) {
          icon = (
            <>
              <Tooltip title={"Paid"}>
                <CheckCircleIcon
                  fontSize="small"
                  className="none-text-green"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Paid</span>
            </>
          );
        } else if (paymentStatus === PaymentTypes.pending) {
          icon = (
            <>
              <Tooltip title={"Pending"}>
                <WatchLaterIcon
                  fontSize="small"
                  className="none-text-orange"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Pending</span>
            </>
          );
        } else {
          icon = (
            <>
              <Tooltip title={"Unpaid"}>
                <CancelIcon
                  fontSize="small"
                  className="none-text-red"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Unpaid</span>
            </>
          );
        }
        return (
          <div style={{ textAlign: "center", margin: "auto" }}>{icon}</div>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Documents",
      header: "Documents",
      accessorKey: "Documents",
      cell: (info) => {
        const { invoice, application, certificate } = PolicyDocTypes;
        const { alerts, certificate_issued_at, status } =
          info.row.original ?? {};

        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <RenderDocumentIcon
                    tooltip="Application"
                    policy_doc_id={application}
                    icon={DescriptionIcon}
                    info={info}
                    getPolicyDocs={getPolicyDocs}
                  />
                  {!!certificate_issued_at && (
                    <RenderDocumentIcon
                      tooltip="Certificate"
                      policy_doc_id={certificate}
                      icon={PictureAsPdfIcon}
                      info={info}
                      getPolicyDocs={getPolicyDocs}
                    />
                  )}
                  {isInvoiceVisible(alerts, status) && (
                    <RenderDocumentIcon
                      tooltip="Invoice"
                      policy_doc_id={invoice}
                      icon={RequestPageIcon}
                      info={info}
                      getPolicyDocs={getPolicyDocs}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "State",
      accessorKey: "intended_parent_state",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="State"
          headerKey="intended_parent_state"
        />
      ),
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "State Filed",
      header: "State Filed",
      accessorKey: "state_filed",
      cell: (info) => {
        const { application_number, id, status, state_filed } =
          info?.row?.original ?? {};
        return (
          <RenderCellInfo
            text={
              <Checkbox
                key={id || ""}
                disabled={status === canceled}
                checked={info.getValue()}
                onChange={() =>
                  updateApplication(id, { state_filed: Number(!state_filed) })
                }
                inputProps={{
                  "aria-label": "State Filed for " + application_number,
                }}
              />
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Applicant Name & Email",
      accessorKey: "intended_parent1_first_name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Applicant Name & Email"
          headerKey="intended_parent1_first_name"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={
            <Box>
              <Typography>
                {info?.row?.original?.intended_parent1_first_name || ""}
              </Typography>
              <Typography>
                {info?.row?.original?.intended_parent1_email || ""}
              </Typography>
            </Box>
          }
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "IP Last Name",
      accessorKey: "intended_parent1_last_name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="IP Last Name"
          headerKey="intended_parent1_last_name"
        />
      ),
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Surrogate Last Name",
      accessorKey: "surrogate_last_name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Surrogate Last Name"
          headerKey="surrogate_last_name"
        />
      ),
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Agency",
      accessorKey: "agency_name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Agency"
          headerKey="agency_name"
        />
      ),
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Broker",
      header: "Broker",
      accessorKey: "broker_code",
      cell: (info) => {
        const { id, status } = info?.row?.original ?? {};
        return (
          <Dropdown
            size="small"
            id="broker"
            options={brokerDropdown ?? []}
            required={false}
            disabled={status === canceled}
            selectLabel="name"
            selectValue="code"
            value={info.getValue()}
            onChange={(e) =>
              updateApplication(id, { broker_code: e.target.value })
            }
            margin="none"
            sx={{ backgroundColor: theme.white }}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Application Date",
      accessorKey: "submitted_on",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Application Date"
          headerKey="submitted_on"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "MSD/COP",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="MSD/COP"
          headerKey="confirmation_of_pregnancy_date"
        />
      ),
      cell: (info) => {
        const {
          is_currently_pregnant,
          medication_start_date,
          confirmation_of_pregnancy_date,
        } = info?.row?.original;
        return (
          <RenderCellInfo
            text={formattedMDYDate(
              is_currently_pregnant
                ? confirmation_of_pregnancy_date
                : medication_start_date
            )}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Date",
      accessorKey: "payment_date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Payment Date"
          headerKey="payment_date"
        />
      ),
      cell: (info) => {
        const { invoice } = info?.row?.original;
        const paymentDate = invoice?.payment_date;
        return (
          <RenderCellInfo
            text={paymentDate ? formattedMDYDate(paymentDate) : ""}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Effective Date",
      header: "Effective Date",
      accessorKey: "effective_from",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Effective Date"
          headerKey="effective_from"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Expiration Date",
      header: "Expiration Date",
      accessorKey: "effective_till",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Expiration Date"
          headerKey="effective_till"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
  ];

  if (loading) return <GlobalLoader loading={loading} />;

  return (
    <>
      {dataList?.length > 0 ? (
        <Box>
          <ReactTable
            tableName={tableNames.sadAdmin}
            tableColumns={tableColumns}
            tableData={dataList}
            page={page}
            setPage={handlePageChange}
            listData={listData}
            onSearch={setSearchText}
            caption={caption}
            isColumnCustomizingRequired={true}
            dataLoading={dataLoading}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
          />
        </Box>
      ) : (
        <NoRecords />
      )}

      {actionLoading && <GlobalLoader loading={actionLoading} />}

      {/* Documents modal */}
      <CommonModal
        open={showDocumentModal}
        onCancel={() => {
          setShowDocumentModal(false);
          setDocumentLink("");
          setHiddenTitle("");
        }}
        cancelButtonLabel="Close"
        maxWidth="md"
        visuallyHiddenTitle={hiddenTitle}
        fullWidth
      >
        {!documentLink ? (
          <Box
            sx={{
              width: "100%",
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <iframe
            src={documentLink}
            width="100%"
            height="500"
            style={{ display: !documentLink ? "none" : "block" }}
          />
        )}
      </CommonModal>

      {/* Modal for successful email of invoice, application, certificate */}
      <CommonModal
        open={!!updateModal.status}
        type="success"
        onConfirm={() => setUpdateModal({ status: false, text: "" })}
        title={updateModal.text}
      />
    </>
  );
};

export { TableData };
