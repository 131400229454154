import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import { CommonModal, DatePicker, Tooltip } from "../../../../components";
import { useSelector } from "react-redux";
import DropdownIVF from "./common/Dropdown";
import { AttachmentApis } from "../../../../api";
import TextInputIvf from "./common/TextInput";
import { FilePickerDonor } from "../components/FilePickerdonor";
import { FilePickerRecipient } from "../components/FilePickerRecipient";
import { useDispatch } from "react-redux";
import { setIvfDocs } from "../../../../redux/slices/globalSlice";
import { natureOfComplicationsTooltip } from "../../../../constants";

const DonorAndRecipient = ({
  id,
  index,
  disabled = false,
  register,
  coverageTypeSelected,
  setValue,
  watch,
  errors,
  setError,
  application_id,
}) => {
  const dispatch = useDispatch();
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const docs = useSelector((state) => state.ivfReducer.ivfDocs);
  const [cycleModal, setCycleModal] = useState(false);
  const [donorAgeModal, setDonorAgeModal] = useState(false);
  const [recipientAgeModal, setRecipientAgeModal] = useState(false);
  const [hasDonorCycledBefore, setHasDonorCycledBefore] = useState("");
  const [hasDonorCyledComplicationBefore, setHasDonorCyledComplicationBefore] =
    useState("");
  const [hasRecipientCyledBefore, setHasRecipientCycledBefore] = useState("");
  const [
    hasRecipientCyledComplicationBefore,
    setHasRecipientCycledComplicationBefore,
  ] = useState("");
  const [files, setFiles] = useState([]);
  const [isValidFile, setIsValidFile] = useState(true);
  const [fileToBeDeleted, setFileToBeDeleted] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDocUploading, setDocUploading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState("");
  const [docRequired, setDocRequired] = useState("");

  const [files2, setFiles2] = useState([]);
  const [isValidFile2, setIsValidFile2] = useState(true);
  const [fileToBeDeleted2, setFileToBeDeleted2] = useState();
  const [showDeleteModal2, setShowDeleteModal2] = useState(false);
  const [isDocUploading2, setDocUploading2] = useState(false);
  const [buttonLoading2, setButtonLoading2] = useState("");
  const [docRequired2, setDocRequired2] = useState("");

  const openPdfInNewTab = (file) => {
    !!file?.url && window.open(file?.url, "_blank");
  };
  const openPdfInNewTab2 = (file) => {
    !!file?.url && window.open(file?.url, "_blank");
  };

  const removePdf = async () => {
    setButtonLoading("delete");
    setIsValidFile(true);
    const resp = await AttachmentApis.deleteAttachment({
      application_id: id,
      document_id: fileToBeDeleted,
    });
    !resp?.success && setButtonLoading("");
    !!resp?.success && getAttachments();
    setShowDeleteModal(false);
    setFileToBeDeleted();
  };
  const removePdf2 = async () => {
    setButtonLoading2("delete");
    setIsValidFile2(true);
    const resp = await AttachmentApis.deleteAttachment({
      application_id: id,
      document_id: fileToBeDeleted2,
    });
    !resp?.success && setButtonLoading2("");
    !!resp?.success && getAttachments2();
    setShowDeleteModal2(false);
    setFileToBeDeleted2();
  };
  const docId = application_id ? application_id : id;
  const getAttachments = async () => {
    const resp = await AttachmentApis.getAttachments({ application_id: docId });
    if (resp) {
      setDocUploading(false);
      setButtonLoading("");
    }
    if (resp?.success && resp?.data?.length) {
      const formattedFiles = resp.data?.map((file) => ({
        name: file.original_name,
        type: "",
        size: 0,
        id: file.id,
        url: file.url,
        labelled_as: file.labelled_as,
      }));
      setFiles(formattedFiles);
      dispatch(setIvfDocs(formattedFiles, `donor_${id}`));
    } else {
      setFiles([]);
      dispatch(setIvfDocs([], docId));
    }
  };
  const getAttachments2 = async () => {
    const resp = await AttachmentApis.getAttachments({ application_id: docId });
    if (resp) {
      setDocUploading2(false);
      setButtonLoading2("");
    }
    if (resp?.success && resp?.data?.length) {
      const formattedFiles = resp.data?.map((file) => ({
        name: file.original_name,
        type: "",
        size: 0,
        id: file.id,
        url: file.url,
        labelled_as: file.labelled_as,
      }));
      setFiles2(formattedFiles);
      dispatch(setIvfDocs(formattedFiles, `recipient_${id}`));
    } else {
      setFiles2([]);
      dispatch(setIvfDocs([], docId));
    }
  };
  const handleFileUpload = async (newFile) => {
    setDocUploading(true);
    const formData = new FormData();
    formData.append("group", "documents");
    formData.append("labelled_as", `DOC_donor_${id}`);
    formData.append("file", newFile?.[0]);
    const resp = await AttachmentApis.uploadAttachment({
      application_id: id,
      formData,
      labelled_as: `DOC_donor_${id}`,
    });
    !resp?.success && setDocUploading(false);
    if (resp?.success) {
      setDocRequired("");
      getAttachments();
    }
  };
  const handleFileUpload2 = async (newFile) => {
    setDocUploading2(true);
    const formData = new FormData();
    formData.append("group", "documents");
    formData.append("labelled_as", `DOC_recipient_${id}`);
    formData.append("file", newFile?.[0]);
    const resp = await AttachmentApis.uploadAttachment({
      application_id: id,
      formData,
      labelled_as: `DOC_recipient_${id}`,
    });
    !resp?.success && setDocUploading2(false);
    if (resp?.success) {
      setDocRequired2("");
      getAttachments2();
    }
  };

  const getDocs = () => {
    if (coverageTypeSelected == "RO") {
      getAttachments2();
    } else if (coverageTypeSelected == "EDO") {
      getAttachments();
    } else {
      getAttachments();
      getAttachments2();
    }
  };

  useEffect(() => {
    if (application_id || docs.length > 0) {
      getDocs();
    }
  }, [application_id]);

  useEffect(() => {
    setHasDonorCycledBefore(
      watch(`applications.${index}.donor_has_cycled_before`)
    );
    setHasDonorCyledComplicationBefore(
      watch(`applications.${index}.donor_has_cycled_before_complications`)
    );
  }, [
    watch(`applications.${index}.donor_has_cycled_before_complications`),
    watch(`applications.${index}.donor_has_cycled_before`),
  ]);

  useEffect(() => {
    setHasRecipientCycledBefore(
      watch(`applications.${index}.recipient_has_cycled_before`)
    );
    setHasRecipientCycledComplicationBefore(
      watch(`applications.${index}.recipient_has_cycled_before_complications`)
    );
  }, [
    watch(`applications.${index}.recipient_has_cycled_before_complications`),
    watch(`applications.${index}.recipient_has_cycled_before`),
  ]);

  return (
    <>
      {/* Donor */}

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <TextInputIvf
          register={register}
          disabled={disabled}
          setError={setError}
          isError={errors?.applications?.[index]?.[`donor_name_id`]?.message}
          errorMsg={errors?.applications?.[index]?.[`donor_name_id`]?.message}
          id={`applications.${index}.donor_name_id`}
          name={`applications.${index}.donor_name_id`}
          type="text"
          label="Name/ID of Donor"
          InputLabelProps={{ shrink: true }}
          sx={{ marginTop: "16px", width: "100%" }}
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DatePicker
          label="Medication Start Date of Donor"
          id={`applications.${index}.donor_medication_start_date`}
          name={`applications.${index}.donor_medication_start_date`}
          disabled={disabled}
          onChange={(date) => {
            setError(`applications.${index}.donor_medication_start_date`, null);
            setValue(`applications.${index}.donor_medication_start_date`, date);
          }}
          isError={
            errors?.applications?.[index]?.[`donor_medication_start_date`]
              ?.message
          }
          errorMsg={
            errors?.applications?.[index]?.[`donor_medication_start_date`]
              ?.message
          }
          minDate={dayjs()}
          value={
            watch(`applications.${index}.donor_medication_start_date`)
              ? dayjs(
                  watch(`applications.${index}.donor_medication_start_date`)
                )
              : undefined
          }
          maxDate={dayjs().add(60, "day")}
          tooltip="The MSD can be selected no more than 60 days in the future of the submission date."
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DatePicker
          label="DOB of Donor"
          id={`applications.${index}.donor_dob`}
          name={`applications.${index}.donor_dob`}
          disabled={disabled}
          value={
            watch(`applications.${index}.donor_dob`)
              ? dayjs(watch(`applications.${index}.donor_dob`))
              : undefined
          }
          onChange={(date) => {
            setError(`applications.${index}.donor_dob`, null);
            setValue(`applications.${index}.donor_dob`, date);
            const age = dayjs().diff(date, "year");
            if (age < 21) {
              setDonorAgeModal(true);
            }
          }}
          defaultCalendarMonth={dayjs().subtract(21, "years")}
          isError={errors?.applications?.[index]?.[`donor_dob`]?.message}
          errorMsg={errors?.applications?.[index]?.[`donor_dob`]?.message}
          disableFuture
          tooltip="Minimum age should be 21 years."
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DropdownIVF
          label="Has Donor cycled before?"
          id={`applications.${index}.donor_has_cycled_before`}
          name={`applications.${index}.donor_has_cycled_before`}
          disabled={disabled}
          options={lookups?.yes_no_list}
          value={watch(`applications.${index}.donor_has_cycled_before`)}
          {...register(`applications.${index}.donor_has_cycled_before`, {
            onChange: (e) => {
              setHasDonorCycledBefore(e.target.value);
              setValue(
                `applications.${index}.donor_has_cycled_before`,
                e.target.value
              );
              setValue(`applications.${index}.donor_num_previous_cycles`, "");
              setValue(
                `applications.${index}.donor_has_cycled_before_complications`,
                null
              );
              setValue(
                `applications.${index}.donor_cycle_complications_description`,
                ""
              );
            },
          })}
          isError={
            errors?.applications?.[index]?.[`donor_has_cycled_before`]?.message
          }
          errorMsg={
            errors?.applications?.[index]?.[`donor_has_cycled_before`]?.message
          }
        />
      </Grid>

      {hasDonorCycledBefore === 1 && (
        <>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <TextInputIvf
              register={register}
              disabled={disabled}
              setError={setError}
              isError={
                errors?.applications?.[index]?.[`donor_num_previous_cycles`]
                  ?.message
              }
              errorMsg={
                errors?.applications?.[index]?.[`donor_num_previous_cycles`]
                  ?.message
              }
              id={`applications.${index}.donor_num_previous_cycles`}
              name={`applications.${index}.donor_num_previous_cycles`}
              type="number"
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              inputProps={{
                min: 1,
                onInput: (e) => {
                  if (e.target.value < 1) {
                    e.target.value = "";
                  }
                },
              }}
              label="Number of Previous Cycles?"
              InputLabelProps={{ shrink: true }}
              sx={{ marginTop: "16px", width: "100%" }}
              onRegisterChange={(e) => {
                if (
                  `applications.${index}.donor_num_previous_cycles` &&
                  e.target.value > 6
                ) {
                  setCycleModal(true);
                }
              }}
            />
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <DropdownIVF
              label="Has the Donor had Previous Cycle Related Complications?"
              id={`applications.${index}.donor_has_cycled_before_complications`}
              name={`applications.${index}.donor_has_cycled_before_complications`}
              disabled={disabled}
              options={lookups?.yes_no_list}
              value={watch(
                `applications.${index}.donor_has_cycled_before_complications`
              )}
              {...register(
                `applications.${index}.donor_has_cycled_before_complications`,
                {
                  onChange: (e) => {
                    setHasDonorCyledComplicationBefore(e.target.value);
                    setValue(
                      `applications.${index}.donor_has_cycled_before_complications`,
                      e.target.value
                    );
                    setError(
                      `applications.${index}.donor_has_cycled_before_complications`,
                      null
                    );
                    setValue(
                      `applications.${index}.donor_cycle_complications_description`,
                      ""
                    );
                  },
                }
              )}
              isError={
                errors?.applications?.[index]?.[
                  `donor_has_cycled_before_complications`
                ]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.[
                  `donor_has_cycled_before_complications`
                ]?.message
              }
            />
          </Grid>

          {hasDonorCyledComplicationBefore === 1 && (
            <>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                paddingTop={"18px"}
              >
                <TextInputIvf
                  register={register}
                  name={`applications.${index}.donor_cycle_complications_description`}
                  id={`applications.${index}.donor_cycle_complications_description`}
                  disabled={disabled}
                  label="Please Describe Nature of Complications"
                  multiline
                  rows={3}
                  sx={{ width: "100%" }}
                  setError={setError}
                  isError={
                    errors?.applications?.[index]?.[
                      `donor_cycle_complications_description`
                    ]?.message
                  }
                  errorMsg={
                    errors?.applications?.[index]?.[
                      `donor_cycle_complications_description`
                    ]?.message
                  }
                  tooltip={natureOfComplicationsTooltip}
                />
              </Grid>
              <Box
                sx={{
                  display:
                    hasDonorCyledComplicationBefore == 1 ? "block" : "none",
                }}
                alignItems="center"
                width="100%"
                marginY="2rem"
                mt={3}
                px={3}
              >
                <Typography
                  marginBottom="1rem"
                  id={`lbl-upload-file-donor_${application_id}`}
                  fontSize="14px"
                >
                  Please upload the supporting documents.
                  <Tooltip tooltip="File can only be type of pdf / word" />
                </Typography>
                <FilePickerDonor
                  disabled={disabled}
                  files={files}
                  setFiles={setFiles}
                  setIsValidFile={setIsValidFile}
                  openPdfInNewTab={openPdfInNewTab}
                  setFileToBeDeleted={setFileToBeDeleted}
                  setShowDeleteModal={setShowDeleteModal}
                  handleFileUpload={(newFile) => handleFileUpload(newFile)}
                  isDocUploading={isDocUploading}
                  id={`donor_${id}`}
                  labelled_as_id={id}
                />
                <FormHelperText
                  sx={{
                    marginLeft: 0,
                    fontStyle: "italic",
                    fontWeight: 600,
                    letterSpacing: 0,
                    marginTop: "1rem",
                  }}
                  id="helper-text-upload-file"
                >
                  Note:{" "}
                  {isValidFile
                    ? "Two Files Max"
                    : "Only pdf and word file allowed"}
                </FormHelperText>
                <FormHelperText error sx={{ marginLeft: 0 }} id={"error-doc"}>
                  {docRequired}
                </FormHelperText>
              </Box>
            </>
          )}
        </>
      )}

      {/* Recipient */}

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <TextInputIvf
          register={register}
          disabled={disabled}
          setError={setError}
          isError={
            errors?.applications?.[index]?.[`recipient_name_id`]?.message
          }
          errorMsg={
            errors?.applications?.[index]?.[`recipient_name_id`]?.message
          }
          id={`applications.${index}.recipient_name_id`}
          name={`applications.${index}.recipient_name_id`}
          type="text"
          label="Name/ID of Recipient"
          InputLabelProps={{ shrink: true }}
          sx={{ marginTop: "16px", width: "100%" }}
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DatePicker
          label="Medication Start Date of Recipient"
          id={`applications.${index}.recipient_medication_start_date`}
          name={`applications.${index}.recipient_medication_start_date`}
          disabled={disabled}
          onChange={(date) => {
            setError(
              `applications.${index}.recipient_medication_start_date`,
              null
            );
            setValue(
              `applications.${index}.recipient_medication_start_date`,
              date
            );
          }}
          isError={
            errors?.applications?.[index]?.[`recipient_medication_start_date`]
              ?.message
          }
          errorMsg={
            errors?.applications?.[index]?.[`recipient_medication_start_date`]
              ?.message
          }
          minDate={dayjs()}
          value={
            watch(`applications.${index}.recipient_medication_start_date`)
              ? dayjs(
                  watch(`applications.${index}.recipient_medication_start_date`)
                )
              : undefined
          }
          maxDate={dayjs().add(60, "day")}
          tooltip={
            "The MSD can be selected no more than 60 days in the future of the submission date."
          }
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DatePicker
          label="DOB of Recipient"
          id={`applications.${index}.recipient_dob`}
          name={`applications.${index}.recipient_dob`}
          disabled={disabled}
          value={
            watch(`applications.${index}.recipient_dob`)
              ? dayjs(watch(`applications.${index}.recipient_dob`))
              : undefined
          }
          onChange={(date) => {
            setError(`applications.${index}.recipient_dob`, null);
            setValue(`applications.${index}.recipient_dob`, date);
            const age = dayjs().diff(date, "year");
            if (age < 21) {
              setRecipientAgeModal(true);
            }
          }}
          defaultCalendarMonth={dayjs().subtract(21, "years")}
          isError={errors?.applications?.[index]?.[`recipient_dob`]?.message}
          errorMsg={errors?.applications?.[index]?.[`recipient_dob`]?.message}
          disableFuture
          tooltip="Minimum age should be 21 years."
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DropdownIVF
          label="Has Recipient cycled before?"
          id={`applications.${index}.recipient_has_cycled_before`}
          name={`applications.${index}.recipient_has_cycled_before`}
          disabled={disabled}
          options={lookups?.yes_no_list}
          value={watch(`applications.${index}.recipient_has_cycled_before`)}
          {...register(`applications.${index}.recipient_has_cycled_before`, {
            onChange: (e) => {
              setHasRecipientCycledBefore(e.target.value);
              setError(
                `applications.${index}.recipient_has_cycled_before`,
                null
              );
              setValue(
                `applications.${index}.recipient_has_cycled_before`,
                e.target.value
              );
              setValue(
                `applications.${index}.recipient_num_previous_cycles`,
                ""
              );
              setValue(
                `applications.${index}.recipient_has_cycled_before_complications`,
                null
              );
              setValue(
                `applications.${index}.recipient_cycle_complications_description`,
                ""
              );
            },
          })}
          isError={
            errors?.applications?.[index]?.[`recipient_has_cycled_before`]
              ?.message
          }
          errorMsg={
            errors?.applications?.[index]?.[`recipient_has_cycled_before`]
              ?.message
          }
        />
      </Grid>

      {hasRecipientCyledBefore === 1 && (
        <>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <TextInputIvf
              register={register}
              disabled={disabled}
              setError={setError}
              isError={
                errors?.applications?.[index]?.[`recipient_num_previous_cycles`]
                  ?.message
              }
              errorMsg={
                errors?.applications?.[index]?.[`recipient_num_previous_cycles`]
                  ?.message
              }
              id={`applications.${index}.recipient_num_previous_cycles`}
              name={`applications.${index}.recipient_num_previous_cycles`}
              type="number"
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              inputProps={{
                min: 1,
                onInput: (e) => {
                  if (e.target.value < 1) {
                    e.target.value = "";
                  }
                },
              }}
              label="Number of Previous Cycles?"
              InputLabelProps={{ shrink: true }}
              sx={{ marginTop: "16px", width: "100%" }}
            />
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <DropdownIVF
              label="Has the Recipient had Previous Cycle Related Complications?"
              id={`applications.${index}.recipient_has_cycled_before_complications`}
              name={`applications.${index}.recipient_has_cycled_before_complications`}
              disabled={disabled}
              options={lookups?.yes_no_list}
              value={watch(
                `applications.${index}.recipient_has_cycled_before_complications`
              )}
              {...register(
                `applications.${index}.recipient_has_cycled_before_complications`,
                {
                  onChange: (e) => {
                    setHasRecipientCycledComplicationBefore(e.target.value);
                    setValue(
                      `applications.${index}.recipient_has_cycled_before_complications`,
                      e.target.value
                    );
                    setError(
                      `applications.${index}.recipient_has_cycled_before_complications`,
                      null
                    );
                    setValue(
                      `applications.${index}.recipient_cycle_complications_description`,
                      ""
                    );
                  },
                }
              )}
              isError={
                errors?.applications?.[index]?.[
                  `recipient_has_cycled_before_complications`
                ]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.[
                  `recipient_has_cycled_before_complications`
                ]?.message
              }
            />
          </Grid>

          {hasRecipientCyledComplicationBefore === 1 && (
            <>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                paddingTop={"18px"}
              >
                <TextInputIvf
                  register={register}
                  name={`applications.${index}.recipient_cycle_complications_description`}
                  id={`applications.${index}.recipient_cycle_complications_description`}
                  disabled={disabled}
                  label="Please Describe Nature of Complications"
                  multiline
                  rows={3}
                  sx={{ width: "100%" }}
                  setError={setError}
                  isError={
                    errors?.applications?.[index]?.[
                      `recipient_cycle_complications_description`
                    ]?.message
                  }
                  errorMsg={
                    errors?.applications?.[index]?.[
                      `recipient_cycle_complications_description`
                    ]?.message
                  }
                  tooltip={natureOfComplicationsTooltip}
                />
              </Grid>
              <Box
                sx={{
                  display:
                    hasRecipientCyledComplicationBefore == 1 ? "block" : "none",
                }}
                alignItems="center"
                width="100%"
                marginY="2rem"
                mt={3}
                px={3}
              >
                <Typography
                  marginBottom="1rem"
                  id={`lbl-upload-file-recipient_${application_id}`}
                  fontSize="14px"
                >
                  Please upload the supporting documents.
                  <Tooltip tooltip="File can only be type of pdf / word" />
                </Typography>
                <FilePickerRecipient
                  disabled={disabled}
                  files={files2}
                  setFiles={setFiles2}
                  setIsValidFile={setIsValidFile2}
                  openPdfInNewTab={openPdfInNewTab2}
                  setFileToBeDeleted={setFileToBeDeleted2}
                  setShowDeleteModal={setShowDeleteModal2}
                  handleFileUpload={(newFile) => handleFileUpload2(newFile)}
                  isDocUploading={isDocUploading2}
                  id={`recipient_${id}`}
                  labelled_as_id={id}
                />
                <FormHelperText
                  sx={{
                    marginLeft: 0,
                    fontStyle: "italic",
                    fontWeight: 600,
                    letterSpacing: 0,
                    marginTop: "1rem",
                  }}
                  id="helper-text-upload-file"
                >
                  Note:{" "}
                  {isValidFile2
                    ? "Two Files Max"
                    : "Only pdf and word file allowed"}
                </FormHelperText>
                <FormHelperText error sx={{ marginLeft: 0 }} id={"error-doc"}>
                  {docRequired2}
                </FormHelperText>
              </Box>
            </>
          )}
        </>
      )}

      <CommonModal
        open={donorAgeModal}
        type="warning"
        onConfirm={() => {
          setValue(`applications.${index}.donor_dob`, null);
          setDonorAgeModal(false);
        }}
        title="Please Note"
        subTitle="The age of this person is below the minimum age requirement which is 21 years.
        Due to the Donor Age, we cannot approve coverage at this time.
        If you have any questions, please contact your broker."
      />

      <CommonModal
        open={recipientAgeModal}
        type="warning"
        onConfirm={() => {
          setValue(`applications.${index}.recipient_dob`, null);
          setRecipientAgeModal(false);
        }}
        title="Please Note"
        subTitle="The age of this person is below the minimum age requirement which is 21 years.
        Due to the Recipient Age, we cannot approve coverage at this time.
        If you have any questions, please contact your broker."
      />

      <CommonModal
        open={cycleModal}
        type="warning"
        onConfirm={() => setCycleModal(false)}
        title="Please Note"
        subTitle="Please be aware!        
        Due to the lifetime cycle count this application will need to be reviewed.        
        If you have any questions, please contact your broker"
      />

      <CommonModal
        open={showDeleteModal}
        type="delete"
        onCancel={() => setShowDeleteModal(false)}
        confirmButtonLabel="Delete"
        onConfirm={removePdf}
        confirmButtonLoading={buttonLoading == "delete"}
        title="Are You Sure?"
        subTitle="Do you want to delete this file?"
      />
      <CommonModal
        open={showDeleteModal2}
        type="delete"
        onCancel={() => setShowDeleteModal2(false)}
        confirmButtonLabel="Delete"
        onConfirm={removePdf2}
        confirmButtonLoading={buttonLoading2 == "delete"}
        title="Are You Sure?"
        subTitle="Do you want to delete this file?"
      />
    </>
  );
};

export default DonorAndRecipient;
