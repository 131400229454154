/*
 * Change SAD/IVF Request
 */

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Box, Typography } from "@mui/material";

import { AttachmentApis, PaymentApis, RequestApis } from "../../api";
import { Cancelation, PolicyTypes } from "../sad/constants";
import { AllRoutes } from "../../routes";
import { AdminPageLayout } from "../../layouts/admin-layout/AdminPageLayout";
import { RequestsHeader } from "../sad/sad-user/components/RequestsHeader";
import { SadActionButtons } from "../sad/sad-user/components/ActionButtons";
import { INSURANCE_TYPES } from "../../constants";
import {
  CommonModal,
  Dropdown,
  GlobalLoader,
  TextInput,
  FilePicker,
  Tooltip,
  MandatoryStatement,
} from "../../components";

const validationSchema = Yup.object({
  type_of_change: Yup.string().required("Change Type is required"),
  additional_recipient_email: Yup.string().email("Invalid email"),
  description: Yup.string().required("Description is required"),
}).required();

const User_ChangeRequest = ({ type }) => {
  const navigate = useNavigate();
  const { id: application_id } = useParams();
  const isIVFPolicy = type === "ivf";
  const summaryData = useSelector((state) => state.sadReducer.sadSummary);
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [requestTypes, setRequestTypes] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isDocUploading, setDocUploading] = useState(false);
  const [docRequired, setDocRequired] = useState("");
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [fileToBeDeleted, setFileToBeDeleted] = useState();
  const [isValidFile, setIsValidFile] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [attachment_id, setAttachment_id] = useState([]);
  const [labelled_as, setLabelled_as] = useState([]);
  const [isCanceled, setCanceled] = useState(false);

  const {
    sad_change_request_types_list,
    ivf_change_request_types_list,
    change_request_charges,
  } = lookups ?? {};

  const { status } = (isIVFPolicy ? summaryData?.[0] : summaryData) ?? {};
  const needToPay = !isIVFPolicy && status == PolicyTypes.certificate_issued;

  const request_types_list = isIVFPolicy
    ? ivf_change_request_types_list
    : sad_change_request_types_list;

  useEffect(() => {
    isCanceled &&
      navigate(
        isIVFPolicy
          ? AllRoutes.IvfPoliciesPage.route
          : AllRoutes.SadPoliciesPage.route
      );
  }, [isCanceled]);

  const {
    register,
    setError,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type_of_change: null,
      additional_recipient_email: "",
      description: "",
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    request_types_list?.length &&
      setRequestTypes(
        request_types_list?.filter((i) => i.value !== Cancelation)
      );
  }, [request_types_list]);

  const openPdfInNewTab = (file) => {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  };

  const handleFileUpload = async (newFile) => {
    setFiles((prev) => [...prev, newFile[0]]);
    setDocUploading(true);
    const formData = new FormData();
    formData.append("group", "documents");
    formData.append("labelled_as", application_id);
    formData.append("file", newFile?.[0]);
    const resp = await AttachmentApis.uploadAttachment({
      change_request_id: application_id,
      formData,
    });
    if (resp?.success) {
      const id = resp?.data?.id;
      const labelled = formData?.labelled_as || application_id;
      setLabelled_as((prev) => [...prev, labelled]);
      setAttachment_id((prev) => [...prev, id]);
      setDocRequired("");
    } else {
      setFiles([]);
    }
    setDocUploading(false);
  };

  const removePdf = async () => {
    const newfiles = files.filter((file, index) => index !== fileToBeDeleted);
    setFiles(newfiles);
    setShowDeleteModal(false);
    setTimeout(() => {
      document.getElementById("btn-upload-file").focus();
      document.getElementById("contained-button-file").value = "";
    }, 300);
  };

  const createPayment = async (invoice) => {
    const payload = {
      invoice_id: invoice?.id,
      insurance_type: INSURANCE_TYPES.sad,
    };

    try {
      const resp = await PaymentApis.createPayment(payload);
      if (resp) {
        setButtonLoading("");
      }
      !!resp?.success && (window.location = resp?.data?.url);
    } catch (error) {
      console.log("createPayment", error);
    }
  };

  const onSubmit = async (data) => {
    setButtonLoading("submit");
    const resp = await RequestApis.postChangeCancelReq({
      application_id,
      ...data,
    });
    if (!resp || (resp && !needToPay)) {
      setButtonLoading("");
    }
    if (resp?.success) {
      if (needToPay) {
        await createPayment(resp?.data?.invoice);
      }
      if (attachment_id.length > 0) {
        for (let i = 0; i < files.length; i++) {
          await RequestApis.attachChangeCancelReqDocs({
            attachment_id: attachment_id[i],
            labelled_as: labelled_as[i],
            change_request_id: resp?.data?.id,
          });
        }
      }
      !needToPay && setUpdateModal(true);
    } else {
      Object.keys(resp?.errors).forEach(function (key) {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  return (
    <AdminPageLayout>
      <RequestsHeader
        isIVFPolicy={isIVFPolicy}
        header="Change Request"
        setLoading={setLoading}
        setCanceled={setCanceled}
      />

      {loading && <GlobalLoader loading={loading} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <MandatoryStatement />
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Dropdown
              label="Type of Change"
              id="type_of_change"
              disabled={buttonLoading}
              options={
                requestTypes
                  ?.filter(
                    (option) =>
                      option.label !==
                      "Surrogate Designated Beneficiary Details"
                  )
                  ?.sort((a, b) => a?.label?.localeCompare?.(b?.label)) ?? []
              }
              value={watch("type_of_change")}
              register={register}
              registerChange={() => {}}
              isError={errors?.type_of_change}
              errorMsg={errors?.type_of_change?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextInput
              inputKey="additional_recipient_email"
              inputLabel="Email"
              name="additional_recipient_email"
              autoComplete="off"
              disabled={buttonLoading}
              required={false}
              helperText="Note: This email address will also receive notifications about change request."
              register={register}
              registerChange={(e) => {
                setError(e.target.name, false);
              }}
              isError={errors?.additional_recipient_email}
              errorMsg={errors?.additional_recipient_email?.message}
            />
          </Grid>
        </Grid>
        <TextInput
          inputKey="description"
          inputLabel="Description"
          disabled={buttonLoading}
          helperText="Note: Use above space to include info pertaining to your change request."
          multiline
          rows={2}
          register={register}
          registerChange={(e) => {}}
          isError={errors?.description}
          errorMsg={errors?.description?.message}
        />

        <Box alignItems="center" marginY="2rem" mt={3}>
          <Typography marginBottom="1rem" id="lbl-upload-file" fontSize="14px">
            Please upload the supporting documents.
            <Tooltip tooltip="File can only be type of pdf / word" />
          </Typography>
          <FilePicker
            disabled={buttonLoading}
            files={files}
            setFiles={setFiles}
            setIsValidFile={setIsValidFile}
            openPdfInNewTab={openPdfInNewTab}
            setFileToBeDeleted={setFileToBeDeleted}
            setShowDeleteModal={setShowDeleteModal}
            handleFileUpload={(newFile) => handleFileUpload(newFile)}
            isDocUploading={isDocUploading}
            errorMsg={docRequired}
            helperText={
              isValidFile
                ? "Note: Two Files Max"
                : "Note: Only pdf and word file allowed"
            }
          />
        </Box>

        {/* Modal to confirm delete documents */}
        <CommonModal
          open={showDeleteModal}
          type="delete"
          onCancel={() => setShowDeleteModal(false)}
          confirmButtonLabel="Delete"
          confirmButtonLoading={buttonLoading == "delete"}
          onConfirm={removePdf}
          title="Are You Sure?"
          subTitle="Do you want to delete this file?"
        />

        <SadActionButtons
          onPressBack={() =>
            isIVFPolicy
              ? navigate(AllRoutes.IvfPoliciesPage.route)
              : navigate(AllRoutes.SadPoliciesPage.route)
          }
          confirmButtonLabel={
            needToPay ? `Pay $${change_request_charges}.00` : "Submit"
          }
          buttonLoading={buttonLoading == "submit"}
          disableContinue={isDocUploading}
          disableBack={isDocUploading}
        />

        {/* Modal to confirm delete documents */}
        <CommonModal
          open={updateModal}
          type="success"
          onConfirm={() =>
            isIVFPolicy
              ? navigate(AllRoutes.IvfPoliciesPage.route)
              : navigate(AllRoutes.SadPoliciesPage.route)
          }
          title="Request submitted successfully!"
        />
      </form>
    </AdminPageLayout>
  );
};

export { User_ChangeRequest };
