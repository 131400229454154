/**
 * Attachment APIs
 */

import { convertToBlob } from "../utils/CommonUtils";
import httpClient from "../utils/HttpUtils";

export const AttachmentApis = {
  uploadAttachment: async (payload) => {
    const { application_id, formData, labelled_as } = payload ?? {};

    try {
      const resp = await httpClient.post("attachments", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (resp?.success && resp?.data?.id && application_id) {
        await httpClient.post(`applications/${application_id}/documents`, {
          attachment_id: resp?.data?.id,
          labelled_as: labelled_as || formData?.labelled_as || application_id,
        });
      }
      return resp;
    } catch (error) {
      console.log("Error uploadAttachment", error);
    }
  },

  getAttachments: async ({ application_id }) => {
    try {
      const resp = await httpClient.get(
        `applications/${application_id}/documents`
      );
      return resp;
    } catch (error) {
      console.log("Error getAttachments", error);
    }
  },

  deleteAttachment: async ({ application_id, document_id }) => {
    try {
      const resp = await httpClient.delete(
        `applications/${application_id}/documents/${document_id}`
      );
      return resp;
    } catch (error) {
      console.log("Error deleteAttachment", error);
    }
  },

  getAffidavit: async ({ application_id, affidavit_id }) => {
    try {
      const resp = await httpClient.get(
        `applications/${application_id}/affidavits/${affidavit_id}`,
        { responseType: "blob" }
      );

      let link = "";
      if (resp.success) {
        link = convertToBlob(resp?.data);
      }
      return link;
    } catch (error) {
      console.log("Error getAffidavit:", error);
    }
  },

  downloadDocument: async ({ application_id, policy_doc_id, download = 0 }) => {
    try {
      const resp = await httpClient.get(
        `applications/${application_id}/policy_docs/${policy_doc_id}?download=${download}`,
        { responseType: "blob" }
      );
      return resp;
    } catch (error) {
      console.log("Error downloadDocument:", error);
    }
  },

  getPolicyDoc: async ({
    application_id,
    policy_doc_id,
    send_email = false,
  }) => {
    let reqUrl = `applications/${application_id}/policy_docs/${policy_doc_id}`;
    if (send_email) {
      reqUrl += `?send_email=1`;
    }
    try {
      const resp = await httpClient.get(reqUrl);
      return resp?.data?.url ?? "";
      // let link = "";
      // if (resp.success) {
      //   link = convertToBlob(resp?.data);
      // }
      // return link;
    } catch (error) {
      console.log("Error getPolicyDoc:", error);
    }
  },

  bulkDownload: async (payload) => {
    try {
      const resp = await httpClient.post("bulk_downloads", payload, {
        responseType: "blob",
      });
      return resp;
    } catch (error) {
      console.log("Error bulkDownload", error);
    }
  },
};
