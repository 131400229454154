/**
 * Change / Cancel Request APIs
 */

import httpClient from "../utils/HttpUtils";

export const RequestApis = {
  postChangeCancelReq: async (payload) => {
    try {
      const resp = await httpClient.post(`/change_requests`, payload);
      return resp;
    } catch (error) {
      console.log("Error postChangeCancelReq:", error);
    }
  },

  getAllChangeRequests: async (payload) => {
    const { sortOrder, sortKey, page, advancedSearch } = payload ?? {};
    let reqUrl = "/change_requests";
    if (payload)
      reqUrl += `?sort=${sortOrder ? "-" : ""}${sortKey}&page=${page}`;

    if (advancedSearch && Object?.keys?.(advancedSearch)?.length > 0) {
      let filtersPart = "";
      for (const [key, value] of Object.entries(advancedSearch)) {
        filtersPart += `&filters[${key}]=${value}`;
      }
      reqUrl += filtersPart;
    }
    try {
      const resp = await httpClient.get(reqUrl);
      return resp;
    } catch (error) {
      console.log("Error getAllChangeRequests:", error);
    }
  },

  attachChangeCancelReqDocs: async (payload) => {
    const { attachment_id, labelled_as, change_request_id } = payload ?? {};
    try {
      const resp = await httpClient.post(
        `/change_requests/${change_request_id}/documents`,
        {
          attachment_id,
          labelled_as,
        }
      );
      return resp;
    } catch (error) {
      console.log("Error attachChangeCancelReqDocs:", error);
    }
  },

  addChangeCancelReqResp: async (payload) => {
    const { change_request_id, ...params } = payload ?? {};
    try {
      const resp = await httpClient.put(
        `/change_requests/${change_request_id}`,
        params
      );
      return resp;
    } catch (error) {
      console.log("Error addChangeCancelReqResp:", error);
    }
  },

  getReqDataById: async (payload) => {
    const { change_request_id } = payload ?? {};
    try {
      const resp = await httpClient.get(
        `/change_requests/${change_request_id}`
      );
      return resp;
    } catch (error) {
      console.log("Error getReqDataById:", error);
    }
  },

  getAllResponses: async ({ change_request_id }) => {
    try {
      const resp = await httpClient.get(
        `change_requests/${change_request_id}/responses`
      );
      return resp;
    } catch (error) {
      console.log("Error getAllResponses", error);
    }
  },
};
