/*
 * Common MUI Stepper
 */

import React from "react";
import { Stepper as MuiStepper, Step, StepLabel } from "@mui/material";

import { theme } from "../styles/theme";

const Stepper = ({ activeStep, marginY, stepperWidth, steps }) => {
  return (
    <MuiStepper
      activeStep={activeStep}
      alternativeLabel
      role="list"
      aria-label="Steps to follow"
      sx={{
        /* C O N N E C T O R   S T Y L E S */
        "& .MuiStepConnector-root": {
          marginX: "-6.5px",
        },
        "& .MuiStepConnector-line": {
          borderTopWidth: "2px",
        },
        "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
          borderColor: theme.primaryDark,
        },
        "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
          borderColor: theme.primaryDark,
        },

        /* C O M P L E T E D  S T Y L E S */
        "& .MuiStepLabel-root .Mui-completed": {
          color: theme.primaryDark, // circle color
          border: `2px solid ${theme.primaryDark}`, // circle border color
          borderRadius: "50%",
        },
        "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
          color: theme.black, // Bottom text color
          border: 0,
          fontWeight: "bold",
        },

        /* A C T I V E   S T Y L E S */
        "& .MuiStepLabel-root .Mui-active": {
          color: theme.white, // circle color
          border: `1px solid ${theme.primaryDark}`, // circle border color
          borderRadius: "50%",
        },
        "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
          color: theme.primaryDark, // Bottom text color
          border: 0,
          fontWeight: "bold",
        },
        "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
          fill: theme.primaryDark, // circle's number color
        },

        /* D I S A B L E D   S T Y L E S */
        "& .Mui-disabled .MuiStepIcon-root ": {
          color: theme.white, // circle color
          border: `1px solid ${theme.grey}`, // circle border color
          borderRadius: "50%",
        },
        "& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel": {
          color: theme.grey, // Bottom text color
          fontWeight: "bold",
        },
        "& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text": {
          fill: theme.grey, // circle's number color
        },
        width: stepperWidth,
        marginY: marginY,
      }}
    >
      {steps?.map((label, index) => (
        <Step key={label} role="listitem">
          {index < activeStep && <span className="hidden-visually">Completed:&nbsp;</span>}
          {index == activeStep && <span className="hidden-visually">Current:&nbsp;</span>}
          <span className="hidden-visually">{++index }&nbsp;</span>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};

export { Stepper };
