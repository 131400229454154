/*
 * Search, clear and advance filter buttons for all admin grids
 */

import React from "react";
import { Button } from "./Button";
import FilterListIcon from "@mui/icons-material/FilterList";

import { theme } from "../styles/theme";
import { Box } from "@mui/material";

const GridActionButtons = ({
  onClear,
  onClickAdvSearch,
  onSearch,
  wrapperStyle,
  searchButtonLoading,
  clearButtonLoading,
}) => {
  return (
    <Box sx={({ display: "flex" }, { ...wrapperStyle })}>
      <Button
        variant="contained"
        disableElevation={false}
        type="submit"
        sx={{
          minWidth: "80px",
          marginRight: "7px",
          minHeight: "2.5rem",
        }}
        onClick={onSearch}
        loading={searchButtonLoading}
        disabled={searchButtonLoading || clearButtonLoading}
        title="Search"
      />
      <Button
        variant="outlined"
        sx={{
          minWidth: "80px",
          borderColor: theme.grey,
          color: theme.grey,
          backgroundColor: theme.white,
          marginRight: "7px",
          minHeight: "2.5rem",
        }}
        onClick={onClear}
        loading={clearButtonLoading}
        disabled={searchButtonLoading || clearButtonLoading}
        title="Clear"
      />

      {!!onClickAdvSearch && (
        <Button
          onClick={onClickAdvSearch}
          variant="outlined"
          sx={{
            backgroundColor: theme.white,
            borderRadius: "50%",
            borderColor: theme.grey,
            width: "40px",
            height: "40px",
            minWidth: "unset",
            marginBottom: "1rem",
            minHeight: "2rem",
          }}
          title={<FilterListIcon sx={{ color: theme.grey }} />}
        />
      )}
    </Box>
  );
};

export { GridActionButtons };
