import React, { useEffect, useState } from "react";
import {
  Accordion,
  AutoComplete,
  CommonModal,
} from "../../../../../components";
import { Grid } from "@mui/material";
import DropdownIVF from "../common/Dropdown";
import { useSelector } from "react-redux";
import { ClinicApis, CommonApis } from "../../../../../api";
import { AddNewClinic } from "../../components/AddNewClinic";
import { useDebounce } from "../../../../../hooks";
import TextInputIvf from "../common/TextInput";
import { LocalStorageHelper } from "../../../../../utils/HttpUtils";
import { USER_ROLE, USER_ROLES } from "../../../../../constants";
import { constructStateList } from "../../../../../utils/CommonUtils";

const IVFClinicForm = ({
  index,
  disabled = false,
  register,
  watch,
  setValue,
  setLoading,
  errors,
  setError,
  headingLevel,
}) => {
  const [showAddNewClinic, setShowAddNewClinic] = useState(false);
  const isUserClinic = LocalStorageHelper.get(USER_ROLE) == USER_ROLES.clinic;
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const clinics = useSelector((state) => state.ivfReducer.clinics);
  const [ivfStateList, setIvfStateList] = useState([]);
  const [clinicList, setClinicList] = useState(clinics);
  const [isBlackListedClinic, setBlackListedClinic] = useState(false);
  const [isNewClinicAdded, setNewClinicAdded] = useState(false);
  const isClinicDisabled = isUserClinic;

  const getClinicStateList = useDebounce(async (value) => {
    const resp = await CommonApis.getZipData({
      country_code: value,
    });
    const getStates = constructStateList(resp);
    setIvfStateList(getStates);
  }, 300);

  useEffect(() => {
    getClinicStateList("US");
  }, []);

  useEffect(() => {
    getClinicList(watch(`applications.${index}.clinic_state_code`));
  }, [isNewClinicAdded]);

  const getClinicList = async (value) => {
    const resp = await ClinicApis.getClinicsList({
      country_code: watch(`applications.${index}.clinic_country_code`),
      state_code: value,
    });
    setClinicList(resp);
  };

  return (
    <>
      <Accordion
        header="IVF Clinic Details"
        component={headingLevel}
        name={`ivf-clinic-details-${index}`}
      >
        <Grid sx={{ marginTop: -1.2 }} container columnSpacing={3}>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <DropdownIVF
              label="Country"
              id={`applications.${index}.clinic_country_code`}
              name={`applications.${index}.clinic_country_code`}
              options={lookups?.countries_list}
              disabled={disabled || isClinicDisabled}
              value={watch(`applications.${index}.clinic_country_code`)}
              {...register(`applications.${index}.clinic_country_code`, {
                onChange: (e) => {
                  getClinicStateList(e.target.value);
                  setValue(
                    `applications.${index}.clinic_country_code`,
                    watch(`applications.${index}.clinic_country_code`)
                  );
                  setValue(`applications.${index}.clinic_state_code`, null);
                  setValue(`applications.${index}.clinic_name`, null);
                  setValue(`applications.${index}.clinic_doctor_name`, null);
                },
              })}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <DropdownIVF
              label="State"
              id={`applications.${index}.clinic_state_code`}
              name={`applications.${index}.clinic_state_code`}
              options={ivfStateList}
              disabled={
                disabled ||
                isClinicDisabled ||
                !watch(`applications.${index}.clinic_country_code`)
              }
              value={watch(`applications.${index}.clinic_state_code`)}
              {...register(`applications.${index}.clinic_state_code`, {
                onChange: async (e) => {
                  setLoading(true);
                  await getClinicList(e.target.value);
                  setLoading(false);
                  setValue(
                    `applications.${index}.clinic_state_code`,
                    watch(`applications.${index}.clinic_state_code`)
                  );
                  setValue(`applications.${index}.clinic_name`, null);
                  setValue(`applications.${index}.clinic_doctor_name`, null);
                },
              })}
              isError={
                errors?.applications?.[index]?.["clinic_state_code"]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.["clinic_state_code"]?.message
              }
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <AutoComplete
              {...register(`applications.${index}.clinic_name`)}
              label="IVF Clinic"
              id={`applications.${index}.clinic_name`}
              disabled={
                disabled ||
                isClinicDisabled ||
                !watch(`applications.${index}.clinic_state_code`)
              }
              value={watch(`applications.${index}.clinic_name`)}
              placeholder="Start typing Clinic name..."
              options={clinicList ?? []}
              optionLabel={(option) =>
                option?.name || watch(`applications.${index}.clinic_name`) || ""
              }
              setShowAddNewAgency={setShowAddNewClinic}
              onInputChange={(_, value) => {
                setValue(`applications.${index}.clinic_name`, value);
              }}
              buttonText="Add New Clinic"
              onChange={(data) => {
                if (data?.is_active === -1) {
                  setBlackListedClinic(true);
                  setValue(`applications.${index}.clinic_name`, null);
                  setValue(`applications.${index}.clinic_doctor_name`, null);
                } else {
                  setValue(`applications.${index}.clinic_name`, data?.name);
                  setError(`applications.${index}.clinic_name`, null);
                  setValue(
                    `applications.${index}.clinic_doctor_name`,
                    data?.doctor_name
                  );
                }
              }}
              isError={errors?.applications?.[index]?.["clinic_name"]?.message}
              errorMsg={errors?.applications?.[index]?.["clinic_name"]?.message}
              name={undefined}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInputIvf
              type="text"
              disabled={disabled || isClinicDisabled}
              required={false}
              register={register}
              name={`applications.${index}.clinic_doctor_name`}
              setError={setError}
              id={`applications.${index}.clinic_doctor_name`}
              label="Doctor Name"
              sx={{ marginTop: "16px", width: "100%" }}
              InputLabelProps={{ shrink: true }}
              isError={
                errors?.applications?.[index]?.["clinic_doctor_name"]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.["clinic_doctor_name"]?.message
              }
            />
          </Grid>
        </Grid>
      </Accordion>

      {showAddNewClinic && (
        <AddNewClinic
          setLoading={setLoading}
          open={showAddNewClinic}
          onClose={() => setShowAddNewClinic(false)}
          newClinicData={({ country_code, state_code, name, doctor_name }) => {
            setNewClinicAdded(!isNewClinicAdded);
            setValue(`applications.${index}.clinic_country_code`, country_code);
            setValue(`applications.${index}.clinic_state_code`, state_code);
            setValue(`applications.${index}.clinic_name`, name);
            setValue(`applications.${index}.clinic_doctor_name`, doctor_name);
            setError(`applications.${index}.clinic_name`, null);
            setError(`applications.${index}.clinic_doctor_name`, null);
          }}
          name="Clinic"
        />
      )}

      {/* Warning modal if BLACKlISTED clinic is selected */}
      <CommonModal
        open={isBlackListedClinic}
        type="warning"
        onConfirm={() => setBlackListedClinic(false)}
        title="Please Note"
        subTitle={`We are sorry!<br />At this time, we are not accepting applications for coverage, from this clinic.<br/>If you have any questions, please contact your broker.`}
      />
    </>
  );
};

export default IVFClinicForm;
