/*
 *Admin -> Sales Report -> Table Data
 */

import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import ReactTable from "../../React Table";
import { PaymentTypes, PolicyTypes } from "../sad/constants";
import { INSURANCE_TYPES, strings } from "../../constants";
import { getIndemnityLimit } from "../ivf/constants";
import { downloadExcel, formattedMDYDate } from "../../utils/CommonUtils";
import {
  GlobalLoader,
  RenderCellInfo,
  RenderChip,
  RenderTableHeader,
} from "../../components";

const { underReview } = PolicyTypes;

// Utility function to get value based on column configuration
const getColumnValue = (column, row, ivf_indemnity_options_list) => {
  const columnFormatters = {
    "Application date": (row) =>
      row.created_at ? formattedMDYDate(row.created_at) : "",

    "Policy Status": (row) =>
      row.status === underReview ? "Submitted" : row.status,

    "Payment Date": (row) =>
      row.invoice?.payment_date
        ? formattedMDYDate(row.invoice.payment_date)
        : "",

    "Effective Date": (row) =>
      row.effective_from ? formattedMDYDate(row.effective_from) : "",

    "Termination Date": (row) =>
      row.effective_till ? formattedMDYDate(row.effective_till) : "",

    "Certificate Issue Date": (row) =>
      row.certificate_issued_at
        ? formattedMDYDate(row.certificate_issued_at)
        : "",

    "Insured Name": (row) =>
      row.insurance_type === INSURANCE_TYPES.ivf
        ? row.contractually_liable_party_first_name
        : row.intended_parent1_first_name,

    "Insured Address": (row) => {
      if (row.insurance_type === INSURANCE_TYPES.ivf) {
        return `${row.contractually_liable_party_address_line_1}${
          row.contractually_liable_party_city
            ? `${row.contractually_liable_party_address_line_1 ? "," : ""} ${
                row.contractually_liable_party_city
              }`
            : ""
        }${
          row.contractually_liable_party_country_code
            ? `, ${row.contractually_liable_party_country_code}`
            : ""
        }${
          row.contractually_liable_party_state_code
            ? `, ${row.contractually_liable_party_state_code}`
            : ""
        }${
          row.contractually_liable_party_zipcode
            ? `, ${row.contractually_liable_party_zipcode}`
            : ""
        }`;
      } else {
        return `${row.intended_parent_address_line_1}${
          row.intended_parent_city
            ? `${row.intended_parent_address_line_1 ? "," : ""} ${
                row.intended_parent_city
              }`
            : ""
        }${
          row.intended_parent_country_code
            ? `, ${row.intended_parent_country_code}`
            : ""
        }${
          row.intended_parent_state_code
            ? `, ${row.intended_parent_state_code}`
            : ""
        }${
          row.intended_parent_zipcode ? `, ${row.intended_parent_zipcode}` : ""
        }`;
      }
    },

    "Total Limit of Indemnity": (row) =>
      `${
        (row?.indemnity_surrogates_beneficiary || 0) +
        (row?.indemnity_intended_parents || 0)
      }` || "-",

    "Limit of Indemnity (IVF)": (row) =>
      row.idemnity_limit
        ? getIndemnityLimit(
            ivf_indemnity_options_list,
            row.coverage_type,
            row.term_length,
            row.idemnity_limit
          )
        : "-",

    "Total Due": (row) => {
      const canPayment = row.invoice?.id && !row.alerts?.length;
      const paymentStatus = row.invoice?.payment_status;
      const isPaid = Boolean(paymentStatus === PaymentTypes.paid);
      return canPayment
        ? isPaid
          ? row.invoice?.total
          : row.invoice?.total_without_card_charges
        : "";
    },
  };

  if (columnFormatters[column.id]) {
    return columnFormatters[column.id](row);
  }

  return column.accessorKey ? row[column.accessorKey] || "-" : "";
};

// Utility function to prepare data for Excel export
const prepareExcelData = (
  dataList,
  tableColumns,
  ivf_indemnity_options_list
) => {
  return dataList?.map?.((row) => {
    const formattedRow = {};

    tableColumns?.forEach?.((column) => {
      formattedRow[column.id] = getColumnValue(
        column,
        row,
        ivf_indemnity_options_list
      );
    });

    return formattedRow;
  });
};

const TableData = ({
  caption,
  dataList,
  listData,
  setSortKey,
  setSortOrder,
  page,
  setPage,
  searchParams,
  setSearchParams,
  loading,
  dataLoading,
}) => {
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const { ivf_indemnity_options_list } = lookups;

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  const tableColumns = [
    {
      id: "Policy Number",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Number"
          headerKey="application_number"
        />
      ),
      accessorKey: "application_number",
      cell: (info) => (
        <RenderCellInfo text={info.getValue()} sx={{ color: "#0A7691" }} />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Application date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Application date"
          headerKey="created_at"
        />
      ),
      accessorKey: "created_at",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Policy Status",
      accessorKey: "status",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Status"
          headerKey="status"
        />
      ),
      cell: (info) => {
        const status = info.getValue();
        return (
          <RenderChip
            label={status === underReview ? "Submitted" : undefined}
            status={status}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Date",
      accessorKey: "payment_date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Payment Date"
          headerKey="payment_date"
        />
      ),
      cell: (info) => {
        const { invoice } = info?.row?.original;
        const paymentDate = invoice?.payment_date;
        return (
          <RenderCellInfo
            text={paymentDate ? formattedMDYDate(paymentDate) : ""}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Effective Date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Effective Date"
          headerKey="effective_from"
        />
      ),
      accessorKey: "effective_from",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Termination Date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Termination Date"
          headerKey="effective_till"
        />
      ),
      accessorKey: "effective_till",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Certificate Issue Date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Certificate Issue Date"
          headerKey="certificate_issued_at"
        />
      ),
      accessorKey: "certificate_issued_at",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Insured Name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Insured Name"
          headerKey="intended_parent1_first_name"
        />
      ),
      accessorKey: "intended_parent1_first_name",
      cell: (info) => {
        const {
          insurance_type,
          intended_parent1_first_name,
          contractually_liable_party_first_name,
        } = info?.row?.original ?? {};
        return (
          <RenderCellInfo
            text={
              insurance_type === INSURANCE_TYPES.ivf
                ? contractually_liable_party_first_name
                : intended_parent1_first_name
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Insured Address",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Insured Address"
          headerKey="intended_parent_address_line_1"
        />
      ),
      accessorKey: "intended_parent_address_line_1",
      cell: (info) => {
        const {
          insurance_type,
          intended_parent_address_line_1,
          intended_parent_city,
          intended_parent_country_code,
          intended_parent_state_code,
          intended_parent_zipcode,
          contractually_liable_party_address_line_1,
          contractually_liable_party_city,
          contractually_liable_party_country_code,
          contractually_liable_party_state_code,
          contractually_liable_party_zipcode,
        } = info?.row?.original ?? {};

        return (
          <RenderCellInfo
            text={
              insurance_type === INSURANCE_TYPES.ivf
                ? `${contractually_liable_party_address_line_1}${
                    contractually_liable_party_city
                      ? `${
                          contractually_liable_party_address_line_1 ? "," : ""
                        } ${contractually_liable_party_city}`
                      : ""
                  }${
                    contractually_liable_party_country_code
                      ? `, ${contractually_liable_party_country_code}`
                      : ""
                  }${
                    contractually_liable_party_state_code
                      ? `, ${contractually_liable_party_state_code}`
                      : ""
                  }${
                    contractually_liable_party_zipcode
                      ? `, ${contractually_liable_party_zipcode}`
                      : ""
                  }`
                : `${intended_parent_address_line_1}${
                    intended_parent_city
                      ? `${
                          intended_parent_address_line_1 ? "," : ""
                        } ${intended_parent_city}`
                      : ""
                  }${
                    intended_parent_country_code
                      ? `, ${intended_parent_country_code}`
                      : ""
                  }${
                    intended_parent_state_code
                      ? `, ${intended_parent_state_code}`
                      : ""
                  }${
                    intended_parent_zipcode
                      ? `, ${intended_parent_zipcode}`
                      : ""
                  }`
            }
            sx={{ whiteSpace: "wrap", wordWrap: "break-word", width: "18rem" }}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    ...(searchParams?.get?.("insurance_type") === INSURANCE_TYPES.ivf
      ? [
          {
            id: "Donor Name/ID",
            accessorKey: "donor_name_id",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Donor Name/ID"
                headerKey="donor_name_id"
              />
            ),
            cell: (info) => <RenderCellInfo text={info.getValue() || "-"} />,
            footer: (props) => props.column.id,
          },
          {
            id: "Recipient Name/ID",
            accessorKey: "recipient_name_id",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Recipient Name/ID"
                headerKey="recipient_name_id"
              />
            ),
            cell: (info) => <RenderCellInfo text={info.getValue() || "-"} />,
            footer: (props) => props.column.id,
          },
          {
            id: "Limit of Indemnity",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Limit of Indemnity"
                headerKey="idemnity_limit"
              />
            ),
            accessorKey: "idemnity_limit",
            cell: (info) => {
              const { coverage_type, term_length } = info.row.original || {};
              return (
                <RenderCellInfo
                  text={
                    info?.getValue()
                      ? getIndemnityLimit(
                          ivf_indemnity_options_list,
                          coverage_type,
                          term_length,
                          info.getValue()
                        )
                      : "-"
                  }
                />
              );
            },
            footer: (props) => props.column.id,
          },
        ]
      : [
          {
            id: "Total Limit of Indemnity",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Total Limit of Indemnity"
                headerKey="indemnity_surrogates_beneficiary"
              />
            ),
            accessorKey: "indemnity_surrogates_beneficiary",
            cell: (info) => {
              const { indemnity_intended_parents } = info.row.original || {};
              return (
                <RenderCellInfo
                  text={
                    `${
                      (info.getValue() || 0) + (indemnity_intended_parents || 0)
                    }` || "-"
                  }
                />
              );
            },
            footer: (props) => props.column.id,
          },
        ]),
    {
      id: "Premium",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Premium"
          headerKey="premium"
        />
      ),
      accessorKey: "premium",
      cell: (info) => <RenderCellInfo text={info?.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Reproductive Organ Loss Rider Level",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Reproductive Organ Loss Rider Level"
          headerKey="reproductive_organ_loss_rider_level"
        />
      ),
      accessorKey: "reproductive_organ_loss_rider_level",
      cell: (info) => {
        return <RenderCellInfo text={info?.getValue()} />;
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Reproductive Organ Loss Rider Premium",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Reproductive Organ Loss Rider Premium"
          headerKey="reproductive_organ_loss_rider_premium"
        />
      ),
      accessorKey: "reproductive_organ_loss_rider_premium",
      cell: (info) => {
        return <RenderCellInfo text={info?.getValue()} />;
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Total Disability Rider Level",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Total Disability Rider Level"
          headerKey="total_disability_rider_level"
        />
      ),
      accessorKey: "total_disability_rider_level",
      cell: (info) => {
        return <RenderCellInfo text={info?.getValue()} />;
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Total Disability Rider Premium",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Total Disability Rider Premium"
          headerKey="total_disability_rider_premium"
        />
      ),
      accessorKey: "total_disability_rider_premium",
      cell: (info) => {
        return <RenderCellInfo text={info?.getValue()} />;
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Total Due",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Total Due"
          headerKey="total"
        />
      ),
      accessorKey: "total",
      cell: (info) => {
        const { invoice, alerts } = info.row.original ?? {};
        const canPayment = invoice?.id && !alerts?.length;
        const paymentStatus = invoice?.payment_status;
        const isPaid = Boolean(paymentStatus === PaymentTypes.paid);
        return (
          <RenderCellInfo
            text={`${
              canPayment
                ? isPaid
                  ? invoice?.total
                  : invoice?.total_without_card_charges
                : ""
            }`}
          />
        );
      },
      footer: (props) => props.column.id,
    },
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            page={page}
            setPage={handlePageChange}
            listData={listData}
            disableZipDownload={!dataList?.length}
            onZipDownload={() => {
              const filteredData = prepareExcelData(
                dataList,
                tableColumns,
                ivf_indemnity_options_list
              );
              downloadExcel(filteredData, "Sales-Report");
            }}
            downloadFileLabel={strings.exportExcel}
            caption={caption}
            dataLoading={dataLoading}
          />
        </Box>
      )}
    </>
  );
};

export { TableData };
