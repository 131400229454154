/*
 * A D M I N --- M O D U L E-   A D D   N E W   B R O K E R
 */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ThemeProvider } from "@mui/styles";

import {
  CommonModal,
  PhoneInput,
  TextInput,
  Dropdown,
  GoBack,
} from "../../../components";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";
import { SadActionButtons } from "../../sad/sad-admin/components/ActionButtons";
import { BrokerApis } from "../../../api";
import {
  emailRegex,
  focusFirstErrorField,
  testNonLetter,
} from "../../../utils/CommonUtils";
import { theme } from "../../../styles/theme";
import { INSURANCE_TYPES_LIST } from "../../../constants";

const formValues = {
  name: "",
  email: "",
  website: "",
  contact_number: "",
  insurance_type: "",
  is_active: 1,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .required("Email is required")
    .matches(emailRegex, "Invalid email"),
  website: Yup.string()
    .required("Website Link is required")
    .test(
      "trimmed-not-empty",
      "Website Link cannot be empty or only spaces",
      (value) => value && value.trim() !== ""
    ),
  contact_number: Yup.string()
    .required("Contact Number is required")
    .min(7, "Contact No. must be at least 7 digits"),
});

const AddNewBroker = () => {
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formValues,
  });

  const handlePhoneChange = (name, value) => {
    setValue(name, value);
    setError("contact_number", null);
  };

  const onSubmit = async (data) => {
    setButtonLoading(true);
    const resp = await BrokerApis.addBroker(data);
    if (resp) {
      setButtonLoading(false);
    }
    if (resp?.success) {
      setUpdateModal(true);
    } else {
      Object.keys(resp?.errors).forEach((key) => {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  useEffect(() => {
    const errorArray = Object.keys(errors);
    if (!errorArray?.length) return;
    focusFirstErrorField();
  }, [errors]);

  return (
    <ThemeProvider theme={theme}>
      <AdminPageLayout>
        <Box display="flex" alignItems="center">
          <GoBack />
          <Typography variant="h1" sx={{ margin: "0" }}>
            Add New Broker
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={2}
            sx={{ marginY: "1rem" }}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="name"
                inputLabel="Broker Name"
                register={register}
                disabled={buttonLoading}
                registerChange={(e) => setValue("name", e.target.value)}
                isError={errors?.name}
                errorMsg={errors?.name?.message}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="email"
                inputLabel="Email"
                register={register}
                disabled={buttonLoading}
                registerChange={() => {}}
                isError={errors?.email}
                errorMsg={errors?.email?.message}
                endAdornment={
                  false && (
                    <InputAdornment position="end">
                      <CheckCircleIcon sx={{ color: "#00AD00" }} />
                    </InputAdornment>
                  )
                }
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="website"
                inputLabel="Website Link"
                register={register}
                disabled={buttonLoading}
                registerChange={() => {}}
                isError={errors?.website}
                errorMsg={errors?.website?.message}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <PhoneInput
                inputKey="contact_number"
                inputLabel="Contact No."
                autoComplete="off"
                {...(testNonLetter(watch("contact_number")) && {
                  value: watch("contact_number"),
                })}
                register={register}
                disabled={buttonLoading}
                registerChange={handlePhoneChange}
                isError={errors?.contact_number}
                errorMsg={errors?.contact_number?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Dropdown
                label="Insurance Type"
                id="insurance_type"
                options={[{ label: "All", value: "" }, ...INSURANCE_TYPES_LIST]}
                register={register}
                disabled={buttonLoading}
                registerChange={(e) => {
                  setValue("insurance_type", e.target.value);
                  setError("insurance_type", null);
                }}
                value={watch("insurance_type")}
                isError={watch("insurance_type") === null}
                errorMsg={errors?.insurance_type?.message}
              />
            </Grid>
          </Grid>

          <SadActionButtons
            onCancel={() => reset(formValues)}
            updateButtonLabel="Add Broker"
            buttonLoading={buttonLoading}
          />
        </form>

        <CommonModal
          open={updateModal}
          type="success"
          onConfirm={() => {
            setUpdateModal(false);
            navigate(-1);
          }}
          title="Broker Added Successfully!"
        />
      </AdminPageLayout>
    </ThemeProvider>
  );
};

export { AddNewBroker };
