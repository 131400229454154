export const config = {
  DEV: {
    BE_BASE_URL: "https://d2idev.newlifeagency.com/api/v1",
    FE_BASE_URL: "https://dev.newlifeagency.com/",
  },
  LOCAL: {
    BE_BASE_URL: "http://localhost:3000/api/v1/",
    FE_BASE_URL: "http://localhost:3000/",
  },
};
