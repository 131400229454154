/*
 * A D M I N --- M O D U L E-  B R O K E R S    D A T A    G R I D
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { TableData } from "./TableData";
import { AdminPageLayout } from "../../../../layouts/admin-layout/AdminPageLayout";
import { Button, GoBack, Tooltip } from "../../../../components";
import { AllRoutes } from "../../../../routes";

const BrokersList = () => {
  const navigate = useNavigate();
  return (
    <AdminPageLayout>
      <Box sx={{ display: "flex" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={7}
            md={8}
            lg={9}
            xl={10}
            sx={{ marginBottom: "1rem" }}
          >
            <Box display="flex" alignItems="center">
              <GoBack />
              <Typography variant="h1" sx={{ margin: "0" }}>
                Brokers Panel
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            md={4}
            lg={3}
            xl={2}
            sx={{ textAlign: "right", marginBottom: "0.5rem" }}
          >
            <Button
              title="Add New Broker"
              variant="contained"
              sx={{ minHeight: "2rem" }}
              startIcon={<AddIcon sx={{ color: "#FFFFFF" }} />}
              onClick={() => navigate(`${AllRoutes.AddNewBroker.route}`)}
            />
          </Grid>
        </Grid>
      </Box>

      <TableData caption="Brokers Panel" />
    </AdminPageLayout>
  );
};

export { BrokersList };
