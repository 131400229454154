/*
 * Common Error Component
 */
import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { theme } from "../styles/theme";
import Alert from "@mui/material/Alert";

const ErrorComponent = ({ message, success = false, className="w-75" }) => {

  return (
    <>
      {success ? (
        <Alert
          severity="success"
          sx={{ paddingX: 1, paddingY: 0, border: `1px solid #24c963` }}
        >
          {message}
        </Alert>
      ) : (
        <Box className={className} sx={{ display: "flex", justifyContent: "center" }}>
          <Paper
            elevation={0}
            sx={{
              background: "#C924341A",
              display: "flex",
              border: `1px solid #C9243426`,
              borderRadius: "4px",
              alignItems: "center",
              font: "normal 13px Lato",
              color: "#C92434",
              p: "5px 10px",
              marginY: "1rem",
              "&:focus": {
                outline: `2px solid ${theme.primaryDark} !important`,
                boxShadow: "inset 0 0 0 1px #fff !important",
              },
            }}
            // ref={errorMessageRef}
          >
            <ErrorIcon sx={{ color: "#C92434", marginRight: "0.3rem" }} />
            <Typography role="alert">{message}</Typography>
          </Paper>
        </Box>
      )}
    </>
  );
};

export { ErrorComponent };
