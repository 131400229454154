/*
 * SAD admin application header
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";

import { GoBack } from "../../../components";
import { RequestApis } from "../../../api";
import { formattedMDYDate } from "../../../utils/CommonUtils";
import {
  coverageTypeLabel,
  INSURANCE_TYPES,
  REQUEST_STATUS,
  typeOfChangeLabel,
} from "../../../constants";

const ApplicationRequestHeader = ({ header, setLoading }) => {
  const { id: change_request_id } = useParams();
  const [requestData, setRequestData] = useState({});
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const { sad_change_request_types_list, ivf_change_request_types_list } =
    lookups ?? {};

  useEffect(() => {
    const getRequestData = async () => {
      setLoading(true);
      const resp = await RequestApis.getReqDataById({ change_request_id });
      !!resp && setLoading(false);
      !!resp?.success && setRequestData(resp?.data);
    };
    getRequestData();
  }, []);

  const {
    status,
    application_number,
    created_at,
    type_of_change,
    coverage_type,
    insurance_type,
    requested_by,
    description,
  } = requestData ?? {};
  const { first_name, last_name, email } = requested_by ?? {};

  const request_types_list =
    insurance_type == INSURANCE_TYPES.ivf
      ? ivf_change_request_types_list
      : sad_change_request_types_list;

  const generalInformation = [
    {
      value: status ? (status == REQUEST_STATUS.close ? "Close" : "Open") : "",
      label: "Request Status:",
    },
    {
      value: application_number,
      label: "Policy Number:",
    },
    {
      value: created_at ? formattedMDYDate(created_at) : "",
      label: "Created On:",
    },
    {
      value: typeOfChangeLabel(request_types_list, type_of_change),
      label: "Type of Change:",
    },
  ];

  const submitInformation = [
    {
      value: coverageTypeLabel(insurance_type, coverage_type),
      label: "Coverage Type:",
    },
    {
      value: `${first_name || ""} ${last_name || ""}`,
      label: "Requested By Name:",
    },
    {
      value: email,
      label: "Requested By Email:",
    },
    {
      value: description,
      label: "Description:",
    },
  ];

  const RenderSummaryItem = ({ options = [] }) => {
    return (
      <Grid container sx={{ paddingTop: "0.7rem", paddingX: "0.5rem" }}>
        {!!options &&
          options?.map((option) => (
            <React.Fragment key={option.label}>
              <Grid item xl={4} lg={4} md={5} sm={5} xs={12}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    color: "#222222",
                    paddingBottom: { xs: "0", sm: "0", md: "0.7rem" },
                  }}
                >
                  {option.label}
                </Typography>
              </Grid>
              <Grid item xl={8} lg={8} md={7} sm={7} xs={12}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    paddingBottom: "0.7rem",
                    paddingLeft: { xs: "0", sm: "0", md: "0.3rem" },
                  }}
                >
                  {option.value}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    );
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <GoBack />
        <Typography variant="h1" sx={{ margin: "0" }}>
          {header}
        </Typography>
      </Box>

      <Grid
        container
        columnSpacing={4}
        sx={{
          backgroundColor: "#F082331A",
          padding: "10px",
          marginY: "0.7rem",
        }}
      >
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <RenderSummaryItem options={generalInformation} />
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <RenderSummaryItem options={submitInformation} />
        </Grid>
      </Grid>
    </>
  );
};

export { ApplicationRequestHeader };
