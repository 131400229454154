/*
 * A D M I N --- M O D U L E-   E D I T   C O U N T R Y   F E E
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/styles";

import {
  CommonModal,
  GlobalLoader,
  TextInput,
  GoBack,
} from "../../../components";
import { CountriesApis } from "../../../api";
import { theme } from "../../../styles/theme";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";
import { SadActionButtons } from "../../sad/sad-admin/components/ActionButtons";

const validationSchema = Yup.object()
  .shape({
    country: Yup.string().required("Country field is required"),
    country_code: Yup.string().required("Country Code field is required"),
  })
  .required();

const EditCountryFee = () => {
  const navigate = useNavigate();
  const { id: country_id } = useParams();
  const [countryData, setCountryData] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  useEffect(() => {
    const getCountryData = async () => {
      setLoading(true);
      const resp = await CountriesApis.getCountryDetail({ country_id });
      !!resp && setLoading(false);
      !!resp?.success && setCountryData(resp?.data);
    };
    getCountryData();
  }, []);

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { country: "", country_code: "" },
  });

  useEffect(() => {
    if (countryData) {
      const { country, country_code } = countryData;
      setValue("country", country);
      setValue("country_code", country_code);
    }
  }, [countryData]);

  const onSubmit = async (data) => {
    setButtonLoading(true);
    const resp = await CountriesApis.updateCountry({
      country_id,
      params: data,
    });
    !!resp && setButtonLoading(false);
    if (resp?.success) {
      setUpdateModal(true);
    } else {
      Object.keys(resp?.errors).forEach((key) => {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AdminPageLayout>
        {loading && <GlobalLoader loading={loading} />}

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <GoBack />
          <Typography variant="h1" sx={{ margin: "0" }}>
            Edit Country
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={2}
            sx={{ marginY: "1rem" }}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="country"
                inputLabel="Country"
                register={register}
                disabled={buttonLoading}
                isError={errors?.country?.message}
                errorMsg={errors?.country?.message}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="country_code"
                inputLabel="Country Code"
                register={register}
                disabled={buttonLoading}
                isError={errors?.country_code?.message}
                errorMsg={errors?.country_code?.message}
              />
            </Grid>
          </Grid>

          <SadActionButtons
            onCancel={() => reset(countryData)}
            buttonLoading={buttonLoading}
          />
        </form>

        <CommonModal
          open={updateModal}
          type="success"
          onConfirm={() => {
            setUpdateModal(false);
            navigate(-1);
          }}
          title="Updated Successfully!"
        />
      </AdminPageLayout>
    </ThemeProvider>
  );
};

export { EditCountryFee };
