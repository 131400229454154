/*
 * ADMIN BROKER'S USERS-ACCOUNTS GRID DATA
 */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Box, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { UsersApis } from "../../../../../api";
import { AllRoutes } from "../../../../../routes";
import ReactTable from "../../../../../React Table";
import { USER_ROLES } from "../../../../../constants";
import {
  CommonModal,
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderTableHeader,
} from "../../../../../components";

const TableData = ({ caption, brokerData }) => {
  const navigate = useNavigate();
  const { broker_code_group } = useParams();
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [hasRecords, setHasRecords] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [listData, setListData] = useState();
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState({
    broker_code_group,
    role: USER_ROLES.broker,
  });

  useEffect(() => {
    const checkRecords = async () => {
      const resp = await UsersApis.getAllUsers({ page, advancedSearch });
      setHasRecords(resp?.data?.results?.length > 0);
      setLoading(resp?.data?.results?.length > 0);
    };
    checkRecords();
  }, []);

  useEffect(() => {
    !!hasRecords && brokersUsersList();
  }, [hasRecords, page, advancedSearch]);

  // Get all broker's users records
  const brokersUsersList = async () => {
    setDataLoading(true);
    let params = {
      page,
      advancedSearch,
    };
    const resp = await UsersApis.getAllUsers(params);
    if (resp) {
      setDataLoading(false);
      setLoading(false);
    }
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
  };

  const resendEmail = async (rowData) => {
    setActionLoading(true);
    const resp = await UsersApis.updateUser({
      user_id: rowData?.id,
      params: { invite: 1 },
    });
    !!resp && setActionLoading(false);
    !!resp?.success && setUpdateModal(true);
  };

  const openActionDropDown = (e, rowData) => {
    const { id } = rowData ?? {};
    setClickable([
      {
        text: "Edit User",
        route: () =>
          navigate(
            `${AllRoutes.EditBrokerUser.route}/${broker_code_group}/${id}`
          ),
      },
      {
        text: "Resend Invite Email",
        route: () => resendEmail(rowData),
      },
    ]);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const tableColumns = [
    {
      id: "Actions",
      header: "Action",
      cell: ({ row }) => (
        <RenderCellInfo
          text={
            <IconButton
              onClick={(e) => openActionDropDown(e, row.original)}
              aria-label={"More actions of " + row.original.application_number}
              disabled={!brokerData?.is_active}
            >
              <MoreHorizIcon />
            </IconButton>
          }
        />
      ),
    },
    {
      id: "Name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Name"
          headerKey="first_name"
        />
      ),
      accessorKey: "first_name",
      cell: (info) => {
        const { last_name } = info?.row?.original ?? {};
        return <RenderCellInfo text={`${info.getValue()} ${last_name}`} />;
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Email",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Email"
          headerKey="email"
        />
      ),
      accessorKey: "email",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Contact No.",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Contact No."
          headerKey="mobile"
        />
      ),
      accessorKey: "mobile",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : hasRecords ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            isUserModule={true}
            page={page}
            setPage={setPage}
            listData={listData}
            onSearch={(e) => {
              setAdvancedSearch((p) => ({ ...p, ["q"]: e }));
              setPage(1);
            }}
            caption={caption}
            dataLoading={dataLoading}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
          />
        </Box>
      ) : (
        <NoRecords />
      )}

      {actionLoading && <GlobalLoader loading={actionLoading} />}

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => setUpdateModal(false)}
        title="Email sent successfully!"
      />
    </>
  );
};

export { TableData };
