/*
 * Common Document Icon component for all grids
 */

import React from "react";
import { IconButton } from "@mui/material";

import { Tooltip } from "../Tooltip";
import { formatString } from "../../utils/CommonUtils";

const RenderDocumentIcon = ({
  icon: Icon,
  info,
  getPolicyDocs,
  policy_doc_id,
  tooltip,
}) => {
  const { id: application_id, docs_being_generated, application_number } = info.row.original;
  const makeButtonLabel = formatString(policy_doc_id) + " for " + application_number;
  return (
    <Tooltip
      title={docs_being_generated ? "Docs are being generated" : tooltip}
    >
      <IconButton
        onClick={() =>
          !docs_being_generated &&
          getPolicyDocs(application_id, policy_doc_id, true)
        }
        sx={{ cursor: docs_being_generated ? "default" : "pointer" }}
        aria-label={makeButtonLabel}
      >
        <Icon fontSize="small" sx={{ color: "#0A7691" }} />
      </IconButton>
    </Tooltip>
  );
};

export { RenderDocumentIcon };
