/*
 * SAD application Constant Data
 */

export const CoverageWithCovid = "C";

export const CoverageWithoutCovid = "H";

export const CoverageTypeValue = (coverage_type) => {
  return coverage_type === CoverageWithCovid
    ? "Surrogate Accidental Death - Coverage for COVID-19"
    : coverage_type === CoverageWithoutCovid
    ? "Surrogate Accidental Death - No Coverage for COVID-19"
    : "";
};

export const SadCoverageTypeLabel = (coverage_type) => {
  return coverage_type === CoverageWithCovid ? "W/ COVID 19" : "W/Out COVID 19";
};

export const Reproductive_organ_loss_rider_value = (
  sad_reproductive_coverage_options_list,
  term_length,
  reproductive_organ_loss_rider_option
) => {
  return sad_reproductive_coverage_options_list?.[term_length]?.filter?.(
    (item) => item.value == reproductive_organ_loss_rider_option
  )?.[0]?.label;
};

export const Disability_rider_value = (
  sad_disability_rider_options_list,
  term_length,
  disability_rider_option
) => {
  return sad_disability_rider_options_list?.[term_length]?.filter?.(
    (item) => item.value == disability_rider_option
  )?.[0]?.label;
};

export const PolicyStatusValue = (application_status_types_list, status) => {
  return status == PolicyTypes.underReview
    ? "Submitted"
    : application_status_types_list?.filter?.(
        (item) => item.value == status
      )?.[0]?.label;
};

export const PolicyStatusList = (application_status_types_list) => {
  const itemsToFilter = [PolicyTypes.draft];
  let filteredList = application_status_types_list
    ?.filter((item) => item !== null)
    ?.filter((status) => !itemsToFilter?.includes?.(status.value));
  const updateList = filteredList?.map((status) => {
    if (status?.value == PolicyTypes.underReview) {
      return { ...status, label: "Submitted" };
    }
    return status;
  });
  const sortedList = updateList?.sort?.((a, b) => {
    if (a?.label < b?.label) return -1;
    if (a?.label > b?.label) return 1;
    return 0;
  });
  return sortedList;
};

export const PolicyTypes = {
  underReview: "UNDER_REVIEW",
  draft: "DRAFT",
  onHold: "ON_HOLD",
  canceled: "CANCELLED",
  cleared: "CLEARED",
  expired: "EXPIRED",
  active: "ACTIVE",
  certificate_issued: "CERTIFICATE_ISSUED",
  onReview: "REVIEW_IN_PROGRESS",
};

export const PaymentTypes = {
  paid: "paid",
  pending: "pending",
  online: "online",
  notAttempted: "n/a",
  offline: "offline",
};

export const InvoiceTypes = {
  cardAuthorization: "CARD_AUTHORIZATION",
  alreadyPaid: "ALREADY_PAID",
};

export const RequestTypes = {
  cancelRequest: "Cancel Request",
  changeRequest: "Change Request",
  payNow: "Pay Now",
  downloadAllDocuments: "Download All Documents",
};

export const Cancelation = "CANCELLATION";

export const CovidCardError = "Covid card with vaccination date is required";

export const PolicyDocTypes = {
  all: "all",
  application: "application",
  certificate: "certificate",
  invoice: "invoice",
  idCardDonor: "id_card_donor",
  idCardRecipient: "id_card_recipient",
};

export const SadFormInitialValues = {
  beneficiaries: [],
  is_currently_pregnant: null,
  confirmation_of_pregnancy_date: null,
  medication_start_date: null,
  coverage_type: null,
  is_covid_vaccinated: null,
  term_length: null,
  indemnity_surrogates_beneficiary: null,
  indemnity_intended_parents: null,
  financial_email: "",

  reproductive_organ_loss_rider: null,
  reproductive_organ_loss_rider_option: null,
  disability_rider: null,
  disability_rider_option: null,

  intended_parent_address_line_1: "",
  intended_parent_country_code: "",
  intended_parent_state_code: "",
  intended_parent_zipcode: null,
  intended_parent_city: "",
  intended_parent1_first_name: "",
  intended_parent1_last_name: "",
  intended_parent1_phone: "",
  intended_parent1_email: "",
  intended_parent2_first_name: "",
  intended_parent2_last_name: "",
  intended_parent2_phone: "",
  intended_parent2_email: "",

  surrogate_first_name: "",
  surrogate_last_name: "",
  surrogate_phone: "",
  surrogate_email: "",
  surrogate_dob: null,
  surrogate_address_line_1: "",
  surrogate_country_code: "",
  surrogate_zipcode: null,
  surrogate_state_code: "",
  surrogate_city: "",

  working_with_agency: "",
  agency_name: null,
  agency_contact_person_name: "",
  agency_country_code: "",
  agency_state_code: "",
  agency_address_line_1: "",
  agency_city: "",
  agency_zipcode: "",
};
