/*
 * Add new agency modal
 */

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Dialog, DialogTitle, DialogContent, Grid } from "@mui/material";

import { AgencyApis } from "../../../api";
import { USER_DATA, USER_ROLE, USER_ROLES } from "../../../constants";
import { LocalStorageHelper } from "../../../utils/HttpUtils";
import {
  AddressInput,
  Button,
  MandatoryStatement,
  PhoneInput,
  TextInput,
} from "../../../components";
import {
  focusFirstErrorField,
  testNonLetter,
} from "../../../utils/CommonUtils";

const AddNewAgency = ({ open, onClose, newAgencyData, setLoading }) => {
  const isUserAgency = LocalStorageHelper.get(USER_ROLE) == USER_ROLES.agency;
  const myData = JSON.parse(LocalStorageHelper.get(USER_DATA));
  const { first_name, last_name, display_name } = myData ?? {};
  const [buttonLoading, setButtonLoading] = useState(false);

  const {
    handleSubmit: handleAgencySubmit,
    register,
    setValue,
    getValues,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      contact_person_name: isUserAgency
        ? display_name || `${first_name || ""} ${last_name || ""}`
        : "",
      contact_number: "",
      email: "",
      email_alt: "",
      address_line_1: "",
      country_code: "US",
      state_code: null,
      city: "",
      zipcode: "",
    },
  });

  const onSubmit = async (values) => {
    setButtonLoading(true);
    let payload = values;
    const { contact_person_name, email_alt } = payload;
    !email_alt && delete payload["email_alt"];
    !contact_person_name && delete payload["contact_person_name"];

    const resp = await AgencyApis.createAgency(payload);
    if (resp) {
      setButtonLoading(false);
    }
    if (resp.success) {
      onClose();
      newAgencyData(resp?.data);
    } else {
      Object.keys(resp?.errors).forEach(function (key) {
        setError(key, { message: resp?.errors[key] });
      });
      focusFirstErrorField();
    }
  };
  // Update mobile number format
  const handlePhoneChange = (name, value) => {
    setValue(name, value);
    setError(name, null);
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="agency-dialog-title"
          aria-describedby="agency-dialog-description"
          scroll="body"
          maxWidth="md"
          fullWidth
        >
          <DialogTitle
            sx={{
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
            id="agency-dialog-title"
          >
            Add New Agency
          </DialogTitle>

          <DialogContent id="agency-dialog-description">
            <MandatoryStatement />

            <form
              onSubmit={(e) => {
                e.preventDefault();
                const values = getValues();
                handleAgencySubmit(onSubmit(values));
                e.stopPropagation();
              }}
            >
              <Grid container columnSpacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextInput
                    inputKey="name"
                    inputLabel="Agency Name"
                    autoComplete="off"
                    disabled={buttonLoading}
                    register={register}
                    registerChange={(e) => {
                      setError(e.target.name, false);
                    }}
                    isError={errors?.name?.message}
                    errorMsg={errors.name?.message}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <TextInput
                    inputKey="contact_person_name"
                    inputLabel="Contact Person Name"
                    name="contact_person_name"
                    autoComplete="off"
                    disabled={buttonLoading}
                    register={register}
                    registerChange={(e) => {
                      setError(e.target.name, false);
                    }}
                    isError={errors?.contact_person_name?.message}
                    errorMsg={errors.contact_person_name?.message}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <PhoneInput
                    inputKey="contact_number"
                    inputLabel="Contact No."
                    autoComplete="off"
                    disabled={buttonLoading}
                    register={register}
                    {...(testNonLetter(watch("contact_number")) && {
                      value: watch("contact_number"),
                    })}
                    registerChange={handlePhoneChange}
                    isError={errors?.contact_number?.message}
                    errorMsg={errors.contact_number?.message}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <TextInput
                    inputKey="email"
                    inputLabel="Email 1"
                    name="email"
                    autoComplete="off"
                    disabled={buttonLoading}
                    register={register}
                    registerChange={(e) => {
                      setError(e.target.name, false);
                    }}
                    isError={errors?.email?.message}
                    errorMsg={errors.email?.message}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <TextInput
                    inputKey="email_alt"
                    inputLabel="Email 2"
                    name="email_alt"
                    autoComplete="off"
                    disabled={buttonLoading}
                    register={register}
                    registerChange={(e) => {
                      setError(e.target.name, false);
                    }}
                    isError={errors?.email_alt?.message}
                    errorMsg={errors.email_alt?.message}
                    required={false}
                  />
                </Grid>
                <AddressInput
                  disabled={buttonLoading}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  watch={watch}
                  addressKey="address_line_1"
                  countryKey="country_code"
                  zipcodeKey="zipcode"
                  stateKey="state_code"
                  cityKey="city"
                  setLoading={setLoading}
                  errors={errors}
                  setError={setError}
                />
              </Grid>
              <Grid
                container
                justifyContent="center"
                sx={{ gap: 2, marginTop: "1rem" }}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    size="large"
                    title="Close"
                    onClick={onClose}
                    disabled={buttonLoading}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    title="Add Agency"
                    loading={buttonLoading}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export { AddNewAgency };
