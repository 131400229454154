/*
 * BROKER SAD- ADD COMMENT ON POLICY
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";

import { NotesApis } from "../../../api";
import { sadApplicationSummary } from "../../../redux/slices/globalSlice";
import { CommonModal, GlobalLoader, TextInput } from "../../../components";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";
import { SadApplicationHeader } from "../../sad/sad-admin/components/SadApplicationHeader";
import { SadActionButtons } from "../../sad/sad-user/components/ActionButtons";

const validationSchema = yup.object().shape({
  description: yup.string().required("Comment is required"),
});

const SADBrokerAddNotes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: application_id } = useParams();
  const [updateModal, setUpdateModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    dispatch(sadApplicationSummary({ application_id }));
  }, []);

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setButtonLoading(true);
    const { description } = data;
    const notesResp = await NotesApis.addNotes({
      application_id,
      description,
    });
    !!notesResp && setButtonLoading(false);
    !!notesResp?.success && setUpdateModal(true);
  };

  return (
    <AdminPageLayout>
      <SadApplicationHeader header="Add Comment" setLoading={setLoading} />

      {loading && <GlobalLoader loading={loading} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          inputKey="description"
          inputLabel="Add Comment"
          multiline
          rows={3}
          register={register}
          registerChange={() => {}}
          isError={errors?.description}
          errorMsg={errors?.description?.message}
        />
        <SadActionButtons
          confirmButtonLabel="Submit"
          buttonLoading={buttonLoading}
        />

        <CommonModal
          open={updateModal}
          type="success"
          onConfirm={() => {
            setUpdateModal(false);
            navigate(-1);
          }}
          title="Comment Added Successfully!"
        />
      </form>
    </AdminPageLayout>
  );
};

export { SADBrokerAddNotes };
