/**
 * Notes APIs
 */

import httpClient from "../utils/HttpUtils";

export const NotesApis = {
  addNotes: async (payload) => {
    const { application_id, description } = payload;
    try {
      const resp = await httpClient.post(
        `applications/${application_id}/notes`,
        { description }
      );
      return resp;
    } catch (error) {
      console.log("Error addNotes", error);
    }
  },

  getAllNotes: async (payload) => {
    const { application_id } = payload;
    try {
      const resp = await httpClient.get(`applications/${application_id}/notes`);
      return resp;
    } catch (error) {
      console.log("Error getAllNotes", error);
    }
  },
};
