import "@fontsource/lato/300.css"; // Lato Light
import "@fontsource/lato/400.css"; // Lato Regular
import "@fontsource/lato/700.css"; // Lato Bold
import "./index.css";

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Error404Component } from "../src/components/Error404.js";
import { getUserRoutes } from "./routes";
import { store } from "./redux/store";
import { LocalStorageHelper } from "./utils/HttpUtils.js";
import { USER_DATA, USER_TOKEN } from "./constants/storageKeys.js";

const ErrorBoundary = ({ ...rest }) => {
  return <Error404Component />;
};

const AllRoutes = getUserRoutes(JSON.parse(LocalStorageHelper.get(USER_DATA)));

const router = createBrowserRouter(
  Object.keys(AllRoutes)?.map((r) => {
    const user = {};
    const Component = AllRoutes[r]?.component
      ? AllRoutes[r]?.component
      : () => {};
    const props = AllRoutes[r]?.props || {};
    const route = `${AllRoutes[r]?.route || "404"}`;
    const children = (AllRoutes[r]?.children || [])?.map((child) => {
      const Component = child?.component ? child?.component : () => {};
      const route = `${child?.route || "404"}`;
      return {
        ...child,
        element: <Component {...{ ...props, user }} />,
        errorElement: <ErrorBoundary />,
        path: route,
      };
    });
    return {
      ...AllRoutes[r],
      errorElement: <ErrorBoundary />,
      path: route,
      element: <Component {...{ ...props, user }} />,
      children,
    };
  })
);

const App = () => {
  // useEffect(() => {
  //   const user = LocalStorageHelper.get(USER_TOKEN);
  //   const currentRoute = window.location.pathname;
  //   // if (!user && currentRoute !== AllRoutes.Login.route) {
  //   //   window.location.href = AllRoutes.Login.route;
  //   // }
  // }, []);

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
