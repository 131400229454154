/*
 * IVF form action buttons
 */

import React from "react";
import { AccordionActions, Button, CircularProgress } from "@mui/material";
import { theme } from "../../../../styles/theme";

const IVFActionButtons = ({
  onPressBack,
  OnSaveExit,
  OnPressContinue,
  disableContinue = false,
  loading,
  disableBack
}) => {
  return (
    <AccordionActions sx={{ justifyContent: "center" }}>
      <Button
        variant="outlined"
        size="large"
        disableElevation
        disabled={disableBack}
        sx={{
          borderRadius: "25px",
          minHeight: "3rem",
          minWidth: "7rem",
          border: `2px ${theme.grey} solid`,
          color: theme.grey,
          display: !!onPressBack ? "block" : "none",
          "&.Mui-disabled": {
            background: theme.lightGrey,
            color: theme.white,
          },
          "&:hover": { background: theme.fadeGrey, border: `2px ${theme.grey} solid` },
        }}
        onClick={onPressBack}
      >
        Back
      </Button>
      <Button
        variant="outlined"
        size="large"
        disableElevation
        sx={{
          display: !!OnSaveExit ? "block" : "none",
          borderRadius: "25px",
          border: `2px ${theme.primaryDark} solid`,
          minHeight: "3rem",
          minWidth: "7rem",
        }}
        onClick={OnSaveExit}
      >
        Save & Exit
      </Button>
      <Button
        variant="contained"
        disableElevation
        type="submit"
        size="large"
        disabled={disableContinue || loading}
        sx={{
          minHeight: "3rem",
          minWidth: "7rem",
          borderRadius: "25px",
          "&.Mui-disabled": {
            background: theme.primaryFade,
            color: theme.white,
          },
        }}
        onClick={OnPressContinue}
      >
        {loading ? (
          <CircularProgress sx={{ color: "white" }} size={24} />
        ) : (
          "Continue"
        )}
      </Button>
    </AccordionActions>
  );
};

export { IVFActionButtons };
