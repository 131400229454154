/*
 * State Tax Table header page
 */

import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import { Dropdown } from "../../../components";

const TableHeader = ({
  setAdvancedSearch,
  setPage,
  country,
  setCountry,
  state,
  setState,
  stateList,
  setSearchParams,
}) => {
  const lookups = useSelector((state) => state.commonReducer.lookups);

  const setSearchObject = (key, value) => {
    setSearchParams((searchParams) => {
      searchParams.set("page", 1);
      searchParams.set([key], value);
      return searchParams;
    });
    setAdvancedSearch((p) => ({ ...p, [key]: value }));
    setPage(1);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ marginTop: "0.3rem", marginBottom: "1.5rem" }}
    >
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Dropdown
          size="small"
          label="Country"
          id="country"
          options={lookups?.countries_list || []}
          selectValue={country}
          value={country}
          disabled
          onChange={(e) => {
            setCountry(e.target.value);
            setSearchObject("country_code", e.target.value);
          }}
          sx={{ backgroundColor: "white" }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Dropdown
          size="small"
          label="State"
          id="state"
          options={stateList || []}
          selectValue={state}
          value={state}
          onChange={(e) => {
            setState(e.target.value);
            setSearchObject("state_code", e.target.value);
          }}
          sx={{ backgroundColor: "white" }}
        />
      </Grid>
    </Grid>
  );
};

export { TableHeader };
