/*
 * Global Loader modal
 */

import React from "react";
import { Box, CircularProgress, Modal, Typography } from "@mui/material";

const GlobalLoader = ({ loading, isSearching = false }) => {
  return (
    <Modal
      open={loading}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ "& > .MuiBackdrop-root": { backgroundColor: "#00000010" } }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
        {!!isSearching && (
          <Typography variant="body1" mt={2} role="status">
            Searching...
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export { GlobalLoader };
