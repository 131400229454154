/*
 * Common Outlined Input
 */

import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

import { countries } from "../constants";
import { trimMiddleSpace } from "../utils/CommonUtils";
import { Tooltip } from "./Tooltip";

const TextInput = ({
  margin = "normal",
  formControlStyle,
  inputKey,
  inputLabel,
  type = "text",
  isPhoneInput = false,
  value,
  onChange,
  onBlur,
  autoComplete,
  required = true,
  isError,
  errorMsg,
  helperText,
  maxLength,
  inputStyle,
  isAuthPage = false,
  register,
  registerChange,
  tooltip,
  ...rest
}) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState("US");
  const selectedCountry = countries?.find(
    (country) => country?.code === selectedCountryCode
  );

  return (
    <FormControl
      variant="outlined"
      fullWidth
      margin={margin}
      sx={formControlStyle}
    >
      <InputLabel
        htmlFor={inputKey}
        shrink
        sx={{
          backgroundColor: "#fff",
          color: "black",
          marginLeft: "0px",
          paddingX: "0.3rem",
        }}
      >
        {inputLabel} {required && <span className="text-danger">*&nbsp;</span>}
        {!!tooltip && <Tooltip tooltip={tooltip} />}
      </InputLabel>
      <OutlinedInput
        id={inputKey}
        name={inputKey}
        label={inputLabel}
        // value={trimMiddleSpace(value || "")}
        // onChange={(e) => e.target.value !== " " && onChange(e)}
        {...(value && { value: trimMiddleSpace(value || "") })}
        {...(onChange && {
          onChange: (e) => e.target.value !== " " && onChange(e),
        })}
        {...(register && {
          ...register(inputKey, {
            onChange: (e) => registerChange && registerChange(e, inputKey),
          }),
        })}
        type={type}
        onBlur={onBlur}
        autoComplete={autoComplete}
        sx={{
          // paddingLeft: "0px",
          ...inputStyle,
          '& input[type="password"]::-ms-reveal': {
            display: "none",
          },
        }}
        startAdornment={
          isPhoneInput && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Select
                id={"country-select-" + inputKey}
                label="Country"
                sx={{
                  "& img": { width: "24px" },
                  "& span": { display: "none" },
                  ".MuiSelect-select": { height: "unset", minHeight: "unset" },
                  boxShadow: "none",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                value={selectedCountryCode}
                onChange={(event) => {
                  setSelectedCountryCode(event.target.value);
                  setTimeout(() => {
                    document.getElementById(inputKey)?.focus();
                  }, 500);
                }}
              >
                {countries?.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                      alt={country.label}
                    />
                    <span style={{ marginLeft: "10px" }}>
                      {`${country.label} (+${country.phone})`}
                    </span>
                  </MenuItem>
                ))}
              </Select>
              <span
                id={`coutry-code-` + inputKey}
                style={{ marginRight: "0.5rem" }}
              >
                {selectedCountry ? `+${selectedCountry.phone}` : ""}
              </span>
            </Box>
          )
        }
        aria-describedby={
          (isError ? "error-" + inputKey : "") +
          (isError && helperText ? " " : "") +
          (helperText ? "helper-text-" + inputKey : "")
        }
        inputProps={{
          "aria-labelledby":
            selectedCountry && isPhoneInput
              ? `${inputKey} coutry-code-${inputKey}`
              : inputKey,
          "aria-required": required ? "true" : undefined,
          maxLength: isPhoneInput ? 13 : maxLength ?? undefined,
        }}
        {...rest}
      />
      {!isAuthPage && isError && (
        <FormHelperText
          error
          sx={{
            marginLeft: 0,
          }}
          id={"error-" + inputKey}
        >
          {errorMsg}
        </FormHelperText>
      )}
      {!!helperText && (
        <FormHelperText
          sx={{
            marginLeft: 0,
            fontStyle: "italic",
            fontWeight: 600,
            fontSize: "0.77rem",
            letterSpacing: 0,
          }}
          id={"helper-text-" + inputKey}
        >
          {helperText}
        </FormHelperText>
      )}
      {isAuthPage && (
        <FormHelperText
          error
          sx={{
            marginLeft: 0,
            height: { xs: "auto", sm: 19.92 },
          }}
          id={"error-" + inputKey}
        >
          {isError ? errorMsg : ""}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export { TextInput };
