/**
 * Payment APIs
 */

import httpClient from "../utils/HttpUtils";
import { envBasedFeUrl } from "../utils/CommonUtils";

export const PaymentApis = {
  createPayment: async ({ invoice_id, insurance_type, payment_mode = "" }) => {
    const resp = await httpClient.post(`/invoices/${invoice_id}/pay`, {
      payment_mode,
      redirect_url: `${envBasedFeUrl()}payment/${insurance_type}/${invoice_id}`,
    });
    return resp;
  },
};
