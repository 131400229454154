/*
 * No data found in table component
 */

import React from "react";
import { Typography, Button, Box } from "@mui/material";

import { theme } from "../styles/theme";
import NoRecordsImg from "../../src/layouts/images/No_Records.svg";

const NoRecords = ({ onPress }) => {
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      display="flex"
      flexDirection="column"
      sx={{ height: { xs: "90%", sm: "90%", md: "70vh" } }}
    >
      <img
        src={NoRecordsImg}
        alt=""
        style={{ height: "100px", width: "180px", marginBottom: "2rem" }}
      />
      <Typography
        sx={{ fontWeight: "bold", lineHeight: "1rem", marginBottom: "1rem" }}
        role="alert"
      >
        No Records to display
      </Typography>
      {!!onPress && (
        <>
          <Typography
            sx={{
              color: theme.grey,
              fontWeight: "600",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            Click the 'New Application' button to initiate a new application
            request
          </Typography>
          <Button
            variant="contained"
            size="large"
            disableElevation
            style={{ border: theme.primary }}
            onClick={onPress}
            sx={{
              borderRadius: "25px",
              marginBottom: "1rem",
            }}
          >
            New Application
          </Button>
        </>
      )}
    </Box>
  );
};

export { NoRecords };
