/*
 * ADMIN CHANGE-CANCEL-REQUEST GRID DATA
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Typography, IconButton } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CancelIcon from "@mui/icons-material/Cancel";

import { AllRoutes } from "../../../routes";
import ReactTable from "../../../React Table";
import { Cancelation, PaymentTypes } from "../../sad/constants";
import { formattedMDYDate } from "../../../utils/CommonUtils";
import {
  GlobalLoader,
  NoRecords,
  RenderCellInfo,
  RenderChip,
  RenderTableHeader,
  Tooltip,
} from "../../../components";
import {
  coverageTypeLabel,
  INSURANCE_TYPES,
  typeOfChangeLabel,
} from "../../../constants";

const filterOptions = [
  { value: "OPEN", label: "Open" },
  { value: "CLOSE", label: "Close" },
];
const secondFilterOptions = [
  { value: "", label: "Change Request" },
  { value: Cancelation, label: "Cancel Request" },
];

const TableData = ({
  caption,
  hasRecords,
  dataList,
  listData,
  setSearchText,
  setSortKey,
  setSortOrder,
  requestStatus,
  setRequestStatus,
  requestType,
  setRequestType,
  page,
  setPage,
  loading,
  dataLoading,
  setAdvancedSearch,
  setSearchButton,
  setSearchParams,
}) => {
  const navigate = useNavigate();
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const { sad_change_request_types_list, ivf_change_request_types_list } =
    lookups ?? {};

  const handleFilterChange = (e, key) => {
    setPage(1);
    setSearchButton(true);
    setSearchParams((searchParams) => {
      searchParams.set("page", 1);
      searchParams.set(key, e.target.value);
      return searchParams;
    });
    setAdvancedSearch((p) => ({ ...p, [key]: e.target.value }));
  };

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  const handleActionIconClick = (row) => {
    const actionStatus = row?.status;
    if (actionStatus === "CLOSE") {
      navigate(`${AllRoutes.ViewResponse.route}/${row.id}`);
    } else {
      navigate(`${AllRoutes.AddResponse.route}/${row.id}`);
    }
  };

  const DescriptionCell = ({ description }) => {
    const [expanded, setExpanded] = useState(false);
    const truncatedDescription = expanded
      ? description
      : description?.slice?.(0, 20) + (description?.length > 20 ? "..." : "");

    return (
      <div className="text-center">
        <Typography sx={{ wordWrap: "break-word", width: "18rem" }}>
          {truncatedDescription}
          {description?.length > 20 && (
            <Tooltip title={expanded ? "Show less" : "Show more"}>
              <span
                style={{ cursor: "pointer", color: "#0A7691" }}
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? " (less)" : " (more)"}
              </span>
            </Tooltip>
          )}
        </Typography>
      </div>
    );
  };

  const tableColumns = [
    {
      id: "Action",
      header: "Action",
      cell: ({ row }) => {
        const actionStatus = row.original["status"];
        return (
          <Tooltip title={actionStatus === "CLOSE" ? "View" : "Add Response"}>
            <IconButton
              onClick={() => handleActionIconClick(row.original)}
              aria-label={
                actionStatus === "CLOSE"
                  ? "View policy of " + row.original?.application_number
                  : "Add Response for " + row.original?.application_number
              }
            >
              {actionStatus === "CLOSE" ? <VisibilityIcon /> : <EditNoteIcon />}
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      id: "Request Status",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Request Status"
          headerKey="status"
        />
      ),
      accessorKey: "status",
      cell: (info) => {
        const reqStatus = info.getValue() ? info.getValue() : "OPEN";
        if (reqStatus === "OPEN") {
          return (
            <RenderChip
              label="Open"
              color="#097997"
              backgroundColor="#0DAFD91A"
              alignment="center"
            />
          );
        } else {
          return (
            <RenderChip label="Close" color="#6E6968" alignment="center" />
          );
        }
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Created On",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Created On"
          headerKey="created_at"
        />
      ),
      accessorKey: "created_at",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Closed On",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Closed On"
          headerKey="closed_on"
        />
      ),
      accessorKey: "closed_on",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Policy Number",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Number"
          headerKey="application_number"
        />
      ),
      accessorKey: "application_number",
      cell: (info) => (
        <RenderCellInfo text={info.getValue()} sx={{ color: "#0A7691" }} />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Type of Change",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Type of Change"
          headerKey="type_of_change"
        />
      ),
      accessorKey: "type_of_change",
      cell: (info) => {
        const { insurance_type, type_of_change } = info.row?.original;
        const request_types_list =
          insurance_type == INSURANCE_TYPES.ivf
            ? ivf_change_request_types_list
            : sad_change_request_types_list;
        return (
          <RenderCellInfo
            text={typeOfChangeLabel(request_types_list, type_of_change)}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Coverage Type",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Coverage Type"
          headerKey="coverage_type"
        />
      ),
      accessorKey: "coverage_type",
      cell: (info) => {
        const { coverage_type, insurance_type } = info.row?.original;
        return (
          <RenderCellInfo
            text={coverageTypeLabel(insurance_type, coverage_type)}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Status",
      header: "Payment Status",
      accessorKey: "payment_status",
      cell: (info) => {
        const { invoice } = info.row.original ?? {};
        if (!invoice?.id) {
          return "";
        }
        const paymentStatus = invoice?.payment_status;
        let icon;
        if (paymentStatus == PaymentTypes.paid) {
          icon = (
            <>
              <Tooltip title="Paid">
                <CheckCircleIcon
                  fontSize="small"
                  className="none-text-green"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Paid</span>
            </>
          );
        } else if (paymentStatus === PaymentTypes.pending) {
          icon = (
            <>
              <Tooltip title="Pending">
                <WatchLaterIcon
                  fontSize="small"
                  className="none-text-orange"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Pending</span>
            </>
          );
        } else {
          icon = (
            <>
              <Tooltip title="Unpaid">
                <CancelIcon
                  fontSize="small"
                  className="none-text-red"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Unpaid</span>
            </>
          );
        }

        return (
          <div style={{ textAlign: "center", margin: "auto" }}>{icon}</div>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Requested By",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Requested By"
          headerKey="requested_by"
        />
      ),
      accessorKey: "requested_by",
      cell: (info) => {
        const { first_name, last_name, email } =
          info?.row?.original?.requested_by;
        const fullname = `${first_name ?? ""} ${last_name ?? ""}`;
        return (
          <>
            <RenderCellInfo text={fullname} />
            <RenderCellInfo text={email} />
          </>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Description",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Description"
          headerKey="description"
        />
      ),
      accessorKey: "description",
      cell: (info) => <DescriptionCell description={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Supporting Documents",
      header: "Supporting Documents",
      accessorKey: "documents",
      cell: (info) => (
        <RenderCellInfo
          text={info.row.original?.documents?.map((doc) => (
            <Tooltip key={doc.id} title="View Document">
              <IconButton>
                <a href={doc.url} target="_blank" rel="noopener noreferrer">
                  <DescriptionIcon fontSize="small" sx={{ color: "#0A7691" }} />
                </a>
              </IconButton>
            </Tooltip>
          ))}
        />
      ),
      footer: (props) => props.column.id,
    },
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : hasRecords ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            padding="0.7rem 0.5rem"
            page={page}
            setPage={handlePageChange}
            listData={listData}
            onSearch={setSearchText}
            caption={caption}
            filterOptions={filterOptions}
            secondFilterOptions={secondFilterOptions}
            dataLoading={dataLoading}
            showFilterDropdown={true}
            label="Request Status"
            firstFilterValue={requestStatus}
            handleFirstFilterChange={(e) => {
              handleFilterChange(e, "status");
              setRequestStatus(e.target.value);
            }}
            secondLabel="Request Type"
            secondFilterValue={requestType}
            handleSecondfilterChange={(e) => {
              handleFilterChange(e, "type_of_change");
              setRequestType(e.target.value);
            }}
            setDataFetching={setSearchButton}
          />
        </Box>
      ) : (
        <NoRecords />
      )}
    </>
  );
};

export { TableData };
