/*
 * IVF ADMIN Edit Form- Notes tab // 2nd tab
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { NotesApis } from "../../../../api";
import { Typography, Avatar, Divider, Box } from "@mui/material";
import { CommonModal, TextInput } from "../../../../components";
import { formattedMDYDate } from "../../../../utils/CommonUtils";
import { SadActionButtons } from "../../../sad/sad-admin/components/ActionButtons";

const Notes = ({ setLoading }) => {
  const { id: application_id } = useParams();
  const [description, setDescription] = useState("");
  const [data, setData] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    getNotes();
  }, []);

  const getNotes = async () => {
    setLoading(true);
    const res = await NotesApis.getAllNotes({ application_id });
    !!res && setLoading(false);
    setData(res?.data);
  };

  const onAddNote = async () => {
    setButtonLoading(true);
    const resp = await NotesApis.addNotes({ application_id, description });
    !!resp && setButtonLoading(false);
    if (resp?.success) {
      setUpdateModal(true);
      getNotes();
      setDescription(" ");
    }
  };

  return (
    <>
      <TextInput
        inputKey="description"
        inputLabel="Add Comment"
        sx={{ width: "100%" }}
        multiline
        rows={6}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        variant="outlined"
        required={false}
      />

      <SadActionButtons
        onCancel={() => setDescription(" ")}
        onUpdate={() => description?.trim()?.length && onAddNote()}
        buttonLoading={buttonLoading}
        disableContinue={!description?.trim()?.length}
      />

      <Box sx={{ display: !data?.length && "none" }}>
        <Typography
          component="h2"
          sx={{ paddingTop: "12px", fontWeight: "bold" }}
        >
          Previous Notes
        </Typography>
        {data?.map((values) => {
          const { created_at, description, id, created_by } = values ?? {};
          const { first_name, profile_image } = created_by ?? {};
          return (
            <div>
              <Typography sx={{ fontSize: "13px", paddingTop: "12px" }}>
                {formattedMDYDate(created_at)}
              </Typography>
              <Typography sx={{ fontSize: "14px", paddingY: "8px" }} key={id}>
                {description}
              </Typography>
              <div className="d-flex align-center">
                <Avatar alt={first_name || ""} src={profile_image || ""} />
                <Typography
                  sx={{
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    fontSize: "16px",
                  }}
                  component="span"
                >
                  {first_name || ""}
                </Typography>
              </div>
              <Divider sx={{ paddingTop: "16px" }} />
            </div>
          );
        })}
      </Box>

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => setUpdateModal(false)}
        title="Updated Successfully!"
      />
    </>
  );
};

export { Notes };
