import React from "react";
import { theme } from "./../styles/theme";
import Typography from "@mui/material/Typography";

export const MandatoryStatement = ({ singleInput }) => {
  return (
    <Typography
      sx={{
        fontSize: "0.8rem",
        fontWeight: "bold",
        paddingBottom: "0.5rem",
        color: theme.grey,
      }}
    >
      {singleInput ? " Field" : " Fields"} marked with asterisk (
      <span style={{ color: "#DC3545" }}>*</span>){singleInput ? " is" : " are"}{" "}
      mandatory.
    </Typography>
  );
};
