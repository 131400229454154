import React from "react";
import {
  Autocomplete,
  TextField,
  Button,
  Paper,
  FormHelperText,
} from "@mui/material";

const AutoComplete = ({
  id,
  options,
  label,
  optionLabel,
  setShowAddNewAgency,
  placeholder = "",
  value,
  onChange,
  inputValue,
  onInputChange,
  required = true,
  isError,
  errorMsg,
  helperText,
  buttonText,
  ...rest
}) => {
  return (
    <div className="App">
      <Autocomplete
        disablePortal
        id={id}
        getOptionLabel={optionLabel}
        options={options}
        value={value}
        onChange={(_, value) => onChange(value)}
        inputValue={inputValue}
        onInputChange={onInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            label={
              required ? (
                <>
                  {label}
                  <span className="text-danger"> *</span>
                </>
              ) : (
                label
              )
            }
            InputLabelProps={{ shrink: true, style: { color: "black" } }}
            inputProps={{
              ...params.inputProps,
              "aria-controls": "list",
              "aria-haspopup": "listbox",
              "aria-describedby": `${
                (isError ? "error-" + id : "") +
                (isError && helperText ? " " : "") +
                (helperText ? "helper-text-" + id : "")
              }`,
            }}
          />
        )}
        sx={{ marginTop: "1rem" }}
        {...(buttonText && {
          PaperComponent: ({ children }) => {
          return (
            <Paper>
              <div tabIndex="0">{children}</div>
              <Button
                color="primary"
                fullWidth
                sx={{ justifyContent: "flex-start", pl: 2 }}
                onMouseDown={() => setShowAddNewAgency(true)}
              >
                + {buttonText}
              </Button>
            </Paper>
          );
          },
        })}
        {...rest}
      />
      {isError && (
        <FormHelperText
          error
          sx={{
            marginLeft: 0,
          }}
          id={"error-" + id}
        >
          {errorMsg}
        </FormHelperText>
      )}
      {!!helperText && (
        <FormHelperText
          sx={{
            marginLeft: 0,
            fontStyle: "italic",
            fontWeight: 600,
            fontSize: "0.77rem",
            letterSpacing: 0,
          }}
          id={"helper-text-" + id}
        >
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
};

export { AutoComplete };
