/*
 * A D M I N --- M O D U L E-  B R O K E R S   T A B L E   D A T A
 */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Link } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import { AllRoutes } from "../../../../routes";
import { BrokerApis } from "../../../../api";
import { strings } from "../../../../constants";
import ReactTable from "../../../../React Table";
import {
  CommonModal,
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderChip,
  RenderTableHeader,
  Tooltip,
} from "../../../../components";
import {
  copyToClipboard,
  envBasedFeUrl,
  truncateString,
} from "../../../../utils/CommonUtils";

const TableData = ({ caption }) => {
  const navigate = useNavigate();
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [hasRecords, setHasRecords] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [listData, setListData] = useState();
  const [sortKey, setSortKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [sortOrder, setSortOrder] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState({});

  useEffect(() => {
    const checkRecords = async () => {
      const resp = await BrokerApis.getBrokerList();
      setHasRecords(resp?.data?.results?.length > 0);
      setLoading(resp?.data?.results?.length > 0);
    };
    checkRecords();
  }, []);

  useEffect(() => {
    !!hasRecords && brokersList();
  }, [hasRecords, page, advancedSearch]); // Removed sortKey, sortOrder to avoid sorting from API

  // Get SAD records
  const brokersList = async () => {
    setDataLoading(true);
    const params = {
      sortOrder: "", // Remove "" to sort from API
      sortKey: "", // Remove "" to sort from API
      page,
      advancedSearch,
      limit: true,
    };
    const resp = await BrokerApis.getBrokerList(params);
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
    if (resp) {
      setDataLoading(false);
      setLoading(false);
    }
  };

  const updateBrokerStatus = async (broker_id, status) => {
    setActionLoading(true);
    const resp = await BrokerApis.updateBroker({
      broker_id,
      params: { is_active: status },
    });
    !!resp && setActionLoading(false);
    if (resp?.success) {
      brokersList();
      setUpdateModal(true);
    }
  };

  const openActionDropDown = (e, rowData) => {
    const { id, code, is_active } = rowData ?? {};

    setClickable([
      {
        text: strings.editBroker,
        route: () => navigate(`${AllRoutes.EditBroker.route}/${id}`),
      },
      {
        text: "View Users",
        route: () => navigate(`${AllRoutes.BrokerUsers.route}/${code}/${id}`),
      },
      {
        text: is_active ? "Inactivate" : "Activate",
        route: () => updateBrokerStatus(id, is_active ? 0 : 1),
      },
    ]);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const handleCopyToClipboard = async (text) => {
    try {
      await copyToClipboard(text);
      alert(`Copied to clipboard.`);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const getFullUrl = (url) => {
    if (
      url &&
      !url?.startsWith?.("http://") &&
      !url?.startsWith?.("https://")
    ) {
      return `http://${url}`;
    }
    return url;
  };

  const tableColumns = [
    {
      id: "Actions",
      header: "Action",
      cell: ({ row }) => (
        <IconButton
          onClick={(e) => openActionDropDown(e, row.original)}
          aria-label={"More actions for " + row.original?.name}
        >
          <MoreHorizIcon />
        </IconButton>
      ),
    },
    {
      id: "Status",
      accessorKey: "is_active",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Status"
          headerKey="is_active"
        />
      ),
      cell: (info) => {
        const is_active = info.getValue();
        return (
          <RenderChip
            label={is_active ? "Active" : "Inactive"}
            color={is_active ? "#0E8C84" : "#C0580E"}
            backgroundColor={is_active ? "#0E8C841A" : "#C0580E1A"}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Broker Name"
          headerKey="name"
        />
      ),
      accessorKey: "name",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Insurance Type",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Insurance Type"
          headerKey="insurance_type"
        />
      ),
      accessorKey: "insurance_type",
      cell: (info) => <RenderCellInfo text={info.getValue() || "ALL"} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Sharable Link",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Sharable Link"
          headerKey="code"
        />
      ),
      accessorKey: "code",
      cell: (info) => {
        const brokerUrl = `${envBasedFeUrl()}?broker_code=${info.getValue()}`;
        return (
          <RenderCellInfo
            text={
              info.getValue() ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Link
                    href={brokerUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary"
                  >
                    {truncateString(brokerUrl)}
                  </Link>
                  <Tooltip title="Copy to Clipboard">
                    <IconButton
                      aria-label={`copy sharable link for ${info?.row?.original?.name}`}
                      onClick={() => handleCopyToClipboard(brokerUrl)}
                    >
                      <FileCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                ""
              )
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Website Link",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Website Link"
          headerKey="website"
        />
      ),
      accessorKey: "website",
      cell: (info) => (
        <RenderCellInfo
          text={
            <Link
              href={getFullUrl(info.getValue())}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              {info.getValue()}
            </Link>
          }
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Contact No.",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Contact No."
          headerKey="contact_number"
        />
      ),
      accessorKey: "contact_number",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : hasRecords ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            isUserModule={true}
            page={page}
            setPage={setPage}
            listData={listData}
            onSearch={(e) => {
              setAdvancedSearch({ ["q"]: e });
              setPage(1);
            }}
            caption={caption}
            dataLoading={dataLoading}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
            selectedRow={selectedRow}
          />
        </Box>
      ) : (
        <NoRecords />
      )}

      {actionLoading && <GlobalLoader loading={actionLoading} />}

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => setUpdateModal(false)}
        title="Status Updated Successfully!"
      />
    </>
  );
};

export { TableData };
