/**
 * All Sagas
 * @format
 */

import { call, put, takeLatest } from "redux-saga/effects";

import {
  sadApplicationSummary,
  sadGetBeneficiaries,
  setSadBeneficiaries,
  setSadSummary,
  ivfApplicationSummary,
  setIvfSummary,
  ivfAllApplicationsSummary,
} from "../slices/globalSlice";
import httpClient from "../../utils/HttpUtils";

/* Get SAD application Summary API */
function* sadApplicationSummarySaga({ payload }) {
  const { application_id } = payload;
  try {
    const resp = yield call(httpClient.get, `applications/${application_id}`);
    yield put(setSadSummary(resp?.data));
  } catch (error) {
    console.log("sadApplicationSummary error", error);
  }
}

/* Get SAD beneficiaries API */
function* sadGetBeneficiariesSaga({ payload }) {
  const { application_id } = payload;
  try {
    const resp = yield call(
      httpClient.get,
      `applications/${application_id}/beneficiaries`
    );
    yield put(setSadBeneficiaries(resp?.data ?? []));
  } catch (error) {
    console.log("sadGetBeneficiaries error", error);
  }
}

/* Get IVF application Summary API */
function* ivfApplicationSummarySaga({ payload }) {
  const { application_id } = payload;
  try {
    const resp = yield call(httpClient.get, `applications/${application_id}`);
    yield put(setIvfSummary([resp?.data]));
  } catch (error) {
    console.log("ivfApplicationSummary error", error);
  }
}

/* Get IVF all applications Summary API */
function* ivfAllApplicationsSummarySaga({ payload }) {
  const { parent_id } = payload;
  try {
    const resp = yield call(httpClient.get, `application_groups/${parent_id}`);
    yield put(setIvfSummary(resp?.data));
  } catch (error) {
    console.log("ivfAllApplicationsSummarySaga error", error);
  }
}

function* rootSaga() {
  yield takeLatest(sadApplicationSummary, sadApplicationSummarySaga);
  yield takeLatest(sadGetBeneficiaries, sadGetBeneficiariesSaga);
  yield takeLatest(ivfApplicationSummary, ivfApplicationSummarySaga);
  yield takeLatest(ivfAllApplicationsSummary, ivfAllApplicationsSummarySaga);
}

export { rootSaga };
