/**
 * Broker APIs
 */

import httpClient from "../utils/HttpUtils";

export const BrokerApis = {
  // we consider limit false for dropdowns (to show all data)
  // and true for grid (to show 20 data)
  getBrokerList: async (payload) => {
    const {
      sortOrder,
      sortKey,
      page,
      limit = false,
      advancedSearch,
    } = payload ?? {};
    let reqUrl = `/brokers`;
    if (payload) {
      reqUrl += `?sort=${sortOrder ? "-" : ""}${sortKey}&limit=${
        limit ? 20 : -1
      }`;
      if (page) {
        reqUrl += `&page=${page}`;
      }
    }

    if (advancedSearch && Object?.keys?.(advancedSearch)?.length > 0) {
      let filtersPart = "";
      for (const [key, value] of Object.entries(advancedSearch)) {
        filtersPart += `&filters[${key}]=${value}`;
      }
      reqUrl += filtersPart;
    }

    try {
      const resp = httpClient.get(reqUrl);
      return resp;
    } catch (error) {
      console.log("Error getBrokerList:", error);
    }
  },

  addBroker: async (payload) => {
    try {
      const resp = await httpClient.post("brokers", payload);
      return resp;
    } catch (error) {
      console.log("Error addBroker", error);
    }
  },

  getBrokerDetail: async ({ broker_id }) => {
    try {
      const resp = await httpClient.get(`brokers/${broker_id}`);
      return resp;
    } catch (error) {
      console.log("Error getBrokerDetail", error);
    }
  },

  updateBroker: async ({ broker_id, params }) => {
    try {
      const resp = await httpClient.put(`brokers/${broker_id}`, params);
      return resp;
    } catch (error) {
      console.log("Error updateBroker", error);
    }
  },
};
