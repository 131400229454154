/*
 * A D M I N --- M O D U L E-S T A T E T A X--- T A B L E   D A T A
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import { AllRoutes } from "../../../routes";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ReactTable from "../../../React Table";
import {
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderDescriptionCell,
  RenderTableHeader,
} from "../../../components";

const TableData = ({
  caption,
  dataList,
  listData,
  setSortKey,
  setSortOrder,
  country,
  state,
  page,
  setPage,
  loading,
  dataLoading,
  setSearchParams,
}) => {
  const navigate = useNavigate();
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  const openActionDropDown = (e, rowData) => {
    const { id } = rowData ?? {};

    setClickable([
      {
        text: "Edit Tax",
        route: () => {
          navigate(`${AllRoutes.EditStateTax.route}/${id}/${country}/${state}`);
        },
      },
    ]);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const tableColumns = [
    {
      id: "Action",
      header: "Action",
      cell: ({ row }) => (
        <RenderCellInfo
          text={
            <IconButton onClick={(e) => openActionDropDown(e, row.original)}>
              <MoreHorizIcon />
            </IconButton>
          }
        />
      ),
    },
    {
      id: "State Code",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="State Code"
          headerKey="state_code"
        />
      ),
      accessorKey: "state_code",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Fee Label",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Fee Label"
          headerKey="label"
        />
      ),
      accessorKey: "label",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Fee",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Fee"
          headerKey="fee_percent"
        />
      ),
      accessorKey: "fee_percent",
      cell: (info) => {
        const { fee_flat, fee_percent } = info?.row?.original;
        return (
          <RenderCellInfo
            text={fee_percent > 0 ? `${fee_percent}%` : `$${fee_flat}`}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Notes",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Notes"
          headerKey="notes"
        />
      ),
      accessorKey: "notes",
      cell: (info) => (
        <RenderDescriptionCell
          description={info.getValue() || ""}
          cellWidth="100%"
        />
      ),
      footer: (props) => props.column.id,
    },
  ];

  if (loading) return <GlobalLoader loading={loading} />;

  return (
    <>
      {dataList?.length > 0 ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            page={page}
            setPage={handlePageChange}
            listData={listData}
            caption={caption}
            dataLoading={dataLoading}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
          />
        </Box>
      ) : (
        <NoRecords />
      )}
    </>
  );
};

export { TableData };
