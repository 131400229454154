/*
 * Policy Extension Order Summary
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Grid, Typography, Divider } from "@mui/material";

import { SadActionButtons } from "../sad-user/components/ActionButtons";
import { INSURANCE_TYPES } from "../../../constants";
import { PaymentApis, SadApis } from "../../../api";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";
import { GlobalLoader } from "../../../components";

const PolicyExtensionSummary = () => {
  const navigate = useNavigate();
  const { id: application_id } = useParams();
  const [applicationData, setApplicationData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { extension_invoice } = applicationData ?? {};
  const { line_items, payment_credits, total } = extension_invoice ?? {};

  useEffect(() => {
    window.scrollTo(0, 0);
    getApplicationData();
  }, []);

  const getApplicationData = async () => {
    const resp = await SadApis.getApplicationData({ application_id });
    !!resp && setLoading(false);
    !!resp?.success && setApplicationData(resp?.data);
  };

  const createPayment = async () => {
    setButtonLoading(true);
    let payload = {
      invoice_id: extension_invoice?.id,
      insurance_type: INSURANCE_TYPES.sad,
    };

    try {
      const resp = await PaymentApis.createPayment(payload);
      !!resp && setButtonLoading(false);
      !!resp?.success && (window.location = resp?.data?.url);
    } catch (error) {
      console.log("createPayment", error);
    }
  };

  const DividerLine = ({ sx }) => {
    return (
      <Grid container sx={sx}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider aria-hidden="true" />
        </Grid>
      </Grid>
    );
  };

  const OrderSummary = ({ options = [] }) => {
    return (
      <Grid container>
        {!!options &&
          options?.map((option) => (
            <>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "2rem",
                    paddingLeft: "25px",
                  }}
                >
                  {option?.label}:
                </Typography>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    lineHeight: "2rem",
                    textAlign: "right",
                  }}
                >
                  ${option?.total}
                </Typography>
              </Grid>
            </>
          ))}
      </Grid>
    );
  };

  return (
    <AdminPageLayout>
      {loading && <GlobalLoader loading={loading} />}

      <Typography
        component="h2"
        sx={{
          fontSize: "1.05em",
          fontWeight: "bold",
          lineHeight: "3rem",
          bgcolor: "#E7EAEC",
          paddingLeft: "20px",
          marginY: "1rem",
        }}
      >
        Order Summary
      </Typography>

      <OrderSummary options={line_items} />
      <DividerLine />
      <DividerLine />

      <OrderSummary
        options={[{ total: total ?? "0.00", label: <b>{"Total"}</b> }]}
      />
      <DividerLine />
      <DividerLine />

      <OrderSummary
        options={[
          {
            total: payment_credits || "0.00",
            label: <b>{"Payment/Credits"}</b>,
          },
          { total: total ?? "0.00", label: <b> {"Balance Due"}</b> },
        ]}
      />
      <DividerLine />
      <DividerLine sx={{ marginBottom: "3rem" }} />

      {/* Action Buttons */}
      <SadActionButtons
        OnPressContinue={createPayment}
        confirmButtonLabel="Pay Now"
        buttonLoading={buttonLoading}
        onPressBack={() => navigate(-1)}
      />
    </AdminPageLayout>
  );
};

export { PolicyExtensionSummary };
