/*
 * ADMIN ALL-BROKERS-USERS GRID
 */

import React, { useEffect, useState } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import AddIcon from "@mui/icons-material/Add";

import { TableData } from "./TableData";
import { BrokerApis } from "../../../../../api";
import { AllRoutes } from "../../../../../routes";
import { Button, GoBack } from "../../../../../components";
import { AdminPageLayout } from "../../../../../layouts/admin-layout/AdminPageLayout";

const BrokerUsers = () => {
  const navigate = useNavigate();
  const { broker_code_group, broker_id } = useParams();
  const [brokerData, setBrokerData] = useState([]);

  useEffect(() => {
    const getBrokerData = async () => {
      const resp = await BrokerApis.getBrokerDetail({ broker_id });
      !!resp?.success && setBrokerData(resp?.data);
    };
    getBrokerData();
  }, []);

  return (
    <AdminPageLayout>
      <Grid container sx={{ marginBottom: "1.5rem" }}>
        <Grid item xs={6} sm={7} md={8} lg={9} xl={10}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <GoBack />
            <Typography variant="h1" sx={{ margin: "0" }}>{`${
              brokerData?.name ? `${brokerData?.name}'s Users` : ""
            }`}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={5} md={4} lg={3} xl={2}>
          <Button
            title="Add New User"
            variant="contained"
            sx={{ width: "100%", minHeight: "2rem" }}
            startIcon={<AddIcon sx={{ color: "#FFFFFF" }} />}
            disabled={!brokerData?.is_active}
            onClick={() =>
              navigate(
                `${AllRoutes.AddNewBrokerUser.route}/${broker_code_group}`
              )
            }
          />
        </Grid>
      </Grid>

      <TableData
        caption={`${
          brokerData?.name ? `${brokerData?.name}'s Users` : "Broker's Users"
        }`}
        brokerData={brokerData}
      />
    </AdminPageLayout>
  );
};

export { BrokerUsers };
