/*
 * IVF User Agreement
 */

import React, { useEffect, useState } from "react";
import {
  Grid,
  Checkbox,
  Typography,
  FormControlLabel,
  Box,
} from "@mui/material";

import { IVFActionButtons } from "../components/ActionButtons";
import { TextInput } from "../../../../components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IvfApis } from "../../../../api";
import { useDispatch } from "react-redux";
import { trimMiddleSpace } from "../../../../utils/CommonUtils";
import {
  ivfAllApplicationsSummary,
  ivfApplicationSummary,
} from "../../../../redux/slices/globalSlice";

const IVFUserAgreement = ({ setActiveStep, parentId }) => {
  const dispatch = useDispatch();
  const { id: application_id } = useParams();
  const agreementData = useSelector(
    (state) => state.ivfReducer.ivfUserAgreement
  );
  const summaryData = useSelector((state) => state.ivfReducer.ivfSummary);
  const [name, setName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { agreement_signature_name } = summaryData?.[0] ?? {};

  useEffect(() => {
    !!agreement_signature_name && setName(agreement_signature_name);
  }, []);

  const saveUserAgreement = async () => {
    setButtonLoading(true);
    const resp = await IvfApis.syncApplication({
      parent_id: parentId,
      params: {
        agreed_agreement_id: agreementData?.id,
        agreement_signature_name: name,
      },
    });
    setButtonLoading(false);
    if (resp?.success) {
      dispatch(ivfAllApplicationsSummary({ parent_id: parentId }));
      setActiveStep(3);
    }
  };

  const saveUserAgreementId = async () => {
    setButtonLoading(true);
    const result = await IvfApis.updateApplication({
      application_id: application_id,
      params: {
        agreed_agreement_id: agreementData?.id,
        agreement_signature_name: name,
      },
    });
    setButtonLoading(false);
    dispatch(ivfApplicationSummary({ application_id }));
    result?.success && setActiveStep(3);
  };

  // Add line breaks in data from API
  const formatApiData = () => {
    const formattedData = agreementData?.content
      ?.split("\n")
      ?.map((line, index) =>
        line?.trim() ? (
          <React.Fragment key={index}>
            <p className="mb-1">{line}</p>
          </React.Fragment>
        ) : null
      )
      .filter(Boolean);
    return <>{formattedData}</>;
  };

  return (
    <>
      {/* Page content */}
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextInput
            inputKey="applicant_signature"
            inputLabel="Applicant Signature"
            autoComplete="off"
            helperText="Note: Please Enter a Legal Name"
            disabled={buttonLoading}
            value={name}
            onChange={(e) => setName(trimMiddleSpace(e.target.value))}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Box
            sx={{
              fontSize: "0.8rem",
              lineHeight: "1.5rem",
              marginTop: "1.5rem",
            }}
          >
            {formatApiData()}
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                disabled={!agreementData}
                onChange={() => setIsChecked(!isChecked)}
              />
            }
            label={
              <Typography component={"span"}>
                <strong>I Accept, E-Signature Agreement</strong>
              </Typography>
            }
          />
        </Grid>
      </Grid>

      {/* Action Buttons */}
      <IVFActionButtons
        onPressBack={() => setActiveStep(1)}
        disableBack={buttonLoading}
        OnPressContinue={() => {
          if (application_id) {
            saveUserAgreementId();
          } else {
            saveUserAgreement();
          }
        }}
        loading={buttonLoading}
        disableContinue={!isChecked || !name}
      />
    </>
  );
};

export { IVFUserAgreement };
