/*
 * IVF Data Table
 */

import React from "react";
import { Typography, Box } from "@mui/material";

import { IVFTableComponent } from "./TableData";
import { AdminPageLayout } from "../../../../layouts/admin-layout/AdminPageLayout";

const IVFPolicies = () => {
  return (
    <AdminPageLayout>
      <Box>
        <Typography variant="h1">IVF Cycle Complications Insurance</Typography>
      </Box>
      <IVFTableComponent caption="IVF Cycle Complications Insurance" />
    </AdminPageLayout>
  );
};

export { IVFPolicies };
