/*
 * Redux store
 */

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import { ivfReducer, sadReducer } from "../slices/globalSlice";
import { commonReducer } from "../slices/commonSlice";
import { rootSaga } from "../sagas";

const rootReducer = combineReducers({
  sadReducer,
  commonReducer,
  ivfReducer,
});

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: () => [sagaMiddleware],
});

// Added line
sagaMiddleware.run(rootSaga);

export { store };
