/*
 * Create new password page
 */

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useFormik, Formik, Form } from "formik";
import * as Yup from "yup";
import { AuthLayout } from "../../layouts/AuthLayout";
import { Typography, InputAdornment, IconButton, Box } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { Button, ErrorComponent, TextInput } from "../../components";
import { AllRoutes } from "../../routes";
import { focusFirstErrorField } from "../../utils/CommonUtils";
import { AuthApis } from "../../api";
import PasswordChecklist from "react-password-checklist";

const validationSchema = {
  password: Yup.string()
    .min(8, "Password must have at least 8 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords does not match")
    .required("Confirm password is required"),
};

const CreateNewPassword = () => {
  const navigate = useNavigate();
  const { token, user_id } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [apiError, setApiError] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [isPasswordValid, setPasswordValid] = useState(false);
  const firstInputRef = useRef(null);

  useEffect(() => {
    const isUserValid = async () => {
      const resp = await AuthApis.resetPassword({
        token,
        params: { user_id },
      });
      !resp?.success && navigate(AllRoutes.Login.route);
    };
    isUserValid();
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object(validationSchema),
    validateOnMount: true,
    onSubmit: async () => {
      setButtonLoading(true);
      setApiError("");
      const resp = await AuthApis.resetPassword({
        token,
        params: { ...formik.values, user_id },
      });
      !!resp && setButtonLoading(false);
      if (resp?.success) {
        setActiveStep(1);
      } else {
        setApiError(resp?.errors?.password ?? resp?.errors?.message);
        firstInputRef.current && firstInputRef.current.focus(); // Set focus on first input
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    focusFirstErrorField(formik);
    formik.handleSubmit(e);
  };

  return (
    <AuthLayout>
      <Typography component="h1" variant="h1">
        Create New Password
      </Typography>

      {activeStep == 0 ? (
        <>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "1rem",
              color: "gray",
              fontSize: "0.9rem",
              fontWeight: "400",
            }}
          >
            Type and confirm a secure new password for the account:
          </Typography>

          {!!apiError && <ErrorComponent message={apiError} />}

          <Box sx={{ marginTop: "1rem" }} />
          <Formik>
            <Form onSubmit={(e) => handleSubmitForm(e)} className="w-75">
              <TextInput
                inputKey="password"
                inputLabel="Create Password"
                disabled={buttonLoading}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setPassword(e.target.value);
                }}
                value={formik.values.password}
                type={showPassword ? "text" : "password"}
                autoComplete="new-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      disabled={buttonLoading}
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                isError={formik.touched.password && formik.errors.password}
                errorMsg={formik.errors.password}
                isAuthPage={true}
                helperText="Note: Password must be having at least 1 uppercase, 1 lowercase, 1 digit, 1 special (#,?,!,@,$,%,^,&,*,-) and minimum 8 characters."
                inputRef={firstInputRef}
              />
              <TextInput
                inputKey="confirm_password"
                inputLabel="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                disabled={buttonLoading}
                value={formik.values.confirm_password}
                autoComplete="new-password"
                onChange={(e) => {
                  formik.handleChange(e);
                  setPasswordAgain(e.target.value);
                }}
                onBlur={formik.handleBlur}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      disabled={buttonLoading}
                      onClick={handleShowConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                isError={
                  formik.touched.confirm_password &&
                  formik.errors.confirm_password
                }
                errorMsg={formik.errors.confirm_password}
                isAuthPage={true}
              />
              <PasswordChecklist
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                ]}
                minLength={8}
                value={password}
                valueAgain={passwordAgain}
                onChange={(isValid) => setPasswordValid(isValid)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                type="submit"
                title="Reset Password"
                size="large"
                disabled={!isPasswordValid}
                loading={buttonLoading}
                sx={{
                  marginBottom: "1rem",
                  marginTop: "0.5rem",
                }}
              />
            </Form>
          </Formik>
        </>
      ) : (
        <>
          <CheckCircleOutlineIcon
            color="success"
            sx={{ fontSize: "5rem", marginBottom: "1rem", marginTop: "2rem" }}
          />
          <Typography
            sx={{
              marginBottom: "0.5rem",
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
          >
            Password Reset Successful!
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "1.1rem", marginBottom: "1.1rem", color: "gray" }}
          >
            Awesome. You’ve successfully updated your password.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            title="Back to Login"
            sx={{
              marginBottom: "1rem",
              marginTop: "0.5rem",
            }}
            onClick={() => navigate(AllRoutes.Login.route)}
            role="link"
          />
        </>
      )}
    </AuthLayout>
  );
};

export { CreateNewPassword };
