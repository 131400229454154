/**
 * Policy Extension APIs
 */

import httpClient from "../utils/HttpUtils";

export const PolicyExtensionApis = {
  postPolicyExtension: async (payload) => {
    try {
      const resp = await httpClient.post(`policy_extensions`, payload);
      return resp;
    } catch (error) {
      console.log("Error postPolicyExtension:", error);
    }
  },

  updatePolicyExtension: async (payload) => {
    const { extension_id, params } = payload;
    try {
      const resp = await httpClient.put(
        `policy_extensions/${extension_id}`,
        params
      );
      return resp;
    } catch (error) {
      console.log("Error updatePolicyExtension:", error);
    }
  },

  getAllExtendedPolicies: async (payload) => {
    const { sortOrder, sortKey, page, advancedSearch } = payload ?? {};
    let reqUrl = "/policy_extensions";
    if (payload)
      reqUrl += `?sort=${sortOrder ? "-" : ""}${sortKey}&page=${page}`;

    if (advancedSearch && Object?.keys?.(advancedSearch)?.length > 0) {
      let filtersPart = "";
      for (const [key, value] of Object.entries(advancedSearch)) {
        filtersPart += `&filters[${key}]=${value}`;
      }
      reqUrl += filtersPart;
    }
    try {
      const resp = await httpClient.get(reqUrl);
      return resp;
    } catch (error) {
      console.log("Error getAllExtendedPolicies:", error);
    }
  },
};
