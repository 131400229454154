/*
 * Change / Cancel Request Add Response
 */

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useForm } from "react-hook-form";

import { RequestApis } from "../../api";
import { TextInput, GlobalLoader, CommonModal } from "../../components";
import { ApplicationRequestHeader } from "./components/AdminRequestHeader";
import { SadActionButtons } from "../sad/sad-user/components/ActionButtons";
import { AdminPageLayout } from "../../layouts/admin-layout/AdminPageLayout";
import { UtilFunctions, focusFirstErrorField } from "../../utils/CommonUtils";
import { PreviousNotes } from "./components/PreviousNotes";

const AddResponse = () => {
  const navigate = useNavigate();
  const { id: change_request_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const {
    formState: { errors },
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    // Check if any text input field contains only whitespace characters
    const containsOnlyWhitespace = (fieldValue) => {
      return typeof fieldValue === "string" && fieldValue.trim() === "";
    };

    // Check if all text input fields are empty or contain only whitespace characters
    const isAllTextInputEmpty = Object.entries(data).every(([key, value]) => {
      return (
        key !== "notify_applicant" &&
        key !== "close" &&
        containsOnlyWhitespace(value)
      );
    });

    // Check if at least one checkbox is checked
    const isAtLeastOneCheckboxChecked = data.notify_applicant || data.close;

    // If all text input fields are empty or contain only whitespace characters
    // and no checkbox is checked, prevent submission
    if (isAllTextInputEmpty && !isAtLeastOneCheckboxChecked) {
      return;
    }
    setButtonLoading(true);
    let payload = data;
    const { internal_description, notification_description } = payload;
    !internal_description &&
      (payload = UtilFunctions.deleteKeys(payload, ["internal_description"]));
    !notification_description &&
      (payload = UtilFunctions.deleteKeys(payload, [
        "notification_description",
      ]));

    const resp = await RequestApis.addChangeCancelReqResp({
      ...payload,
      change_request_id,
    });
    !!resp && setButtonLoading(false);
    if (resp?.success) {
      setSubmitModal(true);
    } else {
      Object.keys(resp?.errors).forEach((key) => {
        setError(key, { message: resp?.errors[key] });
      });
      focusFirstErrorField();
    }
  };

  return (
    <AdminPageLayout>
      <ApplicationRequestHeader header="Add Response" setLoading={setLoading} />

      {loading && <GlobalLoader loading={loading} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          inputKey="internal_description"
          inputLabel="Add Internal Description"
          multiline
          rows={3}
          required={false}
          register={register}
          isError={errors?.internal_description}
          errorMsg={errors?.internal_description?.message}
        />

        <TextInput
          inputKey="notification_description"
          inputLabel="Notification to Applicant"
          multiline
          rows={3}
          required={!!watch("notify_applicant")}
          register={register}
          isError={errors?.notification_description}
          errorMsg={errors?.notification_description?.message}
        />

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={watch("notify_applicant")}
                disabled={watch("close")}
                value={watch("notify_applicant")}
                onChange={() => {
                  setValue(
                    "notify_applicant",
                    Number(!watch("notify_applicant"))
                  );
                }}
              />
            }
            label="Send Notification to Applicant"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={watch("close")}
                disabled={watch("notify_applicant")}
                value={watch("close")}
                onChange={() => {
                  setValue("close", Number(!watch("close")));
                }}
              />
            }
            label="Close Task"
          />
        </FormGroup>

        <SadActionButtons
          confirmButtonLabel="Submit"
          buttonLoading={buttonLoading}
          disableContinue={
            !watch("internal_description") &&
            !watch("notify_applicant") &&
            !watch("notification_description") &&
            !watch("close")
          }
        />
      </form>

      <PreviousNotes setLoading={setLoading} />

      <CommonModal
        open={submitModal}
        onConfirm={() => navigate(-1)}
        type="success"
        title="Response submitted successfully!"
      />
    </AdminPageLayout>
  );
};

export { AddResponse };
