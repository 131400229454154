/*
 * Common Outlined Input
 */

import React from "react";
import { Box, FormControl, FormHelperText, InputLabel } from "@mui/material";

import "react-phone-number-input/style.css";
import PhoneNumberInput from "react-phone-number-input";

const PhoneInput = ({
  margin = "normal",
  formControlStyle,
  inputKey,
  inputLabel,
  message = false,
  type = "text",
  isPhoneInput = false,
  value,
  onChange,
  onBlur,
  autoComplete,
  required = true,
  isError,
  errorMsg,
  helperText,
  maxLength,
  inputStyle,
  isAuthPage = false,
  register,
  registerChange,
  sx,
  ...rest
}) => {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      margin={margin}
      sx={{
        ...formControlStyle,
        "&:focus-within .MuiFormLabel-root": {
          color: "#C0580E",
        },
        "& .PhoneInput": {
          outline: "0.05rem solid #C4C4C4",
          borderRadius: "4px",
          paddingY: "1.2rem",
          paddingX: "1rem",
        },
        "& .PhoneInput--focus": {
          outline: "2px solid #C0580E",
          border: "0 !important",
          borderRadius: "4px",
        },
        "& .PhoneInput input": {
          border: "0 !important",
          outline: "transparent",
          fontSize: "1rem",
          paddingLeft: "0.3rem",
        },
        "& .PhoneInputCountry:focus-within": {
          outline: "2px solid #C0580E",
          outlineOffset: "4px",
        },
      }}
    >
      <InputLabel
        htmlFor={inputKey}
        shrink
        sx={{
          backgroundColor: "#fff",
          color: "black",
          marginLeft: "0px",
          paddingX: "0.3rem",
        }}
      >
        {inputLabel} {required && <span className="text-danger">*&nbsp;</span>}
      </InputLabel>
      <Box
        sx={{
          "& input.PhoneInputInput": { backgroundColor: "transparent" },
          ...sx, // Spread the existing styles from the sx prop
        }}
      >
        <PhoneNumberInput
          international
          countryCallingCodeEditable={false}
          placeholder="Enter contact number"
          defaultCountry="US"
          id={inputKey}
          name={inputKey}
          value={value}
          onBlur={onBlur}
          onChange={(e) => registerChange && registerChange(inputKey, e)}
          maxLength={23}
          autoComplete="off"
          aria-describedby={
            (isError ? "error-" + inputKey : "") +
            (isError && helperText ? " " : "") +
            (helperText ? "helper-text-" + inputKey : "")
          }
          {...rest}
        />
      </Box>
      {!isAuthPage && isError && (
        <FormHelperText error sx={{ marginLeft: 0 }} id={"error-" + inputKey}>
          {errorMsg}
        </FormHelperText>
      )}
      {!!helperText && (
        <FormHelperText
          sx={{
            marginLeft: 0,
            fontStyle: "italic",
            fontWeight: 600,
            fontSize: "0.77rem",
            letterSpacing: 0,
          }}
          id={"helper-text-" + inputKey}
        >
          {helperText}
        </FormHelperText>
      )}
      {isAuthPage && (
        <FormHelperText
          error
          sx={{ marginLeft: 0, height: { xs: "auto", sm: 19.92 } }}
          id={"error-" + inputKey}
        >
          {isError ? errorMsg : ""}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export { PhoneInput };
