import React from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

export const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year
  return (
    <footer>
      <Typography
        variant="body1"
        color="initial"
        sx={{ fontSize: "0.8rem", padding: "0.9rem", textAlign: "center" }}
      >
        Copyright New Life Agency, Insurance Agency, Inc. 2003 - {currentYear}.
        Developed by{" "}
        <Link
          to="https://d2itechnology.com/"
          className="text-primary"
          target="_blank"
        >
          D2i Technology
        </Link>
      </Typography>
    </footer>
  );
};
