/*
 * Error 404 Component
 */

import React from "react";
import { Box, Typography } from "@mui/material";

import Error404 from "../layouts/images/Error404.png";

const Error404Component = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Typography variant="h1" gutterBottom>
        Uh-Oh...
      </Typography>
      <Typography variant="h5" gutterBottom>
        The page you are looking for may have been moved, deleted, or possibly
        never existed.
      </Typography>
      <img
        src={Error404}
        alt="404 Error"
        sx={{ width: "90%", maxWidth: 500 }}
      />
    </Box>
  );
};

export { Error404Component };
