/*
 * ADMIN ADD-NEW-USER
 */

import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
  Accordion,
  AddressInput,
  AgencyDetails,
  ClinicDetails,
  CommonModal,
  Dropdown,
  GlobalLoader,
  GoBack,
  MandatoryStatement,
  PhoneInput,
  TextInput,
} from "../../components";
import {
  UtilFunctions,
  emailRegex,
  focusFirstErrorField,
  testNonLetter,
} from "../../utils/CommonUtils";
import { UsersApis } from "../../api";
import { AdminPageLayout } from "../../layouts/admin-layout/AdminPageLayout";
import { USER_ROLES } from "../../constants";
import { SadActionButtons } from "../sad/sad-admin/components/ActionButtons";

const { agency, clinic } = USER_ROLES;

const formValues = {
  first_name: "",
  last_name: "",
  display_name: "",
  email: "",
  mobile: "",
  role: null,
  address_line_1: "",
  zipcode: "",
  country_code: "US",
  state_code: "",
  city: "",

  agency_name: null,
  agency_country_code: "US",
  agency_state_code: null,

  clinic_name: null,
  clinic_country_code: "US",
  clinic_state_code: null,

  invite: 1,
};

const AddNewUser = () => {
  const navigate = useNavigate();
  const applyNewApplicationButtonRef = useRef(null);
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [isWorkingWithAgency, setWorkingWithAgency] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const { user_types_list } = lookups ?? {};

  const validationSchema = useMemo(() => {
    const requiredSchema = {
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .nullable()
        .required("Email is required")
        .matches(emailRegex, "Invalid email")
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        ),
      mobile: Yup.string().required("Contact number is required"),
      role: Yup.string().required("Role is required"),
      address_line_1: Yup.string().required("Street Address is required"),
      country_code: Yup.string().required("Country is required"),
      zipcode: Yup.string().required("Zip Code is required"),
      state_code: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
    };

    if (userRole === agency || (userRole === clinic && isWorkingWithAgency)) {
      requiredSchema["agency_country_code"] = Yup.string().required(
        "Agency Country is required"
      );
      requiredSchema["agency_state_code"] = Yup.string().required(
        "Agency State is required"
      );
      requiredSchema["agency_name"] = Yup.string().required(
        "Agency Name is required"
      );
    }

    if (userRole === clinic) {
      requiredSchema["clinic_country_code"] = Yup.string().required(
        "Clinic Country is required"
      );
      requiredSchema["clinic_state_code"] = Yup.string().required(
        "Clinic State is required"
      );
      requiredSchema["clinic_name"] = Yup.string().required(
        "Clinic Name is required"
      );
      requiredSchema["working_with_agency"] = Yup.string().required(
        "Working With Agency is required"
      );
    }

    return Yup.object(requiredSchema);
  }, [isWorkingWithAgency, userRole]);

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues: formValues,
    resolver: yupResolver(validationSchema),
  });

  const isRoleAgency = watch("role") === agency;
  const isRoleClinic = watch("role") === clinic;

  useEffect(() => {
    const errorArray = Object.keys(errors);
    if (!errorArray?.length) return;
    focusFirstErrorField();
  }, [errors]);

  const onSubmitForm = async () => {
    setButtonLoading(true);
    let payload = getValues();

    payload = UtilFunctions.deleteKeys(payload, [
      "clinic_country_code",
      "clinic_state_code",
      "clinic_name",
      "agency_country_code",
      "agency_state_code",
      "agency_name",
    ]);

    if (isRoleAgency) {
      payload = UtilFunctions.deleteKeys(payload, ["clinic_id"]);
    }

    if (isRoleClinic && !isWorkingWithAgency) {
      payload = UtilFunctions.deleteKeys(payload, ["agency_id"]);
    }

    const resp = await UsersApis.createNewUser(payload);
    !!resp && setButtonLoading(false);
    if (resp?.success) {
      setUpdateModal(true);
      applyNewApplicationButtonRef?.current?.focus();
    } else {
      Object.keys(resp?.errors).forEach(function (key) {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  return (
    <AdminPageLayout>
      {loading && <GlobalLoader loading={loading} />}

      <Box display="flex" alignItems="center">
        <GoBack />
        <Typography variant="h1" sx={{ margin: "0" }}>
          Add New User
        </Typography>
      </Box>

      <Grid container sx={{ paddingTop: "0.7rem" }}>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Box sx={{ marginLeft: "0.55rem", marginTop: "1.3rem" }}>
            <MandatoryStatement />
          </Box>
          <Grid
            container
            sx={{ justifyContent: "center" }}
            rowSpacing={2}
            columnSpacing={3}
          >
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="first_name"
                inputLabel="First Name"
                register={register}
                disabled={buttonLoading}
                isError={errors?.first_name?.message}
                errorMsg={errors?.first_name?.message}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="last_name"
                inputLabel="Last Name"
                register={register}
                disabled={buttonLoading}
                isError={errors?.last_name?.message}
                errorMsg={errors?.last_name?.message}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
              <TextInput
                inputKey="display_name"
                inputLabel="Display Name"
                disabled={buttonLoading}
                register={register}
                required={false}
                inputProps={{ maxLength: 50 }}
                isError={errors?.display_name?.message}
                errorMsg={errors?.display_name?.message}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
              <TextInput
                inputKey="email"
                inputLabel="Email"
                helperText="Note: Copy of Application, Invoice and All other documents will be sent to this email address."
                disabled={buttonLoading}
                register={register}
                isError={errors?.email?.message}
                errorMsg={errors?.email?.message}
                endAdornment={
                  !!emailRegex.test?.(watch("email")) && (
                    <InputAdornment position="end">
                      <CheckCircleIcon sx={{ color: "#00AD00" }} />
                    </InputAdornment>
                  )
                }
              />
            </Grid>
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
              <PhoneInput
                inputKey="mobile"
                inputLabel="Contact No."
                autoComplete="off"
                disabled={buttonLoading}
                register={register}
                {...(testNonLetter(watch("mobile")) && {
                  value: watch("mobile"),
                })}
                registerChange={(_, val) => {
                  setValue("mobile", val);
                  !!errors?.mobile?.message && setError("mobile", null);
                }}
                isError={errors?.mobile?.message}
                errorMsg={errors?.mobile?.message}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
              <Dropdown
                label="User's role in the Submission process?"
                id="role"
                disabled={buttonLoading}
                options={user_types_list ?? []}
                selectValue="id"
                selectLabel="name"
                register={register}
                registerChange={(e) => {
                  setUserRole(e.target.value);
                  if (e.target.value === agency) {
                    setValue("working_with_agency", 1);
                    setWorkingWithAgency(true);
                  } else {
                    setValue("working_with_agency", null);
                    setWorkingWithAgency(false);
                  }
                }}
                value={watch("role")}
                isError={errors?.role?.message}
                errorMsg={errors?.role?.message}
                helperText="Register as an Agency or IVF Clinic if user is an organization, or as the Intended Parent if user is not an organization."
              />
            </Grid>
            <AddressInput
              disabled={buttonLoading}
              setLoading={setLoading}
              register={register}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              setError={setError}
              errors={errors}
              addressKey="address_line_1"
              countryKey="country_code"
              zipcodeKey="zipcode"
              stateKey="state_code"
              cityKey="city"
            />
          </Grid>

          {/* Clinic Details */}
          {isRoleClinic && (
            <Accordion
              header="Clinic Details"
              component="h2"
              name="clinic-details"
              defaultExpanded={true}
            >
              <ClinicDetails
                disabled={buttonLoading}
                register={register}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
                errors={errors}
                setError={setError}
                loading={loading}
                setLoading={setLoading}
                isRoleClinic={isRoleClinic}
              />
            </Accordion>
          )}

          {/* Agency Details */}
          {(isRoleClinic || isRoleAgency) && (
            <Accordion
              header="Agency Details"
              component="h2"
              name="agency-details"
              defaultExpanded={true}
            >
              <AgencyDetails
                disabled={buttonLoading}
                register={register}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
                errors={errors}
                setError={setError}
                loading={loading}
                setLoading={setLoading}
                isRoleAgency={isRoleAgency}
                isRoleClinic={isRoleClinic}
                setWorkingWithAgency={setWorkingWithAgency}
              />
            </Accordion>
          )}

          <FormControlLabel
            control={
              <Checkbox
                id="invite"
                name="invite"
                disabled={buttonLoading}
                checked={watch("invite")}
                value={watch("invite")}
                onChange={() => {
                  setValue("invite", Number(!watch("invite")));
                }}
              />
            }
            label="Send Invite Email"
          />

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <SadActionButtons
              onCancel={() => reset(formValues)}
              updateButtonLabel="Add User"
              buttonLoading={buttonLoading}
            />
          </Box>
        </form>
      </Grid>

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => {
          setUpdateModal(false);
          navigate(-1);
        }}
        title="User Created Successfully"
      />
    </AdminPageLayout>
  );
};

export { AddNewUser };
