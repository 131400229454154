/**
 * Countries APIs
 */

import httpClient from "../utils/HttpUtils";

export const CountriesApis = {
  // we consider limit false for dropdowns (to show all data)
  // and true for grid (to show 20 data)
  getCountriesList: async (payload) => {
    const {
      sortOrder,
      sortKey,
      page,
      limit = false,
      advancedSearch,
    } = payload ?? {};
    let reqUrl = `/countries`;
    if (payload) {
      reqUrl += `?sort=${sortOrder ? "-" : ""}${sortKey}&limit=${
        limit ? 20 : -1
      }`;
      if (page) {
        reqUrl += `&page=${page}`;
      }
    }

    if (advancedSearch && Object?.keys?.(advancedSearch)?.length > 0) {
      let filtersPart = "";
      for (const [key, value] of Object.entries(advancedSearch)) {
        filtersPart += `&filters[${key}]=${value}`;
      }
      reqUrl += filtersPart;
    }

    try {
      const resp = httpClient.get(reqUrl);
      return resp;
    } catch (error) {
      console.log("Error getCountriesList:", error);
    }
  },

  getCountryDetail: async ({ country_id }) => {
    try {
      const resp = await httpClient.get(`countries/${country_id}`);
      return resp;
    } catch (error) {
      console.log("Error getCountryDetail", error);
    }
  },

  updateCountry: async ({ country_id, params }) => {
    try {
      const resp = await httpClient.put(`countries/${country_id}`, params);
      return resp;
    } catch (error) {
      console.log("Error updateCountry", error);
    }
  },

  getStatesOfCountry: async (payload) => {
    const {
      country_id,
      sortOrder,
      sortKey = "state",
      page,
      limit = false,
      advancedSearch,
    } = payload ?? {};
    let reqUrl = `/countries/${country_id}/states`;
    if (payload) {
      reqUrl += `?sort=${sortOrder ? "-" : ""}${sortKey}&limit=${
        limit ? 20 : -1
      }`;
      if (page) {
        reqUrl += `&page=${page}`;
      }
    }

    if (advancedSearch && Object?.keys?.(advancedSearch)?.length > 0) {
      let filtersPart = "";
      for (const [key, value] of Object.entries(advancedSearch)) {
        filtersPart += `&filters[${key}]=${value}`;
      }
      reqUrl += filtersPart;
    }

    try {
      const resp = httpClient.get(reqUrl);
      return resp;
    } catch (error) {
      console.log("Error getStatesOfCountry:", error);
    }
  },

  addStateofCountry: async ({ country_id, params }) => {
    try {
      const resp = await httpClient.post(
        `/countries/${country_id}/states`,
        params
      );
      return resp;
    } catch (error) {
      console.log("Error addStateofCountry", error);
    }
  },

  getStateofCountryDetail: async ({ country_id, state_id }) => {
    try {
      const resp = await httpClient.get(
        `countries/${country_id}/states/${state_id}`
      );
      return resp;
    } catch (error) {
      console.log("Error getStateofCountryDetail", error);
    }
  },

  updateStateofCountry: async ({ country_id, state_id, params }) => {
    try {
      const resp = await httpClient.put(
        `countries/${country_id}/states/${state_id}`,
        params
      );
      return resp;
    } catch (error) {
      console.log("Error updateStateofCountry", error);
    }
  },
};
