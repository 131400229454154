/*
 * IVF ADMIN Edit Form-Application Details Component // 1st tab
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";

import { Accordion, AutoComplete, CommonModal } from "../../../../components";

import { AddNewClinic } from "../../ivf-user/components/AddNewClinic";
import { useForm } from "react-hook-form";
import TextInputIvf from "../../ivf-user/ivf-form/common/TextInput";
import DropdownIVF from "../../ivf-user/ivf-form/common/Dropdown";
import { useDebounce } from "../../../../hooks";
import { AgencyApis, ClinicApis, CommonApis, IvfApis } from "../../../../api";
import { AddNewAgency } from "../../../sad/components/AddNewAgency";
import {
  financialEmailTooltip,
  RESTRICTED_STATES,
} from "../../../../constants";
import { useNavigate, useParams } from "react-router-dom";
import AdminDonorAndRecipient from "./AdminDonorRecipient";
import AdminDonor from "./AdminDonor";
import AdminRecipient from "./AdminRecipient";
import {
  constructStateList,
  UtilFunctions,
} from "../../../../utils/CommonUtils";
import { setIvfSummary } from "../../../../redux/slices/globalSlice";
import { IvfFormInitialValues } from "../../constants";
import { SadActionButtons } from "../../../sad/sad-admin/components/ActionButtons";

const Edit_ApplicationForm = ({ loading, setLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: application_id } = useParams();
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const summaryData = useSelector((state) => state.ivfReducer.ivfSummary);
  const clinics = useSelector((state) => state.ivfReducer.clinics);
  const agencies = useSelector((state) => state.ivfReducer.agencies);
  const [limitOfIndemnity, setLimitOfIndemnity] = useState();
  const [coverageTypeValue, setCoverageTypeValue] = useState("");
  const [showAddNewClinic, setShowAddNewClinic] = useState(false);
  const [isBlackListedAgency, setBlackListedAgency] = useState(false);
  const [isNewAgencyAdded, setNewAgencyAdded] = useState(false);
  const [isBlackListedClinic, setBlackListedClinic] = useState(false);
  const [isNewClinicAdded, setNewClinicAdded] = useState(false);
  const [showAddNewAgency, setShowAddNewAgency] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [ageModal, setAgeModal] = useState(false);
  const [cycleModal, setCycleModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState("");
  const [coverageLimit, setCoverageLimit] = useState([]);
  const [contractuallyState, setContractuallyState] = useState([]);
  const [ivfStateList, setIvfStateList] = useState([]);
  const [clinicList, setClinicList] = useState(clinics);
  const [isWorkingWithAgency, setIsWorkingWithAgency] = useState();
  const [hasCyledBefore, setHasCycledBefore] = useState("");
  const [stateList, setStateList] = useState([]);
  const [agencyList, setAgencyList] = useState(agencies);
  const [hasCyledComplicationBefore, setHasCycledComplicationBefore] =
    useState("");
  const [countryStateModal, setCountryStateModal] = useState({
    show: false,
    text: "",
  });
  const [isCLPCountryStateChanged, setCLPCountryStateChanged] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    setError,
    watch,
  } = useForm({
    defaultValues: IvfFormInitialValues,
  });

  useEffect(() => {
    !!summaryData?.length && setDataInitially();
  }, [summaryData]);

  const setDataInitially = () => {
    Object.keys(summaryData?.[0]).forEach((key) => {
      if (getValues()?.hasOwnProperty(key)) {
        setValue(key, summaryData?.[0]?.[key]);
      }
    });
  };

  useEffect(() => {
    if (watch("term_length")) {
      setCoverageLimit(
        lookups?.ivf_reproductive_coverage_options_list?.[watch("term_length")]
      );
    }
  }, [watch("reproductive_organ_loss_rider")]);

  const setLimitOfIndemnityHandler = (coverage, termLength) => {
    setCoverageTypeValue(coverage);
    if (coverage == "EDR" && termLength == "4") {
      setLimitOfIndemnity(lookups?.ivf_indemnity_options_list["EDR4"]);
    } else if (coverage == "EDR" && termLength == "6") {
      setLimitOfIndemnity(lookups?.ivf_indemnity_options_list["EDR6"]);
    } else if (coverage == "EDO" && termLength == "4") {
      setLimitOfIndemnity(lookups?.ivf_indemnity_options_list["EDO4"]);
    } else if (coverage == "EDO" && termLength == "6") {
      setLimitOfIndemnity(lookups?.ivf_indemnity_options_list["EDO6"]);
    } else if (coverage == "RO" && termLength == "4") {
      setLimitOfIndemnity(lookups?.ivf_indemnity_options_list["RO4"]);
    } else if (coverage == "RO" && termLength == "6") {
      setLimitOfIndemnity(lookups?.ivf_indemnity_options_list["RO6"]);
    } else {
      setLimitOfIndemnity(null);
    }
  };

  useEffect(() => {
    if (lookups?.ivf_indemnity_options_list) {
      setLimitOfIndemnityHandler(watch("coverage_type"), watch("term_length"));
    }
  }, [watch("coverage_type"), watch("term_length"), lookups]);

  const getContractuallyZipChanges = useDebounce(async (e) => {
    if (watch(`contractually_liable_party_country_code`) === "US") {
      setCLPCountryStateChanged(true);
    }

    const value = e.target.value;
    const resp = await CommonApis.getZipData({
      country_code: watch(`contractually_liable_party_country_code`) || "",
      zipcode: value,
    });
    setValue(
      `contractually_liable_party_state_code`,
      resp?.[0]?.state_code || ""
    );
    setValue(`contractually_liable_party_city`, resp?.[0]?.city || "");
  }, 300);

  const getContractuallyStateList = async (value) => {
    const resp = await CommonApis.getZipData({
      country_code: value,
    });
    const getStates = constructStateList(resp);
    setContractuallyState(getStates);
  };

  useEffect(() => {
    getContractuallyStateList("US");
  }, []);

  const getClinicStateList = useDebounce(async (value) => {
    const resp = await CommonApis.getZipData({
      country_code: value,
    });
    const getStates = constructStateList(resp);
    setIvfStateList(getStates);
  }, 300);

  useEffect(() => {
    getClinicStateList("US");
  }, []);

  useEffect(() => {
    getClinicList(`clinic_state_code`);
  }, [isNewClinicAdded]);

  const getClinicList = async (value) => {
    const resp = await ClinicApis.getClinicsList({
      country_code: watch(`clinic_country_code`),
      state_code: value,
    });
    setClinicList(resp);
  };

  const getStateList = useDebounce(async (value) => {
    const resp = await CommonApis.getZipData({
      country_code: value,
    });
    const getStates = constructStateList(resp);
    setStateList(getStates);
  }, 300);

  useEffect(() => {
    getStateList("US");
  }, []);

  useEffect(() => {
    getAgencyList(watch(`clinic_state_code`));
  }, [isNewAgencyAdded]);

  const getAgencyList = async (value) => {
    const resp = await AgencyApis.getAgenciesList({
      country_code: watch(`agency_country_code`),
      state_code: value,
    });
    setAgencyList(resp);
  };

  useEffect(() => {
    setIsWorkingWithAgency(watch(`working_with_agency`));
  }, [lookups?.yes_no_list, watch(`working_with_agency`)]);

  // Show alert pop if Contractual Liable Party's restricted country/state is selected
  useEffect(() => {
    if (isCLPCountryStateChanged) {
      if (watch(`contractually_liable_party_country_code`) !== "US") {
        setCountryStateModal({ show: true, text: "Non US Country" });
      } else if (
        RESTRICTED_STATES?.includes?.(
          watch(`contractually_liable_party_state_code`)
        )
      ) {
        setCountryStateModal({ show: true, text: "Restricted State" });
      } else {
        setCountryStateModal({ show: false, text: "" });
      }
    }
  }, [
    watch(`contractually_liable_party_country_code`),
    watch(`contractually_liable_party_state_code`),
  ]);

  const onSubmitForm = async (data) => {
    setButtonLoading("continue");

    let payload = getValues();
    const extraPayload = [
      "agency_zipcode",
      "agency_address_line_1",
      "agency_city",
      "clinic_zipcode",
      "clinic_address_line_1",
      "clinic_city",
    ];
    const { coverage_type, reproductive_organ_loss_rider } = payload;
    payload = UtilFunctions.deleteKeys(payload, extraPayload);
    if (coverage_type !== "EDR" || !reproductive_organ_loss_rider) {
      payload = UtilFunctions.deleteKeys(payload, [
        "optional_rider_intended_for",
      ]);
    }
    payload = UtilFunctions.convertToDayjsYMDFormat(payload, [
      "donor_medication_start_date",
      "recipient_medication_start_date",
    ]);

    if (
      summaryData?.[0]?.coverage_type == payload.coverage_type &&
      summaryData?.[0]?.donor_medication_start_date ==
        payload.donor_medication_start_date
    ) {
      payload = UtilFunctions.deleteKeys(payload, [
        "donor_medication_start_date",
      ]);
    }
    if (
      summaryData?.[0]?.coverage_type == payload.coverage_type &&
      summaryData?.[0]?.recipient_medication_start_date ==
        payload.recipient_medication_start_date
    ) {
      payload = UtilFunctions.deleteKeys(payload, [
        "recipient_medication_start_date",
      ]);
    }

    const resp = await IvfApis.updateApplication({
      application_id,
      params: payload,
    });
    if (resp) {
      setButtonLoading("");
    }
    if (resp?.success) {
      setUpdateModal(true);
      dispatch(setIvfSummary([resp?.data]));
    } else {
      Object.keys(resp?.errors).forEach(function (key) {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  return (
    <form
      key={1}
      autoComplete={false}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitForm();
      }}
    >
      <>
        {/* General Information */}
        <Accordion
          defaultExpanded={true}
          header="General Information"
          component="h2"
          name="general information"
        >
          <Grid container columnSpacing={3}>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <DropdownIVF
                label="Coverage Type"
                id={`coverage_type`}
                disabled={buttonLoading}
                options={lookups?.ivf_coverage_type_list}
                name={`coverage_type`}
                {...register("coverage_type", {
                  onChange: (e) => {
                    setValue(`idemnity_limit`, null);
                    setLimitOfIndemnityHandler(
                      e.target.value,
                      watch(`term_length`)
                    );
                  },
                })}
                value={watch("coverage_type")}
                isError={errors?.["coverage_type"]?.message}
                errorMsg={errors?.["coverage_type"]?.message}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <DropdownIVF
                label="Term Length"
                id={`term_length`}
                name={`term_length`}
                disabled={buttonLoading}
                options={lookups?.ivf_terms_duration_list}
                value={watch(`term_length`)}
                {...register(`term_length`, {
                  onChange: (e) => {
                    setValue(`idemnity_limit`, null);
                    setLimitOfIndemnityHandler(
                      watch(`coverage_type`),
                      e.target.value
                    );
                    setCoverageLimit(
                      lookups?.ivf_reproductive_coverage_options_list[
                        e.target.value
                      ]
                    );
                  },
                })}
                isError={errors?.["term_length"]?.message}
                errorMsg={errors?.["term_length"]?.message}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <DropdownIVF
                label="Limit of Indemnity"
                id="idemnity_limit"
                name="idemnity_limit"
                disabled={buttonLoading}
                value={watch("idemnity_limit")}
                defaultValue={watch("idemnity_limit")}
                tooltip="Maximum amount limit that would be paid under policy, for covered services/claims."
                options={limitOfIndemnity}
                {...register("idemnity_limit", {
                  onChange: (e) => {
                    setValue("idemnity_limit", e.target.value);
                  },
                })}
                isError={errors?.["idemnity_limit"]?.message}
                errorMsg={errors?.["idemnity_limit"]?.message}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <DropdownIVF
                label="Reproductive Organ Loss Rider"
                id={`reproductive_organ_loss_rider`}
                name={`reproductive_organ_loss_rider`}
                value={watch(`reproductive_organ_loss_rider`)}
                disabled={buttonLoading}
                options={lookups?.yes_no_list}
                {...register(`reproductive_organ_loss_rider`, {
                  onChange: (e) => {
                    setValue("reproductive_organ_loss_rider", e.target.value);
                  },
                })}
                isError={errors?.["reproductive_organ_loss_rider"]?.message}
                errorMsg={errors?.["reproductive_organ_loss_rider"]?.message}
                tooltip="Endorsement will pay the benefit amount specified in the event that the donor/recipient has to undergo a Total or Subtotal/Partial Hysterectomy, an Oophorectomy or a Salpingectomy due to a medical condition caused directly by complications from IVF procedures/pregnancy and recommended by two independent medical practitioners."
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <DropdownIVF
                label="If Yes Select Coverage Limit"
                id={`reproductive_organ_loss_rider_option`}
                name={`reproductive_organ_loss_rider_option`}
                value={watch(`reproductive_organ_loss_rider_option`)}
                disabled={
                  buttonLoading || !watch(`reproductive_organ_loss_rider`)
                }
                options={coverageLimit}
                {...register(`reproductive_organ_loss_rider_option`, {
                  onChange: (e) => {
                    setValue(
                      "reproductive_organ_loss_rider_option",
                      e.target.value
                    );
                  },
                })}
                isError={
                  errors?.["reproductive_organ_loss_rider_option"]?.message
                }
                errorMsg={
                  errors?.["reproductive_organ_loss_rider_option"]?.message
                }
              />
            </Grid>

            {!!watch("reproductive_organ_loss_rider") &&
              watch("coverage_type") == "EDR" && (
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                  <DropdownIVF
                    label="Optional Coverage Intended For"
                    id={`optional_rider_intended_for`}
                    name={`optional_rider_intended_for`}
                    disabled={buttonLoading}
                    {...register(`optional_rider_intended_for`)}
                    value={watch(`optional_rider_intended_for`)}
                    options={lookups?.ivf_coverage_type_list}
                    isError={errors?.["optional_rider_intended_for"]?.message}
                    errorMsg={errors?.["optional_rider_intended_for"]?.message}
                    tooltip="When purchasing donor or recipient coverage the correct option will be auto selected. When purchasing donor and recipient combined coverage you can select to have the coverage applied to one or the other. If you select Donor & Recipient the correct amount will be calculated to cover both."
                  />
                </Grid>
              )}

            {coverageTypeValue == "EDR" ? (
              <AdminDonorAndRecipient
                register={register}
                disabled={buttonLoading}
                coverageTypeSelected={coverageTypeValue}
                setValue={setValue}
                watch={watch}
                coverageTypeValue={coverageTypeValue}
                errors={errors}
                setError={setError}
                lookups={lookups}
                hasCyledBefore={hasCyledBefore}
                setHasCycledBefore={setHasCycledBefore}
                hasCyledComplicationBefore={hasCyledComplicationBefore}
                setHasCycledComplicationBefore={setHasCycledComplicationBefore}
                setAgeModal={setAgeModal}
                ageModal={ageModal}
                setCycleModal={setCycleModal}
                cycleModal={cycleModal}
                application_id={application_id}
              />
            ) : coverageTypeValue == "RO" ? (
              <AdminRecipient
                register={register}
                disabled={buttonLoading}
                coverageTypeSelected={coverageTypeValue}
                setValue={setValue}
                watch={watch}
                coverageTypeValue={coverageTypeValue}
                errors={errors}
                setError={setError}
                lookups={lookups}
                hasCyledBefore={hasCyledBefore}
                setHasCycledBefore={setHasCycledBefore}
                hasCyledComplicationBefore={hasCyledComplicationBefore}
                setHasCycledComplicationBefore={setHasCycledComplicationBefore}
                setAgeModal={setAgeModal}
                ageModal={ageModal}
                setCycleModal={setCycleModal}
                cycleModal={cycleModal}
                application_id={application_id}
              />
            ) : (
              <AdminDonor
                register={register}
                disabled={buttonLoading}
                coverageTypeSelected={coverageTypeValue}
                setValue={setValue}
                watch={watch}
                coverageTypeValue={coverageTypeValue}
                errors={errors}
                setError={setError}
                lookups={lookups}
                hasCyledBefore={hasCyledBefore}
                setHasCycledBefore={setHasCycledBefore}
                hasCyledComplicationBefore={hasCyledComplicationBefore}
                setHasCycledComplicationBefore={setHasCycledComplicationBefore}
                setAgeModal={setAgeModal}
                ageModal={ageModal}
                setCycleModal={setCycleModal}
                cycleModal={cycleModal}
                application_id={application_id}
              />
            )}

            <Grid
              item
              md={coverageTypeValue === "EDR" ? 12 : 6}
              sm={coverageTypeValue === "EDR" ? 12 : 6}
              xs={coverageTypeValue === "EDR" ? 12 : 6}
            >
              <DropdownIVF
                label="Who is Contractually Liable for this Policy?"
                id={`contractually_liable_party`}
                name={`contractually_liable_party`}
                disabled={buttonLoading}
                options={lookups?.ivf_contractual_liable_options}
                value={watch(`contractually_liable_party`)}
                {...register(`contractually_liable_party`)}
                tooltip="The Contractually Liable party will appear on the certificate as the Insured Party. Please put the Contractually Liable Party’s Full Address below."
                isError={errors?.["contractually_liable_party"]?.message}
                errorMsg={errors?.["contractually_liable_party"]?.message}
              />
            </Grid>

            <Grid
              item
              md={coverageTypeValue === "EDR" ? 12 : 6}
              sm={coverageTypeValue === "EDR" ? 12 : 6}
              xs={coverageTypeValue === "EDR" ? 12 : 6}
              mb={2}
            >
              <TextInputIvf
                type="text"
                id={`contractually_liable_party_first_name`}
                name={`contractually_liable_party_first_name`}
                label="Full Name of Contractually Liable Party"
                disabled={buttonLoading}
                sx={{ marginTop: "16px", width: "100%" }}
                register={register}
                setError={setError}
                isError={
                  errors?.["contractually_liable_party_first_name"]?.message
                }
                errorMsg={
                  errors?.["contractually_liable_party_first_name"]?.message
                }
              />
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              // sx={{ padding: "10px" }}
            >
              <TextInputIvf
                register={register}
                setError={setError}
                type="text"
                id={`contractually_liable_party_address_line_1`}
                name={`contractually_liable_party_address_line_1`}
                disabled={buttonLoading}
                helperText="Note: P.O. Boxes are not allowed. Please enter a full street address."
                label="Street Address"
                sx={{ width: "100%" }}
                isError={
                  errors?.["contractually_liable_party_address_line_1"]?.message
                }
                errorMsg={
                  errors?.["contractually_liable_party_address_line_1"]?.message
                }
              />
            </Grid>
            <Grid
              item
              xl={3}
              lg={3}
              md={6}
              sm={12}
              xs={12}
              // sx={{ padding: "10px" }}
            >
              <DropdownIVF
                label="Country"
                id={`contractually_liable_party_country_code`}
                name={`contractually_liable_party_country_code`}
                value={watch(`contractually_liable_party_country_code`)}
                disabled={buttonLoading}
                options={lookups?.countries_list || []}
                {...register(`contractually_liable_party_country_code`, {
                  onChange: (e) => {
                    setCLPCountryStateChanged(true);
                    getContractuallyStateList(e.target.value);
                    setValue(`contractually_liable_party_zipcode`, "");
                    setValue(`contractually_liable_party_state_code`, null);
                    setValue(`contractually_liable_party_city`, "");
                  },
                })}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <TextInputIvf
                id={`contractually_liable_party_zipcode`}
                name={`contractually_liable_party_zipcode`}
                label="ZIP Code"
                autoComplete="off"
                maxLength={9}
                disabled={buttonLoading}
                register={register}
                onRegisterChange={getContractuallyZipChanges}
                setError={setError}
                isError={
                  errors?.["contractually_liable_party_zipcode"]?.message
                }
                errorMsg={
                  errors?.["contractually_liable_party_zipcode"]?.message
                }
                InputLabelProps={{ shrink: true }}
                sx={{ marginTop: "16px", width: "100%" }}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <DropdownIVF
                id={`contractually_liable_party_state_code`}
                name={`contractually_liable_party_state_code`}
                label="State"
                disabled={buttonLoading}
                options={contractuallyState || []}
                defaultValue={watch(`contractually_liable_party_state_code`)}
                value={watch(`contractually_liable_party_state_code`)}
                {...register(`contractually_liable_party_state_code`, {
                  onChange: () => {
                    setCLPCountryStateChanged(true);
                  },
                })}
                isError={
                  errors?.["contractually_liable_party_state_code"]?.message
                }
                errorMsg={
                  errors?.["contractually_liable_party_state_code"]?.message
                }
              />
            </Grid>
            <Grid
              item
              xl={3}
              lg={3}
              md={6}
              sm={12}
              xs={12}
              // sx={{ padding: "10px" }}
            >
              <TextInputIvf
                register={register}
                disabled={buttonLoading}
                setError={setError}
                isError={errors?.["contractually_liable_party_city"]?.message}
                errorMsg={errors?.["contractually_liable_party_city"]?.message}
                type="text"
                sx={{ marginTop: "16px", width: "100%" }}
                label="City"
                id={`contractually_liable_party_city`}
                name={`contractually_liable_party_city`}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextInputIvf
                setError={setError}
                disabled={buttonLoading}
                register={register}
                type="text"
                required={false}
                id={`financial_email`}
                name={`financial_email`}
                label="Additional Email Address(s) that need a copy of the Invoice and Application"
                sx={{ width: "100%", marginTop: "16px" }}
                InputLabelProps={{ shrink: true }}
                tooltip={financialEmailTooltip}
                isError={errors?.["financial_email"]?.message}
                errorMsg={errors?.["financial_email"]?.message}
              />
            </Grid>
          </Grid>
        </Accordion>

        {/* IVF Clinic Details */}
        <Accordion
          header="IVF Clinic Details"
          component="h2"
          name="ivf clinic details"
        >
          <Grid sx={{ marginTop: -1.2 }} container columnSpacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <DropdownIVF
                label="Country"
                id={`clinic_country_code`}
                name={`clinic_country_code`}
                disabled={buttonLoading}
                options={lookups?.countries_list}
                value={watch(`clinic_country_code`)}
                {...register(`clinic_country_code`, {
                  onChange: (e) => {
                    getClinicStateList(e.target.value);
                    setValue(
                      `clinic_country_code`,
                      watch(`clinic_country_code`)
                    );
                    setValue(`clinic_state_code`, null);
                    setValue(`clinic_name`, null);
                    setValue(`clinic_doctor_name`, null);
                  },
                })}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <DropdownIVF
                label="State"
                id={`clinic_state_code`}
                name={`clinic_state_code`}
                disabled={buttonLoading}
                options={ivfStateList}
                value={watch(`clinic_state_code`)}
                {...register(`clinic_state_code`, {
                  onChange: async (e) => {
                    setLoading(true);
                    getClinicList(e.target.value);
                    setLoading(false);
                    setValue(`clinic_state_code`, watch(`clinic_state_code`));
                    setValue(`clinic_name`, null);
                    setValue(`clinic_doctor_name`, null);
                  },
                })}
                isError={errors?.["clinic_state_code"]?.message}
                errorMsg={errors?.["clinic_state_code"]?.message}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <AutoComplete
                {...register(`clinic_name`)}
                label="IVF Clinic"
                id={`clinic_name`}
                loading={loading}
                disabled={buttonLoading || !watch(`clinic_state_code`)}
                value={watch(`clinic_name`)}
                placeholder="Start typing Clinic name..."
                options={clinicList ?? []}
                optionLabel={(option) =>
                  option?.name || watch(`clinic_name`) || ""
                }
                setShowAddNewAgency={setShowAddNewClinic}
                onInputChange={(_, value) => {
                  setValue(`clinic_name`, value);
                }}
                buttonText="Add New Clinic"
                onChange={(data) => {
                  if (data?.is_active === -1) {
                    setBlackListedClinic(true);
                    setValue(`clinic_name`, null);
                    setValue(`clinic_doctor_name`, null);
                  } else {
                    setValue(`clinic_name`, data?.name);
                    setValue(`clinic_doctor_name`, data?.doctor_name);
                    setError("clinic_name", null);
                  }
                }}
                isError={errors?.["clinic_name"]?.message}
                errorMsg={errors?.["clinic_name"]?.message}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextInputIvf
                type="text"
                register={register}
                name={`clinic_doctor_name`}
                disabled={buttonLoading}
                setError={setError}
                id={`clinic_doctor_name`}
                required={false}
                label="Doctor Name"
                sx={{ marginTop: "16px", width: "100%" }}
                InputLabelProps={{ shrink: true }}
                isError={errors?.["clinic_doctor_name"]?.message}
                errorMsg={errors?.["clinic_doctor_name"]?.message}
              />
            </Grid>
          </Grid>
        </Accordion>

        {/* Agency Details */}
        <Accordion header="Agency Details" component="h2" name="agency details">
          <FormControl sx={{ marginBottom: "1rem" }}>
            <FormLabel
              sx={{
                fontSize: "0.9rem",
                color: "black",
                "&.Mui-focused": {
                  color: "black",
                },
              }}
            >
              Are you working with an Agency?
            </FormLabel>
            <RadioGroup row={true} value={watch("working_with_agency")}>
              {lookups?.yes_no_list?.map((item) => {
                return (
                  <FormControlLabel
                    key={item.value}
                    name={`working_with_agency`}
                    disabled={buttonLoading}
                    sx={{ marginRight: "4rem", fontSize: "2" }}
                    componentsProps={{ typography: { variant: "body2" } }}
                    control={<Radio size="small" />}
                    label={item.label}
                    checked={watch("working_with_agency") == item.value}
                    value={item.value}
                    {...register(`working_with_agency`, {
                      onChange: (e) => {
                        setError(`working_with_agency`, null);
                        setValue(`working_with_agency`, e.target.value);
                        setIsWorkingWithAgency(e.target.value);
                      },
                    })}
                  />
                );
              })}
            </RadioGroup>
            {errors?.["working_with_agency"]?.message && (
              <FormHelperText
                error
                sx={{
                  marginLeft: 0,
                }}
              >
                {errors?.["working_with_agency"]?.message}
              </FormHelperText>
            )}
          </FormControl>
          {!!isWorkingWithAgency && (
            <Grid
              container
              columnSpacing={3}
              sx={{
                display: isWorkingWithAgency == 1 ? "flex" : "none",
              }}
            >
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <DropdownIVF
                  label="Country"
                  id={`agency_country_code`}
                  name={`agency_country_code`}
                  disabled={buttonLoading}
                  options={lookups?.countries_list}
                  value={watch(`agency_country_code`)}
                  {...register(`agency_country_code`, {
                    onChange: (e) => {
                      getStateList(e.target.value);
                      setValue(
                        `agency_country_code`,
                        watch(`agency_country_code`)
                      );
                      setValue(`agency_state_code`, null);
                      setValue(`agency_name`, null);
                      setValue(`agency_contact_person_name`, null);
                    },
                  })}
                  isError={
                    isWorkingWithAgency &&
                    errors?.["agency_country_code"]?.message
                  }
                  errorMsg={errors?.["agency_country_code"]?.message}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <DropdownIVF
                  label="State"
                  id={`agency_state_code`}
                  name={`agency_state_code`}
                  disabled={buttonLoading}
                  options={stateList}
                  value={watch(`agency_state_code`)}
                  {...register(`agency_state_code`, {
                    onChange: async (e) => {
                      setLoading(true);
                      getAgencyList(e.target.value);
                      setLoading(false);
                      setValue(`agency_state_code`, watch(`agency_state_code`));
                      setValue(`agency_name`, null);
                      setValue(`agency_contact_person_name`, null);
                    },
                  })}
                  isError={
                    isWorkingWithAgency &&
                    errors?.["agency_state_code"]?.message
                  }
                  errorMsg={errors?.["agency_state_code"]?.message}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <AutoComplete
                  {...register(`agency_name`)}
                  label="Agency Name"
                  id={`agency_name`}
                  placeholder="Start typing agency name..."
                  loading={loading}
                  disabled={buttonLoading || !watch(`agency_state_code`)}
                  options={agencyList ?? []}
                  optionLabel={(option) =>
                    option?.name || watch(`agency_name`) || ""
                  }
                  setShowAddNewAgency={setShowAddNewAgency}
                  value={watch(`agency_name`)}
                  buttonText="Add New Agency"
                  onInputChange={(_, value) => {
                    setValue(`agency_name`, value);
                  }}
                  onChange={(data) => {
                    if (data?.is_active === -1) {
                      setBlackListedAgency(true);
                      setValue("agency_name", null);
                      setValue("agency_contact_person_name", null);
                    } else {
                      setValue("agency_name", data?.name);
                      deleteFormikError("agency_name");
                      setValue(
                        "agency_contact_person_name",
                        data?.contact_person_name
                      );
                    }
                  }}
                  isError={
                    isWorkingWithAgency && errors?.["agency_name"]?.message
                  }
                  errorMsg={errors?.["agency_name"]?.message}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextInputIvf
                  type="text"
                  name={`agency_contact_person_name`}
                  id={`agency_contact_person_name`}
                  required={false}
                  label="Contact Person Name"
                  disabled={buttonLoading}
                  InputLabelProps={{ shrink: true }}
                  sx={{ marginTop: "16px", width: "100%" }}
                  register={register}
                  setError={setError}
                  isError={
                    isWorkingWithAgency &&
                    errors?.["agency_contact_person_name"]?.message
                  }
                  errorMsg={errors?.["agency_contact_person_name"]?.message}
                />
              </Grid>
            </Grid>
          )}
        </Accordion>
      </>

      {showAddNewClinic && (
        <AddNewClinic
          setLoading={setLoading}
          open={showAddNewClinic}
          onClose={() => setShowAddNewClinic(false)}
          newClinicData={({ country_code, state_code, name, doctor_name }) => {
            setNewClinicAdded(!isNewClinicAdded);
            setValue(`clinic_country_code`, country_code);
            setValue(`clinic_state_code`, state_code);
            setValue(`clinic_name`, name);
            setValue(`clinic_doctor_name`, doctor_name);
          }}
        />
      )}

      {showAddNewAgency && (
        <AddNewAgency
          open={showAddNewAgency}
          onClose={() => setShowAddNewAgency(false)}
          setLoading={setLoading}
          newAgencyData={({
            country_code,
            state_code,
            name,
            contact_person_name,
          }) => {
            setNewAgencyAdded(!isNewAgencyAdded);
            setValue(`agency_country_code`, country_code);
            setValue(`agency_state_code`, state_code);
            setValue(`agency_name`, name);
            setValue(`agency_contact_person_name`, contact_person_name);
          }}
        />
      )}

      <SadActionButtons
        onCancel={setDataInitially}
        buttonLoading={buttonLoading == "continue"}
      />

      {/* Warning modal if Contractual Liable Party's non US country or restricted state selected */}
      <CommonModal
        open={countryStateModal.show}
        type="warning"
        onConfirm={() => {
          setCountryStateModal({ show: false, text: "" });
          setCLPCountryStateChanged(false);
        }}
        title="Please Note"
        subTitle={`Due to ${countryStateModal.text}, this application will need to be reviewed before approval. If you have any questions, please contact your broker.`}
      />

      {/* Warning modal if BLACKlISTED clinic is selected */}
      <CommonModal
        open={isBlackListedClinic}
        type="warning"
        onConfirm={() => setBlackListedClinic(false)}
        title="Please Note"
        subTitle={`We are sorry!<br />At this time, we are not accepting applications for coverage, from this clinic.<br/>If you have any questions, please contact your broker.`}
      />

      {/* Warning modal if BLACKlISTED agency is selected */}
      <CommonModal
        open={isBlackListedAgency}
        type="warning"
        onConfirm={() => setBlackListedAgency(false)}
        title="Please Note"
        subTitle={`We are sorry!<br />At this time, we are not accepting applications for coverage, from this agency.<br/>If you have any questions, please contact your broker.`}
      />

      <CommonModal
        open={showDeleteModal}
        type="delete"
        onCancel={() => setShowDeleteModal(false)}
        confirmButtonLabel="Delete"
        title="Are You Sure?"
        subTitle="Do you want to delete this file?"
      />

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => setUpdateModal(false)}
        title="Application Updated Successfully!"
      />
    </form>
  );
};

export { Edit_ApplicationForm };
