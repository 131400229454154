/*
 * State Tax page
 */

import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import { Box, Grid, Typography } from "@mui/material";

import { TableData } from "./TableData";
import { AllRoutes } from "../../../routes";
import { TableHeader } from "./TableHeader";
import { Button, GoBack } from "../../../components";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";
import { StateFeeApis } from "../../../api/StateFeeApis";
import { CommonApis } from "../../../api";
import { constructStateList } from "../../../utils/CommonUtils";

const StateTax = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataList, setDataList] = useState([]);
  const [listData, setListData] = useState();
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [country, setCountry] = useState("US");
  const [state, setState] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [advancedSearch, setAdvancedSearch] = useState();

  useEffect(() => {
    setSearchParams((searchParams) => {
      if (!searchParams.has("page")) {
        searchParams.set("page", 1);
      }
      if (!searchParams.has("country_code")) {
        searchParams.set("country_code", "US");
      }
      return searchParams;
    });

    const params = [];
    searchParams.forEach((value, key) => {
      params.push([key, value]);
    });
    const obj = params.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    if (obj?.hasOwnProperty("country_code")) {
      setCountry(obj["country_code"]);
    }
    if (obj?.hasOwnProperty("state_code")) {
      setState(obj["state_code"]);
    }

    setPage(Number(obj["page"]));
    const { page, ...rest } = obj;
    setAdvancedSearch(rest);
  }, []);

  useEffect(() => {
    !!country && getStateList();
  }, [country]);

  const getStateList = async () => {
    const resp = await CommonApis.getZipData({ country_code: country });
    const getStates = constructStateList(resp);
    setStateList(getStates ?? []);
  };

  useEffect(() => {
    if (page > 0 && country) {
      getStateFeeList();
    }
  }, [country, state, page]);

  const getStateFeeList = async () => {
    setDataLoading(true);
    let params = {
      sortOrder: "", // Remove "" to sort from API
      sortKey: "", // Remove "" to sort from API
      page,
      advancedSearch,
    };
    const resp = await StateFeeApis.getStateFeeList(params);
    if (resp) {
      setDataLoading(false);
      setLoading(false);
    }
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
  };

  return (
    <AdminPageLayout>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={7}
            md={8}
            lg={9}
            xl={10}
            sx={{ marginBottom: "1rem" }}
          >
            <Box display="flex" alignItems="center">
              <GoBack navigateURL={AllRoutes.WizardSettings.route} />
              <Typography variant="h1" sx={{ margin: "0" }}>
                State Tax
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={5}
            md={4}
            lg={3}
            xl={2}
            sx={{ textAlign: "right", marginBottom: "0.5rem" }}
          >
            <Button
              title="Add New Tax"
              variant="contained"
              sx={{ width: "100%", minHeight: "2rem" }}
              startIcon={<AddIcon sx={{ color: "#FFFFFF" }} />}
              onClick={() =>
                navigate(
                  `${AllRoutes.AddNewStateTax.route}/${country}/${state}`
                )
              }
              disabled={!country || !state}
            />
          </Grid>
        </Grid>
      </Box>

      <TableHeader
        setPage={setPage}
        country={country}
        setCountry={setCountry}
        state={state}
        setState={setState}
        stateList={stateList}
        setAdvancedSearch={setAdvancedSearch}
        setSearchParams={setSearchParams}
      />

      <TableData
        caption="State Tax"
        dataList={dataList}
        setDataList={setDataList}
        listData={listData}
        setSortKey={setSortKey}
        setSortOrder={setSortOrder}
        country={country}
        state={state}
        page={page}
        setPage={setPage}
        loading={loading}
        dataLoading={dataLoading}
        setSearchParams={setSearchParams}
      />
    </AdminPageLayout>
  );
};

export { StateTax };
