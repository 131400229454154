/*
 * Common component to add clinic details in any form
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import { Dropdown } from "./Dropdown";
import { AutoComplete } from "./AutoComplete";
import { ClinicApis, CommonApis } from "../api";
import { AddNewClinic } from "../pages/ivf/ivf-user/components/AddNewClinic";
import { CommonModal } from "./CommonModal";
import { constructStateList } from "../utils/CommonUtils";

const ClinicDetails = ({
  disabled = false,
  register,
  setValue,
  watch,
  getValues,
  loading,
  setLoading,
  errors,
  setError,
}) => {
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [clinicList, setClinicList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isBlackListedClinic, setBlackListedClinic] = useState(false);
  const [isNewClinicAdded, setNewClinicAdded] = useState(false);
  const [showAddNewClinic, setShowAddNewClinic] = useState(false);
  const { countries_list } = lookups ?? {};

  // Get clinic list based on state
  useEffect(() => {
    !!watch("clinic_state_code") && getClinicList();
  }, [watch("clinic_state_code"), isNewClinicAdded]);

  // Get state list based on country for agencies
  useEffect(() => {
    !!watch("clinic_country_code") && getStateList();
  }, [watch("clinic_country_code")]);

  const getStateList = async () => {
    const resp = await CommonApis.getZipData({
      country_code: watch("clinic_country_code"),
    });
    const getStates = constructStateList(resp);
    setStateList(getStates);
  };

  const getClinicList = async () => {
    setLoading(true);
    const resp = await ClinicApis.getClinicsList({
      country_code: watch("clinic_country_code"),
      state_code: watch("clinic_state_code"),
    });
    !!resp && setLoading(false);
    setClinicList(resp);
  };

  return (
    <>
      <Grid container columnSpacing={3} rowSpacing={2}>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <Dropdown
            label="Country"
            id="clinic_country_code"
            disabled={disabled}
            options={countries_list}
            register={register}
            value={watch("clinic_country_code")}
            registerChange={(e) => {
              setValue("clinic_state_code", null);
              setValue("clinic_name", null);
            }}
            isError={errors?.clinic_country_code}
            errorMsg={errors?.clinic_country_code?.message}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <Dropdown
            label="State"
            id="clinic_state_code"
            disabled={disabled || !watch("clinic_country_code")}
            options={stateList}
            value={watch("clinic_state_code")}
            register={register}
            registerChange={(e) => {
              setValue("clinic_name", null);
            }}
            isError={errors?.clinic_state_code}
            errorMsg={errors?.clinic_state_code?.message}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <AutoComplete
            {...register("clinic_name")}
            label="Clinic Name"
            id="clinic_name"
            placeholder="Start typing clinic name..."
            disabled={disabled || !watch("clinic_state_code")}
            options={clinicList ?? []}
            optionLabel={(option) =>
              option?.name || getValues("clinic_name") || ""
            }
            setShowAddNewAgency={setShowAddNewClinic}
            buttonText="Add New Clinic"
            onInputChange={(_, value) => setValue(`clinic_name`, value)}
            loading={loading}
            value={getValues("clinic_name")}
            onChange={(data) => {
              if (data?.is_active === -1) {
                setBlackListedClinic(true);
                setValue("clinic_id", null);
                setValue("clinic_name", null);
              } else {
                setValue("clinic_id", data?.id);
                setValue("clinic_name", data?.name);
                setError("clinic_name", null);
              }
            }}
            isError={errors?.clinic_name}
            errorMsg={errors?.clinic_name?.message}
          />
        </Grid>
      </Grid>

      {showAddNewClinic && (
        <AddNewClinic
          open={showAddNewClinic}
          onClose={() => setShowAddNewClinic(false)}
          setLoading={setLoading}
          newClinicData={({ id, country_code, state_code, name }) => {
            setNewClinicAdded(!isNewClinicAdded);
            setValue("clinic_id", id);
            setValue("clinic_country_code", country_code);
            setValue("clinic_state_code", state_code);
            setValue("clinic_name", name);
            setError("clinic_name", null);
          }}
        />
      )}

      {/* Warning modal if BLACKlISTED clinic is selected */}
      <CommonModal
        open={isBlackListedClinic}
        type="warning"
        onConfirm={() => setBlackListedClinic(false)}
        title="Please Note"
        subTitle={`We are sorry!<br />At this time, we are not accepting applications for coverage, from this clinic.<br/>If you have any questions, please contact your broker.`}
      />
    </>
  );
};

export { ClinicDetails };
