/*
 * Admin -> Wizard -> Coverage Type -> Table header
 */

import React from "react";
import { Grid, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Dropdown, GridActionButtons, TextInput } from "../../../components";
import {
  coverageTypesList,
  INSURANCE_TYPES,
  INSURANCE_TYPES_LIST,
} from "../../../constants";

const TableHeader = ({
  searchText,
  setSearchText,
  setPage,
  setSearchButton,
  buttonLoading,
  setButtonLoading,
  selectedInsurance,
  setSelectedInsurance,
  selectedCoverage,
  setSelectedCoverage,
  setSearchParams,
}) => {
  const searchValues = () => {
    setButtonLoading("search");
    setPage(1);
    setSearchParams({
      page: 1,
      insurance_type: selectedInsurance,
      coverage_type: selectedCoverage,
      name: searchText,
    });
    setSearchButton(true);
  };

  const onClear = () => {
    setButtonLoading("clear");
    setSearchButton(true);
    setSearchText(" ");
    setPage(1);
    setSelectedInsurance(INSURANCE_TYPES.ivf);
    setSelectedCoverage(null);
    setSearchParams({ page: 1 });
  };

  return (
    <form>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: "0.3rem", marginBottom: "1.5rem" }}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Dropdown
            size="small"
            label="Type of Insurance Product"
            id="selectedInsurance"
            options={INSURANCE_TYPES_LIST || []}
            value={selectedInsurance}
            onChange={(e) => {
              setSelectedInsurance(e.target.value);
              setSearchButton(false);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Dropdown
            size="small"
            label="Coverage Type"
            id="selectedCoverage"
            options={coverageTypesList(selectedInsurance) || []}
            selectValue={selectedCoverage}
            value={selectedCoverage}
            onChange={(e) => {
              setSelectedCoverage(e.target.value);
              setSearchButton(false);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextInput
            inputKey="search"
            inputLabel="Search"
            placeholder="Search by Keywords"
            autoComplete="off"
            required={false}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              setSearchButton(false);
            }}
            sx={{ height: "2.5rem" }}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <GridActionButtons
            onSearch={searchValues}
            onClear={onClear}
            searchButtonLoading={buttonLoading == "search"}
            clearButtonLoading={buttonLoading == "clear"}
            wrapperStyle={{ marginTop: "1rem" }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export { TableHeader };
