/*
 * A D M I N --- M O D U L E-   A D D   N E W   S T A T E
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/styles";

import { CountriesApis } from "../../../../api";
import { theme } from "../../../../styles/theme";
import {
  CommonModal,
  TextInput,
  GoBack,
  GlobalLoader,
} from "../../../../components";
import { AdminPageLayout } from "../../../../layouts/admin-layout/AdminPageLayout";
import { SadActionButtons } from "../../../sad/sad-admin/components/ActionButtons";

const formValues = {
  state: "",
  state_code: "",
  broker_surplus_line_no: "",
  broker_surplus_name: "",
  newlife_surplus_line_no: "",
};

const validationSchema = Yup.object()
  .shape({
    state: Yup.string().required("State is required"),
    state_code: Yup.string().required("State code is required"),
    broker_surplus_line_no: Yup.string().required(
      "Broker surplus line no. is required"
    ),
    broker_surplus_name: Yup.string().required(
      "Broker surplus name is required"
    ),
    newlife_surplus_line_no: Yup.string().required(
      "NewLife surplus line no. is required"
    ),
  })
  .required();

const Countries_Add_Edit_State = () => {
  const navigate = useNavigate();
  const { country_id, state_id } = useParams();
  const [stateData, setStateData] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  useEffect(() => {
    const getStateData = async () => {
      setLoading(true);
      const resp = await CountriesApis.getStateofCountryDetail({
        country_id,
        state_id,
      });
      !!resp && setLoading(false);
      !!resp?.success && setStateData(resp?.data);
    };
    !!state_id && getStateData();
  }, []);

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formValues,
  });

  useEffect(() => {
    if (state_id && stateData) {
      Object.keys(stateData).forEach((key) => {
        if (stateData?.hasOwnProperty(key)) {
          setValue(key, stateData[key]);
        }
      });
    }
  }, [stateData]);

  const onSubmit = async (data) => {
    setButtonLoading(true);
    let resp = null;
    if (state_id) {
      resp = await CountriesApis.updateStateofCountry({
        country_id,
        state_id,
        params: data,
      });
    } else {
      resp = await CountriesApis.addStateofCountry({
        country_id,
        params: data,
      });
    }

    !!resp && setButtonLoading(false);
    if (resp?.success) {
      setUpdateModal(true);
    } else {
      Object.keys(resp?.errors).forEach((key) => {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AdminPageLayout>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <GoBack />
          <Typography variant="h1" sx={{ margin: "0" }}>
            {state_id ? "Edit State" : "Add New State"}
          </Typography>
        </Box>

        {loading && <GlobalLoader loading={loading} />}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={2}
            sx={{ marginY: "1rem" }}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="state"
                inputLabel="Region/State"
                register={register}
                isError={errors?.state?.message}
                errorMsg={errors?.state?.message}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="state_code"
                inputLabel="State Code"
                register={register}
                isError={errors?.state_code?.message}
                errorMsg={errors?.state_code?.message}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="broker_surplus_line_no"
                inputLabel="Broker Surplus Line No."
                register={register}
                isError={errors?.broker_surplus_line_no?.message}
                errorMsg={errors?.broker_surplus_line_no?.message}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="broker_surplus_name"
                inputLabel="Broker Surplus Name"
                register={register}
                isError={errors?.broker_surplus_name?.message}
                errorMsg={errors?.broker_surplus_name?.message}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="newlife_surplus_line_no"
                inputLabel="NewLife Surplus Line No."
                register={register}
                isError={errors?.newlife_surplus_line_no?.message}
                errorMsg={errors?.newlife_surplus_line_no?.message}
              />
            </Grid>
          </Grid>

          <SadActionButtons
            onCancel={() => reset(state_id ? stateData : formValues)}
            {...(!state_id && { updateButtonLabel: "Add State" })}
            buttonLoading={buttonLoading}
          />
        </form>

        <CommonModal
          open={updateModal}
          type="success"
          onConfirm={() => {
            setUpdateModal(false);
            navigate(-1);
          }}
          title={`State ${state_id ? "Updated" : "Added"} Successfully!`}
        />
      </AdminPageLayout>
    </ThemeProvider>
  );
};

export { Countries_Add_Edit_State };
