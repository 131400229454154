/*
 * SAD/IVF user change-cancel request page header
 */

import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

import { PolicyStatusValue, PolicyTypes } from "../../constants";
import {
  ivfApplicationSummary,
  sadApplicationSummary,
} from "../../../../redux/slices/globalSlice";
import { formattedMDYDate } from "../../../../utils/CommonUtils";
import { LocalStorageHelper } from "../../../../utils/HttpUtils";
import { coverageTypeLabel, USER_DATA } from "../../../../constants";

const RequestsHeader = ({ header, setCanceled, setLoading, isIVFPolicy }) => {
  const myData = JSON.parse(LocalStorageHelper.get(USER_DATA));
  const dispatch = useDispatch();
  const { id: application_id } = useParams();
  const summaryData = useSelector((state) =>
    isIVFPolicy ? state.ivfReducer.ivfSummary : state.sadReducer.sadSummary
  );
  const lookups = useSelector((state) => state.commonReducer.lookups);

  const { application_status_types_list } = lookups ?? {};
  const {
    alerts,
    application_number,
    coverage_type,
    status,
    submitted_on,
    insurance_type,
  } = (isIVFPolicy ? summaryData?.[0] : summaryData) ?? {};

  const { first_name, last_name, display_name, email } = myData ?? {};

  useEffect(() => {
    dispatch(
      isIVFPolicy
        ? ivfApplicationSummary({ application_id })
        : sadApplicationSummary({ application_id })
    );
  }, []);

  useEffect(() => {
    !!setLoading && !!Object.entries(summaryData)?.length && setLoading(false);
    !!setCanceled && summaryData?.has_cancel_request && setCanceled(true);
  }, [summaryData]);

  const getPolicyStatus = () => {
    if (status == PolicyTypes.underReview) {
      return alerts?.length ? "Under Review" : "Submitted";
    } else {
      return PolicyStatusValue(application_status_types_list, status);
    }
  };

  const getValue = (value) => {
    return application_number ? value : "";
  };

  const generalInformation = [
    {
      value: getValue(getPolicyStatus()),
      label: "Policy Status:",
    },
    {
      value: getValue(application_number),
      label: "Policy Number:",
    },
    {
      value: getValue(coverageTypeLabel(insurance_type, coverage_type)),
      label: "Coverage Type:",
    },
  ];
  const submitInformation = [
    {
      value: getValue(display_name ?? `${first_name ?? ""} ${last_name ?? ""}`),
      label: "Submitted by Applicant:",
    },
    {
      value: getValue(email),
      label: "Submitted by Email:",
    },
    {
      value: getValue(formattedMDYDate(submitted_on)),
      label: "Submitted On:",
    },
  ];

  const RenderSummaryItem = ({ options = [] }) => {
    return (
      <Grid container sx={{ paddingTop: "0.7rem", paddingX: "0.5rem" }}>
        {!!options &&
          options?.map((option) => (
            <React.Fragment key={option.label}>
              <Grid item xl={4} lg={4} md={5} sm={5} xs={12}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    color: "#222222",
                    paddingBottom: { xs: "0", sm: "0", md: "0.7rem" },
                  }}
                >
                  {option.label}
                </Typography>
              </Grid>
              <Grid item xl={8} lg={8} md={7} sm={7} xs={12}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    paddingBottom: "0.7rem",
                    paddingLeft: { xs: "0", sm: "0", md: "0.3rem" },
                  }}
                >
                  {option.value}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    );
  };

  return (
    <>
      <Typography variant="h1">{header}</Typography>
      <Grid
        container
        columnSpacing={3}
        sx={{
          backgroundColor: "#F082331A",
          padding: "10px",
          marginY: "1.7rem",
        }}
      >
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <RenderSummaryItem options={generalInformation}></RenderSummaryItem>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <RenderSummaryItem options={submitInformation}></RenderSummaryItem>
        </Grid>
      </Grid>
    </>
  );
};

export { RequestsHeader };
