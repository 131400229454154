/*
 * Admin -> Wizard -> IVF Clinics -> Clinic's users -> Add new user
 */

import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
  AddressInput,
  Button,
  CommonModal,
  GlobalLoader,
  GoBack,
  MandatoryStatement,
  PhoneInput,
  TextInput,
} from "../../../../components";
import {
  emailRegex,
  focusFirstErrorField,
  testNonLetter,
} from "../../../../utils/CommonUtils";
import { UsersApis } from "../../../../api";
import { AdminPageLayout } from "../../../../layouts/admin-layout/AdminPageLayout";
import { USER_ROLES } from "../../../../constants";
import { SadActionButtons } from "../../../sad/sad-admin/components/ActionButtons";

const formValues = {
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  address_line_1: "",
  zipcode: "",
  country_code: "US",
  state_code: "",
  city: "",
  invite: 1,
};

const AddNewClinicUser = () => {
  const navigate = useNavigate();
  const { clinic_id } = useParams();
  const applyNewApplicationButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const validationSchema = useMemo(() => {
    const requiredSchema = {
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .nullable()
        .required("Email is required")
        .matches(emailRegex, "Invalid email")
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        ),
      mobile: Yup.string().required("Contact number is required"),
      address_line_1: Yup.string().required("Street Address is required"),
      country_code: Yup.string().required("Country is required"),
      zipcode: Yup.string().required("Zip Code is required"),
      state_code: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
    };

    return Yup.object(requiredSchema);
  }, []);

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues: formValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const errorArray = Object.keys(errors);
    if (!errorArray?.length) return;
    focusFirstErrorField();
  }, [errors]);

  const onSubmitForm = async () => {
    setButtonLoading(true);
    let payload = getValues();

    payload = {
      ...payload,
      role: USER_ROLES.clinic,
      clinic_id,
      working_with_agency: 0,
    };

    const resp = await UsersApis.createNewUser(payload);
    !!resp && setButtonLoading(false);
    if (resp?.success) {
      setUpdateModal(true);
      applyNewApplicationButtonRef?.current?.focus();
    } else {
      Object.keys(resp?.errors).forEach(function (key) {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  return (
    <AdminPageLayout>
      {loading && <GlobalLoader loading={loading} />}

      <Box display="flex" sx={{ alignItems: "center" }}>
        <GoBack />
        <Typography variant="h1" sx={{ margin: 0 }}>
          Add New User
        </Typography>
      </Box>

      <Grid container sx={{ paddingTop: "0.7rem" }}>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Box sx={{ marginLeft: "0.55rem", marginTop: "1.3rem" }}>
            <MandatoryStatement />
          </Box>
          <Grid
            container
            sx={{ justifyContent: "center" }}
            rowSpacing={2}
            columnSpacing={3}
          >
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="first_name"
                inputLabel="First Name"
                register={register}
                disabled={buttonLoading}
                isError={errors?.first_name?.message}
                errorMsg={errors?.first_name?.message}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="last_name"
                inputLabel="Last Name"
                register={register}
                disabled={buttonLoading}
                isError={errors?.last_name?.message}
                errorMsg={errors?.last_name?.message}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="email"
                inputLabel="Email"
                disabled={buttonLoading}
                register={register}
                isError={errors?.email?.message}
                errorMsg={errors?.email?.message}
                endAdornment={
                  !!emailRegex.test?.(watch("email")) && (
                    <InputAdornment position="end">
                      <CheckCircleIcon sx={{ color: "#00AD00" }} />
                    </InputAdornment>
                  )
                }
              />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <PhoneInput
                inputKey="mobile"
                inputLabel="Contact No."
                autoComplete="off"
                disabled={buttonLoading}
                register={register}
                {...(testNonLetter(watch("mobile")) && {
                  value: watch("mobile"),
                })}
                registerChange={(_, val) => {
                  setValue("mobile", val);
                  !!errors?.mobile?.message && setError("mobile", null);
                }}
                isError={errors?.mobile?.message}
                errorMsg={errors?.mobile?.message}
              />
            </Grid>
            <AddressInput
              disabled={buttonLoading}
              setLoading={setLoading}
              register={register}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              setError={setError}
              errors={errors}
              addressKey="address_line_1"
              countryKey="country_code"
              zipcodeKey="zipcode"
              stateKey="state_code"
              cityKey="city"
            />
          </Grid>

          <FormControlLabel
            control={
              <Checkbox
                id="invite"
                name="invite"
                disabled={buttonLoading}
                checked={watch("invite")}
                value={watch("invite")}
                onChange={() => {
                  setValue("invite", Number(!watch("invite")));
                }}
              />
            }
            label="Send Invite Email"
          />

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <SadActionButtons
              onCancel={() => reset(formValues)}
              updateButtonLabel="Add User"
              buttonLoading={buttonLoading}
            />
          </Box>
        </form>
      </Grid>

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => {
          setUpdateModal(false);
          navigate(-1);
        }}
        title="User Created Successfully"
      />
    </AdminPageLayout>
  );
};

export { AddNewClinicUser };
