/*
 * A D M I N --- M O D U L E- I V F--C L I N I C--- T A B L E   D A T A
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { AllRoutes } from "../../../routes";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ReactTable from "../../../React Table";
import {
  CommonModal,
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderChip,
  RenderTableHeader,
} from "../../../components";
import { ClinicApis } from "../../../api";
import { orgTypes, strings } from "../../../constants";

const { verified, unverified, blacklisted } = orgTypes;

const filterOptions = [
  { value: "1", label: verified },
  { value: "0", label: unverified },
  { value: "-1", label: blacklisted },
];

const TableData = ({
  caption,
  hasRecords,
  dataList,
  listData,
  setSearchText,
  setSortKey,
  setSortOrder,
  page,
  setPage,
  loading,
  dataLoading,
  setSearchButton,
  clinicStatus,
  setClinicStatus,
  setSearchParams,
}) => {
  const navigate = useNavigate();
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const handleFilterChange = (e, key) => {
    setPage(1);
    setSearchButton(true);
    setSearchParams((searchParams) => {
      searchParams.set("page", 1);
      searchParams.set(key, e.target.value);
      return searchParams;
    });
  };

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  const updateClinic = async (clinic_id, is_active) => {
    setActionLoading(true);
    const resp = await ClinicApis.updateClinic({
      clinic_id,
      params: { is_active },
    });
    !!resp && setActionLoading(false);
    if (resp?.success) {
      setUpdateModal(true);
      setSearchButton(true);
    }
  };

  const openActionDropDown = (e, rowData) => {
    const { id, is_active } = rowData ?? {};

    let allowed_actions = [
      {
        text: strings.editClinic,
        route: () => navigate(`${AllRoutes.EditClinic.route}/${id}`),
      },
      {
        text: "View Users",
        route: () => {
          navigate(`${AllRoutes.ClinicUsers.route}/${id}`);
        },
      },
    ];

    if (is_active !== 1) {
      allowed_actions.push({
        text: is_active < 0 ? "Activate" : "Verify",
        route: () => updateClinic(id, 1),
      });
    }
    if (is_active !== -1) {
      allowed_actions.push({
        text: "Blacklist",
        route: () => updateClinic(id, -1),
      });
    }
    if (is_active !== 0) {
      allowed_actions.push({
        text: "Unverify",
        route: () => updateClinic(id, 0),
      });
    }
    setClickable(allowed_actions);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const tableColumns = [
    {
      id: "Action",
      header: "Action",
      cell: ({ row }) => (
        <IconButton onClick={(e) => openActionDropDown(e, row.original)}>
          <MoreHorizIcon />
        </IconButton>
      ),
    },
    {
      id: "Status",
      accessorKey: "is_active",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Status"
          headerKey="is_active"
        />
      ),
      cell: (info) => {
        const is_active = info.getValue();
        if (is_active === 1) {
          return <RenderChip label={verified} />;
        } else if (is_active === 0) {
          return <RenderChip label={unverified} />;
        } else if (is_active === -1) {
          return <RenderChip label={blacklisted} />;
        }
      },
      footer: (props) => props.column.id,
    },
    {
      id: "IVF Clinic Name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="IVF Clinic Name"
          headerKey="name"
        />
      ),
      accessorKey: "name",
      cell: (info) => (
        <RenderCellInfo text={info.getValue()} sx={{ textAlign: "center" }} />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Contact Person Name & Email",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Contact Person Name & Email"
          headerKey="contact_person_name"
        />
      ),
      accessorKey: "contact_person_name",
      cell: (info) => {
        const { contact_person_name, email } = info?.row?.original;
        return (
          <RenderCellInfo
            text={
              <Typography>
                <Typography>{contact_person_name || ""}</Typography>
                <Typography>{email || ""}</Typography>
              </Typography>
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Contact Number",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Contact Number"
          headerKey="contact_number"
        />
      ),
      accessorKey: "contact_number",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Address",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Address"
          headerKey="address_line_1"
        />
      ),
      accessorKey: "address_line_1",
      cell: (info) => {
        const { city, state_code, zipcode } = info?.row?.original ?? {};
        return (
          <RenderCellInfo
            text={`${info.getValue()}${
              city ? `${info.getValue() ? "," : ""} ${city}` : ""
            }${state_code ? `, ${state_code}` : ""}${
              zipcode ? `, ${zipcode}` : ""
            }`}
          />
        );
      },
      footer: (props) => props.column.id,
    },
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : hasRecords ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            page={page}
            setPage={handlePageChange}
            listData={listData}
            onSearch={setSearchText}
            caption={caption}
            dataLoading={dataLoading}
            showFilterDropdown={true}
            label="IVF Clinic Status"
            filterOptions={filterOptions}
            firstFilterValue={clinicStatus}
            handleFirstFilterChange={(e) => {
              handleFilterChange(e, "is_active");
              setClinicStatus(e.target.value);
            }}
            setDataFetching={setSearchButton}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
            selectedRow={selectedRow}
          />
        </Box>
      ) : (
        <NoRecords />
      )}

      {actionLoading && <GlobalLoader loading={actionLoading} />}

      {/* Modal for successful update of clinic status */}
      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => setUpdateModal(false)}
        title="Status Updated successfully!"
      />
    </>
  );
};

export { TableData };
