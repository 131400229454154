/*
 * A D M I N --- M O D U L E- S T A T E   L I S T   D A T A    G R I D
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Box, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { TableData } from "./TableData";
import { AdminPageLayout } from "../../../../layouts/admin-layout/AdminPageLayout";
import { Button, GoBack } from "../../../../components";
import { AllRoutes } from "../../../../routes";

const StateList = () => {
  const navigate = useNavigate();
  const { country_id } = useParams();
  return (
    <AdminPageLayout>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={7}
            md={8}
            lg={9}
            xl={10}
            sx={{ marginBottom: "1rem" }}
          >
            <Box display="flex" alignItems="center">
              <GoBack navigateURL={AllRoutes.CountryFee.route} />
              <Typography variant="h1" sx={{ margin: "0" }}>
                Regions / States
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={5}
            md={4}
            lg={3}
            xl={2}
            sx={{ textAlign: "right", marginBottom: "0.5rem" }}
          >
            <Button
              title="Add New State"
              variant="contained"
              sx={{ width: "100%", minHeight: "2rem" }}
              startIcon={<AddIcon sx={{ color: "#FFFFFF" }} />}
              onClick={() =>
                navigate(`${AllRoutes.AddNewState.route}/${country_id}`)
              }
            />
          </Grid>
        </Grid>
      </Box>

      <TableData caption="States" />
    </AdminPageLayout>
  );
};

export { StateList };
