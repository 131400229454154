/*
 * Admin module -> Wizard -> Certificate Batch Download // Table Data
 */

import React, { useState } from "react";
import { Box, Button, Checkbox } from "@mui/material";
import { AttachmentApis } from "../../../api";

import ReactTable from "../../../React Table";
import {
  GlobalLoader,
  NoRecords,
  RenderCellInfo,
  RenderTableHeader,
} from "../../../components";
import {
  downloadBlobAsFile,
  formattedMDYDate,
} from "../../../utils/CommonUtils";
import { PolicyDocTypes } from "../../sad/constants";
import { BULK_DOWNLOAD_DOC_TYPES, strings } from "../../../constants";

const TableData = ({
  caption,
  stateList,
  page,
  setPage,
  loading,
  setLoading,
  dataList,
  listData,
  dataLoading,
}) => {
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [selectedCheckAll, setSelectedCheckAll] = useState(false);
  const [isDownloading, setDownloading] = useState(0);
  const [actionLoading, setActionLoading] = useState(false);

  const getStateName = (state_code) => {
    return stateList?.find((state) => state?.value === state_code)?.label || "";
  };

  const downloadDocument = async (application_id, policy_doc_id) => {
    const resp = await AttachmentApis.downloadDocument({
      application_id,
      policy_doc_id,
      download: 1,
    });

    if (resp?.success && resp?.data) {
      downloadBlobAsFile(resp.data, `${resp?.filename || "certificate.pdf"}`);
      setDownloading(0);
    } else {
      setDownloading(0);
      console.error("Failed to download certificate");
    }
  };

  const onZipDownload = async () => {
    const { certificates } = BULK_DOWNLOAD_DOC_TYPES;
    setActionLoading(true);
    const resp = await AttachmentApis.bulkDownload({
      application_ids: selectedCheckbox,
      doc_id: certificates,
      download: 1,
    });

    if (resp?.success && resp?.data) {
      downloadBlobAsFile(resp.data, `${resp?.filename || "certificates.zip"}`);
      setActionLoading(false);
    } else {
      setActionLoading(false);
      console.error(`Failed to download certificates`);
    }
  };

  const handleChangeAll = (event) => {
    const newCheckedState = event.target.checked;
    if (newCheckedState) {
      setSelectedCheckbox(dataList.map((item) => item.id));
    } else {
      setSelectedCheckbox([]);
    }
    setSelectedCheckAll(newCheckedState);
  };

  const handleChange = (id) => {
    setSelectedCheckbox((prev) => {
      const newSelection = prev?.includes(id)
        ? prev?.filter((item) => item !== id)
        : [...prev, id];

      setSelectedCheckAll(newSelection.length === dataList.length);
      return newSelection;
    });
  };

  const tableColumns = [
    {
      id: "Checkbox",
      header: ({ column }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            sx={{
              marginLeft: "0.2rem",
              backgroundColor: "transparent",
              "&.Mui-checked": { color: "white" },
              "& .MuiSvgIcon-root": { color: "white" },
            }}
            checked={selectedCheckAll ? "checked" : undefined}
            onChange={handleChangeAll}
            indeterminate={
              selectedCheckbox.length > 0 &&
              selectedCheckbox.length < dataList.length
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
      accessorKey: "checkbox",
      cell: (info) => (
        <div>
          <Checkbox
            key={info.row.original?.id || ""}
            checked={
              selectedCheckbox.includes(info.row.original?.id)
                ? "checked"
                : undefined
            }
            onChange={() => handleChange(info.row.original?.id)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "File Name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="File Name"
          headerKey="document_name"
        />
      ),
      accessorKey: "document_name",
      cell: (info) => {
        const { id } = info?.row?.original ?? {};
        return (
          <RenderCellInfo
            text={
              <Button
                key={`row-${id}-${isDownloading}`}
                disabled={isDownloading === id}
                onClick={() => {
                  downloadDocument(id, PolicyDocTypes.certificate);
                  setDownloading(id);
                }}
                sx={{ cursor: "pointer", color: "#0A7691" }}
              >
                {isDownloading === id
                  ? "Downloading..."
                  : info.getValue() || ""}
              </Button>
            }
          />
        );
      },

      footer: (props) => props.column.id,
    },
    {
      id: "Policy Number",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Number"
          headerKey="application_number"
        />
      ),
      accessorKey: "application_number",
      cell: (info) => {
        return (
          <RenderCellInfo text={info.getValue()} sx={{ color: "#0A7691" }} />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "State",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="State"
          headerKey="contractually_liable_party_state_code"
        />
      ),
      cell: (info) => {
        const {
          contractually_liable_party_state_code,
          intended_parent_state_code,
        } = info?.row?.original;
        return (
          <RenderCellInfo
            text={getStateName(
              contractually_liable_party_state_code
                ? contractually_liable_party_state_code
                : intended_parent_state_code
            )}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Created On",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Created On"
          headerKey="created_at"
        />
      ),
      accessorKey: "created_at",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Effective Date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Effective Date"
          headerKey="effective_from"
        />
      ),
      accessorKey: "effective_from",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
  ];

  if (loading) return <GlobalLoader loading={loading} />;

  return (
    <>
      {dataList?.length > 0 ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            showFilterDropdown={false}
            page={page}
            setPage={setPage}
            listData={listData}
            onZipDownload={onZipDownload}
            disableZipDownload={selectedCheckbox?.length === 0}
            downloadFileLabel={strings.batchDownload}
            setLoading={setLoading}
            dataLoading={dataLoading}
            caption={caption}
          />
        </Box>
      ) : (
        <NoRecords />
      )}

      {actionLoading && <GlobalLoader loading={actionLoading} />}
    </>
  );
};

export { TableData };
