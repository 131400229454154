/*
 * View if coverage type is with covid selected
 */

import React from "react";
import { Box, Checkbox, FormControlLabel, FormHelperText } from "@mui/material";

const CovidVaccinated = ({
  disabled = false,
  wrapperStyle,
  checked,
  value,
  onChange,
  isError,
}) => {
  return (
    <Box sx={wrapperStyle} alignItems="center" marginY="1rem">
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            checked={Boolean(checked)}
            value={value}
            onChange={onChange}
            inputProps={{
              "aria-describedby": isError
                ? "error-chkCovidVaccinated"
                : undefined,
            }}
            id="is_covid_vaccinated"
            name="is_covid_vaccinated"
          />
        }
        label="I verify that my Surrogate has been Vaccinated in accordance with CDC guidelines."
      />
      {!!isError && (
        <FormHelperText
          error
          sx={{ marginLeft: 0, marginTop: 0 }}
          id={"error-chkCovidVaccinated"}
        >
          {isError}
        </FormHelperText>
      )}
    </Box>
  );
};

export { CovidVaccinated };
