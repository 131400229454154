/**
 * Users APIs
 */

import httpClient from "../utils/HttpUtils";

export const UsersApis = {
  getAllUsers: async (payload) => {
    const { sortOrder, sortKey = "", page, advancedSearch } = payload ?? {};
    let reqUrl = "/users";
    if (payload) {
      reqUrl += `?sort=${sortOrder ? "-" : ""}${sortKey}&page=${page}`;
    }

    if (advancedSearch && Object?.keys?.(advancedSearch)?.length > 0) {
      let filtersPart = "";
      for (const [key, value] of Object.entries(advancedSearch)) {
        filtersPart += `&filters[${key}]=${value}`;
      }
      reqUrl += filtersPart;
    }

    try {
      const resp = await httpClient.get(reqUrl);
      return resp;
    } catch (error) {
      console.log("Error getAllUsers:", error);
    }
  },

  createNewUser: async (payload) => {
    try {
      const resp = await httpClient.post("users", payload);
      return resp;
    } catch (error) {
      console.log("Error createNewUser", error);
    }
  },

  getUserById: async ({ user_id }) => {
    try {
      const resp = await httpClient.get(`users/${user_id}`);
      return resp;
    } catch (error) {
      console.log("Error getUserById", error);
    }
  },

  updateUser: async ({ user_id, params }) => {
    try {
      const resp = await httpClient.put(`users/${user_id}`, params);
      return resp;
    } catch (error) {
      console.log("Error updateUser", error);
    }
  },
};
