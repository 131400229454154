/**
 * StateFee APIs
 */

import httpClient from "../utils/HttpUtils";

export const StateFeeApis = {
  getStateFeeList: async (payload) => {
    const { sortOrder, sortKey, page, advancedSearch } = payload ?? {};
    let reqUrl = "/state_fees";
    if (payload)
      reqUrl += `?sort=${sortOrder ? "-" : ""}${sortKey}&page=${page}`;

    if (advancedSearch && Object?.keys?.(advancedSearch)?.length > 0) {
      let filtersPart = "";
      for (const [key, value] of Object.entries(advancedSearch)) {
        filtersPart += `&filters[${key}]=${value}`;
      }
      reqUrl += filtersPart;
    }
    try {
      const resp = await httpClient.get(reqUrl);
      return resp;
    } catch (error) {
      console.log("Error getStateFee:", error);
    }
  },

  addStateFee: async (payload) => {
    try {
      const resp = await httpClient.post("state_fees", payload);
      return resp;
    } catch (error) {
      console.log("Error addStateFee", error);
    }
  },

  getStateFee: async ({ fee_id }) => {
    try {
      const resp = await httpClient.get(`state_fees/${fee_id}`);
      return resp;
    } catch (error) {
      console.log("Error getStateFee", error);
    }
  },

  updateStateFee: async ({ fee_id, params }) => {
    try {
      const resp = await httpClient.put(`state_fees/${fee_id}`, params);
      return resp;
    } catch (error) {
      console.log("Error updateStateFee", error);
    }
  },

  deleteStateFee: async (payload) => {
    const { fee_id } = payload;
    try {
      const resp = await httpClient.delete(`/state_fees/${fee_id}`);
      return resp;
    } catch (error) {
      console.log(error, "error");
    }
  },
};
