/*
 * Zoho landing page
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AllRoutes } from "../../routes";
import { CommonModal, GlobalLoader } from "../../components";
import { CommonApis, ZohoApis } from "../../api";
import { AdminPageLayout } from "../../layouts/admin-layout/AdminPageLayout";

const ZohoLandingPage = () => {
  const params = new URLSearchParams(location.search);
  const status = params.get("status");
  const navigate = useNavigate();
  const refreshToken = params.get("token");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [popupData, setPopupData] = useState({});

  useEffect(() => {
    const handleSyncToken = async () => {
      if (location.search && status) {
        if (status === "error") {
          setShowModal(true);
          setPopupData({
            title: "Oh, something went wrong",
            type: "warning",
            subTitle:
              "Failed to connect Zoho due to a technical error. It happens, just try again now, or after a couple of minutes.",
          });
        } else if (status === "success" && refreshToken) {
          setLoading(true);
          const resp = await syncToken();
          setLoading(false);
          setShowModal(true);
          setPopupData({
            type: resp?.success ? "success" : "warning",
            title: resp?.success
              ? "Zoho connected successfully!"
              : "Something went wrong",
          });
        }
      }
    };

    handleSyncToken();
  }, [location.search]);

  const syncToken = async () => {
    const resp = await CommonApis.updateMyProfile({
      zoho_refresh_token: refreshToken,
    });
    return resp;
  };

  const connectZoho = async () => {
    setButtonLoading(true);
    try {
      const resp = await ZohoApis.connect();
      !!resp && setButtonLoading(false);
    } catch (error) {
      console.log("connectZoho", error);
    }
  };

  const onPressOkay = () => {
    if (status == "error") {
      connectZoho();
    } else {
      navigate(AllRoutes.Profile.route);
      setShowModal(false);
    }
  };

  if (!location.search || !status) return null;

  return (
    <AdminPageLayout>
      {loading && <GlobalLoader loading={loading} />}
      {!!popupData?.type && (
        <CommonModal
          open={showModal}
          type={popupData.type}
          onConfirm={onPressOkay}
          onCancel={
            status === "error"
              ? () => {
                  navigate(AllRoutes.Profile.route);
                  setShowModal(false);
                }
              : undefined
          }
          confirmButtonLoading={buttonLoading}
          title={popupData?.title || ""}
          subTitle={popupData.subTitle || ""}
        />
      )}
    </AdminPageLayout>
  );
};

export { ZohoLandingPage };
