/*
 * Change / Cancel Request All Notes
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, Divider, Box } from "@mui/material";

import { RequestApis } from "../../../api";
import { formattedMDYDate } from "../../../utils/CommonUtils";

const PreviousNotes = ({ setLoading }) => {
  const { id: change_request_id } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    getResponses();
  }, []);

  const getResponses = async () => {
    setLoading(true);
    const resp = await RequestApis.getAllResponses({ change_request_id });
    !!resp && setLoading(false);
    !!resp?.success && setData(resp?.data);
  };

  // Check if there are any notes with a non-empty description
  const hasNotesWithDescription = data?.results?.some((values) => {
    return (
      values?.internal_description?.trim().length > 0 ||
      values?.notification_description?.trim().length > 0
    );
  });

  return hasNotesWithDescription ? (
    <Box>
      <Typography
        component="h2"
        sx={{ paddingTop: "12px", fontWeight: "bold" }}
      >
        Previous Notes
      </Typography>
      {data?.results?.map((values) => {
        const hasInternalDescription =
          values?.internal_description?.trim().length > 0;
        const hasNotificationDescription =
          values?.notification_description?.trim().length > 0;

        if (!hasInternalDescription && !hasNotificationDescription) {
          return null; // Do not render if both descriptions are empty
        }

        return (
          <div key={values?.id}>
            <Typography sx={{ fontSize: "13px", paddingTop: "12px" }}>
              {formattedMDYDate(values?.created_at)}
            </Typography>
            {hasInternalDescription && (
              <Typography sx={{ fontSize: "14px", paddingY: "8px" }}>
                {`Internal Description:  ${values?.internal_description}`}
              </Typography>
            )}
            {hasNotificationDescription && (
              <Typography sx={{ fontSize: "14px", paddingY: "8px" }}>
                {`Notification to Applicant:  ${values?.notification_description}`}
              </Typography>
            )}
            <Divider sx={{ paddingTop: "16px" }} />
          </div>
        );
      })}
    </Box>
  ) : null;
};

export { PreviousNotes };
