/*
 * SAD   U S E R   DATA GRID
 */

import React from "react";
import { Typography, Box } from "@mui/material";

import { TableData } from "./TableData";
import { AdminPageLayout } from "../../../../layouts/admin-layout/AdminPageLayout";

const SadUserPolicies = () => {
  return (
    <AdminPageLayout>
      <Box>
        <Typography variant="h1">
          Surrogate Accidental Death Insurance
        </Typography>
      </Box>
      <TableData caption="Surrogate Accidental Death Insurance" />
    </AdminPageLayout>
  );
};

export { SadUserPolicies };
