/*
 * BROKER SAD- TABLE HEADER
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Stack,
  MenuItem,
  Select,
  FormControl,
  IconButton,
  InputLabel,
  InputAdornment,
  Grid,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import {
  Button,
  DateRangePicker,
  GridActionButtons,
  RenderChip,
  TextInput,
} from "../../../../components";
import {
  convertDateRangeToString,
  oneMonthDateRangeString,
  oneMonthDateRangeText,
} from "../../../../utils/CommonUtils";
import { theme } from "../../../../styles/theme";
import { PaymentTypes, PolicyStatusList } from "../../../sad/constants";

const TableHeader = ({
  advancedSearch,
  setAdvancedSearch,
  dateRange,
  setDateRange,
  searchText,
  setSearchText,
  isFilterExpanded,
  setIsFilterExpanded,
  fieldValues,
  keyValues,
  setKeyValues,
  selectedFields,
  setSelectedFields,
  policyStatus,
  paymentStatus,
  setPolicyStatus,
  setPaymentStatus,
  setPage,
  searchButton,
  setSearchButton,
  buttonLoading,
  setButtonLoading,
  setSearchParams,
}) => {
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [statusList, setStatusList] = useState([]);
  const { application_status_types_list } = lookups ?? {};

  const setDefaultDate = () => {
    const curDate = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    setDateRange([oneMonthAgo, curDate]);
  };

  useEffect(() => {
    setDefaultDate();
  }, []);

  useEffect(() => {
    const updatedList = PolicyStatusList(application_status_types_list);
    setStatusList(updatedList);
  }, [application_status_types_list]);

  const searchValues = () => {
    setButtonLoading("search");
    setPage(1);
    setSearchButton(!searchButton);
    const newObject = keyValues.reduce(
      (acc, keyValue) => {
        // Find the corresponding label in fieldValues
        const name = fieldValues.find(
          (fieldValue) => fieldValue.value === keyValue.key
        )?.name;
        // If a label is found, add it to the accumulator object
        if (name) {
          acc[name] = keyValue.value;
        }
        return acc;
      },
      { ...advancedSearch }
    );

    setSearchParams((searchParams) => {
      searchParams.set("page", 1);
      Object.entries(newObject).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
      return searchParams;
    });

    setAdvancedSearch(newObject);
  };

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);

    setAdvancedSearch((p) => ({
      ...p,
      ["created_at"]: convertDateRangeToString(newDateRange),
    }));
  };

  const handleKeyValueChange = (index, event) => {
    const updatedKeyValues = [...keyValues];
    updatedKeyValues[index] = {
      ...updatedKeyValues[index],
      [event.target.name]: event.target.value,
    };
    setKeyValues(updatedKeyValues);

    const selectedValue = event.target.value;
    if (selectedValue !== "0" && event.target.name == "key") {
      const updatedSelectedFields = [...selectedFields];
      updatedSelectedFields[index] = selectedValue;
      setSelectedFields(updatedSelectedFields);
    }
  };

  const handleAddKeyValue = () => {
    setKeyValues([...keyValues, { key: "0", value: "" }]);
    setTimeout(() => {
      document.getElementById(`key-${keyValues.length}`).focus();
    }, 300);
  };

  const onClear = () => {
    setButtonLoading("clear");
    setSearchButton(!searchButton);

    setSearchParams({
      ["created_at"]: oneMonthDateRangeText(),
      ["page"]: 1,
      ["status"]: policyStatus,
      ["payment_status"]: paymentStatus,
    });

    setSearchText(" ");
    setKeyValues([{ key: "0", value: "" }]);
    setSelectedFields([]);
    setDefaultDate();
    setPage(1);
    setAdvancedSearch({
      ...oneMonthDateRangeString(),
      status: policyStatus,
      payment_status: paymentStatus,
    });
  };

  const handleStatusChange = (type, status) => {
    type === "status" ? setPolicyStatus(status) : setPaymentStatus(status);
    setSearchButton(!searchButton);
    setSearchParams((searchParams) => {
      searchParams.set("page", 1);
      searchParams.set(type === "status" ? "status" : "payment_status", status);
      return searchParams;
    });
    setPage(1);
    setAdvancedSearch((p) => ({ ...p, [type]: status }));
  };

  return (
    <>
      <form>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginY: "1rem",
            paddingY: "1rem",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DateRangePicker
                dateRange={dateRange}
                handleDateChange={handleDateChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              {!isFilterExpanded && (
                <TextInput
                  inputKey="search"
                  inputLabel="Search"
                  tooltip="Search by Application Number, Agency Name, Intended Parent Name, Surrogate Name, Applicant Name. If you are not able to find your expected results, please use advance search."
                  autoComplete="off"
                  margin="none"
                  required={false}
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    setAdvancedSearch((p) => ({
                      ...p,
                      ["q"]: e.target.value?.trim(),
                    }));
                  }}
                  sx={{ height: "2.5rem" }}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              {!isFilterExpanded && (
                <Box sx={{ display: "flex" }}>
                  <GridActionButtons
                    onSearch={searchValues}
                    onClear={onClear}
                    searchButtonLoading={buttonLoading == "search"}
                    clearButtonLoading={buttonLoading == "clear"}
                  />

                  <Button
                    onClick={() => {
                      setIsFilterExpanded(true);
                      setKeyValues([{ key: "0", value: "" }]);
                      setSelectedFields([]);
                      setAdvancedSearch((p) => ({ ...p, ["q"]: "" }));
                      setSearchText("");
                      setTimeout(() => {
                        document.getElementById("key-0").focus();
                      }, 300);
                    }}
                    variant="outlined"
                    sx={{
                      backgroundColor: theme.white,
                      borderRadius: "50%",
                      borderColor: theme.grey,
                      width: "40px",
                      height: "40px",
                      minWidth: "unset",
                      marginBottom: "1rem",
                      minHeight: "2rem",
                    }}
                    title={
                      <>
                        <FilterListIcon sx={{ color: theme.grey }} />
                        <span className="hidden-visually">add more filter</span>
                      </>
                    }
                    id="add-more-filter"
                  />
                </Box>
              )}
            </Grid>
          </Grid>

          {isFilterExpanded && (
            <>
              <Grid container spacing={2} position="relative">
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginTop: { xs: "1rem", sm: "1rem", md: "0" },
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setIsFilterExpanded(false);
                      setTimeout(() => {
                        document.getElementById("add-more-filter").focus();
                      }, 300);
                    }}
                    sx={{
                      position: "absolute",
                      top: "5px",
                      right: 0,
                      zIndex: 1,
                      backgroundColor: theme.white,
                      padding: "0px",
                    }}
                  >
                    <CancelOutlinedIcon fontSize="large" />
                    <span className="hidden-visually">
                      Clear filter options
                    </span>
                  </IconButton>
                </Grid>
                {keyValues?.map((keyValue, index) => (
                  <Grid item key={index} xs={12}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={2}
                      sx={{ position: "relative", height: "100%" }} // Ensure consistent height
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <FormControl fullWidth margin="none">
                            <InputLabel
                              id={`lbl-key-${index}`}
                              shrink
                              sx={{
                                backgroundColor: "#FFFFFF",
                                paddingX: "0.3rem",
                                color: "black",
                              }}
                              htmlFor={`key-${index}`}
                            >
                              Key
                            </InputLabel>
                            <Select
                              name="key"
                              value={keyValue.key}
                              onChange={(event) =>
                                handleKeyValueChange(index, event)
                              }
                              size="small"
                              variant="outlined"
                              sx={{ backgroundColor: "white" }}
                              id={`key-${index}`}
                              SelectDisplayProps={{
                                "aria-labelledby": `lbl-key-${index} key-${index}`,
                              }}
                            >
                              {fieldValues?.map(
                                (option) =>
                                  (!selectedFields.includes(option.value) ||
                                    option.value === keyValue.key) && (
                                    <MenuItem
                                      key={`${option.value}_${index}`}
                                      value={option.value}
                                      disabled={option.label === "Select"}
                                      sx={{ fontSize: "0.8rem" }}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        {keyValue.key !== "0" && (
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextInput
                              inputKey={`value-of-${index}`}
                              inputLabel="Value"
                              name="value"
                              value={keyValue.value}
                              size="small"
                              required={false}
                              margin="none"
                              onChange={(event) =>
                                handleKeyValueChange(index, event)
                              }
                              sx={{
                                backgroundColor: theme.white,
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Stack>
                  </Grid>
                ))}
                {selectedFields.length < 5 &&
                  keyValues[keyValues.length - 1].key !== "0" && (
                    <Grid item xs={12}>
                      <Button
                        onClick={handleAddKeyValue}
                        title={
                          <>
                            <AddCircleOutlineOutlinedIcon
                              fontSize="small"
                              sx={{ color: theme.mistBlue }}
                            />
                            <Typography
                              component={"span"}
                              sx={{
                                color: theme.mistBlue,
                                fontSize: "0.875rem",
                                marginLeft: "5px",
                              }}
                            >
                              Add New
                            </Typography>
                          </>
                        }
                      />
                    </Grid>
                  )}
                <Box sx={{ marginTop: "10px" }} m={"auto"}>
                  <GridActionButtons
                    onSearch={searchValues}
                    onClear={onClear}
                    searchButtonLoading={buttonLoading == "search"}
                    clearButtonLoading={buttonLoading == "clear"}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Box>
      </form>

      <Box sx={{ flexDirection: "row", display: "flex", gap: "0.5rem" }}>
        <RenderChip
          variant={policyStatus === "" ? "filled" : "outlined"}
          label="All"
          status=""
          onClick={() => handleStatusChange("status", "")}
        />
        {statusList?.map((status) => (
          <RenderChip
            variant={policyStatus === status.value ? "filled" : "outlined"}
            status={status.value}
            onClick={() => handleStatusChange("status", status.value)}
          />
        ))}
      </Box>

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          gap: "0.5rem",
          my: "1rem",
        }}
      >
        <RenderChip
          variant={paymentStatus === "" ? "filled" : "outlined"}
          label="All"
          status=""
          onClick={() => handleStatusChange("payment_status", "")}
        />
        {[
          { value: PaymentTypes.paid, label: "Paid" },
          { value: PaymentTypes.notAttempted, label: "Pending" },
        ]?.map((status) => (
          <RenderChip
            variant={paymentStatus === status.value ? "filled" : "outlined"}
            status={status.value}
            label={status.label}
            onClick={() => handleStatusChange("payment_status", status.value)}
          />
        ))}
      </Box>
    </>
  );
};

export { TableHeader };
