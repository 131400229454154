/*
 * Common header component for all grids
 */

import React from "react";
import { Button } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const RenderTableHeader = ({
  column,
  headerName,
  headerKey,
  setSortOrder = () => {},
  setSortKey = () => {},
}) => {
  return (
    <Button
      sx={{ fontWeight: "10", fontSize: "0.7rem" }}
      variant="ghost"
      onClick={() => {
        setSortOrder((prev) => !prev);
        setSortKey(headerKey);
        column.toggleSorting(column.getIsSorted() === "asc");
      }}
    >
      {headerName}
      {column.getIsSorted() ? (
        column.getIsSorted() === "desc" ? (
          <ArrowDropDownIcon />
        ) : (
          <ArrowDropUpIcon />
        )
      ) : (
        ""
      )}
    </Button>
  );
};

export { RenderTableHeader };
