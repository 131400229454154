/*
 * Change / Cancel Request View Response
 */

import React, { useState } from "react";

import { GlobalLoader } from "../../components";
import { PreviousNotes } from "./components/PreviousNotes";
import { ApplicationRequestHeader } from "./components/AdminRequestHeader";
import { AdminPageLayout } from "../../layouts/admin-layout/AdminPageLayout";

const ViewResponse = () => {
  const [loading, setLoading] = useState(false);

  return (
    <AdminPageLayout>
      <ApplicationRequestHeader
        header="View Response"
        setLoading={setLoading}
      />

      {loading && <GlobalLoader loading={loading} />}

      <PreviousNotes setLoading={setLoading} />
    </AdminPageLayout>
  );
};

export { ViewResponse };
