/*
 * IVF-ADMIN Edit application index file
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";

import { Notes } from "./Notes";
import { Paidstatus } from "./PaidStatus";
import { Edit_ApplicationForm } from "./ApplicationDetails";
import { AdminPageLayout } from "../../../../layouts/admin-layout/AdminPageLayout";

import { GlobalLoader } from "../../../../components";
import { IvfApplicationHeader } from "../components/IvfApplicationHeader";
import { useDispatch } from "react-redux";
import { ivfApplicationSummary } from "../../../../redux/slices/globalSlice";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const IVF_EditApplication = () => {
  const dispatch = useDispatch();
  const { id: application_id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    dispatch(ivfApplicationSummary({ application_id }));
  }, []);

  return (
    <AdminPageLayout>
      <Grid container>
        <IvfApplicationHeader
          header="Edit Application"
          setLoading={setLoading}
        />

        <Grid container sx={{ paddingTop: "20px" }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={activeTab}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Application Details"
                  {...a11yProps(0)}
                  sx={{ margin: "0.1rem" }}
                />
                <Tab
                  label="Paid Status & Effective Date"
                  {...a11yProps(1)}
                  sx={{ margin: "0.1rem" }}
                />
                <Tab
                  label="Notes"
                  {...a11yProps(2)}
                  sx={{ margin: "0.1rem" }}
                />
              </Tabs>
            </Box>

            {loading && <GlobalLoader loading={loading} />}

            <CustomTabPanel value={activeTab} index={0}>
              <Edit_ApplicationForm loading={loading} setLoading={setLoading} />
            </CustomTabPanel>

            <CustomTabPanel value={activeTab} index={1}>
              <Paidstatus />
            </CustomTabPanel>

            <CustomTabPanel value={activeTab} index={2}>
              <Notes setLoading={setLoading} />
            </CustomTabPanel>
          </Box>
        </Grid>
      </Grid>
    </AdminPageLayout>
  );
};

export { IVF_EditApplication };
