/*
 * A D M I N --- M O D U L E-   E D I T   B R O K E R
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ThemeProvider } from "@mui/styles";

import { BrokerApis } from "../../../api";
import { INSURANCE_TYPES_LIST, strings } from "../../../constants";
import { theme } from "../../../styles/theme";
import { SadActionButtons } from "../../sad/sad-admin/components/ActionButtons";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";
import {
  CommonModal,
  GlobalLoader,
  PhoneInput,
  TextInput,
  Dropdown,
  GoBack,
} from "../../../components";
import {
  emailRegex,
  focusFirstErrorField,
  testNonLetter,
} from "../../../utils/CommonUtils";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .required("Email is required")
    .matches(emailRegex, "Invalid email"),
  website: Yup.string()
    .required("Website Link is required")
    .test(
      "trimmed-not-empty",
      "Website Link cannot be empty or only spaces",
      (value) => value && value.trim() !== ""
    ),
  contact_number: Yup.string()
    .required("Contact Number is required")
    .min(7, "Contact No. must be at least 7 digits"),
});

const EditBroker = () => {
  const navigate = useNavigate();
  const { id: broker_id } = useParams();
  const [brokerData, setBrokerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  useEffect(() => {
    const getBrokerData = async () => {
      setLoading(true);
      const resp = await BrokerApis.getBrokerDetail({ broker_id });
      !!resp && setLoading(false);
      !!resp?.success && setBrokerData(resp?.data);
    };
    getBrokerData();
  }, []);

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      email: "",
      website: "",
      contact_number: "",
      insurance_type: "",
    },
  });

  useEffect(() => {
    if (brokerData) {
      const {
        name,
        email,
        website,
        contact_number,
        insurance_type,
        is_active,
      } = brokerData ?? {};
      setValue("name", name);
      setValue("email", email);
      setValue("website", website);
      setValue("contact_number", contact_number);
      setValue("insurance_type", insurance_type || "");
      setValue("is_active", is_active);
    }
  }, [brokerData]);

  const handlePhoneChange = (name, value) => {
    setValue(name, value);
  };

  const onSubmit = async (data) => {
    setButtonLoading(true);
    const resp = await BrokerApis.updateBroker({ broker_id, params: data });
    if (resp) {
      setButtonLoading(false);
    }
    if (resp?.success) {
      setUpdateModal(true);
    } else {
      Object.keys(resp?.errors).forEach((key) => {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  useEffect(() => {
    const errorArray = Object.keys(errors);
    if (!errorArray?.length) return;
    focusFirstErrorField();
  }, [errors]);

  return (
    <ThemeProvider theme={theme}>
      <AdminPageLayout>
        {loading && <GlobalLoader loading={loading} />}

        <Box display="flex" alignItems="center">
          <GoBack />
          <Typography variant="h1" sx={{ margin: "0" }}>
            {strings.editBroker}
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={2}
            sx={{ marginY: "1rem" }}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="name"
                inputLabel="Broker Name"
                register={register}
                disabled={buttonLoading}
                registerChange={(e) => setValue("name", e.target.value)}
                isError={errors?.name}
                errorMsg={errors?.name?.message}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="email"
                inputLabel="Email"
                register={register}
                disabled={buttonLoading}
                isError={errors?.email}
                errorMsg={errors?.email?.message}
                endAdornment={
                  false && (
                    <InputAdornment position="end">
                      <CheckCircleIcon sx={{ color: "#00AD00" }} />
                    </InputAdornment>
                  )
                }
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="website"
                inputLabel="Website Link"
                register={register}
                disabled={buttonLoading}
                isError={errors?.website}
                errorMsg={errors?.website?.message}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <PhoneInput
                inputKey="contact_number"
                inputLabel="Contact No."
                autoComplete="off"
                {...(testNonLetter(watch("contact_number")) && {
                  value: watch("contact_number"),
                })}
                register={register}
                disabled={buttonLoading}
                registerChange={handlePhoneChange}
                isError={errors?.contact_number}
                errorMsg={errors?.contact_number?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Dropdown
                label="Insurance Type"
                id="insurance_type"
                options={[{ label: "All", value: "" }, ...INSURANCE_TYPES_LIST]}
                register={register}
                disabled={buttonLoading}
                registerChange={(e) => {
                  setValue("insurance_type", e.target.value);
                  setError("insurance_type", null);
                }}
                value={watch("insurance_type")}
                isError={watch("insurance_type") === null}
                errorMsg={errors?.insurance_type?.message}
              />
            </Grid>
          </Grid>

          <SadActionButtons
            onCancel={() => reset(brokerData)}
            buttonLoading={buttonLoading}
          />
        </form>

        <CommonModal
          open={updateModal}
          type="success"
          onConfirm={() => {
            setUpdateModal(false);
            navigate(-1);
          }}
          title="Updated Successfully!"
        />
      </AdminPageLayout>
    </ThemeProvider>
  );
};

export { EditBroker };
