/*
 * SAD-ADMIN Edit application index file
 */

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";

import { Notes } from "./Notes";
import { Paidstatus } from "./PaidStatus";
import { Edit_ApplicationForm } from "./ApplicationDetails";
import { AdminPageLayout } from "../../../../layouts/admin-layout/AdminPageLayout";
import { setSadSummary } from "../../../../redux/slices/globalSlice";
import { SadApplicationHeader } from "../components/SadApplicationHeader";
import { SadApis } from "../../../../api";
import { AllRoutes } from "../../../../routes";
import { GlobalLoader } from "../../../../components";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingY: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const Sad_EditApplication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: application_id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFormData();
  }, []);

  const getFormData = async () => {
    const resp = await SadApis.getApplicationData({ application_id });

    if (resp && resp?.success && resp?.data?.agency_state_code) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    !resp?.success && navigate(AllRoutes.SadInsurancePage.route);
    dispatch(setSadSummary(resp?.data));
  };

  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <AdminPageLayout>
      <SadApplicationHeader header="Edit Application" />

      <Grid container sx={{ paddingTop: "0.7rem" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              aria-label="Edit Application Details"
            >
              <Tab
                label="Application Details"
                {...a11yProps(0)}
                sx={{ margin: "0.1rem" }}
              />
              <Tab
                label="Paid Status & Effective Date"
                {...a11yProps(1)}
                sx={{ margin: "0.1rem" }}
              />
              <Tab label="Notes" {...a11yProps(2)} sx={{ margin: "0.1rem" }} />
            </Tabs>
          </Box>

          {loading && <GlobalLoader loading={loading} />}

          <CustomTabPanel value={activeTab} index={0}>
            <Typography variant="h2" className="hidden-visually">
              Application Details
            </Typography>
            <Edit_ApplicationForm loading={loading} setLoading={setLoading} />
          </CustomTabPanel>

          <CustomTabPanel value={activeTab} index={1}>
            <Typography variant="h2" className="hidden-visually">
              Paid Status and Effective Date Information
            </Typography>
            <Paidstatus />
          </CustomTabPanel>

          <CustomTabPanel value={activeTab} index={2}>
            <Typography variant="h2" className="hidden-visually">
              Notes Section
            </Typography>
            <Notes setLoading={setLoading} />
          </CustomTabPanel>
        </Box>
      </Grid>
    </AdminPageLayout>
  );
};

export { Sad_EditApplication };
