/*
 * InstructionNote Component
 */

import React from "react";
import { Box, Typography, Icon } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const InstructionNote = ({ width, alignItems, note, sx }) => {
  return (
    <Box
      sx={{
        p: "3px 15px",
        border: "1px solid #BCE8F1",
        borderRadius: "4px",
        display: "flex",
        width: width ?? "100px",
        alignItems: alignItems ?? "center",
        font: "italic normal 13px Lato",
        lineHeight: "1.2rem",
        textAlign: "justify",
        letterSpacing: "0px",
        color: "#0A738E",
        marginBottom: "1rem",
        background: "#D9EDF7 0% 0% no-repeat padding-box",
        ...sx,
      }}
    >
      <Icon sx={{ mr: "0.2rem" }}>
        <InfoIcon sx={{ fontSize: "1.3rem", verticalAlign: "middle" }} />
      </Icon>
      <Typography variant="body" fontWeight="bold">
        {note}
      </Typography>
    </Box>
  );
};

export { InstructionNote };
