/*
 * Get Statement page
 */

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import { TableData } from "./TableData";
import { TableHeader } from "./TableHeader";
import { LocalStorageHelper } from "../../utils/HttpUtils";
import { oneMonthDateRangeText } from "../../utils/CommonUtils";
import { AgencyApis, ApplicationApis, ClinicApis } from "../../api";
import { AdminPageLayout } from "../../layouts/admin-layout/AdminPageLayout";
import {
  INSURANCE_TYPES,
  USER_DATA,
  USER_ROLE,
  USER_ROLES,
} from "../../constants";

const GetStatement = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isUserAgency = LocalStorageHelper.get(USER_ROLE) === USER_ROLES.agency;
  const isUserClinic = LocalStorageHelper.get(USER_ROLE) === USER_ROLES.clinic;
  const isUserIp = LocalStorageHelper.get(USER_ROLE) === USER_ROLES.ip;
  const myData = JSON.parse(LocalStorageHelper.get(USER_DATA));
  const [dataList, setDataList] = useState([]);
  const [listData, setListData] = useState();
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [searchButton, setSearchButton] = useState(true);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [buttonLoading, setButtonLoading] = useState("");
  const [insuranceType, setInsuranceType] = useState(INSURANCE_TYPES.sad);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [submittedBy, setSubmittedBy] = useState("");
  const [agencyUsers, setAgencyUsers] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState({});

  const propertyMapping = {
    insurance_type: setInsuranceType,
    created_at: (value) =>
      setDateRange(value?.split?.(",")?.map?.((dateStr) => new Date(dateStr))),
    payment_status: setPaymentStatus,
    id: setSubmittedBy,
  };

  useEffect(() => {
    setSearchParams((searchParams) => {
      if (!searchParams.has("insurance_type")) {
        searchParams.set("insurance_type", INSURANCE_TYPES.sad);
      }
      if (!searchParams.has("created_at")) {
        searchParams.set("created_at", oneMonthDateRangeText());
      }
      if (!searchParams.has("page")) {
        searchParams.set("page", 1);
      }
      return searchParams;
    });

    const params = [];
    searchParams.forEach((value, key) => {
      params.push([key, value]);
    });
    const obj = params.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    Object.keys(propertyMapping)?.forEach((key) => {
      if (obj?.hasOwnProperty(key)) {
        propertyMapping?.[key]?.(obj?.[key]);
      }
    });

    setPage(Number(obj["page"]));
    const { page, ...rest } = obj;
    setAdvancedSearch(rest);

    if (isUserAgency || isUserClinic) {
      getAgencyUsersList();
    } else if (isUserIp) {
      const { first_name, last_name, id } = myData || {};
      setAgencyUsers([
        { label: `${first_name || ""} ${last_name || ""}`, value: id },
      ]);
    }
  }, []);

  const getAgencyUsersList = async () => {
    const params = { sortOrder: "", sortKey: "first_name" };
    const res = isUserAgency
      ? await AgencyApis.getUsersWithAgency(params)
      : await ClinicApis.getUsersWithClinic(params);
    const formatList = !!res?.length
      ? res?.map?.((user) => {
          const { first_name, last_name, id } = user || {};
          return { label: `${first_name || ""} ${last_name || ""}`, value: id };
        })
      : [];
    setAgencyUsers(formatList);
  };

  useEffect(() => {
    page > 0 && getAllPoliciesList();
  }, [page, searchButton]); // Removed sortKey, sortOrder to avoid sorting from API

  // Get all policies
  const getAllPoliciesList = async () => {
    setDataLoading(true);
    const params = { sortOrder: "", sortKey: "", page, advancedSearch };
    const resp = await ApplicationApis.getAllPolicies(params);
    if (resp) {
      setButtonLoading("");
      setDataLoading(false);
      setLoading(false);
    }
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
  };

  return (
    <AdminPageLayout>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="h1">Get Statement</Typography>
      </Grid>

      <TableHeader
        advancedSearch={advancedSearch}
        setAdvancedSearch={setAdvancedSearch}
        insuranceType={insuranceType}
        setInsuranceType={setInsuranceType}
        dateRange={dateRange}
        setDateRange={setDateRange}
        paymentStatus={paymentStatus}
        setPaymentStatus={setPaymentStatus}
        agencyUsers={agencyUsers}
        submittedBy={submittedBy}
        setSubmittedBy={setSubmittedBy}
        setPage={setPage}
        searchButton={searchButton}
        setSearchButton={setSearchButton}
        buttonLoading={buttonLoading}
        setButtonLoading={setButtonLoading}
        setSearchParams={setSearchParams}
      />

      <TableData
        caption="Get Statement"
        dataList={dataList}
        listData={listData}
        setSortKey={setSortKey}
        setSortOrder={setSortOrder}
        page={page}
        setPage={setPage}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        loading={loading}
        dataLoading={dataLoading}
      />
    </AdminPageLayout>
  );
};

export { GetStatement };
