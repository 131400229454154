/**
 * Common APIs
 */

import { USER_DATA } from "../constants";
import httpClient, { LocalStorageHelper } from "../utils/HttpUtils";

export const CommonApis = {
  lookups: async () => {
    const resp = await httpClient.get("lookups");
    return resp?.data;
  },

  getZipData: async ({ country_code, zipcode = "" }) => {
    try {
      const response = await httpClient.get(
        `geo?filters[country_code]=${country_code}&filters[zipcode]=${zipcode}`
      );
      return response?.data;
    } catch (error) {
      console.log("Error getZipData:- ", error);
    }
  },

  getMyProfile: async () => {
    try {
      const resp = await httpClient.get("me");
      return resp;
    } catch (error) {
      console.log("Error getMyProfile:- ", error);
    }
  },

  updateMyProfile: async (payload) => {
    try {
      const resp = await httpClient.post("me", payload);
      LocalStorageHelper.store(USER_DATA, JSON.stringify(resp?.data));
      return resp;
    } catch (error) {
      console.log("Error updateMyProfile:- ", error);
    }
  },
};
