/*
 * SAD-ADMIN Edit Status
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Typography } from "@mui/material";

import { NotesApis, SadApis } from "../../../api";
import { SadApplicationHeader } from "./components/SadApplicationHeader";
import { sadApplicationSummary } from "../../../redux/slices/globalSlice";
import {
  CommonModal,
  Dropdown,
  FilePicker,
  GlobalLoader,
  TextInput,
  Tooltip,
} from "../../../components";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";
import { SadActionButtons } from "./components/ActionButtons";
import { PolicyStatusList } from "../constants";

const SAD_EditStatus = () => {
  const dispatch = useDispatch();
  const { id: application_id } = useParams();
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [files, setFiles] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isValidFile, setIsValidFile] = useState(true);
  const [fileToBeDeleted, setFileToBeDeleted] = useState();
  const [statusList, setStatusList] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const { application_status_types_list } = lookups ?? {};
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const summaryData = useSelector((state) => state.sadReducer.sadSummary);

  useEffect(() => {
    dispatch(sadApplicationSummary({ application_id }));
  }, []);

  useEffect(() => {
    !!summaryData?.id && setLoading(false);
    const updatedList = PolicyStatusList(application_status_types_list);
    setStatusList(updatedList);
  }, [application_status_types_list, summaryData]);

  const { register, handleSubmit, setValue, watch } = useForm();

  useEffect(() => {
    setDataInitially();
  }, [statusList]);

  const setDataInitially = () => {
    setValue("status", summaryData?.status);
    setValue("description", "");
  };

  const openPdfInNewTab = (file) => {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  };

  const removePdf = () => {
    setIsValidFile(true);
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== fileToBeDeleted));
    setShowDeleteModal(false);
    setFileToBeDeleted();
    setTimeout(() => {
      document.getElementById("btn-upload-file").focus();
    }, 500);
  };

  const onSubmit = async (data) => {
    setButtonLoading(true);
    const { status, description } = data;
    const statusResp = await SadApis.updateApplication({
      application_id,
      params: { status },
    });

    if (description) {
      const notesResp = await NotesApis.addNotes({
        application_id,
        description,
      });
      !!statusResp && !!notesResp && setButtonLoading(false);
      if (statusResp?.success && notesResp?.success) {
        setUpdateModal(true);
      }
    } else {
      !!statusResp && setButtonLoading(false);
      statusResp?.success && setUpdateModal(true);
    }
    dispatch(sadApplicationSummary({ application_id }));
  };

  return (
    <AdminPageLayout>
      <SadApplicationHeader header="Edit Alert Status" />

      {loading && <GlobalLoader loading={loading} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Dropdown
          label="Policy Status"
          id="status"
          options={statusList}
          value={watch("status")}
          register={register}
          registerChange={() => {}}
        />

        <TextInput
          inputKey="description"
          inputLabel="Add Comment"
          multiline
          rows={3}
          required={false}
          register={register}
          registerChange={() => {}}
        />

        <Typography marginY="1rem" id="lbl-upload-file">
          Please Upload Documents
          <Tooltip tooltip="File can only be type of pdf / word" />
        </Typography>

        <FilePicker
          files={files}
          setFiles={setFiles}
          setIsValidFile={setIsValidFile}
          openPdfInNewTab={openPdfInNewTab}
          setFileToBeDeleted={setFileToBeDeleted}
          handleFileUpload={() => {}}
          setShowDeleteModal={setShowDeleteModal}
          helperText={
            isValidFile
              ? "Note: Two Files Max"
              : "Note: Only pdf and word file allowed"
          }
        />

        <SadActionButtons
          onCancel={setDataInitially}
          buttonLoading={buttonLoading}
        />

        <CommonModal
          open={updateModal}
          type="success"
          onConfirm={() => setUpdateModal(false)}
          title="Updated Successfully!"
        />

        {/* Modal to confirm delete documents */}
        <CommonModal
          open={showDeleteModal}
          type="delete"
          onCancel={() => setShowDeleteModal(false)}
          confirmButtonLabel="Delete"
          onConfirm={removePdf}
          title="Are You Sure?"
          subTitle="Do you want to delete this file?"
        />
      </form>
    </AdminPageLayout>
  );
};

export { SAD_EditStatus };
