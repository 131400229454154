/*
 * Common GoBack Component
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Tooltip } from "./Tooltip";
import IconButton from "@mui/material/IconButton";

const GoBack = ({ sx, navigateURL }) => {
  const navigate = useNavigate();

  return (
    <Tooltip title="Back to Previous Page">
      <IconButton
        aria-label="Back to Previous Page"
        onClick={() => (navigateURL ? navigate(navigateURL) : navigate(-1))}
        sx={{ marginRight: "0.5rem", verticalAlign: "middle", ...sx }}
      >
        <ArrowBackIcon />
      </IconButton>
    </Tooltip>
  );
};

export { GoBack };
