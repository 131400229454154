/*
 * File picker Component
 */

import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  FormLabel,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "../../../../styles/theme";

const FilePickerRecipient = ({
  disabled = false,
  files,
  setIsValidFile,
  openPdfInNewTab,
  setFileToBeDeleted,
  setShowDeleteModal,
  handleFileUpload,
  isDocUploading,
  inputLabel,
  helperText,
  errorMsg = "",
  id,
  labelled_as_id,
}) => {
  const handleFileSelect = (event) => {
    const selectedFiles = event.target.files;
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    // const maxFilesAllowed = 2;

    const newFiles = Array.from(selectedFiles).filter((file) => {
      if (allowedTypes.includes(file.type)) {
        setIsValidFile(true);
        return allowedTypes.includes(file.type);
      } else {
        setIsValidFile(false);
        return allowedTypes.includes(file.type);
      }
    });

    handleFileUpload(newFiles);
    // if (files.length + newFiles.length <= maxFilesAllowed) {
    //   handleFileUpload(newFiles);
    // } else {
    //   // console.log("You can only upload up to 2 files.");
    // }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box tabindex="-1" sx={{ marginRight: "1rem" }}>
          <FormLabel
            tabIndex={files?.length === 2 ? undefined : 0}
            role="button"
            aria-label="Upload file"
            // aria-disabled={files?.length === 2 || isDocUploading}
            aria-disabled={
              disabled ||
              files.filter(
                (file) => file.labelled_as === `DOC_recipient_${labelled_as_id}`
              ).length >= 2 ||
              isDocUploading
            }
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === " ") {
                event.preventDefault();
                document.getElementById(id).click();
              }
            }}
            htmlFor={id}
            sx={{
              padding: "10px 15px",
              borderRadius: "25px",
              color:
                files.filter(
                  (file) =>
                    file.labelled_as === `DOC_recipient_${labelled_as_id}`
                ).length >= 2
                  ? theme.grey
                  : theme.mistBlue,
              height: "2rem",
              cursor:
                files.filter(
                  (file) =>
                    file.labelled_as === `DOC_recipient_${labelled_as_id}`
                ).length >= 2
                  ? "default"
                  : "pointer",
              border:
                files.filter(
                  (file) =>
                    file.labelled_as === `DOC_recipient_${labelled_as_id}`
                ).length >= 2
                  ? `2px ${theme.grey} solid`
                  : `2px ${theme.mistBlue} solid`,
              "&:hover": {
                borderWidth: "2px",
                borderColor:
                  files.filter(
                    (file) =>
                      file.labelled_as === `DOC_recipient_${labelled_as_id}`
                  ).length >= 2
                    ? theme.grey
                    : theme.mistBlue,
              },
              "&:focus": {
                outline:
                  files.filter(
                    (file) =>
                      file.labelled_as === `DOC_recipient_${labelled_as_id}`
                  ).length >= 2
                    ? ""
                    : "2px solid #C0580E !important",
                boxShadow: "inset 0 0 0 2px #fff !important",
              },
            }}
            aria-labelledby={`lbl-upload-file-${id}`}
            aria-describedby={
              "helper-text-upload-file" + (errorMsg === "" ? "" : " error-doc")
            }
          >
            {isDocUploading ? (
              <CircularProgress
                size={24}
                sx={{ color: theme.mistBlue, verticalAlign: "bottom" }}
              />
            ) : (
              "Upload"
            )}

            <input
              onChange={handleFileSelect}
              multiple
              accept=".pdf,.docx,.doc"
              type="file"
              id={id}
              className="d-none"
              //   style={{ display: "none" }}
              // id="contained-button-file"
              //   disabled={files?.length === 2 || isDocUploading}
              disabled={
                disabled ||
                files.filter(
                  (file) =>
                    file.labelled_as === `DOC_recipient_${labelled_as_id}`
                ).length >= 2 ||
                isDocUploading
              }
            />
          </FormLabel>
          {files.length === 0 && (
            <p className="hidden-visually">No files selected yet.</p>
          )}
        </Box>
        {files?.map((file, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              color: "#0A7691",
            }}
          >
            {file.labelled_as === `DOC_recipient_${labelled_as_id}` && (
              <Box>
                <Button
                  variant="text"
                  sx={{
                    color: theme.mistBlue,
                    letterSpacing: 0,
                    fontSize: "0.9rem",
                  }}
                  onClick={() => openPdfInNewTab(file)}
                  autoFocus={index === 0 ? true : false} // focus on first file added
                >
                  {file.name}{" "}
                  <span className="hidden-visually">&nbsp;view file</span>
                </Button>
                <IconButton
                  aria-label={"Delete " + file.name}
                  size="small"
                  sx={{ color: theme.grey }}
                  onClick={() => {
                    setFileToBeDeleted(file.id);
                    setShowDeleteModal(true);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </div>
        ))}
      </Box>
      {!!helperText && (
        <FormHelperText
          sx={{
            marginLeft: 0,
            fontStyle: "italic",
            fontWeight: 600,
            letterSpacing: 0,
            marginTop: "1rem",
          }}
          id="helper-text-upload-file"
        >
          {helperText}
        </FormHelperText>
      )}
      {!!errorMsg && (
        <FormHelperText error sx={{ marginLeft: 0 }} id={"error-doc"}>
          {errorMsg}
        </FormHelperText>
      )}
    </>
  );
};

export { FilePickerRecipient };
