/*
 * IVF ADMIN Edit Form- Paid status tab // 2nd tab
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";

import { CommonModal, DatePicker } from "../../../../components";
import { useForm } from "react-hook-form";
import { IvfApis } from "../../../../api";
import { SadActionButtons } from "../../../sad/sad-admin/components/ActionButtons";
import { PaymentTypes } from "../../../sad/constants";
import { UtilFunctions } from "../../../../utils/CommonUtils";

const Paidstatus = () => {
  const [updateModal, setUpdateModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const summaryData = useSelector((state) => state.ivfReducer.ivfSummary);

  const { setValue, handleSubmit, watch } = useForm();

  const {
    coverage_type,
    donor_medication_start_date,
    recipient_medication_start_date,
    effective_from,
    effective_till,
    invoice,
  } = summaryData?.[0] ?? {};

  useEffect(() => {
    !!Object.entries(summaryData)?.length && setDataInitially();
  }, [summaryData]);

  const setDataInitially = () => {
    if (donor_medication_start_date) {
      setValue(
        "donor_medication_start_date",
        dayjs(donor_medication_start_date)
      );
    }
    if (recipient_medication_start_date) {
      setValue(
        "recipient_medication_start_date",
        dayjs(recipient_medication_start_date)
      );
    }
    if (effective_from) {
      setValue("effective_from", dayjs(effective_from));
    }
    if (effective_till) {
      setValue("effective_till", dayjs(effective_till));
    }
    if (invoice?.payment_date) {
      setValue("payment_date", dayjs(invoice?.payment_date));
    }
    setValue("payment_status", invoice?.payment_status);
  };

  const onSubmitForm = async (data) => {
    setButtonLoading(true);

    let payload = data;

    coverage_type === "EDO" &&
      (payload = UtilFunctions.deleteKeys(payload, [
        "recipient_medication_start_date",
      ]));

    coverage_type === "RO" &&
      (payload = UtilFunctions.deleteKeys(payload, [
        "donor_medication_start_date",
      ]));

    payload = UtilFunctions.convertToDayjsYMDFormat(payload, [
      "donor_medication_start_date",
      "recipient_medication_start_date",
      "effective_from",
      "effective_till",
      "payment_date",
    ]);

    if (donor_medication_start_date == payload.donor_medication_start_date) {
      payload = UtilFunctions.deleteKeys(payload, [
        "donor_medication_start_date",
      ]);
    }

    if (
      recipient_medication_start_date == payload.recipient_medication_start_date
    ) {
      payload = UtilFunctions.deleteKeys(payload, [
        "recipient_medication_start_date",
      ]);
    }

    if (invoice?.payment_mode == PaymentTypes.online) {
      delete payload["payment_date"];
      delete payload["payment_status"];
    }

    // Filter out null values from the payload object
    payload = Object.fromEntries(
      Object.entries(payload).filter(([_, value]) => value !== null)
    );
    const resp = await IvfApis.updateApplication({
      application_id: summaryData?.[0]?.id,
      params: payload,
    });

    !!resp && setButtonLoading(false);
    if (resp?.success) {
      setUpdateModal(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={watch("payment_status") == PaymentTypes.paid}
                  disabled={
                    invoice?.payment_mode == PaymentTypes.online ||
                    !!invoice?.payment_date
                  }
                  value={watch("payment_status")}
                  onChange={() =>
                    setValue(
                      "payment_status",
                      !!invoice?.payment_date
                        ? PaymentTypes.paid
                        : watch("payment_status") !== PaymentTypes.paid
                        ? PaymentTypes.paid
                        : ""
                    )
                  }
                />
              }
              label="Policy Paid Status"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container columnSpacing={3} rowSpacing={2} mb={3}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <DatePicker
            id="donor_medication_start_date"
            label="Medication Start Date of Donor"
            disabled={coverage_type == "RO"}
            {...(coverage_type !== "RO" && {
              value: dayjs(watch("donor_medication_start_date")),
            })}
            onChange={(date) => setValue("donor_medication_start_date", date)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <DatePicker
            id="recipient_medication_start_date"
            label="Medication Start Date of Recipient"
            disabled={coverage_type == "EDO"}
            {...(coverage_type !== "EDO" && {
              value: dayjs(watch("recipient_medication_start_date")),
            })}
            onChange={(date) =>
              setValue("recipient_medication_start_date", date)
            }
          />
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <DatePicker
            id="payment_date"
            label="Date Paid"
            disabled={invoice?.payment_mode == PaymentTypes.online}
            {...(watch("payment_date") && {
              value: dayjs(watch("payment_date")),
            })}
            onChange={(date) => setValue("payment_date", date)}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <DatePicker
            id="effective_from"
            label="Effective Date"
            value={dayjs(watch("effective_from"))}
            onChange={(date) => setValue("effective_from", date)}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <DatePicker
            id="effective_till"
            label="Expiration Date"
            disabled
            value={dayjs(watch("effective_till"))}
            onChange={(date) => setValue("effective_till", date)}
          />
        </Grid>
      </Grid>

      <SadActionButtons
        onCancel={setDataInitially}
        buttonLoading={buttonLoading}
      />

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => setUpdateModal(false)}
        title="Updated Successfully!"
      />
    </form>
  );
};

export { Paidstatus };
