import { FormControl, FormHelperText, TextField } from "@mui/material";
import React from "react";
import { Tooltip } from "../../../../../components";

const TextInputIvf = ({
  name,
  type,
  label,
  id,
  isError,
  errorMsg,
  helperText,
  sx,
  register,
  required = true,
  setError,
  onRegisterChange = () => {},
  tooltip,
  maxLength,
  ...rest
}) => {
  return (
    <FormControl key={`${id}`} sx={{ width: "100%" }}>
      <TextField
        {...register(name, {
          // onChange: (e) => {setError(name, null); onRegisterChange(e.target.value)}
        })}
        {...(onRegisterChange && {
          onChange: (e) => {
            onRegisterChange(e);
            !!setError && isError && setError(name, null);
          },
        })}
        // onPointerDown={() => !!setError && setError(name, null)}
        type={type}
        id={id}
        name={name}
        label={
          <>
            {label}
            {required && <span className="text-danger">&nbsp;*&nbsp;</span>}
            {!!tooltip && <Tooltip tooltip={tooltip} />}
          </>
        }
        InputLabelProps={{ shrink: true, style: { color: "black" } }}
        aria-describedby={
          (isError ? "error-" + id : "") +
          (isError && helperText ? " " : "") +
          (helperText ? "helper-text-" + id : "")
        }
        inputProps={{ maxLength: maxLength ?? undefined }}
        sx={{ ...sx, "& label.MuiInputLabel-root": { color: "black" } }}
        {...rest}
      />
      {isError && (
        <FormHelperText
          error
          sx={{
            marginLeft: 0,
          }}
          id={"error-" + id}
        >
          {errorMsg}
        </FormHelperText>
      )}
      {!!helperText && (
        <FormHelperText
          sx={{
            marginLeft: 0,
            fontStyle: "italic",
            fontWeight: 600,
            fontSize: "0.77rem",
            letterSpacing: 0,
          }}
          id={"helper-text-" + id}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default TextInputIvf;
