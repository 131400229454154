/*
 * Common cell component for all grids
 */

import React from "react";
import { Typography } from "@mui/material";

const RenderCellInfo = ({ text, sx }) => {
  return (
    <div className="text-center">
      <Typography sx={{ whiteSpace: "nowrap", ...sx }}>{text}</Typography>
    </div>
  );
};

export { RenderCellInfo };
