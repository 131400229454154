/*
 * Forgot password page
 */

import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, Formik, Form } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { FormHelperText, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { AuthApis } from "../../api";
import { theme } from "../../styles/theme";
import { AuthLayout } from "../../layouts/AuthLayout";
import { emailRegex, focusFirstErrorField } from "../../utils/CommonUtils";
import {
  Button,
  ErrorComponent,
  InstructionNote,
  TextInput,
} from "../../components";
import { CAPTCHA_KEY } from "../../constants";

const instructionNote =
  "If you forgot your password, an email with a password reset link will be sent to you. Click on the link in that email and you will be taken to a page where you can then create a new password.";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [apiError, setApiError] = useState("");
  const [isCaptchaError, setCaptchaError] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const firstInputRef = useRef(null);

  // Formik initialization
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(emailRegex, "Invalid email")
        .required("Email is required"),
    }),
    onSubmit: async () => {
      const { email, captcha_response } = formik.values;

      if (!captcha_response) {
        setCaptchaError("Please verify captcha");
        return;
      }

      setButtonLoading(true);
      setApiError("");
      const resp = await AuthApis.forgotPassword({ email, captcha_response });
      !!resp && setButtonLoading(false);
      if (resp?.success) {
        setActiveStep(1);
      } else {
        setApiError(resp?.errors?.captcha_response ?? resp?.errors?.message);
        firstInputRef.current && firstInputRef.current.focus(); // Set focus on first input
      }
    },
  });

  const handleSubmitForm = (e) => {
    e.preventDefault();
    focusFirstErrorField(formik);
    formik.handleSubmit(e);
  };

  return (
    <AuthLayout>
      <Typography component="h1" variant="h1">
        Forgot Password
      </Typography>

      <InstructionNote note={instructionNote} width="72%" alignItems="start" />

      {!!apiError && <ErrorComponent message={apiError} />}

      {activeStep === 0 ? (
        <>
          <Formik>
            <Form onSubmit={(e) => handleSubmitForm(e)} className="w-75">
              <TextInput
                inputKey="email"
                inputLabel="Email"
                disabled={buttonLoading}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isError={formik.touched.email && formik.errors.email}
                errorMsg={formik.errors.email}
                autoComplete="email"
                isAuthPage={true}
                inputRef={firstInputRef}
              />

              <ReCAPTCHA
                sitekey={CAPTCHA_KEY}
                onChange={(v) => {
                  formik.setFieldValue("captcha_response", v);
                  setCaptchaError("");
                }}
              />
              {!!isCaptchaError && (
                <FormHelperText
                  error
                  sx={{ marginLeft: 0, height: { xs: "auto", sm: 19.92 } }}
                  id={"error-captcha_response"}
                  role="alert"
                >
                  {isCaptchaError}
                </FormHelperText>
              )}

              <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                size="large"
                type="submit"
                title="Send Reset Link"
                loading={buttonLoading}
                sx={{ marginBottom: "0.5rem", marginTop: "1.2rem" }}
              />
              <Button
                color="primary"
                fullWidth={true}
                title="Cancel"
                disabled={buttonLoading}
                sx={{
                  marginBottom: "0.5rem",
                  marginTop: "0.5rem",
                  border: `2px solid ${theme.primaryDark}`,
                }}
                onClick={() => navigate(-1)}
                role="link"
              />
            </Form>
          </Formik>
        </>
      ) : (
        <>
          <CheckCircleOutlineIcon
            sx={{
              fontSize: "5rem",
              color: "success.main",
              marginBottom: "1rem",
            }}
          />
          <Typography
            sx={{
              marginBottom: "1rem",
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
          >
            Password Reset Email Sent
          </Typography>
          <Typography sx={{ color: "gray", textAlign: "center" }}>
            An email has been sent to your rescue email address (if registered),
          </Typography>
          <Typography
            sx={{ color: "gray", textAlign: "center", fontWeight: "bold" }}
          >
            {formik.values.email}
          </Typography>
          <Typography sx={{ marginY: "1rem", color: "gray" }}>
            Follow the directions in the email to reset your password.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            title="Okay"
            onClick={() => navigate(-1)}
            sx={{
              marginBottom: "1rem",
              marginTop: "0.5rem",
            }}
          />
        </>
      )}
    </AuthLayout>
  );
};

export { ForgotPassword };
