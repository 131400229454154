/*
 * SAD User Agreement
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
} from "@mui/material";

import { SadActionButtons } from "../components/ActionButtons";
import { TextInput } from "../../../../components";
import { SadApis } from "../../../../api";
import { sadApplicationSummary } from "../../../../redux/slices/globalSlice";
import { AllRoutes } from "../../../../routes";

const UserAgreement = ({ setActiveStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: application_id } = useParams();
  const summaryData = useSelector((state) => state.sadReducer.sadSummary);
  const agreementData = useSelector(
    (state) => state.sadReducer.sadUserAgreement
  );
  const [name, setName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { agreement_signature_name } = summaryData ?? {};

  useEffect(() => {
    !!agreement_signature_name && setName(agreement_signature_name);
    window?.scrollTo?.(0, 0);
  }, []);

  // Add line breaks in data from API
  const formatApiData = () => {
    const formattedData = agreementData?.content?.replace?.(/\n/g, "<br>");
    return <div dangerouslySetInnerHTML={{ __html: formattedData }} />;
  };

  // On save user agreements
  const saveUserAgreement = async () => {
    setButtonLoading(true);
    const resp = await SadApis.updateApplication({
      application_id,
      params: {
        agreed_agreement_id: agreementData?.id,
        agreement_signature_name: name,
      },
    });
    !!resp && setButtonLoading(false);
    if (resp?.success) {
      dispatch(sadApplicationSummary({ application_id }));
      setActiveStep(3);
    }
  };

  return (
    <>
      {/* Page content */}
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextInput
            inputKey="applicant_signature"
            inputLabel="Applicant Signature"
            autoComplete="off"
            helperText="Note: Please Enter a Legal Name"
            disabled={buttonLoading}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Box
            sx={{
              fontSize: "0.8rem",
              lineHeight: "1.5rem",
              marginTop: "1.5rem",
            }}
          >
            {formatApiData()}
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                disabled={!agreementData}
                onChange={() => setIsChecked(!isChecked)}
              />
            }
            label={
              <Typography sx={{ fontWeight: "bold", marginLeft: "0.2rem" }}>
                I Accept, E-Signature Agreement
              </Typography>
            }
          />
        </Grid>
      </Grid>

      {/* Action Buttons */}
      <SadActionButtons
        onPressBack={() => setActiveStep(1)}
        OnSaveExit={() => navigate(AllRoutes.SadPoliciesPage.route)}
        OnPressContinue={saveUserAgreement}
        disableContinue={!isChecked || !name}
        buttonLoading={buttonLoading}
      />
    </>
  );
};

export { UserAgreement };
