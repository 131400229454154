/*
 * Admin -> Wizard -> Agencies -> Agency's users -> Edit user
 */

import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Grid, InputAdornment, Badge, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import {
  AddressInput,
  CommonModal,
  GlobalLoader,
  GoBack,
  MandatoryStatement,
  PhoneInput,
  TextInput,
  Tooltip,
} from "../../../../components";
import {
  UtilFunctions,
  emailRegex,
  focusFirstErrorField,
  testNonLetter,
} from "../../../../utils/CommonUtils";
import { AttachmentApis, UsersApis } from "../../../../api";
import logo from "../../../../layouts/images/user.svg";
import { AdminPageLayout } from "../../../../layouts/admin-layout/AdminPageLayout";
import { SadActionButtons } from "../../../sad/sad-admin/components/ActionButtons";

const iconStyles = {
  width: 25,
  height: 25,
  padding: "3px",
  fontSize: "small",
  color: "white",
  cursor: "pointer",
  backgroundColor: "#C0580E",
  borderRadius: "50%",
};

const EditAgencyUser = () => {
  const { agency_id, user_id } = useParams();
  const navigate = useNavigate();
  const applyNewApplicationButtonRef = useRef(null);
  const [myData, setMyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");

  const validationSchema = useMemo(() => {
    const requiredSchema = {
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .nullable()
        .required("Email is required")
        .matches(emailRegex, "Invalid email")
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        ),
      mobile: Yup.string().required("Contact number is required"),
      address_line_1: Yup.string().required("Street Address is required"),
      country_code: Yup.string().required("Country is required"),
      zipcode: Yup.string().required("Zip Code is required"),
      state_code: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
    };

    return Yup.object(requiredSchema);
  }, []);

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      profile_pic_attachment_id: "",
      profile_pic_attachment_url: "",
      first_name: "",
      last_name: "",
      mobile: "",
      address_line_1: "",
      zipcode: "",
      country_code: "US",
      state_code: "",
      city: "",
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const errorArray = Object.keys(errors);
    if (!errorArray?.length) return;
    focusFirstErrorField();
  }, [errors]);

  const hasPicture = watch("profile_pic_attachment_url");

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (Object.entries?.(myData)?.length) {
      const { mobile, ...rest } = myData ?? {};

      Object.keys(rest).forEach((key) => {
        if (getValues()?.hasOwnProperty(key)) {
          setValue(key, rest[key]);
        }
      });

      setValue("mobile", mobile);
    }
  }, [myData]);

  const getUserData = async () => {
    setLoading(true);
    const resp = await UsersApis.getUserById({ user_id });
    !!resp && setLoading(false);
    resp?.success && setMyData(resp?.data ?? {});
  };

  const onSubmitForm = async () => {
    setButtonLoading(true);
    let payload = getValues();

    payload = UtilFunctions.deleteKeys(payload, [
      "profile_pic_attachment_id",
      "profile_pic_attachment_url",
      "email",
      "role",
    ]);

    payload = { ...payload, agency_id };

    const resp = await UsersApis.updateUser({ user_id, params: payload });
    if (resp) {
      setButtonLoading(false);
    }
    if (resp?.success) {
      setSuccessMessage(true);
      setDialogTitle("User Updated Successfully!");
      applyNewApplicationButtonRef?.current?.focus();
    } else {
      Object.keys(resp?.errors).forEach(function (key) {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  const uploadProfilePicture = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("labelled_as", "USER");
    formData.append("file", file);
    const resp = await AttachmentApis.uploadAttachment({
      formData,
    });
    !resp?.success && setLoading(false);

    if (resp?.success && resp?.data) {
      const { id, url } = resp?.data ?? {};
      setValue("profile_pic_attachment_id", id);
      setValue("profile_pic_attachment_url", url);
      const updatePicResp = await UsersApis.updateUser({
        user_id,
        params: {
          profile_pic_attachment_id: id,
          profile_pic_attachment_url: url,
        },
      });
      !!updatePicResp && setLoading(false);
    }
  };

  // Function to handle user image upload
  const handleImageUpload = (event) => {
    // Check if a file is selected
    if (event.target.files && event.target.files.length > 0) {
      const uploadedImage = event.target.files[0];
      setValue(
        "profile_pic_attachment_url",
        URL.createObjectURL(uploadedImage)
      );
      uploadProfilePicture(uploadedImage);
    } else {
      setValue("profile_pic_attachment_url", null);
    }
  };

  const handleUploadClick = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      document.getElementById("imageUpload").click();
    }
  };

  return (
    <AdminPageLayout>
      {loading && <GlobalLoader loading={loading} />}

      <Box display="flex" sx={{ alignItems: "center" }}>
        <GoBack />
        <Typography variant="h1" sx={{ margin: "0" }}>
          Edit User
        </Typography>
      </Box>

      <Grid container sx={{ paddingTop: "0.7rem" }}>
        <Grid
          item
          xl={12}
          md={12}
          lg={12}
          sm={12}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            display: "flex",
          }}
        >
          <label htmlFor="imageUpload">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <span
                  role="button"
                  tabindex="0"
                  className="btnProfileImageUpload"
                  disabled={buttonLoading}
                  onClick={handleUploadClick}
                  onKeyDown={handleUploadClick}
                  aria-label={
                    hasPicture ? "Edit User Image" : "Upload User Image"
                  }
                >
                  <Tooltip
                    title={hasPicture ? "Edit User Image" : "Upload User Image"}
                  >
                    {hasPicture ? (
                      <EditOutlinedIcon sx={iconStyles} />
                    ) : (
                      <FileUploadOutlinedIcon sx={iconStyles} />
                    )}
                  </Tooltip>
                </span>
              }
            >
              <img
                src={hasPicture || logo}
                alt="Profile picture"
                height="119"
                width="119"
                style={{
                  borderRadius: "50%",
                  cursor: buttonLoading ? "default" : "pointer",
                  objectFit: "cover",
                  border: !hasPicture && "3px solid #919298",
                }}
              />
            </Badge>
          </label>
          {/* Input for user to upload their image */}
          <Box sx={{ display: "none" }}>
            <input
              type="file"
              id="imageUpload"
              disabled={buttonLoading}
              onChange={handleImageUpload}
              accept="image/*"
            />
          </Box>
          <p id="msgFileUpload" role="alert" aria-live="polite"></p>
        </Grid>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Box sx={{ marginLeft: "0.55rem", marginTop: "1.3rem" }}>
            <MandatoryStatement />
          </Box>
          <Grid
            container
            sx={{ justifyContent: "center" }}
            rowSpacing={2}
            columnSpacing={3}
          >
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="first_name"
                inputLabel="First Name"
                register={register}
                disabled={buttonLoading}
                isError={errors?.first_name?.message}
                errorMsg={errors?.first_name?.message}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="last_name"
                inputLabel="Last Name"
                register={register}
                disabled={buttonLoading}
                isError={errors?.last_name?.message}
                errorMsg={errors?.last_name?.message}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <TextInput
                inputKey="email"
                inputLabel="Email"
                disabled
                register={register}
                isError={errors?.email?.message}
                errorMsg={errors?.email?.message}
                endAdornment={
                  !errors?.email?.message && (
                    <InputAdornment position="end">
                      <CheckCircleIcon sx={{ color: "#00AD00" }} />
                    </InputAdornment>
                  )
                }
              />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <PhoneInput
                inputKey="mobile"
                inputLabel="Contact No."
                autoComplete="off"
                disabled={buttonLoading}
                register={register}
                {...(testNonLetter(watch("mobile")) && {
                  value: watch("mobile"),
                })}
                registerChange={(_, val) => {
                  setValue("mobile", val);
                  !!errors?.mobile?.message && setError("mobile", null);
                }}
                isError={errors?.mobile?.message}
                errorMsg={errors?.mobile?.message}
              />
            </Grid>
            <AddressInput
              disabled={buttonLoading}
              setLoading={setLoading}
              register={register}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              setError={setError}
              errors={errors}
              addressKey="address_line_1"
              countryKey="country_code"
              zipcodeKey="zipcode"
              stateKey="state_code"
              cityKey="city"
            />
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <SadActionButtons
              onCancel={() => reset(myData)}
              buttonLoading={buttonLoading}
            />
          </Box>
        </form>
      </Grid>

      <CommonModal
        open={successMessage}
        type="success"
        onConfirm={() => {
          setSuccessMessage(false);
          navigate(-1);
        }}
        title={dialogTitle}
      />
    </AdminPageLayout>
  );
};

export { EditAgencyUser };
