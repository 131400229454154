/*
 * Global slice
 */

import { createAction, createSlice } from "@reduxjs/toolkit";

const sadInitialState = {
  sadSummary: {},
  beneficiaries: [],
  sadTermCondition: {},
  sadUserAgreement: {},
};

const ivfInitialState = {
  ivfSummary: [],
  ivfTermCondition: {},
  ivfUserAgreement: {},
  ivfDocs: [],
};

export const sadSlice = createSlice({
  name: "globalSlice",
  initialState: sadInitialState,
  reducers: {
    /* This action will trigger our saga middleware
       and set the loader to true and reset error message.
    */
    setSadSummary(state, action) {
      state.sadSummary = action.payload;
    },
    setSadBeneficiaries(state, action) {
      state.beneficiaries = action.payload;
    },
    setSadTermCondition(state, action) {
      state.sadTermCondition = action.payload;
    },
    setSadUserAgreement(state, action) {
      state.sadUserAgreement = action.payload;
    },
  },
});

export const ivfSlice = createSlice({
  name: "globalSlice",
  initialState: ivfInitialState,
  reducers: {
    /* This action will trigger our saga middleware
       and set the loader to true and reset error message.
    */
    setIvfSummary(state, action) {
      state.ivfSummary = action.payload;
    },
    setIvfTermCondition(state, action) {
      state.ivfTermCondition = action.payload;
    },
    setIvfUserAgreement(state, action) {
      state.ivfUserAgreement = action.payload;
    },
    setIvfDocs(state, action) {
      state.ivfDocs = action.payload;
    },
  },
});

export const {
  setSadSummary,
  setSadBeneficiaries,
  setSadTermCondition,
  setSadUserAgreement,
} = sadSlice.actions;

export const {
  setIvfSummary,
  setIvfTermCondition,
  setIvfUserAgreement,
  setIvfDocs,
} = ivfSlice.actions;

export const sadReducer = sadSlice.reducer;
export const ivfReducer = ivfSlice.reducer;

export const sadApplicationSummary = createAction("SAD/APPLICATION-SUMMARY");
export const sadGetBeneficiaries = createAction("SAD/GET-BENEFICARIES");

export const ivfApplicationSummary = createAction("IVF/APPLICATION-SUMMARY");
export const ivfAllApplicationsSummary = createAction(
  "IVF/ALL/APPLICATION-SUMMARY"
);
