/*
 * Get Statement Table Data page
 */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, CircularProgress, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { AttachmentApis } from "../../api";
import ReactTable from "../../React Table";
import { INSURANCE_TYPES, strings } from "../../constants";
import { getIndemnityLimit } from "../ivf/constants";
import { PaymentTypes, PolicyDocTypes } from "../sad/constants";
import {
  CommonModal,
  GlobalLoader,
  RenderActionMenu,
  RenderCellInfo,
  RenderTableHeader,
} from "../../components";
import {
  downloadBlobAsFile,
  downloadExcel,
  formatString,
  formattedMDYDate,
} from "../../utils/CommonUtils";

// Utility function to get value based on column configuration
const getColumnValue = (column, row, ivf_indemnity_options_list) => {
  const columnFormatters = {
    "Application Submission date": (row) =>
      row.created_at ? formattedMDYDate(row.created_at) : "",

    "Submitted By User": (row) =>
      `${row.created_by_first_name || ""} ${row.created_by_last_name || ""}`,

    "Limit of Indemnity (IVF)": (row) =>
      row.idemnity_limit
        ? getIndemnityLimit(
            ivf_indemnity_options_list,
            row.coverage_type,
            row.term_length,
            row.idemnity_limit
          )
        : "-",

    Insured: (row) =>
      row.insurance_type === INSURANCE_TYPES.ivf
        ? row.contractually_liable_party_first_name
        : row.intended_parent1_first_name,

    "Surrogate Name": (row) =>
      `${row.surrogate_first_name || "-"} ${row.surrogate_last_name || ""}`,

    "Total Payment": (row) => {
      const canPayment = row.invoice?.id && !row.alerts?.length;
      const paymentStatus = row.invoice?.payment_status;
      const isPaid = Boolean(paymentStatus === PaymentTypes.paid);
      return canPayment
        ? isPaid
          ? row.invoice?.total
          : row.invoice?.total_without_card_charges
        : "";
    },

    "Payment Date": (row) =>
      row.invoice?.payment_date
        ? formattedMDYDate(row.invoice.payment_date)
        : "",
  };

  if (columnFormatters[column.id]) {
    return columnFormatters[column.id](row);
  }

  return column.accessorKey ? row[column.accessorKey] || "-" : "";
};

// Utility function to prepare data for Excel export
const prepareExcelData = (
  dataList,
  tableColumns,
  ivf_indemnity_options_list
) => {
  return dataList?.map?.((row) => {
    const formattedRow = {};

    tableColumns?.forEach?.((column) => {
      if (column.id !== "Action") {
        formattedRow[column.id] = getColumnValue(
          column,
          row,
          ivf_indemnity_options_list
        );
      }
    });

    return formattedRow;
  });
};

const TableData = ({
  caption,
  dataList,
  listData,
  setSortKey,
  setSortOrder,
  page,
  setPage,
  searchParams,
  setSearchParams,
  loading,
  dataLoading,
}) => {
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [documentLink, setDocumentLink] = useState("");
  const [updateModal, setUpdateModal] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [hiddenTitle, setHiddenTitle] = useState("");
  const { ivf_indemnity_options_list } = lookups;

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  const getPolicyDocs = async (application_id, policy_doc_id, showIframe) => {
    !showIframe && setActionLoading(true);
    if (showIframe) {
      setShowDocumentModal(true);
      setHiddenTitle(formatString(policy_doc_id));
    }

    let payload = { application_id, policy_doc_id };
    if (!showIframe) {
      payload = { ...payload, send_email: true };
    }
    const resp = await AttachmentApis.getPolicyDoc(payload);
    if (resp) {
      setActionLoading(false);
      showIframe ? setDocumentLink(resp) : setUpdateModal(true);
    } else {
      setActionLoading(false);
      setShowDocumentModal(false);
    }
  };

  const downloadDocument = async (application_id, policy_doc_id) => {
    setActionLoading(true);
    const resp = await AttachmentApis.downloadDocument({
      application_id,
      policy_doc_id,
      download: 1,
    });

    if (resp?.success && resp?.data) {
      downloadBlobAsFile(resp.data, `${resp?.filename || "invoice.pdf"}`);
      setActionLoading(false);
    } else {
      setActionLoading(false);
    }
  };

  const openActionDropDown = (e, rowData) => {
    const { id: application_id } = rowData || {};
    const { invoice } = PolicyDocTypes;

    setClickable([
      {
        text: "View Invoice",
        route: () => getPolicyDocs(application_id, invoice, true),
      },
      {
        text: "Download Invoice",
        route: () => downloadDocument(application_id, invoice),
      },
      {
        text: "Email Invoice",
        route: () => getPolicyDocs(application_id, invoice),
      },
    ]);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const tableColumns = [
    {
      id: "Action",
      header: "Action",
      cell: ({ row }) => (
        <IconButton
          onClick={(e) => openActionDropDown(e, row.original)}
          aria-label={"More actions for " + row.original?.name}
        >
          <MoreHorizIcon />
        </IconButton>
      ),
    },
    {
      id: "Application Submission date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Application Submission date"
          headerKey="created_at"
        />
      ),
      accessorKey: "created_at",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Submitted By User",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Submitted By User"
          headerKey="created_by_first_name"
        />
      ),
      accessorKey: "created_by_first_name",
      cell: (info) => {
        const { created_by_first_name, created_by_last_name } =
          info?.row?.original || {};
        return (
          <RenderCellInfo
            text={`${created_by_first_name || ""} ${
              created_by_last_name || ""
            }`}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    // {
    //   id: "Insurance Type",
    //   header: ({ column }) => (
    //     <RenderTableHeader
    //       column={column}
    //       headerName="Insurance Type"
    //       headerKey="insurance_type"
    //     />
    //   ),
    //   accessorKey: "insurance_type",
    //   cell: (info) => <RenderCellInfo text={info.getValue()} />,
    //   footer: (props) => props.column.id,
    // },
    {
      id: "Policy Number",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Number"
          headerKey="application_number"
        />
      ),
      accessorKey: "application_number",
      cell: (info) => (
        <RenderCellInfo text={info.getValue()} sx={{ color: "#0A7691" }} />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Insured",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Insured"
          headerKey="intended_parent1_first_name"
        />
      ),
      accessorKey: "intended_parent1_first_name",
      cell: (info) => {
        const {
          insurance_type,
          intended_parent1_first_name,
          contractually_liable_party_first_name,
        } = info?.row?.original ?? {};
        return (
          <RenderCellInfo
            text={
              insurance_type === INSURANCE_TYPES.ivf
                ? contractually_liable_party_first_name
                : intended_parent1_first_name
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    ...(searchParams?.get?.("insurance_type") === INSURANCE_TYPES.ivf
      ? [
          {
            id: "Limit of Indemnity",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Limit of Indemnity"
                headerKey="idemnity_limit"
              />
            ),
            accessorKey: "idemnity_limit",
            cell: (info) => {
              const { coverage_type, term_length } = info.row.original || {};
              return (
                <RenderCellInfo
                  text={
                    info?.getValue()
                      ? getIndemnityLimit(
                          ivf_indemnity_options_list,
                          coverage_type,
                          term_length,
                          info.getValue()
                        )
                      : "-"
                  }
                />
              );
            },
            footer: (props) => props.column.id,
          },
          {
            id: "Donor Name/ID",
            accessorKey: "donor_name_id",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Donor Name/ID"
                headerKey="donor_name_id"
              />
            ),
            cell: (info) => <RenderCellInfo text={info.getValue() || "-"} />,
            footer: (props) => props.column.id,
          },
          {
            id: "Recipient Name/ID",
            accessorKey: "recipient_name_id",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Recipient Name/ID"
                headerKey="recipient_name_id"
              />
            ),
            cell: (info) => <RenderCellInfo text={info.getValue() || "-"} />,
            footer: (props) => props.column.id,
          },
        ]
      : [
          {
            id: "Limit of Indemnity for Surrogate",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Limit of Indemnity for Surrogate"
                headerKey="indemnity_surrogates_beneficiary"
              />
            ),
            accessorKey: "indemnity_surrogates_beneficiary",
            cell: (info) => <RenderCellInfo text={info.getValue() || "-"} />,
            footer: (props) => props.column.id,
          },
          {
            id: "Limit of Indemnity for IP",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Limit of Indemnity for IP"
                headerKey="indemnity_intended_parents"
              />
            ),
            accessorKey: "indemnity_intended_parents",
            cell: (info) => <RenderCellInfo text={info.getValue() || "-"} />,
            footer: (props) => props.column.id,
          },
          {
            id: "Surrogate Name",
            accessorKey: "surrogate_first_name",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Surrogate Name"
                headerKey="surrogate_first_name"
              />
            ),
            cell: (info) => {
              const { surrogate_last_name } = info?.row?.original ?? {};
              return (
                <RenderCellInfo
                  text={`${info.getValue() || "-"} ${
                    surrogate_last_name || ""
                  }`}
                />
              );
            },
            footer: (props) => props.column.id,
          },
        ]),
    {
      id: "Total Payment",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Total Payment"
          headerKey="total"
        />
      ),
      accessorKey: "total",
      cell: (info) => {
        const { invoice, alerts } = info.row.original ?? {};
        const canPayment = invoice?.id && !alerts?.length;
        const paymentStatus = invoice?.payment_status;
        const isPaid = Boolean(paymentStatus === PaymentTypes.paid);
        return (
          <RenderCellInfo
            text={`${
              canPayment
                ? isPaid
                  ? invoice?.total
                  : invoice?.total_without_card_charges
                : ""
            }`}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Date",
      accessorKey: "payment_date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Payment Date"
          headerKey="payment_date"
        />
      ),
      cell: (info) => {
        const { invoice } = info?.row?.original;
        const paymentDate = invoice?.payment_date;
        return (
          <RenderCellInfo
            text={paymentDate ? formattedMDYDate(paymentDate) : ""}
          />
        );
      },
      footer: (props) => props.column.id,
    },
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            page={page}
            setPage={handlePageChange}
            disableZipDownload={!dataList?.length}
            onZipDownload={() => {
              const filteredData = prepareExcelData(
                dataList,
                tableColumns,
                ivf_indemnity_options_list
              );
              downloadExcel(filteredData, "Statements");
            }}
            downloadFileLabel={strings.exportExcel}
            listData={listData}
            caption={caption}
            dataLoading={dataLoading}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
          />
        </Box>
      )}

      {actionLoading && <GlobalLoader loading={actionLoading} />}

      <CommonModal
        open={showDocumentModal}
        onCancel={() => {
          setShowDocumentModal(false);
          setDocumentLink("");
          setHiddenTitle("");
        }}
        cancelButtonLabel="Close"
        maxWidth="md"
        visuallyHiddenTitle={hiddenTitle}
        fullWidth
      >
        {!documentLink ? (
          <Box
            sx={{
              width: "100%",
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <iframe
            src={documentLink}
            width="100%"
            height="500"
            style={{ display: !documentLink ? "none" : "block" }}
          />
        )}
      </CommonModal>
      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => setUpdateModal(false)}
        title="Email sent successfully!"
      />
    </>
  );
};

export { TableData };
