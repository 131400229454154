/*
 * SAD admin edit form action buttons
 */

import React from "react";
import { Box } from "@mui/material";

import { Button } from "../../../../components";
import { theme } from "../../../../styles/theme";

const SadActionButtons = ({
  buttonLoading,
  onCancel,
  updateButtonLabel = "Update",
  onUpdate,
  disableContinue = false,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: "1rem",
      }}
    >
      <Button
        variant="outlined"
        size="large"
        onClick={onCancel}
        title="Cancel"
        sx={{ marginRight: "1rem" }}
        disabled={buttonLoading}
      />

      <Button
        variant="contained"
        type="submit"
        size="large"
        style={{ border: `2px ${theme.primaryDark} solid` }}
        title={updateButtonLabel}
        onClick={onUpdate}
        disabled={disableContinue || buttonLoading}
        loading={buttonLoading}
      />
    </Box>
  );
};

export { SadActionButtons };
