/*
 * SAD admin application header
 */

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Tooltip } from "../../../../components";

import {
  CoverageTypeValue,
  Disability_rider_value,
  PolicyStatusValue,
  Reproductive_organ_loss_rider_value,
} from "../../constants";
import { comma_separated } from "../../../../utils/CommonUtils";

const SadApplicationHeader = ({ header, setLoading }) => {
  const navigate = useNavigate();
  const summaryData = useSelector((state) => state.sadReducer.sadSummary);
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const {
    sad_reproductive_coverage_options_list,
    sad_disability_rider_options_list,
    application_status_types_list,
  } = lookups ?? {};

  const {
    status,
    application_number,
    coverage_type,
    term_length,
    indemnity_surrogates_beneficiary,
    indemnity_intended_parents,
    disability_rider,
    disability_rider_option,
    reproductive_organ_loss_rider,
    reproductive_organ_loss_rider_option,
  } = summaryData ?? {};

  useEffect(() => {
    !!setLoading && !!summaryData?.id && setLoading(false);
  }, [summaryData]);

  const getValue = (value) => {
    return application_number ? value : "";
  };

  const generalInformation = [
    {
      value: getValue(PolicyStatusValue(application_status_types_list, status)),
      label: "Policy Status:",
    },
    {
      value: getValue(application_number),
      label: "Policy Number:",
    },
    {
      value: getValue(CoverageTypeValue(coverage_type)),
      label: "Coverage Type:",
    },
    {
      value: getValue(`${term_length} Months`),
      label: "Term Length:",
    },
  ];

  const submitInformation = [
    {
      value: getValue(
        `USD $${comma_separated(indemnity_surrogates_beneficiary)}`
      ),
      label: "Surrogate's Beneficiary Indemnity Limit:",
    },
    {
      value: getValue(`USD $${comma_separated(indemnity_intended_parents)}`),
      label: "IPs Indemnity Limit:",
    },
    {
      value: getValue(
        disability_rider
          ? `Yes ${Disability_rider_value(
              sad_disability_rider_options_list,
              term_length,
              disability_rider_option
            )}`
          : "No"
      ),
      label: "Total Disability Rider:",
    },
    {
      value: getValue(
        reproductive_organ_loss_rider
          ? `Yes ${Reproductive_organ_loss_rider_value(
              sad_reproductive_coverage_options_list,
              term_length,
              reproductive_organ_loss_rider_option
            )}`
          : "No"
      ),
      label: "Reproductive Organ Loss Rider:",
    },
  ];

  const RenderSummaryItem = ({ options = [], leftGrid }) => {
    return (
      <Grid container>
        {!!options &&
          options?.map((option) => (
            <React.Fragment key={option.label}>
              <Grid
                item
                xl={leftGrid ? 4 : 8}
                lg={leftGrid ? 4 : 8}
                md={5}
                sm={5}
                xs={5}
              >
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    color: "#222222",
                    lineHeight: "2rem",
                  }}
                >
                  {option.label}
                </Typography>
              </Grid>
              <Grid
                item
                xl={leftGrid ? 8 : 4}
                lg={leftGrid ? 8 : 4}
                md={7}
                sm={7}
                xs={7}
              >
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    lineHeight: "2rem",
                  }}
                >
                  {option.value}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    );
  };

  return (
    <>
      <Typography variant="h1">
        <Tooltip title="Back to Previous Page">
          <ArrowBackIcon
            onClick={() => navigate(-1)}
            sx={{ marginRight: "1rem", cursor: "pointer" }}
          />
        </Tooltip>
        {header}
      </Typography>

      <Grid
        container
        columnSpacing={4}
        sx={{
          backgroundColor: "#F082331A",
          padding: "10px",
          marginTop: "0.7rem",
        }}
      >
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <RenderSummaryItem options={generalInformation} leftGrid={true} />
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <RenderSummaryItem options={submitInformation} />
        </Grid>
      </Grid>
    </>
  );
};

export { SadApplicationHeader };
