/*
 * Common Chip component for all grids
 */

import React from "react";
import { useSelector } from "react-redux";
import { Chip } from "@mui/material";

import {
  PaymentTypes,
  PolicyStatusValue,
  PolicyTypes,
} from "../../pages/sad/constants";
import { orgTypes, policyExtensionLabels } from "../../constants";

const {
  draft,
  canceled,
  certificate_issued,
  cleared,
  expired,
  onHold,
  underReview,
  onReview,
} = PolicyTypes;

const { verified, unverified, blacklisted } = orgTypes;
const { approveLabel, rejectLabel, pendingLabel } = policyExtensionLabels;
const { paid } = PaymentTypes;

const RenderChip = ({
  status,
  variant,
  backgroundColor,
  color,
  label,
  ...rest
}) => {
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const { application_status_types_list } = lookups ?? {};

  const text = PolicyStatusValue(application_status_types_list, status);

  let textColor = "";
  let bgColor = "";

  if (status == draft || status == certificate_issued || label === unverified) {
    textColor = "#B1520D";
    bgColor = "#C0580E1A";
  } else if (
    status === underReview ||
    label === verified ||
    label === approveLabel
  ) {
    textColor = "#0A7691";
    bgColor = "#0A76911A";
  } else if (status === cleared || label === rejectLabel) {
    textColor = "#C92434";
    bgColor = "#C924341A";
  } else if (status === expired || status == paid) {
    textColor = "#183291";
    bgColor = "#0f053f1a";
  } else if (status === canceled || label === blacklisted) {
    textColor = "#222222";
    bgColor = "#E7EAEC";
  } else if (status === onHold) {
    textColor = "#067153";
    bgColor = "#dae7c6d8";
  } else if (status === onReview || label === pendingLabel) {
    textColor = "#650b0b";
    bgColor = "#ece898d8";
  } else {
    textColor = "#A61FD3";
    bgColor = "#efe4f4";
  }

  return (
    <div className="text-center">
      <Chip
        variant={variant ?? "filled"}
        label={label ?? text}
        sx={{
          whiteSpace: "nowrap",
          fontWeight: "bold",
          backgroundColor:
            variant === "outlined" ? "transparent" : backgroundColor ?? bgColor,
          color: color ?? textColor,
          borderColor: color ?? textColor,
        }}
        {...rest}
      />
    </div>
  );
};

export { RenderChip };
