/*
 * ADMIN POLICY-EXTENSION GRID DATA
 */

import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ReactTable from "../../../React Table";
import { formattedMDYDate } from "../../../utils/CommonUtils";
import {
  GlobalLoader,
  NoRecords,
  RenderTableHeader,
  RenderCellInfo,
  CommonModal,
  RenderChip,
  RenderActionMenu,
} from "../../../components";
import { PolicyExtensionApis } from "../../../api";
import {
  policyExtensionLabels,
  policyExtensionValues,
} from "../../../constants";

const { approved, rejected } = policyExtensionValues;
const { approveLabel, rejectLabel, pendingLabel } = policyExtensionLabels;

const filterOptions = [
  { label: "All", value: "" },
  { label: approveLabel, value: approved },
  { label: rejectLabel, value: rejected },
];

const TableData = ({
  caption,
  hasRecords,
  dataList,
  listData,
  setSearchText,
  setSortKey,
  setSortOrder,
  requestStatus,
  setRequestStatus,
  page,
  setPage,
  loading,
  dataLoading,
  setAdvancedSearch,
  setSearchButton,
  setSearchParams,
}) => {
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [updateModal, setUpdateModal] = useState({ show: false, text: "" });

  const handleFilterChange = (e, key) => {
    setPage(1);
    setSearchButton(true);
    setSearchParams((searchParams) => {
      searchParams.set("page", 1);
      searchParams.set(key, e.target.value);
      return searchParams;
    });
    setAdvancedSearch((p) => ({ ...p, [key]: e.target.value }));
  };

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  const updateExtensionStatus = async (payload) => {
    const resp = await PolicyExtensionApis.updatePolicyExtension(payload);
    !!resp?.success && setUpdateModal((p) => ({ ...p, show: true }));
  };

  const openActionDropDown = (e, rowData) => {
    const { id: extension_id } = rowData ?? {};
    setClickable([
      {
        text: "Approve",
        route: () => {
          updateExtensionStatus({
            extension_id,
            params: { status: approved },
          });
          setUpdateModal({
            show: false,
            text: "Policy Extended Successfully!",
          });
        },
      },
      {
        text: "Reject",
        route: () => {
          updateExtensionStatus({
            extension_id,
            params: { status: rejected },
          });
          setUpdateModal({ show: false, text: "Policy Extension Rejected!" });
        },
      },
    ]);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setActionAnchorEl(null);
    setClickable([]);
  };

  const tableColumns = [
    {
      id: "Action",
      header: "Action",
      cell: ({ row }) => {
        const { status } = row?.original ?? {};
        return (
          <IconButton
            disabled={!!status}
            onClick={(e) => openActionDropDown(e, row.original)}
            aria-label={"More actions of " + row.original?.application_number}
          >
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
    {
      id: "Status",
      accessorKey: "status",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Status"
          headerKey="status"
        />
      ),
      cell: (info) => {
        const status = info.getValue();
        if (status === approved) {
          return <RenderChip label={approveLabel} />;
        } else if (status === rejected) {
          return <RenderChip label={rejectLabel} />;
        } else {
          return <RenderChip label={pendingLabel} />;
        }
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Policy Number",
      header: "Policy Number",
      accessorKey: "application_number",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Number"
          headerKey="application_number"
        />
      ),
      cell: (info) => (
        <RenderCellInfo text={info.getValue()} sx={{ color: "#0A7691" }} />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Extension Period",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Extension Period"
          headerKey="extension_period"
        />
      ),
      accessorKey: "extension_period",
      cell: (info) => <RenderCellInfo text={`${info.getValue()} Months`} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Total Transfers",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Total Transfers"
          headerKey="total_transfers"
        />
      ),
      accessorKey: "total_transfers",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Transfer Lead to Pregnancy",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Transfer Lead to Pregnancy"
          headerKey="transfer_lead_to_pregnancy"
        />
      ),
      accessorKey: "transfer_lead_to_pregnancy",
      cell: (info) => <RenderCellInfo text={info.getValue() ? "Yes" : "No"} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Confirmation of Pregnancy Date",
      header: "Confirmation of Pregnancy Date",
      accessorKey: "confirmation_of_pregnancy_date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Confirmation of Pregnancy Date"
          headerKey="confirmation_of_pregnancy_date"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Expected Delivery Date",
      header: "Expected Delivery Date",
      accessorKey: "expected_delivery_date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Expected Delivery Date"
          headerKey="expected_delivery_date"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : hasRecords ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            padding="0.7rem 0.5rem"
            page={page}
            setPage={handlePageChange}
            listData={listData}
            onSearch={setSearchText}
            caption={caption}
            filterOptions={filterOptions}
            dataLoading={dataLoading}
            showFilterDropdown={true}
            label="Request Status"
            firstFilterValue={requestStatus}
            handleFirstFilterChange={(e) => {
              handleFilterChange(e, "status");
              setRequestStatus(e.target.value);
            }}
            setDataFetching={setSearchButton}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
          />
        </Box>
      ) : (
        <NoRecords />
      )}

      <CommonModal
        open={!!updateModal.show}
        type="success"
        onConfirm={() => setUpdateModal({ show: false, text: "" })}
        title={updateModal.text}
      />
    </>
  );
};

export { TableData };
