/*
 * A D M I N --- M O D U L E-  C O U N T R Y   F E E    D A T A    G R I D
 */

import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import { TableData } from "./TableData";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";
import { GoBack } from "../../../components";
import { AllRoutes } from "../../../routes";

const CountryFee = () => {
  return (
    <AdminPageLayout>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}>
        <GoBack navigateURL={AllRoutes.WizardSettings.route} />
        <Grid container>
          <Grid item xs={6} sm={7} md={8} lg={9} xl={10}>
            <Typography variant="h1" sx={{ margin: "0" }}>
              Country Fee
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <TableData caption="Country Fee" />
    </AdminPageLayout>
  );
};

export { CountryFee };
