/**
 * Impersonation APIs
 */

import httpClient from "../utils/HttpUtils";
import {
  handleInvokeImpersonation,
  handleUserLogin,
} from "../utils/CommonUtils";

export const ImpersonationApis = {
  impersonateUser: async ({ user_id }) => {
    try {
      const resp = await httpClient.get(`mimic/${user_id}`);
      if (resp?.success) {
        handleInvokeImpersonation();
        const route = handleUserLogin(resp.data);
        window.location.href = route;
      }
      return resp;
    } catch (error) {
      console.log("Error impersonateUser", error);
    }
  },
};
