/*
 * ADMIN CHANGE-CANCEL-REQUEST GRID HEADER
 */

import "react-calendar/dist/Calendar.css";
import React, { useEffect } from "react";
import { InputAdornment, Grid, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  DateRangePicker,
  GridActionButtons,
  TextInput,
} from "../../../components";
import {
  convertDateRangeToString,
  oneMonthDateRangeString,
  oneMonthDateRangeText,
} from "../../../utils/CommonUtils";

const TableHeader = ({
  advancedSearch,
  setAdvancedSearch,
  dateRange,
  setDateRange,
  searchText,
  setSearchText,
  requestStatus,
  setPage,
  setSearchButton,
  buttonLoading,
  setButtonLoading,
  setSearchParams,
}) => {
  const setDefaultDate = () => {
    const curDate = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    setDateRange([oneMonthAgo, curDate]);
  };

  useEffect(() => {
    setDefaultDate();
  }, []);

  const setSearchObject = (key, value) => {
    setAdvancedSearch((p) => ({ ...p, [key]: value }));
  };

  const searchValues = () => {
    setButtonLoading("search");
    setPage(1);
    setSearchParams((searchParams) => {
      searchParams.set("page", 1);
      Object.entries(advancedSearch).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
      return searchParams;
    });
    setSearchButton(true);
  };

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
    setSearchButton(false);
    setSearchObject("created_at", convertDateRangeToString(newDateRange));
  };

  const onClear = () => {
    setButtonLoading("clear");
    setSearchButton(true);
    setSearchText(" ");
    setPage(1);
    setDefaultDate();
    setSearchParams({
      ["created_at"]: oneMonthDateRangeText(),
      ["page"]: 1,
      ["status"]: requestStatus,
    });
    setAdvancedSearch({
      ...oneMonthDateRangeString(),
      ["status"]: requestStatus,
    });
  };

  return (
    <form>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginY: "1rem",
          paddingY: "1rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DateRangePicker
              dateRange={dateRange}
              handleDateChange={handleDateChange}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextInput
              inputKey="search"
              inputLabel="Search"
              placeholder="Search by Keywords"
              autoComplete="off"
              margin="none"
              required={false}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setSearchButton(false);
                setSearchObject("q", e.target.value);
              }}
              sx={{ height: "2.5rem" }}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <GridActionButtons
              onSearch={searchValues}
              onClear={onClear}
              searchButtonLoading={buttonLoading == "search"}
              clearButtonLoading={buttonLoading == "clear"}
            />
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export { TableHeader };
