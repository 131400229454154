/*
 * Admin panel layout
 */

import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Typography,
  Dialog,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { ThemeProvider } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import WifiTetheringRoundedIcon from "@mui/icons-material/WifiTetheringRounded";
import WifiTetheringOffRoundedIcon from "@mui/icons-material/WifiTetheringOffRounded";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Logout from "@mui/icons-material/Logout";
import InfoIcon from "@mui/icons-material/Info";
import PersonIcon from "@mui/icons-material/Person";

import logoImg from "../images/new_life_agency_logo.png";
import AddApplication from "../images/Add.svg";
import Ivf from "../images/ivf.svg";
import SAD from "../images/SAD.svg";
import GetStatement from "../images/Get-statement.svg";
import { theme } from "../../styles/theme";
import { Footer } from "./components/Footer";
import { LocalStorageHelper } from "../../utils/HttpUtils";
import { AllRoutes } from "../../routes";
import { CommonApis, ZohoApis } from "../../api";
import { setLookups } from "../../redux/slices/commonSlice";
import {
  getFormattedName,
  handleExitImpersonation,
} from "../../utils/CommonUtils";
import { Button, CommonModal, GlobalLoader } from "../../components";
import {
  INSURANCE_TYPES,
  ADMIN_DATA,
  USER_DATA,
  USER_ROLE,
  USER_TOKEN,
  USER_ROLES,
} from "../../constants";

const drawerWidth = 240;

const useStyles = makeStyles({
  appLogo: {
    height: "40px",
    width: "170px",
    marginLeft: "10px",
  },
  profileImg: {
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    marginRight: "10px",
    marginLeft: "10px",
  },
  rightContent: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  customBadge: {
    backgroundColor: theme.primary,
    margin: "2px",
  },
  drawerIcon: {
    height: "17px",
    width: "17px",
  },
  tabIcon: {
    height: "22px",
    width: "22px",
    marginBottom: "0.3rem",
  },
  logoImage: {
    width: "16rem",
    padding: "1.4rem 0",
  },
});

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: theme.fadeGrey,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  backgroundColor: theme.fadeGrey,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "red",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminPageLayout = ({ hideDrawer = false, children }) => {
  const myData = JSON.parse(LocalStorageHelper.get(USER_DATA));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [drawerData, setDrawerData] = useState([]);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openAppInfo, setOpenAppInfo] = useState(false);
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showZohoModal, setShowZohoModal] = useState(false);
  const currentYear = new Date().getFullYear();
  const isMobile = useMediaQuery("(max-width:600px)");
  const userRole = LocalStorageHelper.get(USER_ROLE);
  const isImpersonated = JSON.parse(LocalStorageHelper.get(ADMIN_DATA));
  const isZohoConnected = myData?.zoho_connected === "Y";
  const isProfilePage = window.location.href?.includes?.(
    AllRoutes.Profile.route
  );

  const handleCloseAppInfo = () => {
    setOpenAppInfo(false);
  };

  useEffect(() => {
    const getLookups = async () => {
      const resp = await CommonApis.lookups();
      dispatch(setLookups(resp));
    };

    const { role, working_with_agency, zipcode } = myData;
    const { id: agency_id } = myData?.agency ?? {};
    const { id: clinic_id } = myData?.clinic ?? {};
    const { agency, clinic, ip } = USER_ROLES;
    if (
      (role === agency && !agency_id) ||
      (role === clinic && (!clinic_id || working_with_agency == null)) ||
      (role === ip && !zipcode)
    ) {
      navigate(AllRoutes.Profile.route);
    }

    getLookups();
  }, []);

  const userDrawerData = [
    {
      title: "New Application",
      icon: AddApplication,
      routes: [
        AllRoutes.NewApplicationPage.route,
        AllRoutes.SadForm.route,
        AllRoutes.IvfCyclePage.route,
      ],
      path: AllRoutes.NewApplicationPage.route,
    },
    {
      title: "IVF Cycles",
      icon: Ivf,
      routes: [
        AllRoutes.IvfPoliciesPage.route,
        AllRoutes.IvfChangeReqPage.route,
        AllRoutes.IvfCancelReqPage.route,
      ],
      path: AllRoutes.IvfPoliciesPage.route,
    },
    {
      title: "SAD Polices",
      icon: SAD,
      routes: [
        AllRoutes.SadPoliciesPage.route,
        AllRoutes.SadChangeReqPage.route,
        AllRoutes.SadCancelReqPage.route,
        AllRoutes.PolicyExtension.route,
      ],
      path: AllRoutes.SadPoliciesPage.route,
    },
    {
      title: "Get Statement",
      icon: GetStatement,
      routes: [AllRoutes.GetStatement.route],
      path: AllRoutes.GetStatement.route,
    },
  ];

  const adminDrawerData = [
    {
      title: "IVF Cycles",
      icon: Ivf,
      routes: [
        AllRoutes.IvfAdminPoliciesPage.route,
        AllRoutes.Ivf_EditApplication.route,
        AllRoutes.Ivf_EditStatus.route,
      ],
      path: AllRoutes.IvfAdminPoliciesPage.route,
    },
    {
      title: "SAD Polices",
      icon: SAD,
      routes: [
        AllRoutes.SadInsurancePage.route,
        AllRoutes.Sad_EditStatus.route,
        AllRoutes.Sad_EditApplication.route,
      ],
      path: AllRoutes.SadInsurancePage.route,
    },
    {
      title: "Sales Report",
      icon: SAD,
      routes: [AllRoutes.SalesReport.route],
      path: AllRoutes.SalesReport.route,
    },
    {
      title: "Change / Cancel Request",
      icon: SAD,
      routes: [
        AllRoutes.Admin_ChangeRequest.route,
        AllRoutes.AddResponse.route,
        AllRoutes.ViewResponse.route,
      ],
      path: AllRoutes.Admin_ChangeRequest.route,
    },
    {
      title: "Policy Extension",
      icon: SAD,
      routes: [AllRoutes.Admin_PolicyExtension.route],
      path: AllRoutes.Admin_PolicyExtension.route,
    },
    {
      title: "Wizard Settings",
      icon: SAD,
      routes: [
        AllRoutes.WizardSettings.route,
        AllRoutes.IvfClinics.route,
        AllRoutes.AddClinic.route,
        AllRoutes.EditClinic.route,
        AllRoutes.ClinicUsers.route,
        AllRoutes.AddNewClinicUser.route,
        AllRoutes.EditClinicUser.route,
        AllRoutes.Agencies.route,
        AllRoutes.AddAgency.route,
        AllRoutes.EditAgency.route,
        AllRoutes.AgencyUsers.route,
        AllRoutes.AddNewAgencyUser.route,
        AllRoutes.EditAgencyUser.route,
        AllRoutes.CountryFee.route,
        AllRoutes.EditCountryFee.route,
        AllRoutes.StateList.route,
        AllRoutes.AddNewState.route,
        AllRoutes.StateTable.route,
        AllRoutes.StateTax.route,
        AllRoutes.AddNewStateTax.route,
        AllRoutes.EditStateTax.route,
        AllRoutes.SignedAffidavitDocuments.route,
        AllRoutes.BrokersPanel.route,
        AllRoutes.AddNewBroker.route,
        AllRoutes.EditBroker.route,
        AllRoutes.BrokerUsers.route,
        AllRoutes.AddNewBrokerUser.route,
        AllRoutes.EditBrokerUser.route,
        AllRoutes.CertificateBatch.route,
        AllRoutes.CoverageType.route,
        AllRoutes.OptionalRiders.route,
      ],
      path: AllRoutes.WizardSettings.route,
    },
    {
      title: "User Account",
      icon: SAD,
      routes: [
        AllRoutes.UserAccounts.route,
        AllRoutes.AddNewUser.route,
        AllRoutes.EditUser.route,
      ],
      path: AllRoutes.UserAccounts.route,
    },
  ];

  const sadBrokerDrawerData = [
    {
      title: "SAD Policies",
      icon: SAD,
      routes: [
        AllRoutes.SadBrokerPolicies.route,
        AllRoutes.SadBrokerAddNotes.route,
      ],
      path: AllRoutes.SadBrokerPolicies.route,
    },
    // {
    //   title: "Sales Report",
    //   icon: SAD,
    //   routes: [AllRoutes.SalesReport.route],
    //   path: AllRoutes.SalesReport.route,
    // },
  ];

  const ivfBrokerDrawerData = [
    {
      title: "IVF Cycles",
      icon: Ivf,
      routes: [
        AllRoutes.IVFBrokerPolicies.route,
        AllRoutes.IVFBrokerAddNotes.route,
      ],
      path: AllRoutes.IVFBrokerPolicies.route,
    },
    // {
    //   title: "Sales Report",
    //   icon: SAD,
    //   routes: [AllRoutes.SalesReport.route],
    //   path: AllRoutes.SalesReport.route,
    // },
  ];

  const allBrokerDrawerData = [
    {
      title: "IVF Cycles",
      icon: Ivf,
      routes: [
        AllRoutes.IVFBrokerPolicies.route,
        AllRoutes.IVFBrokerAddNotes.route,
      ],
      path: AllRoutes.IVFBrokerPolicies.route,
    },
    {
      title: "SAD Policies",
      icon: SAD,
      routes: [
        AllRoutes.SadBrokerPolicies.route,
        AllRoutes.SadBrokerAddNotes.route,
      ],
      path: AllRoutes.SadBrokerPolicies.route,
    },
    // {
    //   title: "Sales Report",
    //   icon: SAD,
    //   routes: [AllRoutes.SalesReport.route],
    //   path: AllRoutes.SalesReport.route,
    // },
  ];

  useEffect(() => {
    let headerOptions = isMobile ? ["App info"] : [];
    if (userRole !== USER_ROLES.broker) {
      headerOptions.unshift("My Profile");
    }
    if (!isImpersonated) {
      headerOptions.push("Logout");
    }
    setSettings(headerOptions);
  }, []);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (setting) => {
    handleCloseUserMenu();
    if (setting === "Logout") {
      LocalStorageHelper.delete(USER_TOKEN);
      LocalStorageHelper.delete(USER_DATA);
      LocalStorageHelper.delete(USER_ROLE);
      window.location.href = AllRoutes.Login.route;
    }
    if (setting === "My Profile") {
      navigate(AllRoutes.Profile.route);
    }
    if (setting === "App info" && isMobile) {
      setOpenAppInfo(true);
    }
  };

  const handleBackToAdmin = () => {
    handleExitImpersonation();
  };

  const handleZohoConnection = async () => {
    setLoading(true);
    isZohoConnected
      ? await CommonApis.updateMyProfile({ zoho_refresh_token: null })
      : await ZohoApis.connect();
    setLoading(false);
    isZohoConnected && setShowZohoModal(true);
  };

  const MyMenu = memo(({ settings = [], onMenuItemClick }) => (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      {settings?.map((setting) => (
        <MenuItem key={setting} onClick={() => onMenuItemClick(setting)}>
          {setting === "Logout" && (
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
          )}
          {setting === "App info" && (
            <ListItemIcon>
              <InfoIcon fontSize="small" />
            </ListItemIcon>
          )}
          {setting === "My Profile" && (
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
          )}
          <Typography textAlign="center">{setting}</Typography>
        </MenuItem>
      ))}
    </Menu>
  ));

  useEffect(() => {
    const { insurance_type } = myData?.broker || {};
    setDrawerData(
      userRole == USER_ROLES.admin
        ? adminDrawerData
        : userRole == USER_ROLES.broker
        ? insurance_type == INSURANCE_TYPES.sad
          ? sadBrokerDrawerData
          : insurance_type == INSURANCE_TYPES.ivf
          ? ivfBrokerDrawerData
          : allBrokerDrawerData
        : userDrawerData
    );
  }, []);

  // Close drawer on window resize
  useEffect(() => {
    const handleResize = () => {
      setOpen(window.innerWidth >= 1000);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const selectedDrawerItem = (item) => {
    return item?.routes?.some?.((path) => pathname.includes(path));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          elevation={0}
          open={open}
          className={open ? "drawer-open" : "drawer-close"}
          sx={{
            backgroundColor: "white",
            ...(isMobile ? { paddingRight: "0 !important" } : undefined),
          }}
        >
          <Toolbar
            sx={{
              background: theme.white,
              borderBottom: 2,
              borderBottomColor: theme.lightGrey,
            }}
          >
            {/* HEADER LEFT VIEW */}
            <IconButton
              onClick={() => setOpen(!open)}
              aria-label={
                open
                  ? "Collapse menu to icon view"
                  : "Expand menu, show detail view"
              }
              edge="start"
              sx={{
                display: {
                  xs: "none",
                  sm: hideDrawer ? "none" : "block",
                  md: hideDrawer ? "none" : "flex",
                },
                paddingLeft: "10px",
                color: theme.grey,
                background: !open && theme.fadeGrey,
              }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={logoImg}
              alt="New Life Agency, Inc. The Surrogacy Insurance Experts logo"
              className={classes.appLogo}
            />

            {/* HEADER MIDDLE VIEW */}
            {!!isImpersonated && (
              <div className={classes.rightContent}>
                <IconButton
                  onClick={handleBackToAdmin}
                  aria-label="Back to admin"
                  aria-haspopup="true"
                  disableElevation
                  disableFocusRipple
                  sx={{ borderRadius: "5px" }}
                >
                  <Logout
                    fontSize="small"
                    sx={{
                      transform: "rotate(180deg)",
                      marginRight: "0.5rem",
                    }}
                  />
                  <Typography
                    component={"span"}
                    sx={{
                      display: { xs: "none", sm: "block" },
                      color: "black",
                    }}
                  >
                    Back to Admin
                  </Typography>
                </IconButton>
              </div>
            )}

            {userRole === USER_ROLES.admin && isProfilePage && (
              <div className={classes.rightContent}>
                <IconButton
                  onClick={handleZohoConnection}
                  aria-label={
                    isZohoConnected ? "Disconnect Zoho" : "Connect to Zoho"
                  }
                  aria-haspopup="true"
                  disableElevation
                  disableFocusRipple
                  sx={{ borderRadius: "5px" }}
                >
                  {isZohoConnected ? (
                    <WifiTetheringOffRoundedIcon
                      fontSize="small"
                      sx={{ marginRight: "0.5rem", color: "black" }}
                    />
                  ) : (
                    <WifiTetheringRoundedIcon
                      fontSize="small"
                      sx={{ marginRight: "0.5rem", color: "black" }}
                    />
                  )}
                  <Typography
                    component={"span"}
                    sx={{
                      display: { xs: "none", sm: "block" },
                      color: "black",
                    }}
                  >
                    {isZohoConnected ? "Disconnect Zoho" : "Connect to Zoho"}
                  </Typography>
                </IconButton>
              </div>
            )}

            {/* HEADER RIGHT VIEW */}
            <div className={classes.rightContent}>
              <Badge
                badgeContent={3}
                classes={{ badge: classes.customBadge }}
                sx={{ display: "none", marginRight: "12px" }}
              >
                <NotificationsIcon sx={{ color: theme.grey }} />
              </Badge>
              <IconButton
                onClick={handleOpenUserMenu}
                aria-label="Account settings"
                aria-haspopup="true"
                disableElevation
                disableFocusRipple
                sx={{
                  borderRadius: "5px",
                  "&.Mui-disabled": {
                    background: theme.primaryFade,
                    color: theme.white,
                  },
                }}
              >
                <img
                  src={myData?.profile_pic_attachment_url || logoImg}
                  alt=""
                  className={classes.profileImg}
                />
                <Typography
                  component={"span"}
                  sx={{
                    display: { xs: "none", sm: "block" },
                    color: "black",
                    marginX: "2px",
                    marginRight: "10px",
                  }}
                >
                  {myData?.display_name ||
                    `${myData?.first_name ?? ""} ${myData?.last_name ?? ""}`}
                </Typography>
                <Typography
                  component={"span"}
                  sx={{
                    display: { xs: "block", sm: "none" },
                    color: theme.grey,
                    marginX: "2px",
                    marginRight: "10px",
                  }}
                >
                  {getFormattedName(
                    myData?.display_name,
                    myData?.first_name,
                    myData?.last_name
                  )}
                </Typography>
                <ExpandMoreRoundedIcon
                  sx={{
                    display: "block",
                    color: theme.grey,
                    paddingRight: "5px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
              <Box sx={{ flexGrow: 0 }}>
                <MyMenu
                  settings={settings}
                  onMenuItemClick={handleMenuItemClick}
                />
              </Box>
            </div>
          </Toolbar>
        </AppBar>

        {/* DRAWER */}
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            display: { xs: "none", sm: hideDrawer ? "none" : "block" },
          }}
          PaperProps={{
            style: { top: 64, paddingTop: 20 }, // 64- height of AppBar
            role: "navigation",
            "aria-label": "Main Menu",
          }}
        >
          <List>
            {drawerData?.map((item, index) => (
              <ListItem
                key={item.title}
                disablePadding
                sx={{ display: "block", paddingRight: open && "1rem" }}
              >
                <ListItemButton
                  sx={{
                    height: "2rem",
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    backgroundColor:
                      open && selectedDrawerItem(item) && theme.purple,
                    marginBottom: 1,
                    borderRightWidth: 1,
                    borderTopRightRadius: 19,
                    borderBottomRightRadius: 19,
                    "&:hover": {
                      backgroundColor: open ? theme.purple : "transparent",
                      color: open && "black",
                    },
                  }}
                  onClick={() => navigate(item.path)}
                  role="link"
                  title={open ? undefined : item.title}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      background:
                        !open && selectedDrawerItem(item) && theme.purple,
                      padding: !open && selectedDrawerItem(item) && 1,
                      borderRadius: !open && selectedDrawerItem(item) && "100%",
                      ml: !open && selectedDrawerItem(item) && -1,
                    }}
                  >
                    <img
                      src={item.icon}
                      alt=""
                      className={classes.drawerIcon}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    disableTypography
                    sx={{
                      opacity: open ? 1 : 0,
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      letterSpacing: 0,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>

        {/* BOTTOM TAB FOR MOBILE VIEW */}
        <Paper
          sx={{
            display: { xs: hideDrawer ? "none" : "block", sm: "none" },
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99,
            padding: 0,
            marginX: "6px",
          }}
          elevation={3}
          role="navigation"
          aria-label="Main Menu"
        >
          <BottomNavigation
            showLabels
            sx={{
              "& .MuiBottomNavigationAction-root": {
                color: theme.grey,
              },
              "& .Mui-selected": {
                color: theme.primaryDark,
              },
              listStyle: "none",
              padding: 0,
              paddingLeft: "6px",
              paddingRight: "6px",
              margin: "-3px",
              justifyContent: "space-around",
              alignItems: "center",
            }}
            component="ul"
          >
            {drawerData?.map((item, index) => (
              <li
                key={item.title}
                className="bottomNavListitem"
                style={{ flex: "1 1 auto!important" }}
              >
                <BottomNavigationAction
                  label={
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "0.6rem",
                          sm: "0.7rem",
                          lineHeight: "1.1",
                          letterSpacing: 0,
                        },
                        fontWeight: "bold",
                      }}
                      component="span"
                    >
                      {item.title}
                    </Typography>
                  }
                  icon={
                    <img src={item.icon} alt="" className={classes.tabIcon} />
                  }
                  onClick={() => navigate(item.path)}
                  role="link"
                  sx={{
                    padding: "0.2rem",
                    textAlign: "center",
                    minWidth: "fit-content",
                    "& span": {
                      opacity: 1,
                    },
                  }}
                  className={selectedDrawerItem(item) ? "Mui-selected" : ""}
                  aria-current={selectedDrawerItem(item) ? "page" : undefined}
                />
              </li>
            ))}
          </BottomNavigation>
        </Paper>

        {/* DYNAMIC CONTENT */}
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <Box
            component="main"
            sx={{ p: 3, paddingBottom: { xs: "6rem", sm: 3, md: 3 } }}
          >
            <DrawerHeader />
            {children}
          </Box>
          <Footer />
        </Box>
      </Box>
      <Dialog fullScreen open={openAppInfo} onClose={handleCloseAppInfo}>
        <DialogContent className="d-flex align-center justify-center ">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {/* Logo */}
            <img
              src={logoImg}
              alt="New Life Agency, Inc. The Surrogacy Insurance Experts logo"
              className={classes.logoImage}
            />

            {/* Copyright Line */}
            <Typography
              variant="body1"
              color="initial"
              className="mb-2 text-center"
            >
              Copyright New Life Agency, Insurance Agency, Inc. 2003 -{" "}
              {currentYear}. Developed by{" "}
              <Link
                to="https://d2itechnology.com/"
                className="text-primary"
                target="_blank"
              >
                D2i Technology
              </Link>
            </Typography>

            {/* Close Button */}
            <Button
              variant="contained"
              onClick={handleCloseAppInfo}
              size="large"
              title="Back"
              autoFocus
            />
          </Box>
        </DialogContent>
      </Dialog>

      {loading && <GlobalLoader loading={loading} />}

      <CommonModal
        open={showZohoModal}
        type="success"
        onConfirm={() => setShowZohoModal(false)}
        title="Zoho Disconnected!"
      />
    </ThemeProvider>
  );
};

export { AdminPageLayout };
