/*
 * Material UI themes
 */

import { createTheme } from "@mui/material";
import { colors } from "@mui/material";

const theme = createTheme({
  ...colors,
  black: "#000",
  fadeGrey: "#F8F8F8",
  grey: "#716C6A",
  green: "#00ba00",
  lightGrey: "#E7EAEC",
  mistBlue: "#0A7691",
  primary: "#F08233",
  primaryDark: "#C0580E",
  primaryFade: "#dfab86",
  primaryLight: "#F082331A",
  purple: "#C0580E26",
  white: "#fff",
  palette: {
    primary: {
      light: "#C0580E",
      main: "#C0580E",
      dark: "#A34B0C",
      contrastText: "#fff",
    },
    secondary:{
      light: "#fff",
      main: "#fff",
      dark: "#A34B0C",
      contrastText: "#716C6A",
    }
  },
  typography: {
    h1: {
      fontSize: "1.6em",
      marginBottom: "1rem",
      fontWeight: 600,
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    },
    button: {
      textTransform: "none",
      fontSize: "1rem",
      fontWeight: 550,
    },
  },
});

export { theme };
