/*
 * Common Tooltip
 */

import React from "react";
import { Tooltip as MuiTooltip, IconButton, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const Tooltip = ({
  tooltip,
  iconStyle,
  placement = "top",
  children,
  disabled = false,
  ...rest
}) => {
  return (
    <MuiTooltip
      describeChild
      arrow
      placement={placement}
      title={
        <Typography variant="body2" sx={{ fontSize: "0.7rem" }}>
          {tooltip}
        </Typography>
      }
      enterTouchDelay={0}
      leaveTouchDelay={9000}
      {...rest}
    >
      {children ?? (
        <IconButton
          aria-label="Tooltip"
          color="primary"
          disabled={disabled}
          sx={
            ({ padding: 0, marginRight: "0.2rem" },
            { ...iconStyle },
            { padding: "0.1rem", backgroundColor: "white !important" })
          }
        >
          <InfoIcon fontSize="small" sx={{ color: "#767676" }} />
        </IconButton>
      )}
    </MuiTooltip>
  );
};

export { Tooltip };
