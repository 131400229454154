/*
 * Admin -> Wizard -> IVF Clinics -> Clinic's users list
 */

import React, { useEffect, useState } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import AddIcon from "@mui/icons-material/Add";

import { TableData } from "./TableData";
import { AllRoutes } from "../../../../../routes";
import { Button, GoBack } from "../../../../../components";
import { AdminPageLayout } from "../../../../../layouts/admin-layout/AdminPageLayout";
import { ClinicApis } from "../../../../../api";

const ClinicUsers = () => {
  const navigate = useNavigate();
  const { clinic_id } = useParams();
  const [clinicData, setClinicData] = useState([]);
  const [hasRecords, setHasRecords] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [listData, setListData] = useState();
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [advancedSearch, setAdvancedSearch] = useState({ clinic_id });

  useEffect(() => {
    const checkRecords = async () => {
      const resp = await ClinicApis.getUsersWithClinic({
        limit: true,
        page,
        advancedSearch,
        clinic_id,
      });
      setHasRecords(resp?.data?.results?.length > 0);
      setLoading(resp?.data?.results?.length > 0);
    };

    const getClinicData = async () => {
      const resp = await ClinicApis.getClinicById({ clinic_id });
      resp?.success ? setClinicData(resp?.data) : navigate(-1);
    };

    checkRecords();
    getClinicData();
  }, []);

  useEffect(() => {
    !!hasRecords && ClinicUsersList();
  }, [hasRecords, page, advancedSearch]);

  // Get all clinic's users records
  const ClinicUsersList = async () => {
    setDataLoading(true);
    let params = {
      sortOrder: "",
      sortKey: "first_name",
      limit: true,
      page,
      advancedSearch,
      clinic_id,
    };
    const resp = await ClinicApis.getUsersWithClinic(params);
    if (resp) {
      setDataLoading(false);
      setLoading(false);
    }
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
  };

  return (
    <AdminPageLayout>
      <Grid container sx={{ marginBottom: "1.5rem" }}>
        <Grid item xs={6} sm={7} md={8} lg={9} xl={10}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <GoBack />
            <Typography variant="h1" sx={{ margin: "0" }}>{`${
              clinicData?.name ? `${clinicData?.name}'s Users` : ""
            }`}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={5} md={4} lg={3} xl={2}>
          <Button
            title="Add New User"
            variant="contained"
            sx={{ width: "100%", minHeight: "2rem" }}
            startIcon={<AddIcon sx={{ color: "#FFFFFF" }} />}
            disabled={clinicData?.is_active <= 0}
            onClick={() =>
              navigate(`${AllRoutes.AddNewClinicUser.route}/${clinic_id}`)
            }
          />
        </Grid>
      </Grid>

      <TableData
        caption={`${
          clinicData?.name ? `${clinicData?.name}'s Users` : "Clinic's Users"
        }`}
        clinicData={clinicData}
        hasRecords={hasRecords}
        page={page}
        setPage={setPage}
        dataList={dataList}
        listData={listData}
        loading={loading}
        dataLoading={dataLoading}
        setAdvancedSearch={setAdvancedSearch}
      />
    </AdminPageLayout>
  );
};

export { ClinicUsers };
