/**
 * Beneficiary APIs
 */

import httpClient from "../utils/HttpUtils";

export const BeneficiaryApis = {
  createBeneficiary: async (payload) => {
    const { application_id, params } = payload;
    try {
      const resp = await httpClient.post(
        `applications/${application_id}/beneficiaries`,
        params
      );
      return resp;
    } catch (error) {
      console.log("Error createBeneficiary", error);
    }
  },

  updateBeneficiary: async (payload) => {
    const { application_id, id, params } = payload;
    try {
      const resp = await httpClient.put(
        `applications/${application_id}/beneficiaries/${id}`,
        params
      );
      return resp;
    } catch (error) {
      console.log("Error updateBeneficiary", error);
    }
  },

  deleteBeneficiary: async (payload) => {
    const { application_id, id, params } = payload;
    try {
      const resp = await httpClient.delete(
        `applications/${application_id}/beneficiaries/${id}`
      );
      return resp;
    } catch (error) {
      console.log("Error deleteBeneficiary", error);
    }
  },
};
