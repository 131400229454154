/*
 * Privacy Policy page
 */

import React from "react";
import { ThemeProvider, makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Typography,
  Toolbar,
  Link,
} from "@mui/material";

import { theme } from "../../styles/theme";
import { Footer } from "../../components";
import logoImg from "../../layouts/images/new_life_agency_logo.png";

const useStyles = makeStyles({
  appLogo: {
    height: "40px",
    width: "170px",
    marginLeft: "10px",
  },
  h1: {
    fontSize: "1.6em !important",
    fontWeight: "600 !important",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif !important',
    textAlign: "center",
    marginTop: "1.5rem !important",
    marginBottom: "1.5rem !important",
  },
  h2: {
    fontSize: "1.3em !important",
    fontWeight: "600 !important",
    textAlign: "center",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif !important',
    marginTop: "1.5rem !important",
    marginBottom: "1.5rem !important",
  },
  h3: {
    fontSize: "1em !important",
    marginTop: "1.6rem !important",
    marginBottom: "0.4rem !important",
    fontWeight: "600 !important",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif !important',
  },
  orderList: {
    listStyleType: "none",
  },
  listItem: {
    paddingBottom: "0.5rem",
  },
  linkHref: {
    color: "#C0580E !important",
    textDecorationColor: "#C0580E !important",
  },
});

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Toolbar
          sx={{
            background: theme.white,
            borderBottom: 2,
            borderBottomColor: theme.lightGrey,
          }}
        >
          <img
            src={logoImg}
            alt="New Life Agency, Inc. The Surrogacy Insurance Experts logo"
            className={classes.appLogo}
          />
        </Toolbar>
      </Grid>

      <Box sx={{ paddingX: "1.5rem" }}>
        <Typography variant="h1" className={classes.h1}>
          NOTICE AND PRIVACY POLICY STATEMENT
        </Typography>

        <ul className={classes.orderList}>
          <li className={classes.listItem}>
            1) THE INSURER IS NOT SUBJECT TO THE FINANCIAL SOLVENCY REGULATION
            AND ENFORCEMENT THAT APPLY TO CALIFORNIA LICENSED INSURERS.
          </li>
          <li className={classes.listItem}>
            2) THE INSURER DOES NOT PARTICIPATE IN ANY OF THE INSURANCE
            GUARANTEE FUNDS CREATED BY CALIFORNIA LAW. THEREFORE, THESE FUNDS
            WILL NOT PAY YOUR CLAIMS OR PROTECT YOUR ASSETS IF THE INSURER
            BECOMES INSOLVENT AND IS UNABLE TO MAKE PAYMENTS AS PROMISED.
          </li>
          <li className={classes.listItem}>
            3) CALIFORNIA MAINTAINS A LIST OF ELIGIBLE SURPLUS LINE INSURERS
            APPROVED BY THE INSURANCE COMMISSIONER. ASK YOUR AGENT OR BROKER IF
            THE INSURER IS ON THAT LIST, OR VIEW THAT LIST AT THE INTERNET
            WEBSITE OF THE CALIFORNIA DEPARTMENT OF INSURANCE:{" "}
            <Link
              href="http://www.insurance.ca.gov"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.linkHref}
            >
              www.insurance.ca.gov
            </Link>
          </li>
          <li className={classes.listItem}>
            4) FOR ADDITIONAL INFORMATION ABOUT THE INSURER, YOU SHOULD ASK
            QUESTIONS OF YOUR INSURANCE AGENT, BROKER, OR “SURPLUS LINE” BROKER
            OR CONTACT THE CALIFORNIA DEPARTMENT OF INSURANCE, AT THE FOLLOWING
            TOLL-FREE TELEPHONE NUMBER:{" "}
            <Link href="tel:1-800-928-4357" className={classes.linkHref}>
              1-800-928-4357
            </Link>
          </li>
          <li className={classes.listItem}>
            5) IF YOU, AS THE APPLICANT, REQUIRE THAT THE INSURANCE POLICY YOU
            HAVE PURCHASED BE BOUND IMMEDIATELY, EITHER BECAUSE EXISTING
            COVERAGE WAS GOING TO LAPSE WITHIN TWO BUSINESS DAYS OR BECAUSE YOU
            WERE REQUIRED TO HAVE COVERAGE WITHIN TWO BUSINESS DAYS, AND YOU DID
            NOT RECEIVE THIS DISCLOSURE FORM AND A REQUEST FOR YOUR SIGNATURE
            UNTIL AFTER COVERAGE BECAME EFFECTIVE, YOU HAVE THE RIGHT TO CANCEL
            THIS POLICY WITHIN FIVE DAYS OF RECEIVING THIS DISCLOSURE. IF YOU
            CANCEL COVERAGE, THE PREMIUM WILL BE PRORATED AND ANY BROKER’S FEE
            CHARGED FOR THIS INSURANCE WILL BE RETURNED TO YOU.
          </li>
        </ul>

        <Typography variant="h2" className={classes.h2}>
          PRIVACY POLICY STATEMENT
        </Typography>

        <Typography sx={{ marginBottom: "1rem" }}>
          New Life Agency, Inc. Cover holder at Lloyd’s New Life Agency, Inc.
          Cover holder at Lloyd’s wants you to understand how we protect the
          confidentiality of non-public personal information we collect about
          you.
        </Typography>

        <Typography variant="h3" className={classes.h3}>
          Information We Collect:
        </Typography>

        <ul className={classes.orderList}>
          <li className={classes.listItem}>
            a) Information we receive from you on applications and other forms;
          </li>
          <li className={classes.listItem}>
            b) Information about your transactions with our affiliates, others
            or us;
          </li>
          <li className={classes.listItem}>
            c) Information we receive from consumer-reporting agencies; and
          </li>
          <li className={classes.listItem}>
            d) Financial and medical sources.
          </li>
        </ul>

        <Typography variant="h3" className={classes.h3}>
          Information We Disclose:
        </Typography>
        <Typography>
          We do not disclose any non-public information about you to anyone
          except as necessary in order to provide our products or services to
          you or otherwise as we are required or permitted by law (e.g.
          subpoena, fraud, investigation, regulatory reporting, etc.).
        </Typography>

        <Typography variant="h3" className={classes.h3}>
          Right to access or correct your personal information:
        </Typography>
        <Typography>
          You have a right to request access to or correction of your personal
          information in our possession.
        </Typography>

        <Typography variant="h3" className={classes.h3}>
          Confidentiality and Security:
        </Typography>
        <Typography>
          We restrict access to non-public personal information about you to our
          employees, our affiliates' employees or others who need to know that
          information to service your account. We maintain physical, electronic,
          and procedural safeguards to protect your nonpublic personal
          information.
        </Typography>

        <Typography variant="h3" className={classes.h3}>
          Contacting Us:
        </Typography>
        <Typography>
          If you have any further questions about this privacy statement or
          would like to learn more about how we protect your privacy, please
          contact the insurance producer who handled this case, or our offices
          at:
        </Typography>
        <Typography>New Life Agency, Inc.</Typography>
        <Typography>P.O. Box 800609</Typography>
        <Typography>Santa Clarita, CA 91380</Typography>
        <Typography>Tel (877) 952-5433 (LIFE) Fax (877) 952-5589</Typography>
      </Box>
      <Footer />
    </ThemeProvider>
  );
};

export { PrivacyPolicy };
