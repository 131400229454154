/*
 * File picker Component
 */

import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  FormLabel,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { theme } from "../styles/theme";

const FilePicker = ({
  disabled = false,
  files,
  setIsValidFile,
  openPdfInNewTab,
  setFileToBeDeleted,
  setShowDeleteModal,
  handleFileUpload,
  isDocUploading,
  helperText,
  errorMsg = "",
}) => {
  const handleFileSelect = (event) => {
    const selectedFiles = event.target.files;
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const maxFilesAllowed = 2;

    const newFiles = Array.from(selectedFiles).filter((file) => {
      if (allowedTypes.includes(file.type)) {
        setIsValidFile(true);
        return allowedTypes.includes(file.type);
      } else {
        setIsValidFile(false);
        return allowedTypes.includes(file.type);
      }
    });

    if (files.length + newFiles.length <= maxFilesAllowed) {
      handleFileUpload(newFiles);
    } else {
      // console.log("You can only upload up to 2 files.");
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          flexDirection: { xs: "column", sm: "row", md: "row" },
        }}
      >
        <Box sx={{ marginRight: "1rem" }}>
          <Button
            id="btn-upload-file"
            variant="outlined"
            size="large"
            disableElevation
            disabled={disabled || files?.length === 2}
            sx={{
              color: theme.mistBlue,
              border: `2px ${theme.mistBlue} solid`,
              borderRadius: "25px",
              "&:hover": { borderWidth: "2px", borderColor: theme.mistBlue },
              "&.Mui-disabled": {
                background: theme.lightGrey,
                color: theme.grey,
                borderColor: theme.grey,
                borderWidth: "2px",
              },
              marginBottom: "0.5rem",
            }}
            onClick={(event) => {
              event.preventDefault();
              document.getElementById("contained-button-file").click();
            }}
            aria-labelledby="lbl-upload-file"
            aria-describedby={
              "helper-text-upload-file" + (errorMsg === "" ? "" : " error-doc")
            }
          >
            {isDocUploading ? (
              <CircularProgress
                size={24}
                sx={{ color: theme.mistBlue, verticalAlign: "bottom" }}
              />
            ) : (
              <>
                Upload
                <span className="hidden-visually"> file</span>
              </>
            )}
          </Button>
          <FormLabel htmlFor="contained-button-file">
            <span className="hidden-visually">Upload file</span>
          </FormLabel>
          <input
            onChange={handleFileSelect}
            multiple
            accept=".pdf,.docx,.doc"
            type="file"
            id="contained-button-file"
            disabled={disabled || files?.length === 2 || isDocUploading}
          />
          {files.length === 0 && (
            <p className="hidden-visually">No files selected yet.</p>
          )}
        </Box>
        {files?.map((file, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              color: "#0A7691",
            }}
          >
            <Box sx={{ marginBottom: "0.5rem" }}>
              <Button
                variant="text"
                size="large"
                sx={{
                  color: theme.mistBlue,
                  letterSpacing: 0,
                  fontSize: "0.9rem",
                }}
                onClick={() => openPdfInNewTab(file)}
                autoFocus={index === 0 ? true : false} // focus on first file added
              >
                {file?.name}{" "}
                <span className="hidden-visually">&nbsp;view file</span>
              </Button>
              <IconButton
                aria-label={"Delete " + file?.name}
                size="small"
                sx={{ color: theme.grey }}
                onClick={() => {
                  setFileToBeDeleted(index);
                  setShowDeleteModal(true);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </div>
        ))}
      </Box>
      {!!helperText && (
        <FormHelperText
          sx={{
            marginLeft: 0,
            fontStyle: "italic",
            fontWeight: 600,
            letterSpacing: 0,
            marginTop: "1rem",
          }}
          id="helper-text-upload-file"
        >
          {helperText}
        </FormHelperText>
      )}
      {!!errorMsg && (
        <FormHelperText error sx={{ marginLeft: 0 }} id={"error-doc"}>
          {errorMsg}
        </FormHelperText>
      )}
    </>
  );
};

export { FilePicker };
