/*
 * Optional Riders Table
 */

import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { ClinicApis } from "../../../api";
import { TableData } from "./TableData";
import { TableHeader } from "./TableHeader";
import { AllRoutes } from "../../../routes";
import { GoBack } from "../../../components";
import { INSURANCE_TYPES } from "../../../constants";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";

const OptionalRiders = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataList, setDataList] = useState([]);
  const [listData, setListData] = useState();
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [searchButton, setSearchButton] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [hasRecords, setHasRecords] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [buttonLoading, setButtonLoading] = useState("");
  const [selectedInsurance, setSelectedInsurance] = useState(
    INSURANCE_TYPES.ivf
  );
  const [selectedCoverage, setSelectedCoverage] = useState(null);
  const [clinicStatus, setClinicStatus] = useState(null);

  const propertyMapping = {
    insurance_type: setSelectedInsurance,
    coverage_type: setSelectedCoverage,
    name: setSearchText,
  };

  useEffect(() => {
    setSearchParams((searchParams) => {
      if (!searchParams.has("page")) {
        searchParams.set("page", 1);
      }
      return searchParams;
    });

    const params = [];
    searchParams.forEach((value, key) => {
      params.push([key, value]);
    });
    const obj = params.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    Object.keys(propertyMapping)?.forEach((key) => {
      if (obj?.hasOwnProperty(key)) {
        propertyMapping?.[key]?.(obj?.[key]);
      }
    });

    setPage(Number(obj["page"]));

    const checkRecords = async () => {
      const resp = await ClinicApis.getClinicsList({
        limit: true,
        insurance_type: INSURANCE_TYPES.ivf,
      });
      setHasRecords(resp?.data?.results?.length > 0);
    };
    checkRecords();
  }, []);

  useEffect(() => {
    if (hasRecords && page && searchButton) {
      getClinics();
    }
  }, [hasRecords, page, searchButton]); // Removed sortKey, sortOrder to avoid sorting from API

  // Get all change/cancel records
  const getClinics = async () => {
    setDataLoading(true);
    let params = {
      sortOrder: "", // Remove "" to sort from API
      sortKey: "", // Remove "" to sort from API
      page,
      insurance_type: selectedInsurance,
      coverage_type: selectedCoverage,
      name: searchText,
      limit: true,
    };
    const resp = await ClinicApis.getClinicsList(params);
    if (resp) {
      setButtonLoading("");
      setDataLoading(false);
      setSearchButton(false);
      setLoading(false);
    }
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
  };

  return (
    <AdminPageLayout>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <GoBack navigateURL={AllRoutes.WizardSettings.route} />
        <Typography variant="h1" sx={{ margin: "0" }}>
          Optional Riders
        </Typography>
      </Box>

      <TableHeader
        searchText={searchText}
        setSearchText={setSearchText}
        setPage={setPage}
        setSearchButton={setSearchButton}
        buttonLoading={buttonLoading}
        setButtonLoading={setButtonLoading}
        selectedInsurance={selectedInsurance}
        setSelectedInsurance={setSelectedInsurance}
        selectedCoverage={selectedCoverage}
        setSelectedCoverage={setSelectedCoverage}
        setSearchParams={setSearchParams}
      />

      <TableData
        caption="Clinics"
        hasRecords={hasRecords}
        dataList={dataList}
        setDataList={setDataList}
        listData={listData}
        setSearchText={setSearchText}
        setSortKey={setSortKey}
        setSortOrder={setSortOrder}
        page={page}
        setPage={setPage}
        loading={loading}
        dataLoading={dataLoading}
        setSearchButton={setSearchButton}
        clinicStatus={clinicStatus}
        setClinicStatus={setClinicStatus}
        setSearchParams={setSearchParams}
      />
    </AdminPageLayout>
  );
};

export { OptionalRiders };
