/*
 * SAD   U S E R   TABLE CONTENT
 */

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionIcon from "@mui/icons-material/Description";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ReactTable from "../../../../React Table";
import {
  CommonModal,
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderChip,
  RenderDocumentIcon,
  RenderTableHeader,
} from "../../../../components";
import { AllRoutes } from "../../../../routes";
import { AttachmentApis, PaymentApis, SadApis } from "../../../../api";
import {
  CoverageWithCovid,
  PaymentTypes,
  PolicyDocTypes,
  PolicyTypes,
  RequestTypes,
} from "../../constants";
import {
  comma_separated,
  downloadBlobAsFile,
  formatString,
  formattedMDYDate,
  isApplication_IdVisible,
  isInvoiceVisible,
} from "../../../../utils/CommonUtils";
import {
  setSadBeneficiaries,
  setSadSummary,
} from "../../../../redux/slices/globalSlice";
import { INSURANCE_TYPES } from "../../../../constants";

const { paid } = PaymentTypes;
const { canceled, cleared, draft, expired, underReview } = PolicyTypes;
const { payNow, cancelRequest, changeRequest, downloadAllDocuments } =
  RequestTypes;

const TableData = ({ caption }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [hasRecords, setHasRecords] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [listData, setListData] = useState();
  const [sortKey, setSortKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState({});
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [documentLink, setDocumentLink] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [hiddenTitle, setHiddenTitle] = useState("");

  useEffect(() => {
    !!hasRecords && sadDataList();
  }, [hasRecords, page, advancedSearch]); // Removed sortKey, sortOrder to avoid sorting from API

  useEffect(() => {
    const checkRecords = async () => {
      const resp = await SadApis.getAllPolicies();
      setHasRecords(resp?.data?.results?.length > 0);
      setLoading(resp?.data?.results?.length > 0);
    };
    checkRecords();
  }, []);

  // Generate ID to create new SAD application
  const createSADApplicationId = async () => {
    dispatch(setSadSummary({}));
    dispatch(setSadBeneficiaries([]));
    navigate(`${AllRoutes.SadForm.route}`);
  };

  // Get SAD records
  const sadDataList = async () => {
    setDataLoading(true);
    const params = {
      sortOrder: "", // Remove "" to sort from API
      sortKey: "", // Remove "" to sort from API
      page,
      advancedSearch,
    };
    const resp = await SadApis.getAllPolicies(params);
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
    if (resp) {
      setDataLoading(false);
      setLoading(false);
    }
  };

  const createPayment = async (invoice_id) => {
    setActionLoading(true);
    try {
      const resp = await PaymentApis.createPayment({
        invoice_id,
        insurance_type: INSURANCE_TYPES.sad,
      });
      !!resp && setActionLoading(false);
      !!resp?.success && (window.location = resp?.data?.url);
    } catch (error) {
      console.log("createPayment", error);
    }
  };

  const getPolicyDocs = async (application_id, policy_doc_id) => {
    setShowDocumentModal(true);
    setHiddenTitle(formatString(policy_doc_id));

    const resp = await AttachmentApis.getPolicyDoc({
      application_id,
      policy_doc_id,
    });

    if (resp) {
      setDocumentLink(resp);
    } else {
      setShowDocumentModal(false);
    }
  };

  const downloadDocs = async (application_id, policy_doc_id) => {
    setActionLoading(true);
    const resp = await AttachmentApis.downloadDocument({
      application_id,
      policy_doc_id,
      download: 1,
    });

    if (resp?.success && resp?.data) {
      downloadBlobAsFile(resp.data, `${resp?.filename || "all_docs.zip"}`);
      setActionLoading(false);
    } else {
      setActionLoading(false);
      console.error("Failed to download policy docs");
    }
  };

  const openActionDropDown = (e, rowData) => {
    const { id, alerts, can_extend, extension_invoice, invoice, status } =
      rowData || {};
    const { payment_status } = invoice || {};

    let fixedActions = [];

    if (status === draft) {
      fixedActions?.push({
        text: "Continue",
        route: () => {
          dispatch(setSadSummary({}));
          dispatch(setSadBeneficiaries([]));
          navigate(`${AllRoutes.SadForm.route}/${id}`);
        },
      });
    } else {
      if (
        !alerts?.length &&
        invoice?.id &&
        payment_status !== paid &&
        (status === underReview ||
          status === cleared ||
          (status === expired && payment_status !== paid))
      ) {
        fixedActions?.push({
          text: payNow,
          route: async () => await createPayment(invoice?.id),
        });
      }
      if (extension_invoice?.id && extension_invoice?.payment_status !== paid) {
        fixedActions?.push({
          text: "Pay for Policy Extension",
          route: () =>
            navigate(`${AllRoutes.PolicyExtensionSummary.route}/${id}`),
        });
      }
      if (
        status !== canceled &&
        (status !== expired || payment_status !== paid)
      ) {
        fixedActions?.push({
          text: changeRequest,
          route: () => {
            dispatch(setSadSummary({}));
            navigate(`${AllRoutes.SadChangeReqPage.route}/${id}`);
          },
        });
        fixedActions?.push({
          text: cancelRequest,
          route: () => {
            dispatch(setSadSummary({}));
            navigate(`${AllRoutes.SadCancelReqPage.route}/${id}`);
          },
        });
      }
      if (can_extend && !extension_invoice?.id) {
        fixedActions?.push({
          text: "Extend Policy",
          route: () => navigate(`${AllRoutes.PolicyExtension.route}/${id}`),
        });
      }

      fixedActions?.push({
        text: downloadAllDocuments,
        route: async () => await downloadDocs(id, PolicyDocTypes.all),
      });
    }

    setClickable(fixedActions);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const tableColumns = [
    {
      id: "Actions",
      header: "Action",
      cell: ({ row }) => (
        <IconButton
          onClick={(e) => openActionDropDown(e, row.original)}
          aria-label={"More actions of " + row.original?.application_number}
        >
          <MoreHorizIcon />
        </IconButton>
      ),
    },
    {
      id: "Policy Status",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => setSortKey("status")}
          >
            Policy Status
          </Button>
        );
      },
      accessorKey: "status",
      cell: (info) => {
        const isSubmitted = !info.row.original?.alerts?.length;
        const status = info.getValue();
        return (
          <RenderChip
            label={
              status === underReview
                ? isSubmitted
                  ? "Submitted"
                  : "Under Review"
                : undefined
            }
            status={status}
            color={
              status === underReview && isSubmitted ? "#0B756E" : undefined
            }
            backgroundColor={
              status === underReview && isSubmitted ? "#0E8C8426" : undefined
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Policy Number",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              setSortKey("application_number");
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Policy Number
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "application_number",
      cell: (info) => (
        <Typography
          sx={{
            color: "#0A7691",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {info.getValue()}
        </Typography>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Submitted By",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Submitted By"
          headerKey="created_by_first_name"
        />
      ),
      accessorKey: "created_by_first_name",
      cell: (info) => {
        const { created_by_first_name, created_by_last_name } =
          info?.row?.original || {};
        return (
          <RenderCellInfo
            text={`${created_by_first_name || ""} ${
              created_by_last_name || ""
            }`}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Coverage Type",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              setSortKey("coverage_type");
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Coverage Type
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "coverage_type",
      cell: (info) => {
        if (info.getValue() == CoverageWithCovid) {
          return (
            <Typography
              sx={{
                color: "black",
                whiteSpace: "nowrap",
                fontSize: "1rem",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              W/ COVID 19
            </Typography>
          );
        } else if (info.getValue() === "H") {
          return (
            <Typography
              sx={{
                color: "black",
                whiteSpace: "nowrap",
                fontSize: "1rem",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              W/Out COVID 19
            </Typography>
          );
        }
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Term Length",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              setSortKey("term_length");
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Term Length
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "term_length",
      cell: (info) => (
        <Typography
          sx={{
            color: "black",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {info?.getValue() ? info.getValue() + " Months" : ""}
        </Typography>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Limit of Indemnity for Surrogate",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              setSortKey("indemnity_surrogates_beneficiary");
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Limit of Indemnity for Surrogate
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "indemnity_surrogates_beneficiary",
      cell: (info) => (
        <Typography
          sx={{
            color: "black",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {info?.getValue() ? "$" + comma_separated(info.getValue()) : ""}
        </Typography>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Limit of Indemnity for IP",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              setSortKey("indemnity_intended_parents");
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Limit of Indemnity for IP
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "indemnity_intended_parents",
      cell: (info) => (
        <Typography
          sx={{
            color: "black",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {info.getValue() !== null
            ? `$${comma_separated(info.getValue())}`
            : ""}
        </Typography>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Application Date",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Application Date
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "submitted_on",
      cell: (info) => (
        <Typography
          sx={{
            color: "black",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        </Typography>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Effective Date",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Effective Date
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "effective_from",
      cell: (info) => (
        <Typography
          sx={{
            color: "black",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        </Typography>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Expiration Date",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Expiration Date
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "effective_till",
      cell: (info) => (
        <Typography
          sx={{
            color: "black",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        </Typography>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Date",
      accessorKey: "payment_date",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              setSortKey("payment_date");
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Payment Date
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      cell: (info) => {
        const { invoice } = info?.row?.original;
        const paymentDate = invoice?.payment_date;
        return (
          <RenderCellInfo
            text={paymentDate ? formattedMDYDate(paymentDate) : ""}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Total Payment",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Total Payment
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "Total Payment",
      cell: (info) => {
        const { invoice, alerts } = info.row.original ?? {};
        const canPayment = invoice?.id && !alerts?.length;
        const paymentStatus = invoice?.payment_status;
        const isPaid = Boolean(paymentStatus === paid);
        return (
          <Typography
            sx={{
              color: "black",
              whiteSpace: "nowrap",
              fontSize: "1rem",
              fontWeight: 500,
              display: canPayment ? "flex" : "none",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            ${isPaid ? invoice?.total : invoice?.total_without_card_charges}
          </Typography>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Status",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Payment Status
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "payment_status",
      cell: (info) => {
        const { invoice, alerts } = info.row.original ?? {};
        const canPayment = invoice?.id && !alerts?.length;
        const paymentStatus = invoice?.payment_status;
        const isPaid = Boolean(paymentStatus === paid);
        const Icon = isPaid ? CheckCircleIcon : AccessTimeFilledIcon;
        const color = isPaid ? "#0D847D" : "#C0580E";

        return (
          <Box
            display={canPayment ? "flex" : "none"}
            alignItems="center"
            justifyContent="center"
          >
            <Icon sx={{ fontSize: "1rem", color: color }} />
            <Typography
              sx={{
                fontSize: "1rem",
                color: color,
                marginLeft: "0.3rem",
              }}
            >
              {isPaid ? "Paid" : "Pending"}
            </Typography>
          </Box>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Documents",
      header: "Documents",
      accessorKey: "Documents",
      cell: (info) => {
        const { alerts, certificate_issued_at, status } =
          info.row.original ?? {};

        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {isApplication_IdVisible(status) && (
                    <RenderDocumentIcon
                      tooltip="Application"
                      policy_doc_id={PolicyDocTypes.application}
                      icon={DescriptionIcon}
                      info={info}
                      getPolicyDocs={getPolicyDocs}
                    />
                  )}
                  {!!certificate_issued_at && (
                    <RenderDocumentIcon
                      tooltip="Certificate"
                      policy_doc_id={PolicyDocTypes.certificate}
                      icon={PictureAsPdfIcon}
                      info={info}
                      getPolicyDocs={getPolicyDocs}
                    />
                  )}
                  {isInvoiceVisible(alerts, status) && (
                    <RenderDocumentIcon
                      tooltip="Invoice"
                      policy_doc_id={PolicyDocTypes.invoice}
                      icon={RequestPageIcon}
                      info={info}
                      getPolicyDocs={getPolicyDocs}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
      },
      footer: (props) => props.column.id,
    },
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : hasRecords ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            isUserModule={true}
            page={page}
            setPage={setPage}
            listData={listData}
            onSearch={(e) => {
              setAdvancedSearch({ ["q"]: e });
              setPage(1);
            }}
            caption={caption}
            dataLoading={dataLoading}
            inputTooltip="Search by Application Number, Agency Name, Intended Parent Name, Surrogate Name, Applicant Name."
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
            selectedRow={selectedRow}
          />
        </Box>
      ) : (
        <NoRecords onPress={createSADApplicationId} />
      )}

      {actionLoading && <GlobalLoader loading={actionLoading} />}

      {/* Documents modal */}
      <CommonModal
        open={showDocumentModal}
        onCancel={() => {
          setShowDocumentModal(false);
          setDocumentLink("");
          setHiddenTitle("");
        }}
        cancelButtonLabel="Close"
        maxWidth="md"
        visuallyHiddenTitle={hiddenTitle}
        fullWidth
      >
        {!documentLink ? (
          <Box
            sx={{
              width: "100%",
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <iframe
              src={documentLink}
              width="100%"
              height="500"
              style={{ display: !documentLink ? "none" : "block" }}
            />
          </>
        )}
      </CommonModal>
    </>
  );
};

export { TableData };
