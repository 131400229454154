/*
 * Common Dropdown
 */

import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import { Tooltip } from "./Tooltip";

const Dropdown = ({
  helperText,
  label,
  id = label.replace(/\s+/g, "_").toLowerCase(),
  options = [],
  selectValue,
  selectLabel,
  value,
  onChange,
  onBlur,
  isError,
  errorMsg,
  required = true,
  tooltip,
  margin = "normal",
  register,
  registerChange,
  ...rest
}) => {
  return (
    <FormControl fullWidth margin={margin}>
      <InputLabel
        id={"lbl-" + id}
        shrink
        sx={{
          backgroundColor: "#FFFFFF",
          color: "black",
          paddingX: "0.3rem",
        }}
      >
        {label} {!!required && <span className="text-danger">*&nbsp;</span>}
        {!!tooltip && <Tooltip tooltip={tooltip} />}
      </InputLabel>
      <Select
        id={id}
        name={id}
        label={label}
        value={value}
        {...(onChange && { onChange })}
        {...(register && {
          ...register(id, {
            onChange: (e) => registerChange && registerChange(e, id),
          }),
        })}
        onBlur={onBlur}
        displayEmpty={true}
        aria-describedby={
          (isError ? "error-" + id : "") +
          (isError && helperText ? " " : "") +
          (helperText ? "helper-text-" + id : "")
        }
        SelectDisplayProps={{
          "aria-labelledby": `${"lbl-" + id} ${id}`,
          "aria-required": required ? "true" : undefined,
        }}
        sx={{ "& .MuiIconButton-root": { padding: 0 } }}
        defaultValue={value}
        {...rest}
      >
        <MenuItem disabled value={null}>
          Select
        </MenuItem>
        {!!options && options?.length
          ? options?.map((option, index) => (
              <MenuItem
                key={index}
                value={option[selectValue] ?? option.value}
                sx={{ fontSize: "0.8rem" }}
              >
                {option[selectLabel] ?? option.label}
                {!!option?.description && (
                  <Tooltip
                    tooltip={option?.description}
                    iconStyle={{ marginLeft: "0.3rem", marginBottom: "0.2rem" }}
                  />
                )}
              </MenuItem>
            ))
          : null}
      </Select>
      {isError && !value && (
        <FormHelperText
          error
          sx={{
            marginLeft: 0,
          }}
          id={"error-" + id}
        >
          {errorMsg}
        </FormHelperText>
      )}
      {!!helperText && (
        <FormHelperText
          sx={{
            marginLeft: 0,
            fontStyle: "italic",
            fontWeight: 600,
            fontSize: "0.77rem",
            letterSpacing: 0,
            marginBottom: "0.5rem",
          }}
          id={"helper-text-" + id}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export { Dropdown };
