/*
 * A D M I N --- M O D U L E-  C O U N T R Y   F E E   T A B L E   D A T A
 */

import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { AllRoutes } from "../../../routes";
import { CountriesApis } from "../../../api";
import ReactTable from "../../../React Table";
import {
  GlobalLoader,
  NoRecords,
  RenderTableHeader,
  RenderCellInfo,
  RenderActionMenu,
} from "../../../components";

const TableData = ({ caption }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [hasRecords, setHasRecords] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] = useState();
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [advancedSearch, setAdvancedSearch] = useState({});

  useEffect(() => {
    const checkRecords = async () => {
      const resp = await CountriesApis.getCountriesList();
      setHasRecords(resp?.data?.results?.length > 0);
      setLoading(resp?.data?.results?.length > 0);
    };
    checkRecords();
  }, []);

  useEffect(() => {
    setSearchParams((searchParams) => {
      if (!searchParams.has("page")) {
        searchParams.set("page", 1);
      }
      return searchParams;
    });

    const params = [];
    searchParams.forEach((value, key) => {
      params.push([key, value]);
    });
    const obj = params.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    if (obj?.hasOwnProperty("q")) {
      setSearchText(obj["q"]);
    }

    setPage(Number(obj["page"]));
    const { page, ...rest } = obj;
    setAdvancedSearch(rest);
  }, []);

  useEffect(() => {
    !!hasRecords && countriesList();
  }, [hasRecords, page, advancedSearch]); // Removed sortKey, sortOrder to avoid sorting from API

  // Get SAD records
  const countriesList = async () => {
    setDataLoading(true);
    const params = {
      sortOrder: "", // Remove "" to sort from API
      sortKey: "country", // Remove "" to sort from API
      page,
      advancedSearch,
      limit: true,
    };
    const resp = await CountriesApis.getCountriesList(params);
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
    if (resp) {
      setDataLoading(false);
      setLoading(false);
    }
  };

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  const onEnterSearchText = (e) => {
    setSearchParams({ ["page"]: 1, ["q"]: e });
    setAdvancedSearch({ ["q"]: e });
    setPage(1);
  };

  const openActionDropDown = (e, rowData) => {
    setClickable([
      {
        text: "Edit Country",
        route: () =>
          navigate(`${AllRoutes.EditCountryFee.route}/${rowData?.id}`),
      },
      {
        text: "View States",
        route: () => navigate(`${AllRoutes.StateList.route}/${rowData?.id}`),
      },
    ]);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const tableColumns = [
    {
      id: "Actions",
      header: "Action",
      cell: ({ row }) => (
        <Box sx={{ textAlign: "center" }}>
          <IconButton
            onClick={(e) => openActionDropDown(e, row.original)}
            aria-label={"More actions for " + row.original?.name}
          >
            <MoreHorizIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      id: "Country",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Country"
          headerKey="country"
        />
      ),
      accessorKey: "country",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Country Code",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Country Code"
          headerKey="country_code"
        />
      ),
      accessorKey: "country_code",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
  ];

  if (loading) return <GlobalLoader loading={loading} />;

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : hasRecords ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            isUserModule={true}
            page={page}
            setPage={handlePageChange}
            listData={listData}
            searchText={searchText}
            setSearchText={setSearchText}
            onSearch={onEnterSearchText}
            caption={caption}
            dataLoading={dataLoading}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
          />
        </Box>
      ) : (
        <NoRecords />
      )}
    </>
  );
};

export { TableData };
