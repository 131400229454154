/*
 * IVF-cycle form
 */

import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";

import { IVFApplicationDetails } from "./ApplicationDetails";
import { IVFTermsAndConditions } from "./TermsAndConditions";
import { IVFUserAgreement } from "./UserAgreement";
import { IVFSummary } from "./Summary";
import { IVFPayment } from "./Payment";
import {
  Stepper,
  MandatoryStatement,
  GlobalLoader,
} from "../../../../components";
import { AdminPageLayout } from "../../../../layouts/admin-layout/AdminPageLayout";

const steps = [
  "Application Details",
  "Terms & Conditions",
  "User Agreement",
  "Summary",
  "Payment",
];

const IvfForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [parentId, setparentId] = useState(null);
  const [applicationsId, setApplicationsId] = useState(null);
  const [applicationsLength, setApplicationsLength] = useState(0);

  useEffect(() => {
    window?.scrollTo?.(0, 0);
  }, [activeStep]);

  return (
    <AdminPageLayout>
      <Typography variant="h1">IVF Cycle Complications Insurance</Typography>

      {loading && <GlobalLoader loading={loading} />}

      {(activeStep === 0 || activeStep === 2) && <MandatoryStatement />}

      {/* Step indicator */}
      <Stepper
        activeStep={activeStep}
        marginY="2rem"
        steps={steps}
        stepperWidth="100%"
      />

      {/* FORM STEP WISE CONTENT */}
      {activeStep === 0 && (
        <IVFApplicationDetails
          setLoading={setLoading}
          setActiveStep={setActiveStep}
          setparentId={setparentId}
          parentId={parentId}
          applicationsId={applicationsId}
          setApplicationsId={setApplicationsId}
          setApplicationsLength={setApplicationsLength}
        />
      )}

      {activeStep === 1 && (
        <IVFTermsAndConditions
          setActiveStep={setActiveStep}
          parentId={parentId}
        />
      )}

      {activeStep === 2 && (
        <IVFUserAgreement setActiveStep={setActiveStep} parentId={parentId} />
      )}

      {activeStep === 3 && (
        <IVFSummary
          setActiveStep={setActiveStep}
          parentId={parentId}
          applicationsLength={applicationsLength}
        />
      )}

      {activeStep === 4 && (
        <IVFPayment
          setLoading={setLoading}
          parentId={parentId}
          applicationsLength={applicationsLength}
        />
      )}
    </AdminPageLayout>
  );
};

export { IvfForm };
